import { DBOHRate } from '@core/schemas/db/schema.db.common';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';

export const OHRateExample: OmitDBRecord<DBOHRate>[] = [
  {
    year: 1234,
    type: 2,
    description: 'Truvant',
    oh_rate_percent: 18.2,
    planning_oh_finished_goods_dkk: 0.395,
    planning_oh_prepacks_dkk: 0.128,
    disabled: false,
  },
];
