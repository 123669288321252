import { DBPackSource } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { PackSource } from '@core/types/types.packaging';

export const PackSourceExample: OmitDBRecord<DBPackSource>[] = [
  {
    year: 1234,
    source: PackSource.Duplo,
    description: 'abc',
  },
];
