import {
  DBPackingStandardPrepackRate,
  DBPackingStandardFinalPackRate,
} from '@core/schemas/db/schema.db.packaging';
import { Calculate_FetchQuery, CalculatorPackagingPackingStandardCost } from '../calculator.types';
import { Calculator, CalculationError, useCacheOrFetchQuery } from '../calculator';
import { PK } from '@core/types/types.pk';
import { Region } from '@core/schemas/schema.common';
import {
  ReportStatement,
  getCostReportStatement,
  getRateReportStatement,
  getRateReportStatementsBoth,
} from '../calculator.util.report';
import { PackagingSize } from '@core/schemas/interface/schema.interface';
import { PackSource } from '@core/types/types.packaging';
import { PACKING_KEY_ID_TO_TYPE } from '@core/const/const.PACKING_KEYS';

type Rates = {
  fmc1rate: number;
  fmc2rate: number;
};

type CalculatePackingStandardCostReturnValue = {
  fmc1_cost: number;
  fmc2_cost: number;
  report: ReportStatement[];
};

export async function calculate_PackingStandardCost(props: {
  input: {
    packingStandardCost: CalculatorPackagingPackingStandardCost;
    source: PackSource | undefined;
    packaging: PackagingSize | undefined;
    fmcYear: number;
    packingRegionEU: Region;
    packingRegionUS: Region;
    salesRegionEU: Region;
    salesRegionUS: Region;
  };
  cache?: {
    finalPackRates?: DBPackingStandardFinalPackRate[];
    prepackRates?: DBPackingStandardPrepackRate[];
    cost?: { fmc1_cost?: number; fmc2_cost?: number };
  };
  query?: { query?: Calculate_FetchQuery };
}): Promise<CalculatePackingStandardCostReturnValue> {
  const { input, cache, query } = props;

  if (cache?.cost) {
    if (cache.cost.fmc1_cost === undefined) {
      throw new CalculationError({
        region: 'EU',
        message: 'Cost was provided as cache but fmc1_cost was missing',
      });
    }

    if (cache.cost.fmc2_cost === undefined) {
      throw new CalculationError({
        region: 'US',
        message: 'Cost was provided as cache but fmc2_cost was missing',
      });
    }

    return {
      fmc1_cost: cache.cost.fmc1_cost,
      fmc2_cost: cache.cost.fmc2_cost,
      report: [
        getCostReportStatement({
          region: 'EU',
          cost: cache.cost.fmc1_cost,
          cache: true,
        }),
        getCostReportStatement({
          region: 'US',
          cost: cache.cost.fmc2_cost,
          cache: true,
        }),
      ],
    };
  }

  const finalPackRatesFilter =
    Calculator.Pack.Packing.StandardCost.Filter.PackingStandardFinalPackRate({
      year: input.fmcYear,
    });
  const finalPackRates = await useCacheOrFetchQuery({
    cachedItems: Calculator.DoFilter.Query(cache?.finalPackRates, finalPackRatesFilter),
    fetchQuery: query?.query,
    fetchQueryArgs: {
      pk: PK.PackingStandardFinalPackRate,
      query: finalPackRatesFilter,
    },
    dataTypeDescription: 'final pack rates',
  });

  const prepackRatesFilter = Calculator.Pack.Packing.StandardCost.Filter.PackingStandardPrepackRate(
    { year: input.fmcYear },
  );
  const prepackRates = await useCacheOrFetchQuery({
    cachedItems: Calculator.DoFilter.Query(cache?.prepackRates, prepackRatesFilter),
    fetchQuery: query?.query,
    fetchQueryArgs: {
      pk: PK.PackingStandardPrepackRate,
      query: prepackRatesFilter,
    },
    dataTypeDescription: 'prepack rates',
  });

  const packingType = PACKING_KEY_ID_TO_TYPE[input.packingStandardCost.packingKey];

  if ((packingType !== 'PREPACK' && packingType !== 'FINAL_PACK') || input.source === undefined) {
    throw new CalculationError({
      message: 'Packing type is neither prepack or final pack, or source was not provided',
    });
  }

  const { fmc1rate, fmc2rate, report } =
    packingType === 'PREPACK'
      ? getRatesForPrepack({
          packingStandardCost: input.packingStandardCost,
          year: input.fmcYear,
          packingStandardPrepackRates: prepackRates,
          source: input.source,
        })
      : getRatesForFinalPack({
          packingStandardCost: input.packingStandardCost,
          packaging: input.packaging,
          packingRegionEU: input.packingRegionEU,
          packingRegionUS: input.packingRegionUS,
          salesRegionEU: input.salesRegionEU,
          salesRegionUS: input.salesRegionUS,
          source: input.source,
          standardRates: finalPackRates,
          year: input.fmcYear,
        });

  const fmc1Cost = input.packingStandardCost.fmc1quantity * fmc1rate;
  const fmc2cost = input.packingStandardCost.fmc2quantity * fmc2rate;

  return {
    fmc1_cost: fmc1Cost,
    fmc2_cost: fmc2cost,
    report: report.concat(
      getCostReportStatement({
        region: 'EU',
        cost: fmc1Cost,
        quantity: input.packingStandardCost.fmc1quantity,
        rate: fmc1rate,
      }),
      getCostReportStatement({
        region: 'US',
        cost: fmc2cost,
        quantity: input.packingStandardCost.fmc2quantity,
        rate: fmc2rate,
      }),
    ),
  };
}

function getRatesForPrepack(props: {
  packingStandardPrepackRates: DBPackingStandardPrepackRate[] | undefined;
  packingStandardCost: CalculatorPackagingPackingStandardCost;
  source: PackSource;
  year: number;
}): Rates & { report: ReportStatement[] } {
  const { packingStandardCost, year, packingStandardPrepackRates, source } = props;

  const defaultFmc1 = packingStandardCost.fmc1quantity === 0 ? 0 : undefined;
  const defaultFmc2 = packingStandardCost.fmc2quantity === 0 ? 0 : undefined;

  if (defaultFmc1 !== undefined && defaultFmc2 !== undefined) {
    return {
      fmc1rate: defaultFmc1,
      fmc2rate: defaultFmc2,
      report: [
        getRateReportStatement({ region: 'EU', rateType: 'default_from_quantity' }),
        getRateReportStatement({ region: 'US', rateType: 'default_from_quantity' }),
      ],
    };
  }

  const rate: DBPackingStandardPrepackRate | undefined = packingStandardPrepackRates?.find(
    (rate) => rate.packing_key === packingStandardCost.packingKey && rate.source === source,
  );

  if (!rate) {
    throw new CalculationError({
      report: getRateReportStatement({
        rateType: 'Packing standard prepack rate',
        rate: 'Not found',
        rateIdentifier: [packingStandardCost, [{ Year: year }, 'packingKey', { Source: source }]],
      }),
    });
  }

  return {
    fmc1rate: rate.cost,
    fmc2rate: rate.cost,
    report: getRateReportStatementsBoth({
      rateType: 'Packing standard prepack rate',
      rate: rate.cost,
      rateIdentifier: [rate, ['year', 'packing_key', 'source']],
    }),
  };
}

function getRatesForFinalPack(props: {
  packaging: PackagingSize | undefined;
  standardRates: DBPackingStandardFinalPackRate[] | undefined;
  packingStandardCost: CalculatorPackagingPackingStandardCost;
  source: PackSource;
  year: number;
  packingRegionEU: Region;
  packingRegionUS: Region;
  salesRegionEU: Region;
  salesRegionUS: Region;
}): Rates & { report: ReportStatement[] } {
  const {
    packingStandardCost,
    packaging,
    source,
    standardRates,
    year,
    packingRegionEU,
    packingRegionUS,
    salesRegionEU,
    salesRegionUS,
  } = props;

  const defaultFmc1 = packingStandardCost.fmc1quantity === 0 ? 0 : undefined;
  const defaultFmc2 = packingStandardCost.fmc2quantity === 0 ? 0 : undefined;

  if (defaultFmc1 !== undefined && defaultFmc2 !== undefined) {
    return {
      fmc1rate: defaultFmc1,
      fmc2rate: defaultFmc2,
      report: getRateReportStatementsBoth({ rateType: 'default_from_quantity' }),
    };
  }

  if (!packaging) {
    throw new CalculationError({ message: 'Missing packaging' });
  }

  const {
    FMC1: fmc1rate,
    FMC2: fmc2rate,
    report,
  } = Calculator.Pack.Packing.StandardCost.FindFinalPackRates({
    packingKey: packingStandardCost.packingKey,
    packaging,
    standardRates,
    source,
    year,
    packingRegionEU,
    packingRegionUS,
    salesRegionEU,
    salesRegionUS,
  });

  if (!fmc1rate && defaultFmc1 === undefined) {
    throw new CalculationError({
      report: getRateReportStatement({
        region: 'EU',
        rateType: 'Packing standard final pack rate',
        rate: 'Not found',
        rateIdentifier: [
          packaging,
          [
            { Year: year, 'Packing key': packingStandardCost.packingKey },
            'packaging_facing',
            'packaging_size',
            { 'Packing region': packingRegionEU, 'Sales region': salesRegionEU, Source: source },
          ],
        ],
      }),
    });
  }

  if (!fmc2rate && defaultFmc2 === undefined) {
    throw new CalculationError({
      report: getRateReportStatement({
        region: 'US',
        rateType: 'Packing standard final pack rate',
        rate: 'Not found',
        rateIdentifier: [
          packaging,
          [
            { Year: year, 'Packing key': packingStandardCost.packingKey },
            'packaging_facing',
            'packaging_size',
            { 'Packing region': packingRegionUS, 'Sales region': salesRegionUS, Source: source },
          ],
        ],
      }),
    });
  }

  return {
    fmc1rate: (fmc1rate?.cost ?? defaultFmc1) as number,
    fmc2rate: (fmc2rate?.cost ?? defaultFmc2) as number,
    report,
  };
}
