import { PackagingPackingKey } from '@core/types/types.packaging';

export const PACKAGING_KEY_TO_DESCRIPTION: Record<PackagingPackingKey, string> = {
  BIPRE: 'Global BI prepack',
  BOX: 'Box price',
  FOIL: 'Foil for PP bags',
  OUT: 'Outer',
  OUTL: 'Outer labels',
  SEAL: 'Sealing labels',
};
