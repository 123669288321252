import { FileUploader, Icon, InlineNotification, Link, Stack, Switch, Text } from '@lego/klik-ui';
import { ArrowLongUp, CloudUpload } from '@lego/klik-ui/icons';
import React, { useContext, useState } from 'react';
import readXlsxFile, { Row } from 'read-excel-file';
import { CircleSpinner } from '@frontend/common/components/CircleSpinner';
import { PK } from '@core/types/types.pk';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { DataMaintenanceUploadEndpoint } from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';
import { PK_TO_SCHEMA } from '@core/schemas/db/schema.db.lib';
import { OMIT_DB_RECORD } from '@core/schemas/const/schema.const.OMIT_DB_RECORD';
import { z } from 'zod';
import { Cell } from 'read-excel-file/types';
import { parseErrorToString } from '../dataMaintenance.util';
import { showErrorToast } from '@frontend/common/lib/functions';
import { getErrorMessage } from '@core/util/util.getErrorMessage';
import { UserDataContext } from '@frontend/common/lib/contexts';

interface ExcelSheetUploadProps {
  selectedPk: PK;
  onSuccess(): void;
}

export function ExcelSheetUploader(props: ExcelSheetUploadProps) {
  const [isInDropArea, setIsInDropArea] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [shouldOverwrite, setShouldOverwrite] = useState(false);

  const { userData } = useContext(UserDataContext);

  function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(true);
  }

  function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(false);
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(true);
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(false);
    upload(e.dataTransfer.files[0]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function parse(rowsAsArray: Row[], schema: z.ZodObject<any>) {
    const rowsWithoutHeader = rowsAsArray.slice(1);

    const properties: Cell[] = rowsAsArray[0];

    const schemaProps = Object.keys(schema.omit(OMIT_DB_RECORD).shape);

    properties.forEach((prop) => {
      if (!schemaProps.find((k) => k === prop)) {
        throw new Error(`Property '${prop}' does not exist in this data type`);
      }
    });

    schemaProps.forEach((prop) => {
      if (!properties.find((p) => p === prop)) {
        throw new Error(`Property '${prop}' is missing as a header in sheet`);
      }
    });

    const rows = rowsWithoutHeader.map((values) => {
      const item: Record<string, unknown> = {};
      properties.forEach((p, i) => (item[p.toString()] = values[i]));
      return item;
    });

    const formattedRows = rows.map((row, i) => {
      const item = structuredClone(row);
      Object.keys(row).forEach((key) => {
        const shape = schema.shape[key];

        let type = shape._def.typeName;

        if (type === 'ZodOptional') {
          type = shape._def.innerType._def.typeName;
        }

        if (row[key] === null) {
          item[key] = undefined;
          return;
        }

        if (type === 'ZodNumber') {
          let v = `${row[key]}`;
          if (
            (userData?.comma_as_decimal_seperator && v.includes('.')) ||
            (!userData?.comma_as_decimal_seperator && v.includes(','))
          ) {
            throw new Error(
              `Row ${i + 1} contains invalid separator sign: '${
                userData?.comma_as_decimal_seperator ? '.' : ','
              }'`,
            );
          }
          v = v.replaceAll(',', '.');

          item[key] = Number(Number(v).toFixed(7));
        } else if (type === 'ZodBoolean') {
          item[key] = row[key] === 'true';
        }
      });
      return item;
    });

    return formattedRows;
  }

  async function upload(file: File) {
    setErrors([]);

    const schema = PK_TO_SCHEMA[props.selectedPk];
    const rows = await readXlsxFile(file);

    try {
      const parsedRows = parse(rows, schema);
      const results = parsedRows.map((row) => schema.omit(OMIT_DB_RECORD).safeParse(row));
      const errors: string[] = [];

      results.forEach((r, i) => {
        if (!r.success) {
          errors.push(...r.error.errors.map((e) => `Item ${i + 1}: ${parseErrorToString(e)}`));
        }
      });

      if (errors.length > 0) {
        setErrors(errors);
        return;
      }

      setIsUploading(true);
      const [err] = await callEndpoint({
        endpoint: DataMaintenanceUploadEndpoint,
        input: { pk: props.selectedPk, items: parsedRows, overwrite: shouldOverwrite },
        errorHandling: { header: 'Uploading items' },
      });
      setIsUploading(false);

      if (err) {
        return;
      }

      props.onSuccess();
    } catch (error) {
      showErrorToast('Uploading items', getErrorMessage(error));
    }
  }

  const renderContent = () => {
    if (isInDropArea) {
      return (
        <React.Fragment>
          <Icon as={ArrowLongUp} />
          <Text fontSize="md">Drop to upload</Text>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Icon as={CloudUpload} />
        <Text fontSize="md">Drag and drop a .xlsx file to upload items</Text>
        <Text fontSize="xs">
          or{' '}
          <Link
            isInline={true}
            onClick={() => {
              return true; // todo?
            }}
            size="xs"
          >
            browse
          </Link>{' '}
          to choose a .xlsx file
        </Text>
      </React.Fragment>
    );
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 36 }}>
      <div>
        <FileUploader
          isInDropArea={isInDropArea}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e)}
        >
          <input
            accept=".xlsx"
            value=""
            onChange={({ target: { files } }) => {
              if (files === null) {
                return;
              }
              upload(files[0]);
            }}
            type="file"
          />
          {renderContent()}
        </FileUploader>
        <div
          style={{
            marginTop: 16,
            display: 'flex',
            alignItems: 'center',
            columnGap: 16,
            justifyContent: 'center',
          }}
        >
          <div>Overwrite existing items with same key</div>
          <Switch
            aria-label="overwrite"
            isChecked={shouldOverwrite}
            onChange={() => setShouldOverwrite((v) => !v)}
          />
        </div>
      </div>
      <div style={{ display: 'grid', rowGap: 4, height: 'min-content' }}>
        {isUploading && (
          <div style={{ display: 'flex', alignItems: 'center', columnGap: 12 }}>
            <CircleSpinner />
            <div style={{ fontSize: 18 }}>Uploading...</div>
          </div>
        )}
        {errors.length > 0 && (
          <Stack>
            {errors.map((error) => (
              <InlineNotification variant="error" key={error}>
                <InlineNotification.Content style={{ padding: '8px 12px', gap: 0 }}>
                  <InlineNotification.Title>{error.split(':')[0]}</InlineNotification.Title>
                  <InlineNotification.Description>
                    {error.split(': ').slice(1).join(': ')}
                  </InlineNotification.Description>
                </InlineNotification.Content>
              </InlineNotification>
            ))}
          </Stack>
        )}
      </div>
    </div>
  );
}
