import { Spinner } from '@lego/klik-ui';

interface LoadingOverlayProps {
  loading?: boolean;
}

export function LoadingOverlay(props: LoadingOverlayProps) {
  return !props.loading ? null : (
    <div
      style={{
        position: 'absolute',
        background: 'rgba(255,255,255,0.7)',
        width: '100%',
        height: '100%',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner style={{ marginTop: -240 }} />
    </div>
  );
}
