import { QueryLandingPage } from '@frontend/reporting/components/QueryLandingPage';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  ReportCommonQueryInput,
  ReportCostingStatusGetEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.reporting';
import { CommonReportQueryPanel } from './CommonReportQueryPanel';

export function CostingStatusReportPage() {
  async function downloadReport(query: ReportCommonQueryInput) {
    const [err, data] = await callEndpoint({
      endpoint: ReportCostingStatusGetEndpoint,
      input: {
        productIds: query.productIds,
        productIdRanges: query.productIdRanges,
        year: query.year,
      },
      errorHandling: { header: 'Fetching report' },
    });

    if (err) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.open(data.url, '_blank').focus();
  }

  return (
    <QueryLandingPage title="Costing status">
      <CommonReportQueryPanel hideRevisionFilter onOk={downloadReport} />
    </QueryLandingPage>
  );
}
