import { DBProdHeadFC } from '@core/schemas/db/schema.db.prod';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { now } from '@core/util/util.timestamp';

export const ProdHeadFCExample: OmitDBRecord<DBProdHeadFC>[] = [
  {
    product_id: 1,
    revision: 1,
    year: 1,
    created_by: 'casper.aagaard.laursen@lego.com',
    created_timestamp: now(),
    changed_by: undefined,
    changed_timestamp: undefined,
    approved_by: undefined,
    approved_timestamp: undefined,
    rejected_by: undefined,
    rejected_timestamp: undefined,
    rejection_lock: false,
    errors: JSON.stringify([]),
  },
];
