import { CostComponent } from '@core/types/types.costComponent';
import { C } from '../calculator';
import { CalculatorCommonPPO, CostComponentCost, CostComponentFull } from '../calculator.types';
import { round2relaxed } from '../calculator.util';

export function calculate_CC_TotalCosts(props: {
  costs: Record<
    | CostComponent.LEGOElements
    | CostComponent.PurchasedElements
    | CostComponent.OverdoseElements
    | CostComponent.ElementsScrap
    | CostComponent.Packing
    | CostComponent.Packaging
    | CostComponent.Instructions
    | CostComponent.License
    | CostComponent.FinishedGoodOverhead
    | CostComponent.Others,
    CostComponentCost
  >;
  ppos: CalculatorCommonPPO;
}): CostComponentFull {
  const { ppos, costs } = props;

  const fmc1cost = C.SumList(Object.values(costs), 'fmc1cost');
  const fmc2cost = C.SumList(Object.values(costs), 'fmc2cost');

  return {
    fmc1cost: round2relaxed(fmc1cost),
    fmc2cost: round2relaxed(fmc2cost),
    fmc1ppo: round2relaxed(ppos?.FMC1),
    fmc2ppo: round2relaxed(ppos?.FMC2),
    fmc1variance: round2relaxed(C.Variance(ppos?.FMC1, fmc1cost)),
    fmc2variance: round2relaxed(C.Variance(ppos?.FMC2, fmc2cost)),
  };
}
