import { useCallback, useEffect, useMemo, useState } from 'react';
import { PackagingAddOnType } from './packaging.types';
import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';

export function usePackagingAddOns(payload: PackagingGetEndpointResponse['prodHead'] | undefined) {
  const [embossing, setEmbossing] = useState(false);
  const [uvLacquer, setUvLacquer] = useState(false);
  const [windowCutout, setWindowCutout] = useState(false);
  const [hotfoil, setHotfoil] = useState(false);
  const [partialUvLacquer, setPartialUvLacquer] = useState(false);
  const [windowFoil, setWindowFoil] = useState(false);

  useEffect(() => {
    if (!payload) {
      return;
    }

    setEmbossing(payload.embossing);
    setUvLacquer(payload.uv_lacquer);
    setWindowCutout(payload.window_cutout);
    setHotfoil(payload.hotfoil);
    setPartialUvLacquer(payload.partial_uv_lacquer);
    setWindowFoil(payload.window_foil);
  }, [payload]);

  const updateAddOn = useCallback((addOn: PackagingAddOnType, selected: boolean) => {
    if (addOn === 'embossing') {
      setEmbossing(selected);
    } else if (addOn === 'uvLacquer') {
      setUvLacquer(selected);
    } else if (addOn === 'windowCutout') {
      setWindowCutout(selected);
    } else if (addOn === 'hotfoil') {
      setHotfoil(selected);
    } else if (addOn === 'partialUvLacquer') {
      setPartialUvLacquer(selected);
    } else if (addOn === 'windowFoil') {
      setWindowFoil(selected);
    }
  }, []);

  const addOns = useMemo(
    () => ({ embossing, uvLacquer, windowCutout, hotfoil, partialUvLacquer, windowFoil }),
    [embossing, uvLacquer, windowCutout, hotfoil, partialUvLacquer, windowFoil],
  );

  return {
    addOns,
    updateAddOn,
  };
}
