import { WithRequired } from '@core/util/util.typing';
import { Calculator } from '../calculator';
import { CalculatorCommonPPO, CostComponentFull } from '../calculator.types';
import { round2relaxed } from '../calculator.util';

export function calculate_CC_Others_full(props: {
  cost: { fmc_eu_cost: number; fmc_us_cost: number };
  ppos: CalculatorCommonPPO;
}): WithRequired<CostComponentFull, 'fmc1cost' | 'fmc2cost'> {
  const { ppos, cost } = props;

  if (cost.fmc_eu_cost === 0 && cost.fmc_us_cost === 0) {
    return {
      fmc1cost: round2relaxed(ppos?.FMC1) || 0,
      fmc2cost: round2relaxed(ppos?.FMC2) || 0,
      fmc1ppo: round2relaxed(ppos?.FMC1),
      fmc2ppo: round2relaxed(ppos?.FMC2),
      fmc1variance: round2relaxed(ppos?.FMC1 === undefined ? undefined : 0),
      fmc2variance: round2relaxed(ppos?.FMC2 === undefined ? undefined : 0),
    };
  }

  const { fmc1cost, fmc2cost } = Calculator.CC_Others.Sum({ cost });

  return {
    fmc1cost: round2relaxed(fmc1cost) || 0,
    fmc2cost: round2relaxed(fmc2cost) || 0,
    fmc1ppo: round2relaxed(ppos?.FMC1),
    fmc2ppo: round2relaxed(ppos?.FMC2),
    fmc1variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC1, fmc1cost)),
    fmc2variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC2, fmc2cost)),
  };
}
