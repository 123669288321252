import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { getErrorStatement, showErrorToast, showInfoToast } from '@frontend/common/lib/functions';
import { PackagingNonstandardCostsRow } from './packaging.types';
import { Calculator } from '@core/calculator/calculator';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  MaterialGetEndpoint,
  PackagingGetEndpointResponse,
} from '@core/schemas/endpoint/schema.endpoint.packaging';
import { Region, RegionFMC } from '@core/schemas/schema.common';
import { Unpacked } from '@core/util/util.typing';
import { getErrorMessage } from '@core/util/util.getErrorMessage';
import { useMessages } from '@frontend/common/lib/hooks/useMessages';
import { Message } from '@frontend/common/lib/models';
import { PasteChanges } from '@frontend/table/lib';
import { DBPackagingNonstandardCost } from '@core/schemas/db/schema.db.packaging';
import { PackSource, PackagingCostMaterial } from '@core/types/types.packaging';
import { PACKAGING_KEY_TO_DESCRIPTION } from '@core/const/const.PACKAGING_KEY_TO_DESCRIPTION';
import { getFreshId } from '@core/util/util.geFreshId';
import { MATERIAL_NOT_FOUND_TEXT } from '@core/const/const.MATERIAL_NOT_FOUND_TEXT';

type FMCNumbers = {
  fmc1: number;
  fmc2: number;
};

export type UsePackagingNonstandardCostsReturnValue = {
  packagingNonstandardCostsRows: PackagingNonstandardCostsRow[];
  reset: () => void;
  reinitialize: () => void;
  handlers: {
    changeDescription: (key: string, newDescription: string) => void;
    changeQuantity: (key: string, fmcRegion: RegionFMC, newQuantity: number) => void;
    changeMaterial: (
      key: string,
      fmcRegion: RegionFMC,
      newMaterial:
        | { materialId: number; description: string | undefined; cost: number | undefined }
        | undefined,
    ) => void;
    changeCost: (key: string, fmcRegion: RegionFMC, newCost: number) => void;
    addRow: () => void;
    deleteRow: (key: string) => void;
    onPaste: (
      updates: PasteChanges<PackagingNonstandardCostsRow>,
      updateRowWithMaterial: (
        key: string,
        fmcRegion: RegionFMC,
        materialId: number | undefined,
      ) => Promise<void>,
    ) => void;
    importRows: (rows: DBPackagingNonstandardCost[]) => void;
  };
  totals: {
    cost: FMCNumbers;
    quantity: FMCNumbers;
    costPlusScrap: FMCNumbers;
  };
  materials: Record<string, Record<RegionFMC, PackagingCostMaterial | undefined>>;
  messages: Message[];
};

export function usePackagingNonstandardCosts(props: {
  packagingData:
    | Pick<PackagingGetEndpointResponse, 'packagingScrapRates' | 'packagingNonstandardCosts'>
    | undefined;
  packingRegionEU: Region | undefined;
  packingRegionUS: Region | undefined;
  selectedSource: PackSource | undefined;
  selectedPackagingSize: number | undefined;
  fmcYear: number | undefined;
  readOnly?: boolean;
}): UsePackagingNonstandardCostsReturnValue {
  const {
    fmcYear,
    packagingData,
    packingRegionEU,
    packingRegionUS,
    selectedPackagingSize,
    selectedSource,
    readOnly,
  } = props;

  const [quantities, setQuantities] = useState<Record<string, Record<RegionFMC, number>>>({});
  const [descriptions, setDescriptions] = useState<Record<string, string>>({});
  const [materials, setMaterials] = useState<UsePackagingNonstandardCostsReturnValue['materials']>(
    {},
  );
  const [manualCosts, setManualCosts] = useState<Record<string, Record<RegionFMC, number>>>({});
  const [rowIds, setRowIds] = useState<string[]>([]);
  const [
    packagingNonstandardCostsRowsWithoutDescription,
    setPackagingNonstandardCostsRowsWithoutDescription,
  ] = useState<Omit<PackagingNonstandardCostsRow, 'description'>[]>([]);

  const {
    addMessage: addLocalMessage,
    clearMessages: clearLocalMessages,
    messages: localMessages,
  } = useMessages();

  const { packagingScrapRates, packagingNonstandardCosts } = packagingData || {};

  const updateRowsFromCostInputs = useCallback(
    (
      costInputs: (Partial<
        Omit<
          OmitDBRecord<DBPackagingNonstandardCost>,
          'product_id' | 'revision' | 'bun' | 'currency' | 'is_standard'
        >
      > & { row_id: string; description: string })[],
    ) => {
      const preQuantities: typeof quantities = {};
      const preDescriptions: typeof descriptions = {};
      const preManualCosts: typeof manualCosts = {};

      costInputs.forEach((r) => {
        preQuantities[r.row_id] = {
          FMC1: r.fmc1_quantity || 0,
          FMC2: r.fmc2_quantity || 0,
        };
        preDescriptions[r.row_id] = r.description;

        preManualCosts[r.row_id] = {
          FMC1: r.fmc1_cost || 0,
          FMC2: r.fmc2_cost || 0,
        };
      });

      const materialsFromRows = costInputs.reduce<
        { key: string; fmcRegion: RegionFMC; materialId: number | undefined }[]
      >(
        (materialsSoFar, r) =>
          materialsSoFar.concat(
            { key: r.row_id, fmcRegion: 'FMC1', materialId: r.fmc1_material },
            { key: r.row_id, fmcRegion: 'FMC2', materialId: r.fmc2_material },
          ),
        [],
      );

      Promise.all<{
        key: string;
        fmcRegion: RegionFMC;
        material: PackagingCostMaterial | undefined;
      }>(
        materialsFromRows.map(async (matFromRow) => {
          if (!matFromRow.materialId) {
            return { key: matFromRow.key, fmcRegion: matFromRow.fmcRegion, material: undefined };
          }
          const [err, fetchedMaterial] = await callEndpoint({
            endpoint: MaterialGetEndpoint,
            input: { materialId: matFromRow.materialId },
            errorHandling: { disable: true },
          });

          if (err) {
            return {
              key: matFromRow.key,
              fmcRegion: matFromRow.fmcRegion,
              material: {
                materialId: matFromRow.materialId,
                description: undefined,
                cost: undefined,
              },
            };
          }
          return {
            key: matFromRow.key,
            fmcRegion: matFromRow.fmcRegion,
            material: {
              materialId: fetchedMaterial.material_id,
              description: fetchedMaterial.description,
              cost: fetchedMaterial.cost,
            },
          };
        }),
      ).then((materialsFromRows) => {
        const preMaterials: typeof materials = {};
        materialsFromRows.forEach((mat) => {
          if (!preMaterials[mat.key]) {
            preMaterials[mat.key] = { FMC1: undefined, FMC2: undefined };
          }
          preMaterials[mat.key][mat.fmcRegion] = mat.material;
        });
        setMaterials(preMaterials);
      });

      setQuantities(preQuantities);
      setDescriptions(preDescriptions);
      setManualCosts(preManualCosts);
      setRowIds(costInputs.map((cost) => cost.row_id));
    },
    [],
  );

  const reinitialize = useCallback(() => {
    hasRunInitialSetup.current = true;

    const inputs = Calculator.Pack.Packaging.NonstandardCost.GetRows();

    updateRowsFromCostInputs(
      inputs.map((i) => ({ row_id: i.rowId, description: PACKAGING_KEY_TO_DESCRIPTION[i.rowId] })),
    );
  }, [updateRowsFromCostInputs]);

  // first time hook
  const hasRunInitialSetup = useRef(false);
  useEffect(() => {
    if (hasRunInitialSetup.current) {
      return;
    }

    if (!packagingNonstandardCosts || !selectedPackagingSize || !selectedSource) {
      return;
    }

    if (packagingNonstandardCosts.length === 0) {
      hasRunInitialSetup.current = true;
    } else {
      updateRowsFromCostInputs(packagingNonstandardCosts);
      hasRunInitialSetup.current = true;
    }
  }, [
    updateRowsFromCostInputs,
    reinitialize,
    selectedPackagingSize,
    selectedSource,
    packagingNonstandardCosts,
  ]);

  useEffect(() => {
    if (
      !fmcYear ||
      !selectedSource ||
      !packingRegionEU ||
      !packingRegionUS ||
      !selectedPackagingSize
    ) {
      setPackagingNonstandardCostsRowsWithoutDescription([]);
      return;
    }

    (async () => {
      try {
        const packagingNonstandardCostInputs = rowIds.map((rowId) => ({
          fmc1quantity: quantities[rowId]?.FMC1,
          fmc2quantity: quantities[rowId]?.FMC2,
          fmc1manualCost: manualCosts[rowId]?.FMC1,
          fmc2manualCost: manualCosts[rowId]?.FMC2,
          rowId,
        }));

        const prePackagingNonstandardCostRows: typeof packagingNonstandardCostsRowsWithoutDescription =
          await Promise.all(
            packagingNonstandardCostInputs.map(async (pci) => {
              const existingPackagingCost = packagingNonstandardCosts?.find(
                (pc) => pc.row_id === pci.rowId,
              );
              try {
                const { fmc1_cost, fmc2_cost, fmc1_cost_plus_scrap, fmc2_cost_plus_scrap, report } =
                  await Calculator.Pack.Packaging.NonstandardCost.Calculate({
                    input: {
                      packagingNonstandardCost: pci,
                      packingRegionEU,
                      packingRegionUS,
                      source: selectedSource,
                      fmcYear: fmcYear,
                    },
                    cache: {
                      materials: {
                        fmc1: materials[pci.rowId]?.FMC1,
                        fmc2: materials[pci.rowId]?.FMC2,
                      },
                      packagingScrapRates,
                      cost: readOnly
                        ? {
                            fmc1_cost: existingPackagingCost?.fmc1_cost,
                            fmc2_cost: existingPackagingCost?.fmc2_cost,
                            fmc1_cost_plus_scrap: existingPackagingCost?.fmc1_cost_plus_scrap,
                            fmc2_cost_plus_scrap: existingPackagingCost?.fmc2_cost_plus_scrap,
                          }
                        : undefined,
                    },
                  });

                const row: Unpacked<typeof packagingNonstandardCostsRowsWithoutDescription> = {
                  rowId: pci.rowId,
                  fmc1quantity: quantities[pci.rowId]?.FMC1,
                  fmc2quantity: quantities[pci.rowId]?.FMC2,
                  fmc1material: materials[pci.rowId]?.FMC1?.materialId,
                  fmc2material: materials[pci.rowId]?.FMC2?.materialId,
                  fmc1materialDescription:
                    materials[pci.rowId]?.FMC1 &&
                    materials[pci.rowId].FMC1?.description === undefined
                      ? MATERIAL_NOT_FOUND_TEXT
                      : materials[pci.rowId]?.FMC1?.description || '',
                  fmc2materialDescription:
                    materials[pci.rowId]?.FMC2 &&
                    materials[pci.rowId].FMC2?.description === undefined
                      ? MATERIAL_NOT_FOUND_TEXT
                      : materials[pci.rowId]?.FMC2?.description || '',
                  fmc1cost: fmc1_cost,
                  fmc2cost: fmc2_cost,
                  fmc1costPlusScrap: fmc1_cost_plus_scrap,
                  fmc2costPlusScrap: fmc2_cost_plus_scrap,
                  fmc1manualCost: pci.fmc1manualCost,
                  fmc2manualCost: pci.fmc2manualCost,
                  currency: 'DKK',
                  report,
                };

                return row;
              } catch (error) {
                const errorStatement = getErrorStatement(error);

                const row: Unpacked<typeof packagingNonstandardCostsRowsWithoutDescription> = {
                  rowId: pci.rowId,
                  fmc1quantity: quantities[pci.rowId]?.FMC1,
                  fmc2quantity: quantities[pci.rowId]?.FMC2,
                  fmc1material: materials[pci.rowId]?.FMC1?.materialId,
                  fmc2material: materials[pci.rowId]?.FMC2?.materialId,
                  fmc1materialDescription:
                    materials[pci.rowId]?.FMC1 &&
                    materials[pci.rowId].FMC1?.description === undefined
                      ? MATERIAL_NOT_FOUND_TEXT
                      : materials[pci.rowId]?.FMC1?.description || '',
                  fmc2materialDescription:
                    materials[pci.rowId]?.FMC2 &&
                    materials[pci.rowId].FMC2?.description === undefined
                      ? MATERIAL_NOT_FOUND_TEXT
                      : materials[pci.rowId]?.FMC2?.description || '',
                  fmc1cost: 0,
                  fmc2cost: 0,
                  fmc1manualCost: pci.fmc1manualCost,
                  fmc2manualCost: pci.fmc2manualCost,
                  fmc1costPlusScrap: 0,
                  fmc2costPlusScrap: 0,
                  currency: 'DKK',
                  report: errorStatement ? [errorStatement] : [],
                };

                return row;
              }
            }),
          );

        setPackagingNonstandardCostsRowsWithoutDescription(prePackagingNonstandardCostRows);
      } catch (error) {
        showErrorToast('Setting up packaging nonstandard costs', getErrorMessage(error));
      }
    })();
  }, [
    rowIds,
    quantities,
    materials,
    manualCosts,
    fmcYear,
    packingRegionEU,
    packingRegionUS,
    readOnly,
    selectedPackagingSize,
    packagingScrapRates,
    selectedSource,
    packagingNonstandardCosts,
  ]);

  const totalCost = useMemo(
    () => ({
      fmc1: Calculator.Common.SumListStrict(
        packagingNonstandardCostsRowsWithoutDescription,
        'fmc1cost',
      ),
      fmc2: Calculator.Common.SumListStrict(
        packagingNonstandardCostsRowsWithoutDescription,
        'fmc2cost',
      ),
    }),
    [packagingNonstandardCostsRowsWithoutDescription],
  );

  const totalCostPlusScrap = useMemo(
    () => ({
      fmc1: Calculator.Common.SumListStrict(
        packagingNonstandardCostsRowsWithoutDescription,
        'fmc1costPlusScrap',
      ),
      fmc2: Calculator.Common.SumListStrict(
        packagingNonstandardCostsRowsWithoutDescription,
        'fmc2costPlusScrap',
      ),
    }),
    [packagingNonstandardCostsRowsWithoutDescription],
  );

  const totalQuantity = useMemo(
    () => ({
      fmc1: Calculator.Common.SumListStrict(
        packagingNonstandardCostsRowsWithoutDescription,
        'fmc1quantity',
      ),
      fmc2: Calculator.Common.SumListStrict(
        packagingNonstandardCostsRowsWithoutDescription,
        'fmc2quantity',
      ),
    }),
    [packagingNonstandardCostsRowsWithoutDescription],
  );

  function changeDescription(key: string, newDescription: string) {
    setDescriptions((curr) => ({ ...curr, [key]: newDescription }));
  }

  function changeQuantity(key: string, fmcRegion: RegionFMC, newQuantity: number) {
    setQuantities((curr) => ({ ...curr, [key]: { ...curr[key], [fmcRegion]: newQuantity } }));
  }

  function changeMaterial(
    key: string,
    fmcRegion: RegionFMC,
    newMaterial:
      | { materialId: number; description: string | undefined; cost: number | undefined }
      | undefined,
  ) {
    setMaterials((curr) => ({ ...curr, [key]: { ...curr[key], [fmcRegion]: newMaterial } }));
  }

  function changeCost(key: string, fmcRegion: RegionFMC, newCost: number) {
    setManualCosts((curr) => ({ ...curr, [key]: { ...curr[key], [fmcRegion]: newCost } }));
  }

  function addRow() {
    const rowId = getFreshId();

    setRowIds((curr) => curr.concat(rowId));
    setDescriptions((curr) => ({ ...curr, [rowId]: '' }));
    setQuantities((curr) => ({ ...curr, [rowId]: { FMC1: 0, FMC2: 0 } }));
    setMaterials((curr) => ({
      ...curr,
      [rowId]: { FMC1: undefined, FMC2: undefined },
    }));
    setManualCosts((curr) => ({ ...curr, [rowId]: { FMC1: 0, FMC2: 0 } }));
  }

  function deleteRow(key: string) {
    setRowIds((curr) => curr.filter((k) => k !== key));

    setDescriptions((curr) => {
      const newCurr = { ...curr };
      delete curr[key];
      return newCurr;
    });
    setQuantities((curr) => {
      const newCurr = { ...curr };
      delete curr[key];
      return newCurr;
    });
    setManualCosts((curr) => {
      const newCurr = { ...curr };
      delete curr[key];
      return newCurr;
    });
  }

  const packagingNonstandardCostsRows = useMemo(
    () =>
      packagingNonstandardCostsRowsWithoutDescription.map((r) => ({
        ...r,
        description: descriptions[r.rowId] || '',
      })),
    [packagingNonstandardCostsRowsWithoutDescription, descriptions],
  );

  useEffect(() => {
    clearLocalMessages(/packaging-nonstandard-cost.*/);

    packagingNonstandardCostsRows.forEach((pnsc) => {
      pnsc.report
        .filter((report) => report.isError)
        .forEach((report) => {
          addLocalMessage({
            id: `packaging-nonstandard-cost_error_${pnsc.rowId}`,
            message: `${pnsc.description}: ${report.shortDescription}`,
          });
        });
    });
  }, [packagingNonstandardCostsRows, clearLocalMessages, addLocalMessage]);

  function onPaste(
    updates: PasteChanges<PackagingNonstandardCostsRow>,
    updateRowWithMaterial: (
      key: string,
      fmcRegion: RegionFMC,
      materialId: number | undefined,
    ) => Promise<void>,
  ) {
    updates.forEach(({ row, changes }) => {
      if (typeof changes.description === 'string')
        changeDescription(row.rowId, changes.description);
      if (typeof changes.fmc1quantity === 'number')
        changeQuantity(row.rowId, 'FMC1', changes.fmc1quantity);
      if (typeof changes.fmc2quantity === 'number')
        changeQuantity(row.rowId, 'FMC2', changes.fmc2quantity);
      if (typeof changes.fmc1cost === 'number') changeCost(row.rowId, 'FMC1', changes.fmc1cost);
      if (typeof changes.fmc2cost === 'number') changeCost(row.rowId, 'FMC2', changes.fmc2cost);
      if (typeof changes.fmc1material === 'number')
        updateRowWithMaterial(row.rowId, 'FMC1', changes.fmc1material);
      if (typeof changes.fmc2material === 'number')
        updateRowWithMaterial(row.rowId, 'FMC2', changes.fmc2material);
    });
  }

  const reset = useCallback(() => {
    hasRunInitialSetup.current = false;
  }, []);

  function importRows(rows: DBPackagingNonstandardCost[]) {
    if (rows.length === 0) {
      showInfoToast('No packaging non-standard costs to import');
      return;
    }

    updateRowsFromCostInputs(rows);
  }

  return {
    packagingNonstandardCostsRows,
    reinitialize,
    reset,
    handlers: {
      changeDescription,
      changeQuantity,
      changeMaterial,
      changeCost,
      addRow,
      deleteRow,
      onPaste,
      importRows,
    },
    totals: { cost: totalCost, quantity: totalQuantity, costPlusScrap: totalCostPlusScrap },
    materials,
    messages: localMessages,
  };
}
