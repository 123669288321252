import { useCallback, useState } from 'react';
import { logDev, showErrorToast } from '@frontend/common/lib/functions';

export function useBiStatus() {
  const [biStatus, setBiStatus] = useState(0);

  const updateBiStatus = useCallback((newStatus: number) => {
    const status = newStatus;
    if (![0, 40, 42, 43].includes(status)) {
      showErrorToast('Application error: Unable to update BI status');
      logDev(newStatus, typeof newStatus);
      return;
    }

    setBiStatus(status);
  }, []);

  return { biStatus, updateBiStatus };
}
