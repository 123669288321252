import { DBBICost } from '@core/schemas/db/schema.db.bi';

export function calculate_BICOST_selectRelevantCost(
  biCost: (Pick<DBBICost, 'cost' | 'super_design'> & { _manualCost: number }) | undefined,
): number | undefined {
  if (!biCost) {
    return undefined;
  }

  if (biCost.super_design) {
    return biCost.cost;
  }

  return biCost._manualCost;
}
