import { CalculatorBICCInstructionsBooks, CostComponentCost } from '../calculator.types';
import { Calculator } from '../calculator';

export function calculate_CC_Instructions_sum(
  books: CalculatorBICCInstructionsBooks,
): Required<CostComponentCost> {
  const fmc1cost = Calculator.Common.SumListStrict(
    books?.filter((b) => b.fmcRegion === 'FMC1'),
    'cost',
  );
  const fmc2cost = Calculator.Common.SumListStrict(
    books?.filter((b) => b.fmcRegion === 'FMC2'),
    'cost',
  );

  return {
    fmc1cost,
    fmc2cost,
  };
}
