import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export const CLIENT_ID = import.meta.env.FE_CLIENT_ID;

const configuration: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: 'https://login.microsoftonline.com/1d063515-6cad-4195-9486-ea65df456faa',
    redirectUri: window.location.origin,
  },
};

export const authProvider = new PublicClientApplication(configuration);

export async function getMsGraphToken() {
  const account = authProvider.getAllAccounts()[0];
  try {
    return (
      await authProvider.acquireTokenSilent({
        account,
        scopes: ['openid', 'profile', 'User.Read', 'email'],
      })
    ).accessToken;
  } catch (e) {
    authProvider.logoutRedirect();
    window.location.reload();
    return '';
  }
}

export async function getApiToken(forceRefresh?: boolean) {
  return (
    await authProvider.acquireTokenSilent({
      account: authProvider.getAllAccounts()[0],
      scopes: [import.meta.env.FE_API_SCOPE],
      forceRefresh,
    })
  ).accessToken;
}
