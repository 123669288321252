import { ReportCommonQueryInput } from '@core/schemas/endpoint/schema.endpoint.reporting';
import { RevisionsMode, RevisionsModeEnum } from '@core/schemas/schema.common';
import {
  REGEX_POSITIVE_INTEGER_OR_EMPTY,
  REGEX_PRODUCT_ID_OR_EMPTY,
} from '@core/types/types.regex';
import { NCModal } from '@frontend/common/components/NCModal';
import { Tag } from '@frontend/common/components/Tag';
import { showErrorToast } from '@frontend/common/lib/functions';
import { FInput } from '@frontend/form/components/FInput';
import { FSelect } from '@frontend/form/components/FSelect';
import { QueryPanelContainer } from '@frontend/reporting/components/QueryPanelContainer';
import { Button, Link, useDisclosure } from '@lego/klik-ui';
import { CrossBold } from '@lego/klik-ui/icons';
import { useEffect, useMemo, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ProductIdSelectionsContent } from './ProductIdSelectionsContent';
import { ProductIndexContext } from '@frontend/common/lib/contexts';

interface CommonReportQueryPanelProps {
  onOk(query: ReportCommonQueryInput): Promise<void>;
  hideRevisionFilter?: boolean;
}

export function CommonReportQueryPanel(props: CommonReportQueryPanelProps) {
  const [productId, setProductId] = useState('');
  const [productIds, setProductIds] = useState<number[]>([]);
  const [productIdRangeFrom, setProductIdRangeFrom] = useState('');
  const [productIdRangeTo, setProductIdRangeTo] = useState('');
  const [productIdRanges, setProductIdRanges] = useState<{ from: number; to: number }[]>([]);
  const [yearFrom, setYearFrom] = useState('');
  const [yearTo, setYearTo] = useState('');
  const [revisionsMode, setRevisionsMode] = useState<RevisionsMode>(RevisionsModeEnum.Enum.LATEST);
  const [highlightedInputs, setHighlightedInputs] = useState<
    ('Product ID' | 'Product ID range from' | 'Product ID range to')[]
  >([]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { suggestions } = useContext(ProductIndexContext);

  const location = useLocation();

  useEffect(() => {
    setProductId('');
    setProductIds([]);
    setProductIdRangeFrom('');
    setProductIdRangeTo('');
    setProductIdRanges([]);
    setYearFrom('');
    setYearTo('');
    setRevisionsMode(RevisionsModeEnum.Enum.LATEST);
  }, [location.pathname]);

  function addProductId(input: string) {
    if (input === '') {
      return;
    }
    setProductIds((curr) => curr.filter((pid) => pid !== Number(input)).concat(Number(input)));
  }

  function addCurrentProductId() {
    addProductId(productId);
    setProductId('');
  }

  function addProductIdRange() {
    if (!productIdRangeFrom || !productIdRangeTo) {
      return;
    }

    const from = Number(productIdRangeFrom);
    const to = Number(productIdRangeTo);

    const newRange = { from: from <= to ? from : to, to: from <= to ? to : from };

    setProductIdRanges((ranges) =>
      ranges.filter((range) => JSON.stringify(range) !== JSON.stringify(newRange)).concat(newRange),
    );
    setProductIdRangeFrom('');
    setProductIdRangeTo('');
  }

  function removeProductId(productId: number) {
    setProductIds((curr) => curr.filter((pid) => pid !== productId));
  }

  function removeProductIdRange(productIdRange: { from: number; to: number }) {
    setProductIdRanges((curr) =>
      curr.filter((range) => JSON.stringify(range) !== JSON.stringify(productIdRange)),
    );
  }

  const selections = useMemo(
    () => [...productIds, ...productIdRanges],
    [productIds, productIdRanges],
  );

  const inputHasValue = useMemo(
    () => !!(productId || productIdRangeFrom || productIdRangeTo),
    [productId, productIdRangeFrom, productIdRangeTo],
  );

  return (
    <QueryPanelContainer
      disabled={
        productIds.length === 0 && productIdRanges.length === 0 && !yearFrom && inputHasValue
      }
      onOk={async () => {
        if (inputHasValue) {
          showErrorToast(
            'Generating report',
            'Product ID or product ID range has been filled but not added. Either add or remove the entry/entries',
          );
          if (productId) {
            setHighlightedInputs((curr) => Array.from(new Set(curr.concat('Product ID'))));
          }
          if (productIdRangeFrom) {
            setHighlightedInputs((curr) =>
              Array.from(new Set(curr.concat('Product ID range from'))),
            );
          }
          if (productIdRangeTo) {
            setHighlightedInputs((curr) => Array.from(new Set(curr.concat('Product ID range to'))));
          }
          setTimeout(() => setHighlightedInputs([]), 1500);
          return;
        }

        const from = Number(yearFrom);
        const to = Number(yearTo);
        return await props.onOk({
          revisionsMode,
          productIds,
          productIdRanges,
          year: { from: from <= to ? from : to, to: from <= to ? to : from },
        });
      }}
    >
      <NCModal header="Product ID selections" isOpen={isOpen} onClose={onClose} width={600}>
        <ProductIdSelectionsContent
          productIds={productIds}
          productIdRanges={productIdRanges}
          onRemove={(selection) => {
            if (selection.type === 'single') {
              removeProductId(Number(selection.value));
            } else {
              const [from, to] = selection.value.split('-').map((v) => Number(v));
              removeProductIdRange({ from, to });
            }
          }}
          onMultiValuePaste={(productIds) => {
            productIds.map(addProductId);
            setTimeout(() => setProductId(''), 1);
          }}
          addCurrentProductId={addProductId}
        />
      </NCModal>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', columnGap: 8, alignItems: 'end', width: '100%' }}>
          <FInput
            isInvalid={highlightedInputs.includes('Product ID')}
            topLabel="Product ID"
            suggestions={suggestions}
            editable
            align="start"
            regex={REGEX_PRODUCT_ID_OR_EMPTY}
            disallowChangeOnInvalid
            onMultiValuePaste={(productIds) => {
              productIds.map(addProductId);
              setTimeout(() => setProductId(''), 1);
            }}
            size="md"
            disableSmartBehavior
            value={productId}
            disableBlurOnEnter
            onChange={setProductId}
            onEnter={addCurrentProductId}
            style={{ width: 'inherit' }}
          />
          <Button
            colorScheme="success"
            size="md"
            disabled={!productId}
            onClick={addCurrentProductId}
          >
            Add
          </Button>
        </div>
        <div>
          <div style={{ marginTop: 8, marginBottom: 4 }}>Product ID range</div>
          <div
            style={{
              display: 'flex',
              columnGap: 8,
              alignItems: 'end',
              width: '100%',
            }}
          >
            <FInput
              isInvalid={highlightedInputs.includes('Product ID range from')}
              placeholder="From"
              editable
              align="start"
              regex={REGEX_PRODUCT_ID_OR_EMPTY}
              disallowChangeOnInvalid
              size="md"
              disableSmartBehavior
              value={productIdRangeFrom}
              disableBlurOnEnter
              onChange={setProductIdRangeFrom}
              onEnter={addProductIdRange}
              style={{ width: 'inherit' }}
            />
            <div style={{ marginBottom: 14 }}>-</div>
            <FInput
              isInvalid={highlightedInputs.includes('Product ID range to')}
              editable
              placeholder="To"
              align="start"
              regex={REGEX_PRODUCT_ID_OR_EMPTY}
              disallowChangeOnInvalid
              size="md"
              disableSmartBehavior
              value={productIdRangeTo}
              disableBlurOnEnter
              onChange={setProductIdRangeTo}
              onEnter={addProductIdRange}
              style={{ width: 'inherit' }}
            />
            <Button
              colorScheme="success"
              size="md"
              disabled={!productIdRangeFrom || !productIdRangeTo}
              onClick={addProductIdRange}
            >
              Add
            </Button>
          </div>
        </div>
        {selections.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: 4,
              rowGap: 4,
              marginTop: 16,
            }}
          >
            {selections.slice(0, 5).map((idOrRange) => (
              <Tag
                key={
                  typeof idOrRange === 'number' ? idOrRange : idOrRange.from + ',' + idOrRange.to
                }
                style={{
                  fontSize: 14,
                  height: 28,
                  padding: '0 8px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (typeof idOrRange === 'number') {
                    removeProductId(idOrRange);
                  } else {
                    removeProductIdRange(idOrRange);
                  }
                }}
              >
                {typeof idOrRange === 'number' ? idOrRange : idOrRange.from + '-' + idOrRange.to}
                <CrossBold style={{ fontSize: 10, marginLeft: 4, marginTop: -2 }} />
              </Tag>
            ))}
          </div>
        )}
        {selections.length > 5 && (
          <div style={{ marginTop: 8, textAlign: 'center' }}>
            and {selections.length - 5} more.
            <Link onClick={onOpen} style={{ marginLeft: 8 }} className="link-message">
              Show
            </Link>
          </div>
        )}
      </div>
      <div>
        <div style={{ marginBottom: 4 }}>Year range</div>
        <div style={{ display: 'flex', columnGap: 8, alignItems: 'end' }}>
          <FInput
            placeholder="From"
            editable
            align="start"
            size="md"
            value={yearFrom}
            onChange={setYearFrom}
            regex={REGEX_POSITIVE_INTEGER_OR_EMPTY}
            disallowChangeOnInvalid
          />
          <div style={{ marginBottom: 14 }}>-</div>
          <FInput
            placeholder="To"
            align="start"
            editable
            size="md"
            value={yearTo}
            onChange={setYearTo}
            regex={REGEX_POSITIVE_INTEGER_OR_EMPTY}
            disallowChangeOnInvalid
          />
        </div>
      </div>
      {!props.hideRevisionFilter && (
        <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
          <FSelect
            topLabel="Revision(s)"
            containerStyle={{ width: '100%' }}
            value={revisionsMode}
            onChange={(v) => setRevisionsMode(v as typeof revisionsMode)}
            editable
            size="md"
            options={[
              { text: 'Latest', value: RevisionsModeEnum.Enum.LATEST },
              { text: 'Latest approved', value: RevisionsModeEnum.Enum.LATEST_APPROVED },
              { text: 'All', value: RevisionsModeEnum.Enum.ALL },
            ]}
            labelStyle={{ fontWeight: 'normal' }}
          />
        </div>
      )}
    </QueryPanelContainer>
  );
}
