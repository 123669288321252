export function calculate_nullable_sum_list_primitive(
  numbers: (number | undefined)[] | undefined,
): number | undefined {
  if (numbers?.length === 0) {
    return 0;
  }

  const result = numbers?.reduce<undefined | number>(
    (sum, n) => (sum === undefined ? n : n === undefined ? sum : sum + n),
    undefined,
  );

  if (result === undefined) {
    return undefined;
  }

  return result;
}
