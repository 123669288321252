import { Calculator } from '../calculator';
import { CalculatorCommonPPO, CostComponentFull } from '../calculator.types';
import { round2, round2relaxed } from '../calculator.util';

export function calculate_CC_Factor_full(props: {
  cost: {
    leadPrice: { fmc1cost: number; fmc2cost: number };
    totalCosts: { fmc1cost: number; fmc2cost: number };
  };
  ppos: CalculatorCommonPPO;
}): Omit<CostComponentFull, 'fmc1cost' | 'fmc2cost'> & { fmc1factor: number; fmc2factor: number } {
  const { ppos, cost } = props;

  const { fmc1factor, fmc2factor } = Calculator.CC_Factor.Sum(cost);

  return {
    fmc1factor: round2(fmc1factor ?? 0),
    fmc2factor: round2(fmc2factor ?? 0),
    fmc1ppo: round2relaxed(ppos?.FMC1),
    fmc2ppo: round2relaxed(ppos?.FMC2),
    fmc1variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC1, fmc1factor)),
    fmc2variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC2, fmc2factor)),
  };
}
