import { z } from 'zod';

export function defineEndpoint<P extends string, I extends z.ZodType, R extends z.ZodType>(props: {
  path: P;
  input: I;
  response: R;
  mutation?: true;
}) {
  const { input, path, response, mutation } = props;

  return {
    type: 'internal' as const,
    path,
    input,
    response,
    mutation,
  };
}

export type SupportedQueryType = z.ZodBoolean | z.ZodString | z.ZodNumber;
export type ParsedQueryParams = Record<string, number | boolean | string | undefined>;

export function defineAMMAEndpoint<
  P extends string,
  I extends z.ZodType,
  Q extends z.ZodType,
  R extends z.ZodType,
>(props: {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  path: P;
  bodyInput?: I;
  queryInput?: Q;
  response: R;
  mutation?: true;
}) {
  const { method, bodyInput, path, response, queryInput, mutation } = props;

  return {
    type: 'amma' as const,
    method,
    path,
    bodyInput: bodyInput as typeof bodyInput extends undefined
      ? undefined
      : Exclude<typeof bodyInput, undefined>,
    queryInput: queryInput as typeof queryInput extends undefined
      ? undefined
      : Exclude<typeof queryInput, undefined>,
    response,
    mutation,
  };
}

export function defineLambdaEndpoint<
  P extends string,
  I extends z.ZodType,
  R extends z.ZodType,
>(props: { path: P; input: I; response: R }) {
  const { input, path, response } = props;

  return {
    type: 'lambda' as const,
    path,
    input,
    response,
  };
}
