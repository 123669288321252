import React, { useContext, useMemo, useState } from 'react';
import { Button } from '@lego/klik-ui';
import { getSchemaProperties, getSchemaProperty } from '@core/util/util.schemaPropDescription';
import { FSelect } from '@frontend/form/components/FSelect';
import { FInput } from '@frontend/form/components/FInput';
import { IoMdOptions } from 'react-icons/io';
import { VscSymbolString } from 'react-icons/vsc';
import { NCModal } from '@frontend/common/components/NCModal';
import { Filter } from '../dataMaintenance.types';
import { FilterPanelModalContent } from './FilterSettingsModalContent';
import { showInternalApplicationError } from '@frontend/common/lib/functions';
import { getFilterDisplayValue } from '../dataMaintenance.util';
import { FilterType } from '@core/types/types.es';
import { SchemaProperty } from '@core/types/types.schema';
import {
  REGEX_NONNEGATIVE_INTEGER_OR_EMPTY,
  get_REGEX_NONNEGATIVE_INTEGER_OR_1TO5DECIMAL_OR_EMPTY,
} from '@core/types/types.regex';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { CircleSpinner } from '@frontend/common/components/CircleSpinner';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  DataMaintenanceGetLOVConfigEndpointResponse,
  DataMaintenanceGetLOVEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';
import { PK } from '@core/types/types.pk';
import { PK_TO_SCHEMA } from '@core/schemas/db/schema.db.lib';
import { LOVModal } from './LOVModel';

interface FilterPanelProps {
  onFetch(): void;
  selectedPk: PK;
  filters: Record<string, Filter>;
  updateFilter(key: string, filter: Omit<Filter, 'isNumberType'>): void;
}

export function FilterPanel(props: FilterPanelProps) {
  const [selectedKey, setSelectedKey] = useState('');
  const [fetchingLovKey, setFetchingLovKey] = useState('');
  const [lovValues, setLovValues] = useState<{
    selectedProperty: string;
    values: DataMaintenanceGetLOVConfigEndpointResponse;
  }>();
  // const [selectedValues, setSelectedValues] = useState<unknown[]>([]);

  const schema = useMemo(() => PK_TO_SCHEMA[props.selectedPk], [props.selectedPk]);

  const { updateFilter } = props;

  const { userData } = useContext(UserDataContext);

  const selectedProperty: (SchemaProperty & { filter: Filter }) | undefined = useMemo(() => {
    const prop = getSchemaProperty(schema, selectedKey);

    if (!prop || !props.filters[selectedKey]) {
      return undefined;
    }

    return { ...prop, filter: props.filters[prop.key] };
  }, [schema, selectedKey, props.filters]);

  async function fetchLov(key: string) {
    setFetchingLovKey(key);
    const [err, data] = await callEndpoint({
      endpoint: DataMaintenanceGetLOVEndpoint,
      input: { pk: props.selectedPk, field: key },
      errorHandling: { header: `Fetching list of values for field '${key}'` },
    });
    setFetchingLovKey('');

    if (err) {
      return;
    }

    setLovValues({ selectedProperty: key, values: data });
  }

  return (
    <div>
      {lovValues && (
        <LOVModal
          values={lovValues.values}
          selectedProperty={lovValues.selectedProperty}
          filter={props.filters[lovValues.selectedProperty]}
          onClose={() => {
            setLovValues(undefined);

            const filter = props.filters[lovValues.selectedProperty];

            if (
              (filter.type === FilterType.Include || filter.type === FilterType.Exclude) &&
              filter.value.length === 0
            ) {
              props.updateFilter(lovValues.selectedProperty, { type: FilterType.Exact, value: '' });
            }
          }}
          updateFilter={props.updateFilter}
        />
      )}
      {selectedProperty && (
        <NCModal
          header={`Filter settings for ${selectedKey}`}
          isOpen={!!selectedKey}
          closeButtonProps={{ text: 'OK' }}
          onClose={() => {
            const filter = props.filters[selectedKey];

            if (!filter) {
              showInternalApplicationError();
              return;
            }

            let reset = false;

            // Reset to Exact if values are empty anyway
            if (filter.type === FilterType.Exact) {
              reset = false;
            } else if (filter.type === FilterType.Include) {
              reset = filter.value.length === 0 && filter.value.length === 0;
            } else if (filter.type === FilterType.Exclude) {
              reset = filter.value.length === 0 && filter.value.length === 0;
            } else if (filter.type === FilterType.Range) {
              reset = filter.value.min === undefined && filter.value.max === undefined;
            } else {
              showInternalApplicationError();
              return;
            }

            if (reset) {
              props.updateFilter(selectedKey, { type: FilterType.Exact, value: '' });
            }

            setSelectedKey('');
          }}
          width={600}
        >
          <FilterPanelModalContent
            property={selectedProperty}
            setFilterValue={(v) => props.updateFilter(selectedProperty.key, v)}
            onClose={() => setSelectedKey('')}
          />
        </NCModal>
      )}
      <div
        style={{
          display: 'grid',
          rowGap: 4,
          gridTemplateColumns: `min-content 400px 32px 32px`,
          columnGap: 8,
          alignItems: 'center',
          height: 'min-content',
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            props.onFetch();
          }
        }}
      >
        {getSchemaProperties(schema)
          .filter(({ isDbRecordProp }) => !isDbRecordProp)
          .map(({ key, enumValues, inputType, int, isPartOfKey, supportLov }) => {
            const filterOn = props.filters[key]?.type !== FilterType.Exact;
            return (
              <React.Fragment key={key}>
                <div style={{ fontWeight: isPartOfKey ? 'bold' : undefined, whiteSpace: 'nowrap' }}>
                  {key}:
                </div>
                {enumValues ? (
                  <FSelect
                    value={getFilterDisplayValue(props.filters[key])}
                    options={[{ text: '', value: '' }].concat(
                      ...enumValues.map((v) => ({ text: v, value: v })),
                    )}
                    disabled={props.filters[key]?.type !== FilterType.Exact}
                    editable
                    placeholder={
                      props.filters[key]?.type !== FilterType.Exact
                        ? getFilterDisplayValue(props.filters[key])
                        : undefined
                    }
                    onChange={(v) => updateFilter(key, { type: FilterType.Exact, value: v })}
                  />
                ) : (
                  <FInput
                    key={key}
                    regex={
                      inputType !== 'ZodNumber'
                        ? undefined
                        : int
                        ? REGEX_NONNEGATIVE_INTEGER_OR_EMPTY
                        : get_REGEX_NONNEGATIVE_INTEGER_OR_1TO5DECIMAL_OR_EMPTY(
                            !!userData?.comma_as_decimal_seperator,
                          )
                    }
                    align="start"
                    editable
                    disabled={props.filters[key]?.type !== FilterType.Exact}
                    disallowChangeOnInvalid
                    value={getFilterDisplayValue(props.filters[key])}
                    onChange={(v) =>
                      updateFilter(key, {
                        type: FilterType.Exact,
                        value: v,
                      })
                    }
                  />
                )}

                <div
                  style={{
                    height: '100%',
                    aspectRatio: '1 / 1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px solid ${filterOn ? '#09b4ff' : 'rgb(207, 207, 204)'}`,
                    borderRadius: 4,
                    cursor: 'pointer',
                    color: filterOn ? '#09b4ff' : '#494949',
                  }}
                  onClick={() => {
                    setSelectedKey(key);
                    if (props.filters[key].type === FilterType.Exact) {
                      props.updateFilter(key, {
                        type: FilterType.Include,
                        value: [],
                      });
                    }
                  }}
                >
                  <IoMdOptions style={{ fontSize: 20 }} />
                </div>
                {supportLov ? (
                  <div
                    style={{
                      height: '100%',
                      aspectRatio: '1 / 1',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: `1px solid ${filterOn ? '#09b4ff' : 'rgb(207, 207, 204)'}`,
                      borderRadius: 4,
                      cursor: 'pointer',
                      color: filterOn ? '#09b4ff' : '#494949',
                    }}
                    onClick={() => fetchLov(key)}
                  >
                    {fetchingLovKey === key ? (
                      <CircleSpinner size={20} />
                    ) : (
                      <VscSymbolString style={{ fontSize: 24, marginBottom: -2 }} />
                    )}
                  </div>
                ) : (
                  <div />
                )}
              </React.Fragment>
            );
          })}
      </div>
      <Button size="sm" onClick={props.onFetch} style={{ marginBottom: 16, marginTop: 16 }}>
        Fetch
      </Button>
    </div>
  );
}
