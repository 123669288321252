import { DBProdPPO } from '@core/schemas/db/schema.db.prod';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { now } from '@core/util/util.timestamp';
import { CostComponent } from '@core/types/types.costComponent';

export const ProdPPOExample: OmitDBRecord<DBProdPPO>[] = [
  {
    product_id: 123123,
    revision: 3,
    cost_component: CostComponent.LEGOElements,
    currency: 'DKK',
    fmc_eu_cost: 1.23,
    fmc_us_cost: 1.32,
    created_timestamp: now(),
    created_by: 'dkcaslar',
    changed_timestamp: now(),
    changed_by: 'dkcaslar',
  },
];
