import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { DBPackagingAddonRate } from '@core/schemas/db/schema.db.packaging';
import { PackagingAddOnKey } from '@core/types/types.packaging';

export const PackagingAddOnRateExample: OmitDBRecord<DBPackagingAddonRate>[] = [
  {
    year: 1234,
    packing_region: 'EU',
    packing_key: PackagingAddOnKey.Embossing,
    rate_percent: 12,
  },
];
