import { z } from 'zod';
import { DBRecordSchema } from '@core/schemas/db/schema.db.common';
import { PK } from '@core/types/types.pk';

/**
 * DBConfig
 */
export const DBConfigSchema = DBRecordSchema.extend({
  PK: z.literal(PK.Config),
  SK: z.literal(PK.Config),
  fmc_active_year: z.number().int(),
  developer_contacts: z.string(), // JSON.stringify(["emailA", "emailB", ...])
  es_reindex_postfix: z.string().optional(),
  alternative_bom_no_max_inclusive: z.number().int(),
  bom_status_min_inclusive: z.number().int(),
  bom_status_max_inclusive: z.number().int(),
  block_iserver_item: z.boolean(),
  block_iserver_bom: z.boolean(),
});
export type DBConfig = z.infer<typeof DBConfigSchema>;
