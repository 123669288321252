import { DBElemCost } from '@core/schemas/db/schema.db.elements';
import { Calculator } from '../calculator';
import { round5 } from '../calculator.util';
import { CostComponent } from '@core/types/types.costComponent';

export function calculate_CC_ElementsScrap_sum(
  elements: Pick<DBElemCost, 'total_cost' | 'cost_comp'>[],
  scrapRatePercent: number | undefined,
  options?: { noRounding?: boolean },
): number | undefined {
  if (scrapRatePercent === undefined) {
    return 0;
  }

  const costLEGOElements = Calculator.Elements.CC_Elements.Sum(
    elements,
    CostComponent.LEGOElements,
  );
  const costPurchasedElements = Calculator.Elements.CC_Elements.Sum(
    elements,
    CostComponent.PurchasedElements,
  );

  const cost =
    Calculator.Common.QuotientStrict(scrapRatePercent, 100) *
    (costLEGOElements + costPurchasedElements);

  if (options?.noRounding) {
    return cost;
  }

  return round5(cost);
}
