import React, { useContext, useState } from 'react';
import { Switch } from '@lego/klik-ui';
import { getColumnId, TableColumn } from './table';

import { HiddenColumnsContext } from '@frontend/common/lib/contexts';
import { CircleSpinner } from '@frontend/common/components/CircleSpinner';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  HiddenColumnsHideEndpoint,
  HiddenColumnsShowEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.hiddenColumns';

interface ShownTableColumnsModalContentProps<T extends object> {
  tableId: string;
  columns: TableColumn<T>[];
  hiddenColumnIds: string[];
}

export function ShownTableColumnsModalContent<T extends object>(
  props: ShownTableColumnsModalContentProps<T>,
) {
  const [refreshing, setRefreshing] = useState(false);
  const { refreshHiddenColumns } = useContext(HiddenColumnsContext);

  async function showColumn(columnId: string) {
    setRefreshing(true);
    const [err] = await callEndpoint({
      endpoint: HiddenColumnsShowEndpoint,
      input: { tableId: props.tableId, columnId },
      errorHandling: { header: 'Showing column' },
    });
    setRefreshing(false);

    if (err) {
      return;
    }

    refreshHiddenColumns();
  }

  async function hideColumn(columnId: string) {
    setRefreshing(true);
    const [err] = await callEndpoint({
      endpoint: HiddenColumnsHideEndpoint,
      input: { tableId: props.tableId, columnId },
      errorHandling: { header: 'Hiding column' },
    });
    setRefreshing(false);

    if (err) {
      return;
    }

    refreshHiddenColumns();
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto min-content',
        gap: 8,
        padding: '2px',
        position: 'relative',
      }}
    >
      {refreshing && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: 'rgba(255,255,255,0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 100,
          }}
        >
          <CircleSpinner size={40} />
        </div>
      )}
      {props.columns
        .filter((col) => col.title)
        .map((col) => (
          <React.Fragment key={getColumnId(col)}>
            <div>{(col.groupName ? col.groupName + ', ' : '') + col.title}</div>
            <Switch
              aria-label=""
              isChecked={!props.hiddenColumnIds.includes(getColumnId(col))}
              onChange={() => {
                if (props.hiddenColumnIds.includes(getColumnId(col))) {
                  showColumn(getColumnId(col));
                } else {
                  hideColumn(getColumnId(col));
                }
              }}
            />
          </React.Fragment>
        ))}
    </div>
  );
}
