import { ReportStatement } from '@core/calculator/calculator.util.report';
import { TableColumn } from '@frontend/table/table';
import { TbReportSearch } from 'react-icons/tb';

export type ReportWithHeader = { header: string; statements: ReportStatement[] };

export type ReportSetter = (report: ReportWithHeader) => void;

export function getReportColumn<T extends object>(
  descriptionKey: keyof T,
  setReport: ReportSetter,
  showReport: boolean,
): TableColumn<T> {
  return {
    title: '',
    dataIndex: 'report',
    hide: !showReport,
    disableSorting: true,
    align: 'center',
    excludeInExport: true,
    format: (v: ReportStatement[]) =>
      v
        .map(
          ({ description, type, region, value }) => `${region}, ${type}: ${description} = ${value}`,
        )
        .join('; '),
    render: (v: ReportStatement[], r) => (
      <div>
        {v.length > 0 && (
          <TbReportSearch
            onClick={() =>
              setReport({
                header: (r[descriptionKey] as string) || '',
                statements: v,
              })
            }
            style={{ cursor: 'pointer', fontSize: 18 }}
          />
        )}
      </div>
    ),
  };
}
