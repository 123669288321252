import { z } from 'zod';
import { DBMaraSchema } from '@core/schemas/db/schema.db.mara';
import { defineAMMAEndpoint, defineEndpoint } from '@core/util/util.defineEndpoint';
import { MaterialType } from '@core/types/types.mara';

// MaraGetEndpoint
export const MaraGetEndpoint = defineEndpoint({
  path: '/mara/get',
  input: z.object({
    materialId: z.number().int(),
    type: z.enum([MaterialType.Product, MaterialType.Material]),
  }),
  response: DBMaraSchema,
});
export type MaraGetEndpointInput = z.infer<typeof MaraGetEndpoint.input>;
export type MaraGetEndpointResponse = z.infer<typeof MaraGetEndpoint.response>;

// MaraListEndpoint
export const MaraListEndpoint = defineEndpoint({
  path: '/mara/list',
  input: z.object({
    type: z.enum([MaterialType.Product, MaterialType.Material]),
  }),
  response: z.array(DBMaraSchema),
});
export type MaraListEndpointInput = z.infer<typeof MaraListEndpoint.input>;
export type MaraListEndpointResponse = z.infer<typeof MaraListEndpoint.response>;

export const MaterialEndpoint = defineAMMAEndpoint({
  method: 'POST',
  path: '/v1/material',
  bodyInput: z.object({ materialId: z.number().int() }),
  response: DBMaraSchema,
});

export const MaterialsEndpoint = defineAMMAEndpoint({
  method: 'POST',
  path: '/v1/materials',
  bodyInput: z.object({ materialIds: z.array(z.number().int()) }),
  response: z.array(DBMaraSchema.or(z.null())),
});
