import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { DBUserSettings } from '@core/schemas/db/schema.db.userSettings';

export const UserSettingsExample: OmitDBRecord<DBUserSettings>[] = [
  {
    comma_as_decimal_seperator: true,
    email: 'abc@lego.com',
    name: 'ABC',
  },
];
