import { Table } from '@frontend/table/table';
import { VirtualGroupsColumns, getAllUsersColumns } from '../administration.columns.am';
import { useContext, useEffect, useMemo, useState } from 'react';
import { NCModal } from '@frontend/common/components/NCModal';
import { VIRTUAL_GROUPS } from '@core/const/const.VIRTUAL_GROUPS';
import { Unpacked } from '@core/util/util.typing';
import { VirtualGroupModalContent } from './VirtualGroupModalContent';
import { Tabs } from '@lego/klik-ui';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  AMGetAllUsersEndpoint,
  AMGetAllUsersEndpointResponse,
} from '@core/schemas/endpoint/schema.endpoint.am';
import { UserDataContext } from '@frontend/common/lib/contexts';

export function AccessManagement() {
  const [selectedVirtualGroup, setSelectedVirtualGroup] =
    useState<Unpacked<typeof VIRTUAL_GROUPS>>();
  const [users, setUsers] = useState<AMGetAllUsersEndpointResponse>([]);
  const [isFetchingUser, setIsFetchingUsers] = useState(false);

  const { getName } = useContext(UserDataContext);

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    setIsFetchingUsers(true);
    const [err, allUsers] = await callEndpoint({
      endpoint: AMGetAllUsersEndpoint,
      input: {},
      errorHandling: {
        header: 'Fetching all users',
      },
    });
    setIsFetchingUsers(false);

    if (err) {
      return;
    }

    setUsers(allUsers);
  }

  const allUsersColumns = useMemo(() => getAllUsersColumns(getName), [getName]);

  return (
    <div>
      {selectedVirtualGroup && (
        <NCModal
          header={selectedVirtualGroup.name}
          isOpen
          width={800}
          onClose={() => setSelectedVirtualGroup(undefined)}
        >
          <VirtualGroupModalContent group={selectedVirtualGroup} />
        </NCModal>
      )}
      <Tabs>
        <Tabs.TabList>
          <Tabs.Tab className="tab-header">Groups</Tabs.Tab>
          <Tabs.Tab className="tab-header">Users</Tabs.Tab>
        </Tabs.TabList>
        <Tabs.TabPanels>
          <Tabs.TabPanel>
            <Table
              id="administration_virtual-groups"
              rows={VIRTUAL_GROUPS}
              columns={VirtualGroupsColumns}
              rowKey="name"
              itemType="group"
              removeInfoText
              removeSearch
              onRowClick={setSelectedVirtualGroup}
            />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <Table
              id="administration_virtual-groups"
              rows={users}
              columns={allUsersColumns}
              isRefreshing={isFetchingUser}
              onRefresh={fetchUsers}
              rowKey="email"
              itemsPerPage={15}
              itemType="user"
            />
          </Tabs.TabPanel>
        </Tabs.TabPanels>
      </Tabs>
    </div>
  );
}
