import { Input } from '@lego/klik-ui';
import { Search } from '@lego/klik-ui/icons';

interface SearchInputProps {
  value: string;
  onChange(newValue: string): void;
  itemType?: string;
}

export function SearchInput(props: SearchInputProps) {
  return (
    <Input.Group>
      <Input.LeftElement
        pointerEvents="none"
        style={{ height: '100%', width: 'min-content', paddingLeft: 8 }}
      >
        <Search color="slate.400" />
      </Input.LeftElement>
      <Input
        placeholder={`Search ${(props.itemType ?? 'item') + 's'}...`}
        size="sm"
        style={{ paddingLeft: 30, fontSize: 16 }}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </Input.Group>
  );
}
