import {
  AdministrationGetBOMEndpoint,
  AdministrationUpdateBOMEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.administration';
import {
  REGEX_8DIGITS,
  REGEX_8DIGITS_OR_EMPTY,
  REGEX_NONNEGATIVE_INTEGER_OR_EMPTY,
  REGEX_POSITIVE_INTEGER_OR_EMPTY,
} from '@core/types/types.regex';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { FInput } from '@frontend/form/components/FInput';
import { Button } from '@lego/klik-ui';
import React, { createRef, useState } from 'react';
import { BOMMaterial } from '../administration.lib';
import { showErrorToast, showSuccessToast } from '@frontend/common/lib/functions';
import { DeleteBold } from '@lego/klik-ui/icons';
import { FInputReadOnly } from '@frontend/form/components/FInputReadOnly';
import { prettifyTimestamp } from '@frontend/common/lib/common.util';

export function ManipulateBOM() {
  const [productId, setProductId] = useState('');
  const [materials, setMaterials] = useState<BOMMaterial[] | undefined>(undefined);
  const [lastChanged, setLastChanged] = useState('');
  const [status, setStatus] = useState('');
  const [bomNumber, setBomNumber] = useState('');
  const [materialId, setMaterialId] = useState('');
  const [quantity, setQuantity] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  async function fetchBom() {
    if (!productId || !REGEX_8DIGITS.test(productId)) {
      return;
    }

    setIsFetching(true);
    const [err, data] = await callEndpoint({
      endpoint: AdministrationGetBOMEndpoint,
      input: { productId: Number(productId) },
      errorHandling: {
        header: 'Fetching BOM',
        handlers: {
          404: () => {
            setMaterials([]);
            setStatus('15');
          },
        },
      },
    });
    setIsFetching(false);

    if (err) {
      return;
    }

    if (data) {
      setLastChanged(data.lastChanged);
      setMaterials(data.materials);
      setStatus(`${data.status}`);
      setBomNumber(`${data.bomNumber}`);
    }
  }

  function addMaterial() {
    if (!materials || !materialId || !quantity) {
      return;
    }

    const existingMaterial = materials.find((m) => m.materialId === Number(materialId));

    if (existingMaterial) {
      showErrorToast('Material already in BOM');
      return;
    }

    setMaterials((curr) =>
      curr?.concat({ materialId: Number(materialId), quantity: Number(quantity) }),
    );

    setMaterialId('');
    setQuantity('');
  }

  async function updateBom() {
    if (status === '' || !materials || bomNumber === '') {
      return;
    }

    setIsUpdating(true);
    const [err] = await callEndpoint({
      endpoint: AdministrationUpdateBOMEndpoint,
      input: {
        productId: Number(productId),
        materials,
        bomNumber: Number(bomNumber),
        status: Number(status),
      },
      errorHandling: { header: 'Updating BOM' },
    });
    setIsUpdating(false);

    if (err) {
      return;
    }

    showSuccessToast('Updated BOM', 'Successfully updated BOM');
    fetchBom();
  }

  function deleteMaterial(materialId: number) {
    setMaterials((curr) => curr?.filter((m) => m.materialId !== materialId));
  }

  const materialIdInputRef = createRef<HTMLInputElement>();
  const quantityInputRef = createRef<HTMLInputElement>();

  return (
    <div>
      <div style={{ display: 'flex', columnGap: 8 }}>
        <FInput
          editable
          placeholder="Product ID..."
          value={productId}
          onChange={setProductId}
          regex={REGEX_8DIGITS_OR_EMPTY}
          align="start"
          onEnter={fetchBom}
        />
        <Button
          size="sm"
          onClick={fetchBom}
          isLoading={isFetching}
          disabled={!productId || !REGEX_8DIGITS.test(productId)}
        >
          Get BOM
        </Button>
      </div>
      {materials && (
        <div style={{ marginTop: 24 }}>
          <FInputReadOnly label="Last changed" value={prettifyTimestamp(lastChanged)} />
          <FInput
            editable
            label="BOM number"
            align="start"
            onChange={setBomNumber}
            value={bomNumber}
            style={{ width: 200, marginTop: 8 }}
            regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
            disallowChangeOnInvalid
          />
          <FInput
            editable
            label="Status"
            align="start"
            onChange={setStatus}
            value={status}
            style={{ width: 120, marginTop: 8 }}
            regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
            disallowChangeOnInvalid
          />
          <div style={{ marginTop: 8, fontWeight: 'bold' }}>Materials:</div>
          <div
            style={{
              marginTop: 8,
              display: 'grid',
              gridTemplateColumns: '100px 100px min-content',
              columnGap: 8,
              rowGap: 2,
            }}
          >
            <div style={{ whiteSpace: 'nowrap', marginBottom: 8 }}>Material ID</div>
            <div style={{ marginBottom: 8 }}>Quantity</div>
            <div />
            {materials
              .sort((a, b) => a.materialId - b.materialId)
              .map((material) => (
                <React.Fragment key={material.materialId}>
                  <div>{material.materialId}</div>
                  <div>{material.quantity}</div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <DeleteBold
                      className="clickable-icon clickable-icon__danger"
                      onClick={() => deleteMaterial(material.materialId)}
                    />
                  </div>
                </React.Fragment>
              ))}
            <FInput
              editable
              inputRef={materialIdInputRef}
              value={materialId}
              align="start"
              placeholder="Material ID"
              onChange={setMaterialId}
              regex={REGEX_POSITIVE_INTEGER_OR_EMPTY}
              disallowChangeOnInvalid
              style={{ marginTop: 8 }}
            />
            <FInput
              editable
              align="start"
              inputRef={quantityInputRef}
              value={quantity}
              placeholder="Quantity"
              onChange={setQuantity}
              regex={REGEX_POSITIVE_INTEGER_OR_EMPTY}
              disallowChangeOnInvalid
              onEnter={() => {
                addMaterial();
                materialIdInputRef.current?.focus();
              }}
              style={{ marginTop: 8 }}
            />
            <Button
              size="sm"
              colorScheme="success"
              disabled={!materialId || !quantity}
              onClick={addMaterial}
              style={{ marginTop: 8 }}
            >
              Add
            </Button>
          </div>
        </div>
      )}
      {materials && (
        <Button
          disabled={!status || !bomNumber}
          isLoading={isUpdating}
          style={{ marginTop: 36 }}
          size="sm"
          onClick={updateBom}
        >
          Update BOM
        </Button>
      )}
    </div>
  );
}
