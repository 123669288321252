import { z } from 'zod';
import { PK } from '@core/types/types.pk';
import { DBRecordSchema } from '@core/schemas/db/schema.db.common';
import { RegionEnum, RegionFMCEnum } from '@core/schemas/schema.common';
import { createDescription } from '@core/util/util.schemaPropDescription';
import { ProdHeadCoreSchema } from './schema.db.prod';

/**
 * DBProdHeadBI
 */
export type DBProdHeadBI = z.infer<typeof DBProdHeadBISchema>;
export const DBProdHeadBISchema = ProdHeadCoreSchema.extend({
  PK: z.literal(PK.ProdHeadBI),
  bi_qr_code: z.boolean(),
  bi_status: z
    .number()
    .int()
    .describe(createDescription({ supportLov: true })),
});

/**
 * DBBICost
 */
export type DBBICost = z.infer<typeof DBBICostSchema>;
export const DBBICostSchema = DBRecordSchema.extend({
  PK: z.literal(PK.BICost),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  revision: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  book_number: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  fmc_region: RegionFMCEnum.describe(createDescription({ isPartOfKey: true })),
  region: RegionEnum,
  super_design: z.number().int().optional(),
  number_of_pages: z.number().int(),
  number_of_colors_frontside: z.number().int(),
  number_of_colors_backside: z.number().int(),
  number_of_cover_pages: z.number().int(),
  paper_weight_gr: z.number().int(),
  cover_weight_gr: z.number().int(),
  cost: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
  note: z.string(),
});

/**
 * DBBIRate
 */

export type DBBIRate = z.infer<typeof DBBIRateSchema>;
export const DBBIRateSchema = DBRecordSchema.extend({
  PK: z.literal(PK.BIRate),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  super_design: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  total_number_of_pages: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  number_of_cover_pages: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  number_of_colors_frontside: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  number_of_colors_backside: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  paper_weight_gr: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  cover_weight_gr: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  fmc1_with_scrap: z.number(),
  fmc2_with_scrap: z.number(),
  fmc1_qr_with_scrap: z.number(),
  fmc2_qr_with_scrap: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
});
