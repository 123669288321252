import { ReportStatement } from '@core/calculator/calculator.util.report';
import { NCMessageModal } from './NCMessageModal';
import { useContext, useMemo } from 'react';
import { Table } from '@frontend/table/table';
import { RegionEnum } from '@core/schemas/schema.common';
import { getReportStatementsColumns } from '../lib/common.columns';
import { UserDataContext } from '../lib/contexts';

interface ReportModalProps {
  report: { header: string; statements: ReportStatement[] } | undefined;
  onClose(): void;
}

export function ReportModal(props: ReportModalProps) {
  const { userData } = useContext(UserDataContext);

  const [other, eu, us] = useMemo(() => {
    const otherPre: ReportStatement[] = [];
    const euPre: ReportStatement[] = [];
    const usPre: ReportStatement[] = [];

    if (props.report) {
      props.report.statements.forEach((s) => {
        if (s.region === RegionEnum.Enum.EU) {
          euPre.push(s);
        } else if (s.region === RegionEnum.Enum.US) {
          usPre.push(s);
        } else {
          otherPre.push(s);
        }
      });
    }

    return [otherPre, euPre, usPre];
  }, [props.report]);

  const columns = useMemo(
    () => getReportStatementsColumns(!!userData?.comma_as_decimal_seperator),
    [userData?.comma_as_decimal_seperator],
  );

  return (
    <NCMessageModal
      header={`Report for ${props.report?.header}`}
      size="4xl"
      body={
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 24 }}>
            {other.length > 0 && (
              <div>
                <div style={{ marginBottom: 8, marginLeft: 8 }}>Both</div>
                <Table
                  id="report-other"
                  rows={other}
                  rowKey={(r) => r.region + ',' + r.type}
                  removeInfoText
                  removeSearch
                  rowHasError={(r) => r.isError}
                  columns={columns}
                />
              </div>
            )}
            {eu.length > 0 && (
              <div>
                <div style={{ marginBottom: 8, marginLeft: 8 }}>EU</div>
                <Table
                  id="report-eu"
                  rows={eu}
                  rowKey={(r) => JSON.stringify(r)}
                  removeInfoText
                  removeSearch
                  rowHasError={(r) => r.isError}
                  columns={columns}
                />
              </div>
            )}
            {us.length > 0 && (
              <div>
                <div style={{ marginBottom: 8, marginLeft: 8 }}>US</div>
                <Table
                  id="report-us"
                  rows={us}
                  rowKey={(r) => JSON.stringify(r)}
                  removeInfoText
                  removeSearch
                  rowHasError={(r) => r.isError}
                  columns={columns}
                />
              </div>
            )}
          </div>
        </div>
      }
      isOpen={!!props.report}
      onClose={props.onClose}
      variant="info"
    />
  );
}
