import { DBRecord } from '../db/schema.db.common';

export const OMIT_DB_RECORD: Record<keyof DBRecord, true> = {
  PK: true,
  SK: true,
} as const;

export const OMIT_DB_RECORD_EXCEPT_PK: Record<Exclude<keyof DBRecord, 'PK'>, true> = {
  SK: true,
} as const;
