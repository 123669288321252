import { DBPackagingScrapRate } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { PackSource } from '@core/types/types.packaging';

export const PackagingScrapRateExample: OmitDBRecord<DBPackagingScrapRate>[] = [
  {
    year: 1234,
    source: PackSource.TruvantBoosterLine,
    packing_region: 'EU',
    rate_percent: 10,
  },
];
