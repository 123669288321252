import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { Region } from '@core/schemas/schema.common';

export type ReportStatement = {
  region: Region | 'other';
  type: string;
  description: string;
  shortDescription: string;
  value: string | number | undefined;
  isError: boolean;
  unit: string | undefined;
};

type RateType =
  | 'Packing standard final pack rate'
  | 'Packing standard prepack rate'
  | 'Packing non-standard rate'
  | 'Addon rate'
  | 'Packaging rate'
  | 'Scrap rate'
  | 'Packaging foil rate'
  | 'Packaging non-standard rate'
  | 'Material'
  | 'default_from_quantity';

export function getErrorReportStatement(
  region: Region | 'other',
  errorMessage: string,
): ReportStatement {
  return {
    region,
    type: 'Error',
    description: errorMessage,
    shortDescription: errorMessage,
    value: undefined,
    isError: true,
    unit: undefined,
  };
}

export function getRateReportStatementsBoth<T extends object>(props: {
  rateType: RateType;
  rateIdentifier?: [T | undefined, (keyof OmitDBRecord<T> | Record<string, unknown>)[]];
  rate?: number | string | 'Not found';
  fiveDecimals?: boolean;
  unit?: string;
}): [ReportStatement, ReportStatement] {
  return [
    getRateReportStatement({ ...props, region: 'EU' }),
    getRateReportStatement({ ...props, region: 'US' }),
  ];
}

export function getRateReportStatement<T extends object>(props: {
  region?: Region | 'other';
  rateType: RateType;
  rateTypeAdditionl?: string;
  rateIdentifier?: [T | undefined, (keyof OmitDBRecord<T> | Record<string, unknown>)[]];
  rate?: number | (string & {}) | 'Not found';
  fiveDecimals?: boolean;
  unit?: string;
  isError?: boolean;
}): ReportStatement {
  const { region, rateType, rateTypeAdditionl, rateIdentifier, rate, fiveDecimals, unit, isError } =
    props;

  let description = '';

  if (rateType === 'default_from_quantity') {
    description = 'Quantity is 0 or missing, defaulting to 0';
  } else {
    description = rateType;

    if (rateTypeAdditionl) {
      description += `, ${rateTypeAdditionl}`;
    }

    if (rateIdentifier?.[0]) {
      description += ` ${formatObject<T>(rateIdentifier[0], rateIdentifier[1])}`;
    }

    if (rate === 'Not found') {
      description += ' missing';
    }
  }

  let value: string | number =
    typeof rate === 'string' ? rate : (rate || 0).toFixed(fiveDecimals ? 5 : 2);

  if (rateType === 'default_from_quantity') {
    value = 0;
  }

  return {
    region: region ?? 'other',
    type: 'Rate',
    description,
    shortDescription:
      rate === 'Not found'
        ? `Rate not found${!region || region === 'other' ? '' : ` (${region})`}`
        : description,
    value,
    isError: isError !== undefined ? isError : rate === 'Not found',
    unit: unit ?? 'DKK',
  };
}

export function getCostReportStatement(props: {
  region?: Region | 'other';
  cache?: boolean;
  rate?: number | [string, number];
  quantity?: number;
  cost?: number;
  fiveDecimals?: boolean;
  extraTag?: string;
  unit?: string;
}): ReportStatement {
  const { region, cost, quantity, rate, cache, fiveDecimals, extraTag, unit } = props;

  let description = '';

  if (cache) {
    description = 'Using saved cost';
  } else {
    description = `${quantity === undefined ? '' : `Quantity (${quantity}) · `}${
      rate === undefined
        ? 'N/A'
        : typeof rate === 'object'
        ? rate[0] + ` (${rate[1]})`
        : `Rate (${rate})`
    }`;
  }

  return getCostCustomReportStatement({ cost, description, region, extraTag, fiveDecimals, unit });
}

export function getCostCustomReportStatement(props: {
  region?: Region | 'other';
  description: string;
  cost?: number;
  fiveDecimals?: boolean;
  extraTag?: string;
  unit?: string;
}): ReportStatement {
  const { region, cost, description, fiveDecimals, extraTag, unit } = props;

  return {
    region: region ?? 'other',
    type: 'Cost' + (extraTag ? ` ${extraTag}` : ''),
    description,
    shortDescription: description,
    value: cost === undefined ? 'N/A' : cost.toFixed(fiveDecimals ? 5 : 2),
    isError: false,
    unit: unit ?? 'DKK',
  };
}

export function getReportStatement(props: {
  region?: Region | 'other';
  description: string;
  value?: number;
  unit?: string;
}): ReportStatement {
  const { description, value, region, unit } = props;

  return {
    region: region ?? 'other',
    type: 'Info',
    description,
    shortDescription: description,
    value,
    isError: false,
    unit,
  };
}

function formatKey(key: string) {
  const elements = key.split('_');

  const keyMap: Record<string, string> = { id: 'ID' };

  let firstElement = elements[0][0].toUpperCase() + elements[0].slice(1);

  firstElement = keyMap[firstElement.toLowerCase()] ?? firstElement;

  const rest = elements
    .slice(1)
    .map((e) => keyMap[e.toLowerCase()] ?? e)
    .join(' ');

  return firstElement + (elements.length > 1 ? ' ' + rest : '');
}

function formatObject<T extends object>(obj: T, keys: (keyof T | Record<string, unknown>)[]) {
  const trimmedObj: Record<string, unknown> = {};

  keys.forEach((k) => {
    if (typeof k === 'string') {
      trimmedObj[formatKey(k as string)] = obj[k];
    } else {
      Object.entries(k).forEach(([k, v]) => (trimmedObj[formatKey(k)] = v));
    }
  });

  return (
    '(' +
    Object.entries(trimmedObj)
      .map(([k, v]) => `${k}: ${v}`)
      .join(', ') +
    ')'
  );
}
