import { Calculator } from '@core/calculator/calculator';
import { DBProdCost, DBProdPPO } from '@core/schemas/db/schema.db.prod';
import { CostComponent } from '@core/types/types.costComponent';
import { CostOverviewRow } from '@frontend/common/lib/models';
import { useMemo } from 'react';

export function useLeadPriceCostsOverviewRow(productCosts: {
  ppo: Pick<DBProdPPO, 'fmc_eu_cost' | 'fmc_us_cost'> | undefined;
  cost: Pick<DBProdCost, 'fmc_eu_cost' | 'fmc_us_cost'> | undefined;
}): {
  cc_LeadPrice: CostOverviewRow;
} {
  const cc_LeadPrice = useMemo(() => {
    const r: CostOverviewRow = {
      costComp: CostComponent.LeadPrice,
      description: 'Lead price (NIP)',
      currency: 'DKK',
      fmc1ppo: productCosts.ppo?.fmc_eu_cost,
      fmc1cost: productCosts.cost?.fmc_eu_cost,
      fmc1variance: Calculator.Common.Variance(
        productCosts.ppo?.fmc_eu_cost,
        productCosts.cost?.fmc_eu_cost,
      ),
      fmc2ppo: productCosts.ppo?.fmc_us_cost,
      fmc2cost: productCosts.cost?.fmc_us_cost,
      fmc2variance: Calculator.Common.Variance(
        productCosts.ppo?.fmc_eu_cost,
        productCosts.cost?.fmc_us_cost,
      ),
    };
    return r;
  }, [productCosts]);

  return { cc_LeadPrice };
}
