import { ElementsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.elements';
import { FinalCostsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.finalCosts';

import { ElementCosts } from '@frontend/elements/components/ElementsCosts';
import { FInput } from '@frontend/form/components/FInput';

interface FinalCostsElementsTabProps {
  elementsData: Exclude<FinalCostsGetEndpointResponse['elementsData'], null>;
  elementErrors: ElementsGetEndpointResponse['elementErrors'];
  bomStatus: number | undefined;
}

export function FinalCostsElementsTab(props: FinalCostsElementsTabProps) {
  return (
    <div>
      <FInput
        label="BOM status"
        value={props.bomStatus}
        style={{ marginBottom: 16 }}
        align="start"
      />
      <ElementCosts
        elementCosts={props.elementsData.elementCosts}
        elementErrors={props.elementErrors}
      />
    </div>
  );
}
