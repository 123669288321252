import { Region, RegionFMC } from '@core/schemas/schema.common';

export const pageRoutes = {
  Elements: '/elements',
  Packaging: '/packaging',
  Instructions: '/building-instructions',
  'Final costs': '/final-costs',
  Reporting: {
    'Elements costs': '/reporting/elements-costs',
    'Elements errors': '/reporting/elements-errors',
    Packaging: '/reporting/packaging',
    Instructions: '/reporting/building-instructions',
    'Instructions (LBX)': '/reporting/building-instructions-lbx',
    'Costing status': '/reporting/costing-status',
    'Final costs': '/reporting/final-costs',
  },
  Administration: '/administration',
};

const otherRoutes = {
  Settings: '/settings',
};

export const allRoutes = { ...pageRoutes, ...otherRoutes };

export const BI_STATUS_TO_PRETTY: { [status in string]: string } = {
  INI: 'Initial',
  APP: 'Approved',
};

export const FMCREGION_TO_PRETTY: { [fmc in RegionFMC]: string } = {
  FMC1: 'EU',
  FMC2: 'US',
};

export const REGION_TO_PRETTY: { [fmc in Region]: string } = {
  EU: 'EU',
  US: 'US',
};

export const LF_LAST_VISITED = 'nc_lf_last-visited';
export const LF_SUGGESTIONS = 'nc_lf_suggestions';

export const COLOR_SELECTED_BLUE = '#247bbf';
