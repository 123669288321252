import { Table } from '@frontend/table/table';
import { useMemo, useState } from 'react';
import { ProductIDSelectionRow, getProductIDSelectionsColumns } from '../reporting.lib';
import { Button } from '@lego/klik-ui';
import { DeleteBold } from '@lego/klik-ui/icons';
import { FInput } from '@frontend/form/components/FInput';
import { REGEX_NONNEGATIVE_INTEGER_OR_EMPTY } from '@core/types/types.regex';

interface ProductIdSelectionsContentProps {
  productIds: number[];
  productIdRanges: { from: number; to: number }[];
  onRemove(selection: ProductIDSelectionRow): void;
  onMultiValuePaste(productIds: string[]): void;
  addCurrentProductId(productId: string): void;
}

export function ProductIdSelectionsContent(props: ProductIdSelectionsContentProps) {
  const [productId, setProductId] = useState('');
  const [selected, setSelected] = useState<ProductIDSelectionRow[]>([]);

  const columns = useMemo(() => getProductIDSelectionsColumns(props.onRemove), [props.onRemove]);
  const rows = useMemo(() => {
    const preRows: ProductIDSelectionRow[] = [];

    props.productIds
      .sort((a, b) => Number(a) - Number(b))
      .forEach((sel) => preRows.push({ type: 'single', value: `${sel}` }));

    props.productIdRanges
      .sort((a, b) => Number(a.from) - Number(b.from))
      .forEach(({ from, to }) => preRows.push({ type: 'range', value: `${from}-${to}` }));

    return preRows;
  }, [props.productIds, props.productIdRanges]);

  function removeMultiple() {
    selected.forEach(props.onRemove);
  }

  return (
    <div>
      <Table
        id="report_product-id-selctions"
        columns={columns}
        headerContent={
          <div>
            <Button
              size="sm"
              leftIcon={<DeleteBold />}
              colorScheme="error"
              variant="outline"
              disabled={selected.length === 0}
              onClick={removeMultiple}
            >
              Remove
            </Button>
          </div>
        }
        multiSelection={{ selected, setSelected }}
        rowKey="value"
        rows={rows}
        itemType="selection"
        fixedHeight={430}
        itemsPerPage={15}
      />
      <div style={{ marginTop: 24, width: '60%' }}>
        <div style={{ display: 'flex', columnGap: 8, alignItems: 'end', width: '100%' }}>
          <FInput
            placeholder="Product ID"
            editable
            align="start"
            regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
            disallowChangeOnInvalid
            onMultiValuePaste={props.onMultiValuePaste}
            disableSmartBehavior
            value={productId}
            disableBlurOnEnter
            onChange={setProductId}
            onEnter={() => props.addCurrentProductId(productId)}
            style={{ width: 'inherit' }}
          />
          <Button
            colorScheme="success"
            size="sm"
            disabled={!productId}
            onClick={() => props.addCurrentProductId(productId)}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
}
