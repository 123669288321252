type DefaultValueType = number | string | boolean | undefined;

class MigrationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MigrationError';
  }
}

export function parseFormattedFloat<T extends DefaultValueType>(
  valueInObject: Record<string, string>,
  defaultValue?: T,
): number | T {
  if (Object.keys(valueInObject).length !== 1) {
    throw new MigrationError('valueInObject must contain exactly one key');
  }

  const value = Object.values(valueInObject)[0];

  const parsedValue = parseFloat(value.replace(/\./gm, '').replace(/\,/, '.'));

  if (value === '' || isNaN(parsedValue)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return defaultValue;
  }

  return parsedValue;
}

export function parseFormattedInt<T extends DefaultValueType>(
  valueInObject: Record<string, string>,
  defaultValue?: T,
): number | T {
  const asFloatOrDefaultValue = parseFormattedFloat(valueInObject, defaultValue);

  if (typeof asFloatOrDefaultValue !== 'number') {
    return asFloatOrDefaultValue;
  }

  return Math.floor(asFloatOrDefaultValue);
}
