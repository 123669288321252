import { useCallback, useEffect, useMemo, useState } from 'react';
import { PackingStandardCostsRow } from '@frontend/packaging/lib/packaging.types';
import { Calculator } from '@core/calculator/calculator';
import {
  getErrorStatement,
  showInfoToast,
  showInternalApplicationError,
} from '@frontend/common/lib/functions';
import { useMessages } from '@frontend/common/lib/hooks/useMessages';
import { Message } from '@frontend/common/lib/models';
import { Region, RegionFMC } from '@core/schemas/schema.common';
import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import {
  DBPackingStandardPrepackRate,
  DBPackingStandardFinalPackRate,
  DBPackingStandardCost,
} from '@core/schemas/db/schema.db.packaging';
import { PasteChanges } from '@frontend/table/lib';
import { PackagingSize } from '@core/schemas/interface/schema.interface';
import { PackSource, PackingKeyId } from '@core/types/types.packaging';
import { PACKING_KEYS } from '@core/const/const.PACKING_KEYS';

export type UsePackingStandardCostsRowsReturnValue = {
  packingStandardCostsRows: PackingStandardCostsRow[];
  handlers: {
    changeQuantity: (packKey: PackingKeyId, fmcRegion: RegionFMC, newQuantity: number) => void;
    copyQuantities: () => void;
    resetQuantities: (props: {
      source: PackSource | undefined;
      packaging: PackagingSize;
      packingRegionEU: Region;
      packingRegionUS: Region;
      salesRegionEU: Region;
      salesRegionUS: Region;
    }) => void;
    onPaste(updates: PasteChanges<PackingStandardCostsRow>): void;
    importRows(rows: DBPackingStandardCost[]): void;
  };
  totals: {
    cost: { fmc1: number; fmc2: number };
    quantity: { fmc1: number; fmc2: number };
  };
  messages: Message[];
};

const DEFAULT_QUANTITIES = {
  BIPRE: { FMC1: 0, FMC2: 0 },
  DOUBL: { FMC1: 0, FMC2: 0 },
  F99: { FMC1: 0, FMC2: 0 },
  FIPAK: { FMC1: 0, FMC2: 0 },
  JOKER: { FMC1: 0, FMC2: 0 },
  QDOUB: { FMC1: 0, FMC2: 0 },
  QSING: { FMC1: 0, FMC2: 0 },
  SINGL: { FMC1: 0, FMC2: 0 },
  SPECI: { FMC1: 0, FMC2: 0 },
};

export function usePackingStandardCostsRows(props: {
  packingStandardFinalPackRates: DBPackingStandardFinalPackRate[] | undefined;
  packingStandardPrepackRates: DBPackingStandardPrepackRate[] | undefined;
  packingStandardCosts: PackagingGetEndpointResponse['packingStandardCosts'] | undefined;
  selectedSource: PackSource | undefined;
  selectedPackaging: PackagingSize | undefined;
  year: number | undefined;
  packingRegionEU: Region | undefined;
  packingRegionUS: Region | undefined;
  salesRegionEU: Region;
  salesRegionUS: Region;
  readOnly?: boolean;
}): UsePackingStandardCostsRowsReturnValue {
  const {
    packingStandardPrepackRates,
    packingStandardCosts,
    selectedPackaging,
    selectedSource,
    packingStandardFinalPackRates,
    year,
    readOnly,
    packingRegionEU,
    packingRegionUS,
    salesRegionEU,
    salesRegionUS,
  } = props;
  const [quantities, setQuantities] = useState<Record<PackingKeyId, Record<RegionFMC, number>>>({
    ...DEFAULT_QUANTITIES,
  });
  const [packingStandardCostsRows, setPackingStandardCostsRows] = useState<
    PackingStandardCostsRow[]
  >([]);
  const [isResetting, setIsResetting] = useState(false);

  const {
    addMessage: addLocalMessage,
    clearMessages: clearLocalMessages,
    messages: localMessages,
  } = useMessages();

  useEffect(() => {
    const newQuantities = { ...DEFAULT_QUANTITIES };

    packingStandardCosts?.forEach(
      (packKey) =>
        (newQuantities[packKey.packing_key] = {
          FMC1: packKey.fmc1_quantity,
          FMC2: packKey.fmc2_quantity,
        }),
    );

    setQuantities(newQuantities);
  }, [packingStandardCosts]);

  const changeQuantity = useCallback(
    (packKey: PackingKeyId, fmcRegion: RegionFMC, newQuantity: number) =>
      setQuantities((qs) => {
        const newQuantities = { ...qs };

        newQuantities[packKey][fmcRegion] = newQuantity;

        return newQuantities;
      }),
    [],
  );

  const copyQuantities = useCallback(
    () =>
      setQuantities((curr) => {
        const newQuantities = { ...curr };
        Object.entries(curr).forEach(([packKey, fmcQuantities]) => {
          newQuantities[packKey as PackingKeyId].FMC2 = fmcQuantities.FMC1;
        });
        return newQuantities;
      }),
    [],
  );

  const resetQuantities = useCallback(
    (props: {
      source: PackSource | undefined;
      packaging: PackagingSize;
      packingRegionEU: Region;
      packingRegionUS: Region;
      salesRegionEU: Region;
      salesRegionUS: Region;
    }) => {
      setIsResetting(true);

      const { packaging, packingRegionEU, packingRegionUS, salesRegionEU, salesRegionUS, source } =
        props;

      if (!source || !packingRegionEU || !packingRegionUS) {
        setIsResetting(false);
        return;
      }

      if (year === undefined) {
        showInternalApplicationError();
        setIsResetting(false);
        return;
      }

      setQuantities((currQuantities) => {
        const newQuantities = structuredClone(currQuantities);

        Object.keys(currQuantities).forEach((k) => {
          if (k === PackingKeyId.FinalPack) {
            // see if we can autofill FIPAK quantities
            const { FMC1, FMC2 } = Calculator.Pack.Packing.StandardCost.FindFinalPackRates({
              packingKey: PackingKeyId.FinalPack,
              packaging,
              packingRegionEU,
              packingRegionUS,
              salesRegionEU,
              salesRegionUS,
              source,
              standardRates: packingStandardFinalPackRates,
              year,
            });
            newQuantities[k] = { FMC1: FMC1?.fmc_quantity || 0, FMC2: FMC2?.fmc_quantity || 0 };
          } else {
            newQuantities[k as PackingKeyId] = { FMC1: 0, FMC2: 0 };
          }
        });

        return newQuantities;
      });
      setIsResetting(false);
    },
    [packingStandardFinalPackRates, year],
  );

  useEffect(() => {
    if (!selectedPackaging || !year || !packingRegionEU || !packingRegionUS || !selectedSource) {
      setPackingStandardCostsRows([]);
      return;
    }

    if (isResetting) {
      return;
    }

    (async () => {
      const rows = await Promise.all(
        PACKING_KEYS.map(async (packKey) => {
          const { FMC1: quantity1, FMC2: quantity2 } = quantities[packKey.packingKey] || {
            FMC1: 0,
            FMC2: 0,
          };

          try {
            const existingPackingStandardCost = packingStandardCosts?.find(
              (pc) => pc.packing_key === packKey.packingKey,
            );

            const { fmc1_cost, fmc2_cost, report } =
              await Calculator.Pack.Packing.StandardCost.Calculate({
                input: {
                  packingStandardCost: {
                    fmc1quantity: quantity1,
                    fmc2quantity: quantity2,
                    packingKey: packKey.packingKey,
                  },
                  source: selectedSource,
                  packaging: selectedPackaging,
                  packingRegionEU,
                  packingRegionUS,
                  salesRegionEU,
                  salesRegionUS,
                  fmcYear: year,
                },
                cache: {
                  finalPackRates: packingStandardFinalPackRates,
                  prepackRates: packingStandardPrepackRates,
                  cost: readOnly
                    ? existingPackingStandardCost || { fmc1_cost: 0, fmc2_cost: 0 }
                    : undefined,
                },
              });

            return {
              ...packKey,
              fmc1quantity: quantity1,
              fmc1cost: fmc1_cost,
              fmc2quantity: quantity2,
              fmc2cost: fmc2_cost,
              bun: 'PC',
              currency: 'DKK',
              report,
            };
          } catch (error) {
            const errorStatement = getErrorStatement(error);

            return {
              packingKey: packKey.packingKey,
              description: packKey.description,
              fmc1quantity: quantity1,
              fmc1cost: 0,
              fmc2quantity: quantity2,
              fmc2cost: 0,
              bun: 'PC',
              currency: 'DKK',
              report: errorStatement ? [errorStatement] : [],
            };
          }
        }),
      );
      setPackingStandardCostsRows(rows);
    })();
  }, [
    isResetting,
    selectedSource,
    quantities,
    packingStandardPrepackRates,
    packingStandardFinalPackRates,
    selectedPackaging,
    year,
    packingRegionEU,
    packingRegionUS,
    salesRegionEU,
    salesRegionUS,
    addLocalMessage,
    clearLocalMessages,
    readOnly,
    packingStandardCosts,
  ]);

  useEffect(() => {
    clearLocalMessages(/packing-standard-cost.*/);

    packingStandardCostsRows.forEach((psc) => {
      psc.report
        .filter((report) => report.isError)
        .forEach((report) => {
          addLocalMessage({
            id: `packing-standard-cost_error_${psc.packingKey}`,
            message: `${psc.description}: ${report.shortDescription}`,
          });
        });
    });
  }, [packingStandardCostsRows, clearLocalMessages, addLocalMessage]);

  const totalCost = useMemo(() => {
    const { fmc1cost, fmc2cost } = Calculator.Pack.CC_Packing.PackingStandardCosts.Sum(
      packingStandardCostsRows.map((r) => ({ fmc1_cost: r.fmc1cost, fmc2_cost: r.fmc2cost })),
    );
    return { fmc1: fmc1cost, fmc2: fmc2cost };
  }, [packingStandardCostsRows]);

  const totalQuantity = useMemo(
    () => ({
      fmc1: packingStandardCostsRows.reduce((total, row) => total + row.fmc1quantity, 0),
      fmc2: packingStandardCostsRows.reduce((total, row) => total + row.fmc2quantity, 0),
    }),
    [packingStandardCostsRows],
  );

  function onPaste(updates: PasteChanges<PackingStandardCostsRow>) {
    updates.forEach(({ row, changes }) => {
      if (typeof changes.fmc1quantity === 'number')
        changeQuantity(row.packingKey, 'FMC1', changes.fmc1quantity);
      if (typeof changes.fmc2quantity === 'number')
        changeQuantity(row.packingKey, 'FMC2', changes.fmc2quantity);
    });
  }

  function importRows(rows: DBPackingStandardCost[]) {
    if (rows.length === 0) {
      showInfoToast('No packing standard costs to import');
      return;
    }

    setQuantities((curr) => {
      const newQuantities = { ...curr };
      Object.keys(curr).forEach((packingKey) => {
        const packingStandardCost = rows.find((r) => r.packing_key === packingKey);
        if (packingStandardCost) {
          newQuantities[packingStandardCost.packing_key] = {
            FMC1: packingStandardCost.fmc1_quantity,
            FMC2: packingStandardCost.fmc2_quantity,
          };
        } else {
          newQuantities[packingKey as keyof typeof newQuantities] = { FMC1: 0, FMC2: 0 };
        }
      });
      return newQuantities;
    });
  }

  return {
    packingStandardCostsRows,
    handlers: { changeQuantity, copyQuantities, resetQuantities, onPaste, importRows },
    totals: { cost: totalCost, quantity: totalQuantity },
    messages: localMessages,
  };
}
