import { z } from 'zod';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsMounted } from '@frontend/common/lib/hooks/useIsMounted';
import { InternalEndpoint, EndpointError } from '@core/types/types.endpoint';
import { ErrorHandlingParam, callEndpoint } from '@frontend/common/lib/callEndpoint';

export function useEndpoint<E extends InternalEndpoint = never>({
  endpoint,
  input,
  condition = true,
  errorHandling,
}: {
  condition?: boolean;
  endpoint: E;
  input: z.infer<E['input']>;
  errorHandling?: ErrorHandlingParam;
}) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<z.infer<E['response']> | null>(null);
  const [error, setError] = useState<EndpointError | null>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);

  const req = {
    endpoint,
    input,
    errorHandling,
  };

  const reqIdentifier = JSON.stringify(req);

  // Ensure updating when updating body or query
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cachedReq = useMemo(() => req, [reqIdentifier]);

  const queryFunction = useCallback(async () => {
    setLoading(true);
    setStatusCode(null);
    setError(null);
    await callEndpoint(cachedReq).then(([err, data, res]) => {
      if (isMounted.current) {
        setLoading(false);
        setData(data);
        setError(
          err
            ? {
                ...err,
                error: [500, 502, 504].includes(res?.status || 0)
                  ? 'An error occurred.'
                  : err.error,
              }
            : null,
        );
        setStatusCode(res ? res.status : null);
      }
    });
  }, [cachedReq, isMounted]);

  useEffect(() => {
    setStatusCode(null);
    setError(null);
    setData(null);

    if (!condition) return;
    // Using function to only update if changed
    setLoading(() => true);
    queryFunction();
  }, [condition, queryFunction]);

  return {
    error,
    data,
    statusCode,
    loading,
    queryFunction,
  };
}
