import { PK_TO_PRETTY_NAME } from '@core/const/const.PK_TO_PRETTY_NAME';
import { AdministrationTransferDataEndpoint } from '@core/schemas/endpoint/schema.endpoint.administration';
import { DataMaintenanceGetDataAdminConfigEndpoint } from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';
import { PK } from '@core/types/types.pk';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showInternalApplicationError, showSuccessToast } from '@frontend/common/lib/functions';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import { FSelect } from '@frontend/form/components/FSelect';
import { Button } from '@lego/klik-ui';
import { useState } from 'react';

export function MigrateData() {
  const [selectedPk, setSelectedPk] = useState<PK | ''>('');
  const [selectedSource, setSelectedSource] = useState<'development' | 'test' | 'production' | ''>(
    '',
  );

  const { data: permissions, loading: fetchingPermissions } = useEndpoint({
    endpoint: DataMaintenanceGetDataAdminConfigEndpoint,
    input: null,
    errorHandling: { header: 'Fetching data admin permissions' },
  });
  const [isLoading, setIsLoading] = useState(false);

  async function triggerMigrate() {
    if (!selectedPk || !selectedSource) {
      showInternalApplicationError('Trigger migration');
      return;
    }

    setIsLoading(true);
    const [err] = await callEndpoint({
      endpoint: AdministrationTransferDataEndpoint,
      input: { from: selectedSource, pk: selectedPk },
      errorHandling: { header: 'Migrate data' },
    });
    setIsLoading(false);

    if (err) {
      return;
    }

    showSuccessToast('Migrate data', 'Request for data migration successfully sent');

    setSelectedPk('');
  }

  return (
    <div>
      <div>
        Migration of data can take up to 15 minutes. A mail will be sent to you once the migration
        has been completed.
      </div>
      <div>
        <FSelect
          placeholder={'Select source environment'}
          options={[
            { text: 'Development', value: 'development', disabled: STAGE === 'development' },
            { text: 'Test', value: 'test', disabled: STAGE === 'test' },
            { text: 'Production', value: 'production', disabled: STAGE === 'production' },
          ]}
          labelStyle={{ fontSize: 18, fontWeight: 'bold' }}
          editable
          value={selectedSource}
          onChange={(v) => setSelectedSource(v as typeof selectedSource)}
          style={{ width: 500, marginTop: 24 }}
        />
        <FSelect
          placeholder={fetchingPermissions ? 'Loading...' : 'Select item type'}
          options={
            !permissions
              ? []
              : [
                  ...Object.entries(PK_TO_PRETTY_NAME)
                    .filter(([pk]) => permissions[pk as PK]?.write)
                    .map(([pk, description]) => ({
                      value: pk,
                      text: description,
                    }))
                    .sort(({ text: textA }, { text: textB }) => textA.localeCompare(textB)),
                ]
          }
          labelStyle={{ fontSize: 18, fontWeight: 'bold' }}
          editable
          value={selectedPk}
          onChange={(v) => setSelectedPk(v as typeof selectedPk)}
          style={{ width: 500, marginTop: 16 }}
        />
        <Button
          style={{ marginTop: 16 }}
          size="sm"
          disabled={!selectedPk || !selectedSource}
          isLoading={isLoading}
          onClick={triggerMigrate}
        >
          Migrate
        </Button>
      </div>
    </div>
  );
}
