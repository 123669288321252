import { useMemo } from 'react';
import { useElementsCostsOverviewRows } from '@frontend/elements/logic/useElementsCostsOverviewRows';
import { useBiCostsOverviewRow } from '@frontend/bi/logic/useBiCostsOverviewRows';
import { CostOverviewRow } from '@frontend/common/lib/models';
import { useLeadPriceCostsOverviewRow } from './useLeadPriceCostsOverviewRow';
import { useFactorOverviewRow } from './useFactorCostsOverviewRow';
import { Calculator } from '@core/calculator/calculator';
import { usePackagingCostsOverviewRows } from '@frontend/packaging/lib/usePackagingCostsOverviewRows';
import { FinalCostsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.finalCosts';
import { CostComponent } from '@core/types/types.costComponent';

export function useFinalCostsOverviewRows(params: {
  readOnly: boolean;
  elements: {
    data: FinalCostsGetEndpointResponse['elementsData'];
    scrapRatePercent: number | undefined;
  };
  packaging: FinalCostsGetEndpointResponse['packagingData'];
  bi: FinalCostsGetEndpointResponse['biData'];
  productCosts: FinalCostsGetEndpointResponse['productCosts'];
  otherProductCosts: FinalCostsGetEndpointResponse['otherProductCosts'];
  latestApprovedRevisions: FinalCostsGetEndpointResponse['latestApprovedRevisions'];
  cc_FinishedGoodOverhead: CostOverviewRow;
  cc_Others: CostOverviewRow;
  cc_License: CostOverviewRow;
}) {
  const {
    readOnly,
    elements,
    bi,
    otherProductCosts,
    cc_FinishedGoodOverhead,
    cc_Others,
    cc_License,
    productCosts,
    packaging,
    latestApprovedRevisions,
  } = params;

  const {
    cc_LEGOElements,
    cc_PurchasedElements,
    cc_OverdoseElements,
    cc_ElementsScrap,
    cc_ElementsCosts,
  } = useElementsCostsOverviewRows({
    readOnly,
    elementCosts: elements.data?.elementCosts,
    productCosts: productCosts.elements,
    scrapRatePercent: elements.scrapRatePercent,
    copyPpoToCost: !latestApprovedRevisions.elements,
  });

  const { cc_Packing, cc_Packaging } = usePackagingCostsOverviewRows({
    packingStandardCosts: packaging?.packingStandardCosts,
    packagingStandardCosts: packaging?.packagingStandardCosts,
    packagingNonstandardCosts: packaging?.packagingNonstandardCosts,
    productCosts: productCosts.packaging,
    packingNonstandardCosts: packaging?.packingNonstandardCosts,
    copyPpoToCost: !latestApprovedRevisions.packaging,
  });

  const { cc_Instructions } = useBiCostsOverviewRow({
    books: bi?.biCosts.map((bic) => ({ ...bic, _manualCost: !bic.super_design ? bic.cost : 0 })),
    productCosts: productCosts.bi,
    copyPpoToCost: !latestApprovedRevisions.buildingInstructions,
  });

  const { cc_LeadPrice } = useLeadPriceCostsOverviewRow({
    ppo: otherProductCosts.ppo[CostComponent.LeadPrice],
    cost: otherProductCosts.ppo[CostComponent.LeadPrice], // always copy from PPO on lead price
  });

  const cc_TotalCosts = useMemo(() => {
    const costPpoVariance = Calculator.CC_TotalCosts({
      costs: {
        [CostComponent.LEGOElements]: cc_LEGOElements,
        [CostComponent.PurchasedElements]: cc_PurchasedElements,
        [CostComponent.OverdoseElements]: cc_OverdoseElements,
        [CostComponent.ElementsScrap]: cc_ElementsScrap,
        [CostComponent.Packing]: cc_Packing,
        [CostComponent.Packaging]: cc_Packaging,
        [CostComponent.Instructions]: cc_Instructions,
        [CostComponent.License]: cc_License,
        [CostComponent.FinishedGoodOverhead]: cc_FinishedGoodOverhead,
        [CostComponent.Others]: cc_Others,
      },
      ppos: {
        FMC1: otherProductCosts.ppo[CostComponent.TotalCosts]?.fmc_eu_cost,
        FMC2: otherProductCosts.ppo[CostComponent.TotalCosts]?.fmc_us_cost,
      },
    });

    const r: CostOverviewRow = {
      costComp: CostComponent.TotalCosts,
      description: 'Total costs',
      currency: 'DKK',
      ...costPpoVariance,
    };

    return r;
  }, [
    cc_LEGOElements,
    cc_PurchasedElements,
    cc_OverdoseElements,
    cc_ElementsScrap,
    cc_Packing,
    cc_Packaging,
    cc_Instructions,
    cc_License,
    cc_FinishedGoodOverhead,
    cc_Others,
    otherProductCosts.ppo,
  ]);

  const { cc_Factor: cc92 } = useFactorOverviewRow(cc_LeadPrice, cc_TotalCosts);

  const finalCostsRows = useMemo<CostOverviewRow[]>(() => {
    const preRows: CostOverviewRow[] = [
      cc_LEGOElements,
      cc_PurchasedElements,
      cc_OverdoseElements,
      cc_ElementsScrap,
      cc_ElementsCosts,
      cc_Packing,
      cc_Packaging,
      cc_Instructions,
      cc_License,
      cc_FinishedGoodOverhead,
      cc_Others,
      cc_TotalCosts,
      cc_LeadPrice,
      cc92,
    ];

    return preRows;
  }, [
    cc_LEGOElements,
    cc_PurchasedElements,
    cc_OverdoseElements,
    cc_ElementsScrap,
    cc_ElementsCosts,
    cc_Packing,
    cc_Packaging,
    cc_Instructions,
    cc_License,
    cc_FinishedGoodOverhead,
    cc_LeadPrice,
    cc92,
    cc_Others,
    cc_TotalCosts,
  ]);

  return {
    finalCostsRows,
  };
}
