import { DBProdCost } from '@core/schemas/db/schema.db.prod';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { now } from '@core/util/util.timestamp';
import { CostComponent } from '@core/types/types.costComponent';

export const ProdCostExample: OmitDBRecord<DBProdCost>[] = [
  {
    product_id: 1231,
    revision: 1,
    cost_component: CostComponent.LEGOElements,
    fmc_eu_cost: 1.23,
    fmc_us_cost: 1.23,
    currency: 'DKK',
    created_timestamp: now(),
    created_by: 'casper.aagaard.laursen@lego.com',
    changed_timestamp: undefined,
    changed_by: undefined,
  },
];
