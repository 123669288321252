import { PK } from '@core/types/types.pk';
import { prettifyTimestamp } from '@frontend/common/lib/common.util';
import { TableColumn } from '@frontend/table/table';
import { z } from 'zod';
import { Filter } from './dataMaintenance.types';
import { FilterType } from '@core/types/types.es';
import { DeleteBold } from '@lego/klik-ui/icons';

export function bold(value: unknown) {
  return (
    <div style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
      {value === undefined ? '' : (value as string | number | boolean).toString()}
    </div>
  );
}

export function parseErrorToString(error: z.ZodIssue) {
  if (error.code === 'unrecognized_keys') {
    return error.message;
  }

  return `Propert${error.path.length === 1 ? 'y' : 'ies'} ${error.path
    .map((k) => `'${k}'`)
    .join(', ')}: ${error.message}`;
}

export const getColumnFormatters: Partial<
  Record<PK | 'ALL', Record<string, Pick<TableColumn<object>, 'render' | 'format' | 'style'>>>
> = {
  ALL: {
    _timestamp: {
      style: () => ({ whiteSpace: 'nowrap' }),
      format: (v) => prettifyTimestamp(v, { timeMode: 'MILLISECONDS' }),
    },
    approved_timestamp: {
      style: () => ({ whiteSpace: 'nowrap' }),
      format: (v) => prettifyTimestamp(v),
    },
    rejected_timestamp: {
      style: () => ({ whiteSpace: 'nowrap' }),
      format: (v) => prettifyTimestamp(v),
    },
    created_timestamp: {
      style: () => ({ whiteSpace: 'nowrap' }),
      format: (v) => prettifyTimestamp(v),
    },
    changed_timestamp: {
      style: () => ({ whiteSpace: 'nowrap' }),
      format: (v) => prettifyTimestamp(v),
    },
  },
  BOM: {
    materials: {
      format: (v: string) => JSON.parse(v).length,
      style: () => ({
        textAlign: 'end',
        width: '100%',
      }),
    },
  },
  MARA: {
    market_exit_date: {
      style: () => ({ whiteSpace: 'nowrap' }),
      format: (v) => prettifyTimestamp(v),
    },
  },
};

export function getFilterDisplayValue(filter: Filter | undefined) {
  if (!filter) {
    return '';
  }

  if (filter.type === FilterType.Exact) {
    return filter.value;
  }

  if (filter.type === FilterType.Include) {
    return filter.value.slice(0, 20).join(',');
  }

  if (filter.type === FilterType.Exclude) {
    return filter.value
      .slice(0, 20)
      .map((e) => `!${e}`)
      .join(',');
  }

  if (filter.type === FilterType.Range) {
    const { min, max } = filter.value;
    const bothHas = min !== undefined && max !== undefined;
    return (
      (min !== undefined ? `${min} ≤` : '') +
      (bothHas ? ' and ' : '') +
      (max !== undefined ? `≤ ${max}` : '')
    );
  }

  return '';
}

export function getSelectionsColumns(
  onRemove: (selection: string) => void,
): TableColumn<{ value: string }>[] {
  return [
    { title: 'Value', dataIndex: 'value' },
    {
      title: '',
      dataIndex: '',
      ignoreInExport: true,
      align: 'center',
      render: (_v, r) => (
        <DeleteBold
          onClick={() => onRemove(r.value)}
          className="clickable-icon clickable-icon__danger"
        />
      ),
    },
  ];
}
