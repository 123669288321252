import { Button, Switch, Textarea } from '@lego/klik-ui';
import {
  CheckTickBold,
  CrossBold,
  FilesCodeBold,
  RotateLeftBold,
  StatusWarningBold,
} from '@lego/klik-ui/icons';
import { useCallback, useContext, useMemo, useState } from 'react';
import { GREEN, RED } from '@frontend/common/lib/common.styles';
import { PK } from '@core/types/types.pk';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  DataMaintenanceUpdateEndpoint,
  DataMaintenanceUploadEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';
import { PK_TO_SCHEMA } from '@core/schemas/db/schema.db.lib';
import { OMIT_DB_RECORD } from '@core/schemas/const/schema.const.OMIT_DB_RECORD';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { z } from 'zod';
import { parseErrorToString } from '../dataMaintenance.util';
import { getKeys } from '@core/util/util.schemaPropDescription';
import { showInternalApplicationError } from '@frontend/common/lib/functions';
import { DBRecord, DBRecordSchema } from '@core/schemas/db/schema.db.common';

const UNDEFINED_PLACEHOLDER = '<__undefined__>';

interface EditItemModelContent {
  item: Record<string, unknown | typeof UNDEFINED_PLACEHOLDER>;
  selectedPk: PK;
  mode: 'create' | 'edit';
  onSuccess(item: DBRecord): void;
}

export function EditItemModelContent(props: EditItemModelContent) {
  const [shouldOverwrite, setShouldOverwrite] = useState(false);

  const properties: { key: string; isPartOfKey: boolean }[] = useMemo(() => {
    const schema = PK_TO_SCHEMA[props.selectedPk];
    return Object.entries(schema.omit(OMIT_DB_RECORD).shape).map(([k, shape]) => ({
      key: k,
      isPartOfKey: shape.description === 'key',
    }));
  }, [props.selectedPk]);

  const jsonToString = useCallback(
    (item: EditItemModelContent['item']) => {
      const o: typeof item = {};

      properties.forEach((p) => {
        if (item[p.key] === undefined) {
          o[p.key] = UNDEFINED_PLACEHOLDER;
        } else {
          o[p.key] = item[p.key];
        }
      });

      return JSON.stringify(o, null, 4).replaceAll(`"${UNDEFINED_PLACEHOLDER}"`, 'undefined');
    },
    [properties],
  );

  const [editedJson, setEditedJson] = useState(jsonToString(props.item));
  const [isWorking, setIsWorking] = useState(false);

  const { userData } = useContext(UserDataContext);

  const stringToJson = useCallback((itemAsString: string) => {
    const item = JSON.parse(itemAsString.replaceAll('undefined', `"${UNDEFINED_PLACEHOLDER}"`));
    Object.keys(item).forEach((k) => {
      if (item[k] === UNDEFINED_PLACEHOLDER) {
        item[k] = undefined;
      }
    });

    return item;
  }, []);

  const isValidJson = useMemo(() => {
    try {
      stringToJson(editedJson);
      return true;
    } catch {
      return false;
    }
  }, [editedJson, stringToJson]);

  const error = useMemo(() => {
    if (!isValidJson) {
      return '';
    }

    const schema = PK_TO_SCHEMA[props.selectedPk];

    const parsedItem = stringToJson(editedJson);

    if (props.mode === 'edit') {
      // check if key props has changed
      for (const prop of getKeys(schema)) {
        const oVal = props.item[prop.key];
        const cVal = parsedItem[prop.key];
        if (oVal !== cVal) {
          return `Property ${
            prop.key
          } is part of the item key and must therefore not be changed. Original value: ${
            typeof oVal === 'string' ? `"${oVal}"` : oVal
          }, current value: ${typeof cVal === 'string' ? `"${cVal}"` : cVal}`;
        }
      }
    }

    const ok = schema.omit(OMIT_DB_RECORD).strict().safeParse(parsedItem);

    if (ok.success) {
      return '';
    }

    return parseErrorToString(ok.error.errors[0]);
  }, [editedJson, stringToJson, isValidJson, props.selectedPk, props.item, props.mode]);

  const updateDisabled = useMemo(() => !isValidJson || !!error, [isValidJson, error]);

  const { selectedPk, onSuccess } = props;

  const addItem = useCallback(async () => {
    if (updateDisabled) {
      showInternalApplicationError();
      return;
    }

    const item = stringToJson(editedJson);

    setIsWorking(true);
    const [err, data] = await callEndpoint({
      endpoint: DataMaintenanceUploadEndpoint,
      input: { pk: selectedPk, items: [item], overwrite: shouldOverwrite, singleMode: true },
      errorHandling: { header: 'Creating item' },
    });
    setIsWorking(false);

    if (err) {
      return;
    }

    if (!data.uploadedItem) {
      showInternalApplicationError();
      return;
    }

    onSuccess(data.uploadedItem);
  }, [updateDisabled, editedJson, selectedPk, onSuccess, stringToJson, shouldOverwrite]);

  const updateItem = useCallback(async () => {
    if (updateDisabled) {
      showInternalApplicationError();
      return;
    }

    if (typeof props.item.SK !== 'string') {
      showInternalApplicationError();
      return;
    }

    const item = stringToJson(editedJson);

    setIsWorking(true);
    const [err] = await callEndpoint({
      endpoint: DataMaintenanceUpdateEndpoint,
      input: { pk: selectedPk, sk: props.item.SK, item },
      errorHandling: { header: 'Updating item' },
    });
    setIsWorking(false);

    if (err) {
      return;
    }

    const reconstructedItem = item;

    Object.keys(DBRecordSchema.shape).forEach((k) => (reconstructedItem[k] = props.item[k]));

    onSuccess(reconstructedItem);
  }, [stringToJson, editedJson, updateDisabled, selectedPk, onSuccess, props.item]);

  const hasNumberField = useMemo(() => {
    const schema = PK_TO_SCHEMA[props.selectedPk];
    return !!Object.values(schema.omit(OMIT_DB_RECORD).shape).find(
      (shape) => shape instanceof z.ZodNumber,
    );
  }, [props.selectedPk]);

  return (
    <div style={{ marginBottom: 16 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 8,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 6,

            marginLeft: 4,
          }}
        >
          <div style={{ fontSize: 18 }}>
            {isValidJson ? (
              <CheckTickBold color={GREEN} style={{ marginTop: -2 }} />
            ) : (
              <CrossBold color={RED} style={{ marginTop: -2 }} />
            )}
          </div>
          <div>{isValidJson ? 'Valid' : 'Invalid'}</div>
        </div>
        <div style={{ display: 'flex', columnGap: 8 }}>
          <Button
            size="sm"
            variant="outline"
            disabled={!isValidJson}
            leftIcon={<FilesCodeBold />}
            onClick={() => {
              if (!isValidJson) {
                return;
              }
              setEditedJson(jsonToString(stringToJson(editedJson)));
            }}
          >
            Format
          </Button>
          <Button
            size="sm"
            variant="outline"
            leftIcon={<RotateLeftBold />}
            disabled={jsonToString(props.item) === editedJson}
            onClick={() => setEditedJson(jsonToString(props.item))}
          >
            Reset
          </Button>
        </div>
      </div>
      <div style={{ height: 60, overflow: 'auto' }}>
        {error && (
          <div style={{ margin: '12px 0', display: 'flex', columnGap: 12 }}>
            <StatusWarningBold color={RED} style={{ marginTop: 2 }} />
            <div style={{ lineHeight: 'normal' }}>{error}</div>
          </div>
        )}
      </div>
      {!!userData?.comma_as_decimal_seperator && hasNumberField && (
        <div style={{ marginTop: 8, marginBottom: 12, fontSize: 14 }}>
          <span>{"Note: Decimal numbers must be written with a '.'"}</span>
          <span style={{ marginLeft: 24 }}>Example: 123.45</span>
        </div>
      )}
      <Textarea value={editedJson} onChange={(e) => setEditedJson(e.target.value)} rows={15} />
      <div
        style={{
          marginTop: 8,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          columnGap: 24,
        }}
      >
        {props.mode === 'create' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 16,
              justifyContent: 'center',
            }}
          >
            <div>Overwrite potentially existing item</div>
            <Switch
              aria-label="overwrite"
              isChecked={shouldOverwrite}
              onChange={() => setShouldOverwrite((v) => !v)}
            />
          </div>
        )}
        <Button
          size="sm"
          disabled={updateDisabled}
          isLoading={isWorking}
          colorScheme={props.mode === 'create' ? 'success' : undefined}
          onClick={props.mode === 'create' ? addItem : updateItem}
        >
          {props.mode === 'create' ? 'Create item' : 'Update item'}
        </Button>
      </div>
    </div>
  );
}
