import { FCheckbox } from '@frontend/form/components/FCheckbox';
import { FInput } from '@frontend/form/components/FInput';
import { FSelect } from '@frontend/form/components/FSelect';
import { showInternalApplicationError } from '@frontend/common/lib/functions';
import { BIGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.bi';
import { FInputReadOnly } from '@frontend/form/components/FInputReadOnly';
import { InfoIconClickable } from '@frontend/common/components/InfoIconClickable';
import { convertProductIdFrom8digits } from '@core/util/util.convertProductIdFrom8digits';
import { convertBiStatusToText } from '@core/util/util.convertBiStatusToText';

interface BIProductHeaderProps {
  prodHead: BIGetEndpointResponse['prodHead'];
  biStatus: number;
  erectCode: string | undefined;
  maxRevision: number;
  readOnly: boolean;
  onRevisionSelect?(revision: number): void;
  onBiStatusChange?(newStatus: number): void;
}

export function BIProductHeader(props: BIProductHeaderProps) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'min-content 36px min-content auto',
        columnGap: 24,
      }}
    >
      <FInput
        label="Product ID"
        value={convertProductIdFrom8digits(props.prodHead.product_id)}
        align="start"
      />
      <div />
      <FInputReadOnly
        label="Erect size code"
        blankDash
        value={
          props.erectCode && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.erectCode}
              <InfoIconClickable
                hide={!props.prodHead.approved_by}
                description={`Shown erect size code, ${props.erectCode}, is the erect size code of the currently maintained packaging size in the packaging screen.`}
              />
            </div>
          )
        }
        align="start"
      />
      <FSelect
        label="BI status"
        editable={!props.readOnly && !props.prodHead.approved_by && !!props.onBiStatusChange}
        value={props.prodHead.approved_by && props.biStatus === 0 ? 'None' : `${props.biStatus}`}
        onChange={(v) => {
          if (!props.onBiStatusChange) {
            showInternalApplicationError();
            return;
          }
          props.onBiStatusChange(Number(v));
        }}
        options={[
          { value: '0', text: 'None' },
          { value: '40', text: convertBiStatusToText(40) },
          { value: '43', text: convertBiStatusToText(43) },
        ]}
        align="start"
        style={{ width: 300 }}
      />
      <FSelect
        label="Revision"
        value={`${props.prodHead.revision}`}
        editable={!!props.onRevisionSelect}
        onChange={(rev) => {
          if (!props.onRevisionSelect) {
            showInternalApplicationError();
            return;
          }
          props.onRevisionSelect(Number(rev));
        }}
        options={Array.from(new Array(props.maxRevision)).map((_, i) => ({
          text: i + 1 + (i + 1 === props.maxRevision ? ' (latest)' : ''),
          value: `${i + 1}`,
        }))}
        align="start"
        style={{ width: 200 }}
      />
      <div />
      <FCheckbox label="QR code" checked={props.prodHead.bi_qr_code} align="start" />
      <div />
      <FInput label="FMC year" value={props.prodHead.year} align="start" />
    </div>
  );
}
