import { DBBIRate } from '@core/schemas/db/schema.db.bi';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';

export const BIRateExample: OmitDBRecord<DBBIRate>[] = [
  {
    year: 1234,
    super_design: 1234,
    total_number_of_pages: 64,
    number_of_cover_pages: 10,
    number_of_colors_frontside: 1,
    number_of_colors_backside: 3,
    paper_weight_gr: 100,
    cover_weight_gr: 50,
    fmc1_with_scrap: 1.23,
    fmc1_qr_with_scrap: 1.23,
    fmc2_with_scrap: 1.23,
    fmc2_qr_with_scrap: 1.23,
    currency: 'DKK',
  },
];
