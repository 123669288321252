import { Calculator } from '@core/calculator/calculator';
import { CostComponent } from '@core/types/types.costComponent';
import { CostOverviewRow } from '@frontend/common/lib/models';
import { useMemo } from 'react';

export function useFactorOverviewRow(
  cc_LeadPrice: CostOverviewRow,
  cc_TotalCosts: CostOverviewRow,
): { cc_Factor: CostOverviewRow } {
  const cc_Factor = useMemo(() => {
    const fmc1ppo = Calculator.Common.Quotient(cc_LeadPrice.fmc1ppo, cc_TotalCosts.fmc1ppo);
    const fmc2ppo = Calculator.Common.Quotient(cc_LeadPrice.fmc2ppo, cc_TotalCosts.fmc2ppo);

    const fmc1cost = Calculator.Common.Quotient(cc_LeadPrice.fmc1cost, cc_TotalCosts.fmc1cost);
    const fmc2cost = Calculator.Common.Quotient(cc_LeadPrice.fmc2cost, cc_TotalCosts.fmc2cost);

    const r: CostOverviewRow = {
      costComp: CostComponent.Factor,
      description: 'Factor (NIP/FMC)',
      currency: 'DKK',
      fmc1cost,
      fmc2cost,
      fmc1ppo: fmc1ppo,
      fmc2ppo: fmc2ppo,
      fmc1variance: Calculator.Common.Variance(fmc1ppo, fmc1cost),
      fmc2variance: Calculator.Common.Variance(fmc2ppo, fmc2cost),
    };

    return r;
  }, [cc_LeadPrice, cc_TotalCosts]);

  return { cc_Factor };
}
