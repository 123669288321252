import { DBPackingNonstandardRate } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { PackSource } from '@core/types/types.packaging';

export const PackingNonstandardRateExample: OmitDBRecord<DBPackingNonstandardRate>[] = [
  {
    year: 1234,
    source: PackSource.Duplo,
    packing_type: 'a',
    packing_region: 'EU',
    operator_rate: 1,
    machine_rate: 1,
    currency: 'DKK',
  },
];
