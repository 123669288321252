import { NCAccordion } from '@frontend/common/components/NCAccordion';
import { UserPreferences } from './UserPreferences';

export function SettingsPage() {
  return (
    <NCAccordion
      sections={[
        {
          title: 'Preferences',
          content: <UserPreferences />,
        },
      ]}
    />
  );
}
