import { Table } from '@frontend/table/table';
import { useMemo, useState } from 'react';
import { Button } from '@lego/klik-ui';
import { DeleteBold } from '@lego/klik-ui/icons';
import { FInput } from '@frontend/form/components/FInput';
import { getSelectionsColumns } from '../dataMaintenance.util';

interface SelectionsContentProps {
  values: string[];
  regex?: RegExp;
  onRemove(selection: string): void;
  addCurrentValue(input: string | string[]): void;
}

export function SelectionsContent(props: SelectionsContentProps) {
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState<{ value: string }[]>([]);

  const columns = useMemo(() => getSelectionsColumns(props.onRemove), [props.onRemove]);
  const rows = useMemo(() => {
    const preRows: { value: string }[] = [];

    props.values
      .sort((a, b) => Number(a) - Number(b))
      .forEach((sel) => preRows.push({ value: `${sel}` }));

    return preRows;
  }, [props.values]);

  function removeMultiple() {
    selected.forEach((v) => props.onRemove(v.value));
  }

  return (
    <div>
      <Table
        id="dm-selctions"
        columns={columns}
        headerContent={
          <div>
            <Button
              size="sm"
              leftIcon={<DeleteBold />}
              colorScheme="error"
              variant="outline"
              disabled={selected.length === 0}
              onClick={removeMultiple}
            >
              Remove
            </Button>
          </div>
        }
        multiSelection={{ selected, setSelected }}
        rowKey="value"
        rows={rows}
        itemType="selection"
        itemsPerPage={10}
      />
      <div style={{ marginTop: 24, width: '60%' }}>
        <div style={{ display: 'flex', columnGap: 8, alignItems: 'end', width: '100%' }}>
          <FInput
            placeholder="Value"
            editable
            align="start"
            regex={props.regex}
            disallowChangeOnInvalid={!!props.regex}
            onMultiValuePaste={(values) => {
              props.addCurrentValue(values);
              setTimeout(() => setValue(''), 1);
            }}
            disableSmartBehavior
            value={value}
            disableBlurOnEnter
            onChange={setValue}
            onEnter={() => props.addCurrentValue(value)}
            style={{ width: 'inherit' }}
          />
          <Button
            colorScheme="success"
            size="sm"
            disabled={!value}
            onClick={() => props.addCurrentValue(value)}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
}
