import { Calculator } from '@core/calculator/calculator';
import { ElementCostRow } from '../types/elements.types';
import { DBElemCost } from '@core/schemas/db/schema.db.elements';

export function aggregateCosts(
  elementCosts: ElementCostRow[],
): Pick<DBElemCost, 'quantity' | 'total_cost'> {
  return {
    quantity: Calculator.Common.SumListStrict(elementCosts, 'quantity'),
    total_cost: Calculator.Common.SumListStrict(elementCosts, 'total_cost'),
  };
}
