export function formatNumber(
  number: string | number | undefined | null | unknown,
  commaAsDecimalSeperator: boolean,
  decimals?: number,
) {
  const numberFormatted = number?.toString().replaceAll(',', '.');

  if (number === '' || number === null || isNaN(Number(numberFormatted))) {
    return '';
  }

  const formattedString = Number(numberFormatted).toLocaleString(
    commaAsDecimalSeperator ? 'de-DE' : 'en-US',
    decimals !== undefined
      ? {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }
      : undefined,
  );

  return formattedString;
}

export function roundNumber(number: number, decimals: number) {
  const numberAsString = formatNumber(number, false, decimals);
  return Number(numberAsString);
}
