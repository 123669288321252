import { ButtonGroup, Button, useDisclosure, Spinner } from '@lego/klik-ui';
import { useContext, useEffect, useMemo, useState } from 'react';
import { BIRatesModalContent } from './BiRatesModalContent';
import { FInput } from '@frontend/form/components/FInput';
import { FSelect } from '@frontend/form/components/FSelect';
import { NCModal } from '@frontend/common/components/NCModal';
import { FMCREGION_TO_PRETTY } from '@frontend/common/lib/definitions';
import { getBookKey, showInternalApplicationError, twoDec } from '@frontend/common/lib/functions';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { GREEN_BUTTON_STYLES } from '@frontend/common/lib/common.styles';
import { ArrowRightBold, DeleteBold, PlusBold, QrCode } from '@lego/klik-ui/icons';
import { MessageStack } from '@frontend/common/components/MessageStack';
import { Book, UseBooksAndRatesReturnValue } from '../logic/useBooksAndRates';
import { Message } from '@frontend/common/lib/models';
import { parseFormattedInt } from '@core/util/util.parsing';
import { Tag } from '@frontend/common/components/Tag';
import {
  REGEX_NONNEGATIVE_INTEGER,
  REGEX_NONNEGATIVE_INTEGER_OR_EMPTY,
  get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL,
} from '@core/types/types.regex';
import { Region, RegionFMC } from '@core/schemas/schema.common';
import { DBBIRate } from '@core/schemas/db/schema.db.bi';
import { InfoIcon } from '@frontend/common/components/InfoIcon';
import { SelectBiRateModalContent } from './SelectBiRateModalContent';

interface BICostsProps {
  productId: number | undefined;
  fmcYear: number | undefined;
  books: UseBooksAndRatesReturnValue['books'];
  handlers?: UseBooksAndRatesReturnValue['handlers'];
  isFetchingRate?: UseBooksAndRatesReturnValue['isFetchingRate'];
  readOnly: boolean;
  hasQrCode: boolean;
  messages?: Message[];
}

export function BICosts(props: BICostsProps) {
  const [selectedBookNumber, setSelectedBookNumber] = useState(1);
  const [ratesToSelectFrom, setRatesToSelectFrom] = useState<{
    book: Book;
    rates: (DBBIRate & { description: string })[];
  }>();
  const { userData } = useContext(UserDataContext);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const selectedSubbooks = useMemo(
    () => props.books.filter((biCost) => biCost.book_number === selectedBookNumber),
    [selectedBookNumber, props.books],
  );

  const numberOfBooks = useMemo(
    () => props.books.reduce((highest, biCost) => Math.max(highest, biCost.book_number), 1),
    [props.books],
  );

  const regionToIsFreeInput: Partial<Record<RegionFMC, boolean>> = useMemo(() => {
    const relevantBooks = props.books.filter((b) => b.book_number === selectedBookNumber);
    const result: typeof regionToIsFreeInput = {};

    relevantBooks.forEach((b) => (result[b.fmc_region] = !b.super_design));

    return result;
  }, [props.books, selectedBookNumber]);

  useEffect(() => setSelectedBookNumber(1), [props.productId]);

  async function updateBookByDesign(book: Book, updates: Partial<DBBIRate & { region?: Region }>) {
    if (!props.handlers || !props.fmcYear) {
      showInternalApplicationError();
      return;
    }

    props.handlers.updateBookByFetchingDesign({
      book,
      design: {
        year: props.fmcYear,
        ...book,
        super_design: book.super_design,
        total_number_of_pages: book.number_of_pages,
        region: book.region,
        cover_weight_gr: book.cover_weight_gr,
        number_of_colors_backside: book.number_of_colors_backside,
        number_of_colors_frontside: book.number_of_colors_frontside,
        number_of_cover_pages: book.number_of_cover_pages,
        paper_weight_gr: book.paper_weight_gr,
        ...updates,
      },
      onMultipleRates:
        Object.keys(updates).includes('super_design') ||
        Object.keys(updates).includes('total_number_of_pages')
          ? (rates) => setRatesToSelectFrom({ book, rates })
          : undefined,
    });
  }

  return (
    <div style={{ position: 'relative' }}>
      {props.messages && <MessageStack messages={props.messages} />}
      {props.isFetchingRate && (
        <Spinner size="sm" style={{ position: 'absolute', right: 0, top: -12 }} />
      )}
      <NCModal
        header="Building instructions rates"
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={1200}
      >
        <BIRatesModalContent
          books={selectedSubbooks}
          onRateSelected={(book, rate) => {
            props.handlers?.updateBookByFetchingDesign({ book, design: rate });
            onClose();
          }}
        />
      </NCModal>
      {ratesToSelectFrom && (
        <NCModal
          header="Select rate to apply"
          isOpen
          onClose={() => {
            setRatesToSelectFrom(undefined);
          }}
          maxWidth={1200}
        >
          <SelectBiRateModalContent
            onRateSelected={(rate) => {
              props.handlers?.updateBookByFetchingDesign({
                book: ratesToSelectFrom.book,
                design: { ...rate, region: ratesToSelectFrom.book.region },
              });
              setRatesToSelectFrom(undefined);
            }}
            ratesToSelectFrom={ratesToSelectFrom.rates}
          />
        </NCModal>
      )}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
        {props.books.length > 0 && (
          <>
            <FSelect
              label="Book"
              options={Array.from(new Array(numberOfBooks)).map((_, i) => ({
                text: i + 1,
                value: `${i + 1}`,
              }))}
              editable
              value={`${selectedBookNumber}`}
              onChange={(bookNumber) => setSelectedBookNumber(Number(bookNumber))}
            />
            <div style={{ marginLeft: 8, marginRight: 36 }}>of {numberOfBooks}</div>
          </>
        )}
        <ButtonGroup spacing="8px">
          {!props.readOnly && (
            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }

                setSelectedBookNumber(Math.floor(props.books.length / 2) + 1);
                props.handlers.addBook();
              }}
              style={GREEN_BUTTON_STYLES}
              leftIcon={<PlusBold />}
            >
              Add book
            </Button>
          )}
          {!props.readOnly && props.books.length > 0 && (
            <Button
              size="sm"
              variant="outline"
              colorScheme="error"
              onClick={() => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.deleteBook(selectedBookNumber);
                setSelectedBookNumber((curr) => Math.max(1, curr - 1));
              }}
              leftIcon={<DeleteBold />}
            >
              Delete book
            </Button>
          )}
          {!props.readOnly && props.books.length > 0 && (
            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.copyToUs(selectedBookNumber);
              }}
              leftIcon={<ArrowRightBold />}
            >
              Copy to US
            </Button>
          )}
          {!props.readOnly && (
            <Button size="sm" variant="ghost" onClick={onOpen}>
              Display rate table entries
            </Button>
          )}
        </ButtonGroup>
      </div>
      {props.books.length > 0 && selectedBookNumber === 1 && props.hasQrCode && (
        <Tag
          icon={<QrCode style={{ marginTop: -2 }} />}
          style={{ background: '#3b7e9b', marginBottom: 8 }}
        >
          QR code
        </Tag>
      )}
      {props.books.length === 0 ? (
        props.readOnly ? (
          'No books has been added'
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InfoIcon /> Add a book to begin maintaining the building instructions
          </div>
        )
      ) : (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `min-content repeat(${selectedSubbooks.length}, 160px)`,
            columnGap: 12,
            rowGap: 4,
            whiteSpace: 'nowrap',
            alignItems: 'center',
            marginTop: 24,
          }}
        >
          <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', height: 32 }}>
            FMC region:
          </div>
          {selectedSubbooks.map((book) => (
            <div
              key={getBookKey(book)}
              style={{
                fontWeight: 'bold',
                justifyContent: 'end',
                display: 'flex',
                alignItems: 'center',
                height: 32,
              }}
            >
              {FMCREGION_TO_PRETTY[book.fmc_region]}
            </div>
          ))}
          <div style={{ fontWeight: 'bold' }}>Cost (DKK):</div>
          {selectedSubbooks.map((book) => (
            <FInput
              key={getBookKey(book)}
              value={book.cost}
              align="end"
              format={(v) => twoDec(v, !!userData?.comma_as_decimal_seperator)}
            />
          ))}
          <div style={{ fontWeight: 'bold' }}>Super design:</div>
          {selectedSubbooks.map((book) => (
            <FInput
              key={getBookKey(book)}
              value={book.super_design}
              editable={!props.readOnly && !book._manualCost}
              isInvalid={
                !!props.handlers?.getIsMissingRate(book) ||
                (!book._manualCost && !book.super_design)
              }
              regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
              onSubmit={(newSuperdesign) => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }

                props.handlers.changeBook(
                  book,
                  'super_design',
                  parseFormattedInt({ newSuperdesign }, undefined),
                );

                if (newSuperdesign === '') {
                  props.handlers.changeBook(book, 'cost', 0);
                  props.handlers.changeBook(book, 'description', '');
                  return;
                }

                updateBookByDesign(book, { super_design: parseFormattedInt({ newSuperdesign }) });
              }}
              onChange={() => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.updateBookKeyToRateNotFound(book, false);
              }}
            />
          ))}

          <div style={{ fontWeight: 'bold' }}>Description</div>
          {selectedSubbooks.map((book) => (
            <FInput key={getBookKey(book)} value={book.description} />
          ))}

          <div style={{ fontWeight: 'bold' }}>Total number of pages:</div>
          {selectedSubbooks.map((book) => (
            <FInput
              key={getBookKey(book)}
              value={book.number_of_pages}
              isInvalid={!!props.handlers?.getIsMissingRate(book) && !!book.super_design}
              onSubmit={(numPages) => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }

                props.handlers.changeBook(book, 'number_of_pages', parseFormattedInt({ numPages }));

                if (book.super_design) {
                  updateBookByDesign(book, {
                    total_number_of_pages: parseFormattedInt({ numPages }),
                  });
                }
              }}
              onChange={() => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.updateBookKeyToRateNotFound(book, false);
              }}
              editable={!props.readOnly}
              required
              regex={REGEX_NONNEGATIVE_INTEGER}
              emptyValueReplacement={0}
              disallowChangeOnInvalid
            />
          ))}
          <div style={{ fontWeight: 'bold' }}>Region:</div>
          {selectedSubbooks.map((book) => (
            <FSelect
              key={getBookKey(book)}
              value={book.region}
              editable={!props.readOnly}
              required
              isInvalid={!!props.handlers?.getIsMissingRate(book) && !!book.super_design}
              onChange={(packRegion) => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }

                props.handlers.changeBook(book, 'region', packRegion);

                if (book.super_design) {
                  updateBookByDesign(book, { region: packRegion as Region });
                }
              }}
              options={[
                { text: 'Europe', value: 'EU' },
                { text: 'USA', value: 'US' },
              ]}
            />
          ))}
          <div style={{ fontWeight: 'bold' }}>Number of colors on front:</div>
          {selectedSubbooks.map((book) => (
            <FInput
              key={getBookKey(book)}
              value={book.number_of_colors_frontside}
              isInvalid={!!props.handlers?.getIsMissingRate(book) && !!book.super_design}
              onSubmit={(numColors) => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }

                props.handlers.changeBook(book, 'number_of_colors_frontside', Number(numColors));

                if (book.super_design) {
                  updateBookByDesign(book, {
                    number_of_colors_frontside: parseFormattedInt({ numColors }),
                  });
                }
              }}
              editable={!props.readOnly}
              regex={REGEX_NONNEGATIVE_INTEGER}
              emptyValueReplacement={0}
              disallowChangeOnInvalid
              onChange={() => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.updateBookKeyToRateNotFound(book, false);
              }}
            />
          ))}
          <div style={{ fontWeight: 'bold' }}>Number of colors on back:</div>
          {selectedSubbooks.map((book) => (
            <FInput
              key={getBookKey(book)}
              value={book.number_of_colors_backside}
              isInvalid={!!props.handlers?.getIsMissingRate(book) && !!book.super_design}
              onSubmit={(numColors) => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }

                props.handlers.changeBook(book, 'number_of_colors_backside', Number(numColors));

                if (book.super_design) {
                  updateBookByDesign(book, {
                    number_of_colors_backside: parseFormattedInt({ numColors }),
                  });
                }
              }}
              editable={!props.readOnly}
              regex={REGEX_NONNEGATIVE_INTEGER}
              emptyValueReplacement={0}
              disallowChangeOnInvalid
              onChange={() => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.updateBookKeyToRateNotFound(book, false);
              }}
            />
          ))}
          <div style={{ fontWeight: 'bold' }}>Number of cover pages:</div>
          {selectedSubbooks.map((book) => (
            <FInput
              key={getBookKey(book)}
              value={book.number_of_cover_pages}
              isInvalid={!!props.handlers?.getIsMissingRate(book) && !!book.super_design}
              onSubmit={(numPages) => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.changeBook(book, 'number_of_cover_pages', Number(numPages));

                if (book.super_design) {
                  updateBookByDesign(book, {
                    number_of_cover_pages: parseFormattedInt({ numPages }),
                  });
                }
              }}
              editable={!props.readOnly}
              regex={REGEX_NONNEGATIVE_INTEGER}
              emptyValueReplacement={0}
              disallowChangeOnInvalid
              onChange={() => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.updateBookKeyToRateNotFound(book, false);
              }}
            />
          ))}
          <div style={{ fontWeight: 'bold' }}>Paper weight (gr.):</div>
          {selectedSubbooks.map((book) => (
            <FInput
              key={getBookKey(book)}
              value={book.paper_weight_gr}
              isInvalid={!!props.handlers?.getIsMissingRate(book) && !!book.super_design}
              onSubmit={(weight) => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.changeBook(book, 'paper_weight_gr', Number(weight));

                if (book.super_design) {
                  updateBookByDesign(book, { paper_weight_gr: parseFormattedInt({ weight }) });
                }
              }}
              regex={REGEX_NONNEGATIVE_INTEGER}
              emptyValueReplacement={0}
              disallowChangeOnInvalid
              editable={!props.readOnly}
              onChange={() => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.updateBookKeyToRateNotFound(book, false);
              }}
            />
          ))}
          <div style={{ fontWeight: 'bold' }}>Cover weight (gr.):</div>
          {selectedSubbooks.map((book) => (
            <FInput
              key={getBookKey(book)}
              value={book.cover_weight_gr}
              isInvalid={!!props.handlers?.getIsMissingRate(book) && !!book.super_design}
              onSubmit={(weight) => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }

                props.handlers.changeBook(book, 'cover_weight_gr', Number(weight));

                if (book.super_design) {
                  updateBookByDesign(book, { cover_weight_gr: parseFormattedInt({ weight }) });
                }
              }}
              regex={REGEX_NONNEGATIVE_INTEGER}
              emptyValueReplacement={0}
              disallowChangeOnInvalid
              editable={!props.readOnly}
              onChange={() => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.updateBookKeyToRateNotFound(book, false);
              }}
            />
          ))}
          <div style={{ gridColumn: `span ${selectedSubbooks.length + 1}`, height: 16 }} />
          <div style={{ fontWeight: 'bold' }}>Manual cost (DKK):</div>
          {selectedSubbooks.map((book) => (
            <FInput
              key={getBookKey(book)}
              value={book._manualCost}
              onSubmit={(manualCost) => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.changeBook(book, '_manualCost', Number(manualCost));
              }}
              emptyValueReplacement={0}
              regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL(
                !!userData?.comma_as_decimal_seperator,
              )}
              disallowChangeOnInvalid
              editable={!props.readOnly && regionToIsFreeInput[book.fmc_region]}
              format={(v) => twoDec(v, !!userData?.comma_as_decimal_seperator)}
              isInvalid={!book.super_design && !book._manualCost}
            />
          ))}
          <div style={{ gridColumn: `span ${selectedSubbooks.length + 1}`, height: 16 }} />
          <div style={{ fontWeight: 'bold', alignSelf: 'start' }}>Note:</div>
          {selectedSubbooks.map((book) => (
            <FInput
              multiline
              key={getBookKey(book)}
              value={book.note}
              align="start"
              onSubmit={(note) => {
                if (!props.handlers) {
                  showInternalApplicationError();
                  return;
                }
                props.handlers.changeBook(book, 'note', note);
              }}
              editable={!props.readOnly}
              containerStyle={{ alignSelf: 'start', whiteSpace: 'normal' }}
            />
          ))}
        </div>
      )}
    </div>
  );
}
