import { AdministrationDeleteProductEndpoint } from '@core/schemas/endpoint/schema.endpoint.administration';
import { REGEX_CUSTOM_PRODUCT_ID, REGEX_CUSTOM_PRODUCT_ID_OR_EMPTY } from '@core/types/types.regex';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showSuccessToast } from '@frontend/common/lib/functions';

import { FInput } from '@frontend/form/components/FInput';
import { Button } from '@lego/klik-ui';
import { useState } from 'react';

export function DeleteProduct() {
  const [productId, setProductId] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  async function deleteProduct() {
    setIsDeleting(true);
    const [err] = await callEndpoint({
      endpoint: AdministrationDeleteProductEndpoint,
      input: { productId: Number(productId) },
      errorHandling: { header: 'Deleting product' },
    });
    setIsDeleting(false);

    if (err) {
      return;
    }

    showSuccessToast('Deleted product', `Successfully deleted '${productId}'`);
    setProductId('');
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
      <FInput
        label="Product ID"
        value={productId}
        onChange={setProductId}
        align="start"
        regex={REGEX_CUSTOM_PRODUCT_ID_OR_EMPTY}
        placeholder="1234567"
        editable
        inputStyle={{ width: 250 }}
      />
      <div>
        <Button
          size="sm"
          colorScheme="error"
          isLoading={isDeleting}
          onClick={deleteProduct}
          disabled={!productId || !REGEX_CUSTOM_PRODUCT_ID.test(productId)}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}
