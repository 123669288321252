// order in this enum is used for sorting in report and frontend
export enum CostComponent {
  LEGOElements = 'LEGO_ELEMENTS', // 10
  PurchasedElements = 'PURCHASED_ELEMENTS', // 20
  OverdoseElements = 'OVERDOSE_ELEMENTS', // 25
  ElementsScrap = 'ELEMENTS_SCRAP', // 30
  // ElementsCosts = 'ELEMENTS_COSTS', // 40, deprecated
  Packing = 'PACKING', // 50
  Packaging = 'PACKAGING', // 60
  Instructions = 'INSTRUCTIONS', // 70
  License = 'LICENSE', // 80
  FinishedGoodOverhead = 'FINISHED_GOOD_OVERHEAD', // 90
  Others = 'OTHERS', // 100
  TotalCosts = 'TOTAL_COSTS', // 110
  LeadPrice = 'LEAD_PRICE', // 91
  Factor = 'FACTOR', // 92
}
