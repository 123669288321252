import { Button, useDisclosure } from '@lego/klik-ui';
import { ArrowRightBold, DeleteBold, DuplicateBold, EditBold, PlusBold } from '@lego/klik-ui/icons';
import { useContext, useMemo, useState } from 'react';
import { FCheckbox } from '@frontend/form/components/FCheckbox';
import { FInput } from '@frontend/form/components/FInput';
import { NCModal } from '@frontend/common/components/NCModal';
import { Table } from '@frontend/table/table';
import { PackagingSizesContent } from '@frontend/packaging/components/PackagingSizesContent';
import {
  getPackingStandardCostsColumns,
  getPackingNonstandardCostsColumns,
} from '../lib/packaging.columns';
import {
  PackingStandardCostsRow,
  PackingNonstandardCostsRow,
} from '@frontend/packaging/lib/packaging.types';
import { GREEN_BUTTON_STYLES } from '@frontend/common/lib/common.styles';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { showInternalApplicationError } from '@frontend/common/lib/functions';
import { UsePackingStandardCostsRowsReturnValue } from '../lib/usePackingStandardCostsRows';
import { UsePackingNonstandardCostsRowsReturnValue } from '../lib/usePackingNonstandardCostsRows';
import { MessageStack } from '@frontend/common/components/MessageStack';
import { ExportButton } from '@frontend/common/components/ExportButton';
import { REGEX_0_TO_100 } from '@core/types/types.regex';
import {
  PackagingGetEndpointResponse,
  PackagingSizesListEndpointResponse,
  PackingNonstandardCostsEndpoint,
  PackingStandardCostsEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.packaging';
import { Unpacked } from '@core/util/util.typing';
import { ReportStatement } from '@core/calculator/calculator.util.report';
import { ReportModal } from '@frontend/common/components/ReportModal';
import { DBPackingNonstandardKey } from '@core/schemas/db/schema.db.packaging';
import { FInputReadOnly } from '@frontend/form/components/FInputReadOnly';
import { InfoIconClickable } from '@frontend/common/components/InfoIconClickable';
import { InfoIcon } from '@frontend/common/components/InfoIcon';
import { PackagingSize } from '@core/schemas/interface/schema.interface';
import { PackingKeyId, PackingNonstandardKey } from '@core/types/types.packaging';
import { ImportFromRevisionButton } from './ImportFromRevisionButton';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { TOTAL_KEY } from '@core/const/const.TOTAL_KEY';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';

interface PackagingPackingCostsProps {
  approved: boolean;
  readOnly: boolean;
  modelBagChecked: boolean;
  onModelBagChecked?(newValue: boolean): void;
  prodHead: PackagingGetEndpointResponse['prodHead'];
  maxRevision?: number;
  selectedPackaging: Unpacked<PackagingSizesListEndpointResponse> | undefined;
  onSelectedPackagingChange?(newValue: Unpacked<PackagingSizesListEndpointResponse>): void;
  boxFillingDegree: number;
  onBoxFillingDegreeChange?(newValue: number): void;
  packagingSizes: PackagingSizesListEndpointResponse;
  packagingSizesLoading: boolean;
  packingStandardCostsRows: PackingStandardCostsRow[];
  packingStandardCostsHandlers?: UsePackingStandardCostsRowsReturnValue['handlers'];
  packingStandardCostsTotals: UsePackingStandardCostsRowsReturnValue['totals'];
  packingStandardCostsMessages: UsePackingStandardCostsRowsReturnValue['messages'];
  packingNonstandardKeys: DBPackingNonstandardKey[];
  packingNonstandardCostsRows: UsePackingNonstandardCostsRowsReturnValue['rows'];
  packingNonstandardCostsTotals: UsePackingNonstandardCostsRowsReturnValue['totals'];
  packingNonstandardCostsHandlers?: UsePackingNonstandardCostsRowsReturnValue['handlers'];
  packingNonstandardCostsMessages: UsePackingNonstandardCostsRowsReturnValue['messages'];
  note: string;
  onNoteChange?(newNote: string): void;
  hideCostTable: boolean;
  ppoPackagingSize: PackagingSize | undefined;
}

export function PackagingPackingCosts(props: PackagingPackingCostsProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedPackingNonstandardCostRow, setSelectedPackingNonstandardCostRow] =
    useState<PackingNonstandardCostsRow>();
  const { userData, showReport } = useContext(UserDataContext);
  const [report, setReport] = useState<{ header: string; statements: ReportStatement[] }>();

  const packingStandardCostsColumns = useMemo(
    () =>
      getPackingStandardCostsColumns({
        commaAsDecimalSeperator: !!userData?.comma_as_decimal_seperator,
        onQuantityChange: props.readOnly
          ? undefined
          : props.packingStandardCostsHandlers?.changeQuantity,
        onReportSelect: setReport,
        showReport,
      }),
    [
      props.packingStandardCostsHandlers,
      userData?.comma_as_decimal_seperator,
      props.readOnly,
      showReport,
    ],
  );

  const packingNonstandardCostsColumns = useMemo(
    () =>
      getPackingNonstandardCostsColumns({
        commaAsDecimalSeperator: !!userData?.comma_as_decimal_seperator,
        onReportSelect: setReport,
        packingNonstandardCostsHandlers: props.readOnly
          ? undefined
          : props.packingNonstandardCostsHandlers,
        showReport,
        packingNonstandardKeys: props.packingNonstandardKeys,
      }),
    [
      props.packingNonstandardCostsHandlers,
      userData?.comma_as_decimal_seperator,
      props.readOnly,
      showReport,
      props.packingNonstandardKeys,
    ],
  );

  const shownPackingStandardCostsRows = useMemo(
    () =>
      props.packingStandardCostsRows.concat({
        packingKey: TOTAL_KEY as PackingKeyId, // non critial false type casting
        description: 'Total',
        fmc1quantity: props.packingStandardCostsTotals.quantity.fmc1,
        fmc1cost: props.packingStandardCostsTotals.cost.fmc1,
        fmc2quantity: props.packingStandardCostsTotals.quantity.fmc2,
        fmc2cost: props.packingStandardCostsTotals.cost.fmc2,
        bun: 'PC',
        currency: 'DKK',
        report: [],
      }),
    [props.packingStandardCostsRows, props.packingStandardCostsTotals],
  );

  const { packingNonstandardCostsTotals, packingNonstandardCostsRows } = props;

  const shownPackingNonstandardCostsRows = useMemo(() => {
    const row: Unpacked<typeof packingNonstandardCostsRows> = {
      rowId: TOTAL_KEY,
      description: 'Total',
      packingType: '' as PackingNonstandardKey,
      fmc1operators: packingNonstandardCostsTotals.operations.fmc1,
      fmc2operators: packingNonstandardCostsTotals.operations.fmc2,
      fmc1machineTime: packingNonstandardCostsTotals.machineTime.fmc1,
      fmc2machineTime: packingNonstandardCostsTotals.machineTime.fmc2,
      fmc1quantity: packingNonstandardCostsTotals.quantity.fmc1,
      fmc2quantity: packingNonstandardCostsTotals.quantity.fmc2,
      fmc1cost: packingNonstandardCostsTotals.cost.fmc1,
      fmc2cost: packingNonstandardCostsTotals.cost.fmc2,
      bun: 'PC',
      currency: 'DKK',
      report: [],
    };

    return packingNonstandardCostsRows.concat(row);
  }, [packingNonstandardCostsTotals, packingNonstandardCostsRows]);

  async function fetchAndUpdatePackingStandardRows(productId: number, revision: number) {
    const [err, rows] = await callEndpoint({
      endpoint: PackingStandardCostsEndpoint,
      input: { productId: convertProductIdTo8digits(productId), revision },
      errorHandling: { header: 'Importing packing standard costs' },
    });

    if (err) return;

    props.packingStandardCostsHandlers?.importRows(rows);
  }

  async function fetchAndUpdatePackingNonstandardRows(productId: number, revision: number) {
    const [err, rows] = await callEndpoint({
      endpoint: PackingNonstandardCostsEndpoint,
      input: { productId: convertProductIdTo8digits(productId), revision },
      errorHandling: { header: 'Importing packing non-standard costs' },
    });

    if (err) return;

    props.packingNonstandardCostsHandlers?.importRows(rows);
  }

  return (
    <div>
      <ReportModal report={report} onClose={() => setReport(undefined)} />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 24,
        }}
      >
        <NCModal header="Packaging sizes" isOpen={isOpen} onClose={onClose} maxWidth={1200}>
          <PackagingSizesContent
            packagingSizes={props.packagingSizes}
            loading={props.packagingSizesLoading}
            selectedPackagingSize={props.selectedPackaging}
            onPackagingSizeSelect={(packSize) => {
              if (!props.onSelectedPackagingChange) {
                showInternalApplicationError();
                return;
              }
              props.onSelectedPackagingChange(packSize);
              onClose();
            }}
          />
        </NCModal>
        <FInput
          label="Box fill degree %"
          editable={!props.readOnly}
          value={props.boxFillingDegree}
          readOnlyAlign="start"
          regex={REGEX_0_TO_100}
          emptyValueReplacement={0}
          onChange={(v) => {
            if (!props.onBoxFillingDegreeChange) {
              showInternalApplicationError();
              return;
            }
            props.onBoxFillingDegreeChange(Number(v));
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
          <FInput
            label="Packaging size"
            blankDash
            value={
              props.selectedPackaging
                ? props.selectedPackaging.packaging_size + ', ' + props.selectedPackaging.erect_code
                : ''
            }
          />
          {!props.readOnly && (
            <EditBold
              className="clickable-icon"
              onClick={() => {
                if (props.readOnly) {
                  return;
                }
                onOpen();
              }}
            />
          )}
        </div>
        <FInputReadOnly
          label="Packaging size (PPO)"
          blankDash
          value={
            props.ppoPackagingSize ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {`${props.ppoPackagingSize.packaging_size}, ${props.ppoPackagingSize.erect_code}`}
                <InfoIconClickable
                  hide={!props.approved}
                  description={`Shown packaging size, ${props.ppoPackagingSize.packaging_size}, is the current PPO packaging size.`}
                />
              </div>
            ) : (
              ''
            )
          }
          align="start"
        />
        <FCheckbox
          label="Model bag"
          checked={props.modelBagChecked}
          onChange={props.onModelBagChecked}
          align="start"
          editable={!props.readOnly}
        />
      </div>
      <div style={{ width: 300, margin: '8px 0' }}>
        <FInput
          blankDash
          topLabel="Note:"
          multiline
          value={props.note}
          editable={!props.readOnly}
          onChange={(v) => {
            if (!props.onNoteChange) {
              showInternalApplicationError();
              return;
            }

            props.onNoteChange(v);
          }}
          labelStyle={{ fontWeight: 'bold' }}
        />
      </div>

      {props.hideCostTable ? (
        <div style={{ marginTop: 24, display: 'flex', alignItems: 'center' }}>
          <InfoIcon />
          Please select a source, packing regions and a packaging size before maintaining packing
          costs
        </div>
      ) : (
        <div>
          <div style={{ marginTop: 24 }}>
            <div style={{ marginBottom: 12, fontSize: 18, fontWeight: 'bold' }}>Standard costs</div>
            <MessageStack messages={props.packingStandardCostsMessages} />
            <Table
              id="packaging_standard-costs"
              removeInfoText
              onPaste={props.packingStandardCostsHandlers?.onPaste}
              removeSearch
              headerContent={
                <div style={{ display: 'flex', columnGap: 8 }}>
                  <ExportButton
                    rows={shownPackingStandardCostsRows}
                    columns={packingStandardCostsColumns}
                  />
                  {!props.readOnly && (
                    <Button
                      size="sm"
                      variant="outline"
                      leftIcon={<ArrowRightBold />}
                      onClick={props.packingStandardCostsHandlers?.copyQuantities}
                    >
                      Copy quantity
                    </Button>
                  )}
                  {!props.readOnly && (
                    <ImportFromRevisionButton
                      productId={props.prodHead.product_id}
                      fetchRows={fetchAndUpdatePackingStandardRows}
                    />
                  )}
                </div>
              }
              rowHasError={(r) => !!r.report.find((r) => r.isError)}
              rows={shownPackingStandardCostsRows}
              rowKey="packingKey"
              columns={packingStandardCostsColumns}
            />
          </div>
          <div style={{ marginTop: 24, marginBottom: 12, fontSize: 18, fontWeight: 'bold' }}>
            Nonstandard costs
          </div>
          <MessageStack messages={props.packingNonstandardCostsMessages} />
          <Table
            removeInfoText
            removeSearch
            onPaste={props.packingNonstandardCostsHandlers?.onPaste}
            noDataText="No nonstandard costs added..."
            id="packaging_packing-nonstandard-costs"
            onRowClick={props.readOnly ? undefined : setSelectedPackingNonstandardCostRow}
            selectedRow={props.readOnly ? undefined : selectedPackingNonstandardCostRow}
            rowHasError={(r) =>
              !!props.packingNonstandardCostsMessages.find((m) =>
                m.id.includes(r.rowId.toString()),
              ) || !!r.report.find((r) => r.isError)
            }
            headerContent={
              <div style={{ display: 'flex', columnGap: 8 }}>
                <ExportButton
                  rows={shownPackingNonstandardCostsRows}
                  columns={packingNonstandardCostsColumns}
                />
                {!props.readOnly && (
                  <>
                    <Button
                      size="sm"
                      variant="outline"
                      style={GREEN_BUTTON_STYLES}
                      onClick={props.packingNonstandardCostsHandlers?.addRow}
                      leftIcon={<PlusBold />}
                    >
                      Add row
                    </Button>
                    <Button
                      size="sm"
                      variant="outline"
                      disabled={!selectedPackingNonstandardCostRow}
                      onClick={() => {
                        if (!selectedPackingNonstandardCostRow) {
                          return;
                        }

                        if (!props.packingNonstandardCostsHandlers) {
                          showInternalApplicationError();
                          return;
                        }

                        props.packingNonstandardCostsHandlers.copyRow(
                          selectedPackingNonstandardCostRow.rowId,
                        );
                      }}
                      leftIcon={<DuplicateBold />}
                    >
                      Copy row
                    </Button>
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="error"
                      disabled={!selectedPackingNonstandardCostRow}
                      onClick={() => {
                        if (!selectedPackingNonstandardCostRow) {
                          return;
                        }
                        if (!props.packingNonstandardCostsHandlers) {
                          showInternalApplicationError();
                          return;
                        }

                        props.packingNonstandardCostsHandlers.removeRow(
                          selectedPackingNonstandardCostRow.rowId,
                        );
                        setSelectedPackingNonstandardCostRow(undefined);
                      }}
                      leftIcon={<DeleteBold />}
                    >
                      Remove row
                    </Button>
                    {!props.readOnly && (
                      <ImportFromRevisionButton
                        productId={props.prodHead.product_id}
                        fetchRows={fetchAndUpdatePackingNonstandardRows}
                      />
                    )}
                  </>
                )}
              </div>
            }
            rows={shownPackingNonstandardCostsRows}
            rowKey="rowId"
            rowIsDisabled={(r) => r.rowId === TOTAL_KEY}
            columns={packingNonstandardCostsColumns}
          />
        </div>
      )}
    </div>
  );
}
