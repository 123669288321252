import { RevisionError } from '@core/schemas/schema.common';

export function parseRevisionErrors(errors: string | undefined): RevisionError[] {
  if (!errors) {
    return [];
  }

  try {
    const val = JSON.parse(errors);

    if (!Array.isArray(val)) {
      return [];
    }

    return val.map((v) => ({ message: v.message || '', origin: v.origin || '', id: v.id || '' }));
  } catch {
    return [];
  }
}
