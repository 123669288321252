import { Calculator } from '@core/calculator/calculator';
import { DBBICost } from '@core/schemas/db/schema.db.bi';
import { DBElementsScrapRate, DBOHRate } from '@core/schemas/db/schema.db.common';
import { DBElemCost } from '@core/schemas/db/schema.db.elements';
import {
  DBPackagingNonstandardCost,
  DBPackagingStandardCost,
  DBPackingNonstandardCost,
  DBPackingStandardCost,
} from '@core/schemas/db/schema.db.packaging';
import { DBProdCost, DBProdPPO } from '@core/schemas/db/schema.db.prod';
import { CostComponent } from '@core/types/types.costComponent';
import { showInternalApplicationError } from '@frontend/common/lib/functions';

import { CostOverviewRow } from '@frontend/common/lib/models';
import { useEffect, useState } from 'react';

export function useFinishedGoodsOverheadCostsOverviewRow(props: {
  productId: number | undefined;
  revision: number | undefined;
  readOnly: boolean;
  productCosts: {
    cost: DBProdCost | undefined;
    ppo: DBProdPPO | undefined;
  };
  biCosts: Pick<DBBICost, 'cost' | 'fmc_region'>[] | undefined;
  elementCosts: DBElemCost[] | undefined;
  packingStandardCosts: DBPackingStandardCost[] | undefined;
  copyPpoToCost?: boolean;
  overheadRate: DBOHRate | undefined;
  elementsScrapRate: DBElementsScrapRate | undefined;
  packagingStandardCosts: DBPackagingStandardCost[] | undefined;
  packagingNonstandardCosts: DBPackagingNonstandardCost[] | undefined;
  packingNonstandardCosts: DBPackingNonstandardCost[] | undefined;
  otherCost: { fmc1cost: number | undefined; fmc2cost: number | undefined };
  fmcYear: number | undefined;
  invalidRates: boolean; // have this to avoid calculation when rates are undergoing update due to year change
}): { cc_FinishedGoodOverhead: CostOverviewRow } {
  const {
    productCosts,
    copyPpoToCost,
    biCosts,
    readOnly,
    elementCosts,
    packingStandardCosts,
    fmcYear,
    overheadRate,
    elementsScrapRate,
    otherCost,
    packagingStandardCosts,
    packagingNonstandardCosts,
    packingNonstandardCosts,
    invalidRates,
    productId,
    revision,
  } = props;

  const [cc_FinishedGoodOverhead, setCC_FinishedGoodOverhead] = useState<CostOverviewRow>();

  useEffect(() => {
    if (!fmcYear || invalidRates || !productId || !revision) {
      return;
    }

    if (!readOnly && (!overheadRate || !elementsScrapRate)) {
      return;
    }

    (async () => {
      try {
        const costPpoVariance = await Calculator.CC_FinishedGoodOverhead({
          input: {
            productId,
            revision,
            copyPpoToCost: copyPpoToCost,
            ppos: { FMC1: productCosts.ppo?.fmc_eu_cost, FMC2: productCosts.ppo?.fmc_us_cost },
            fmcYear,
            overheadType: overheadRate?.type,
            scrapType: elementsScrapRate?.type,
          },
          cache: {
            biCosts,
            elementCosts,
            otherCost: { fmc1cost: otherCost.fmc1cost || 0, fmc2cost: otherCost.fmc2cost || 0 },
            packingStandardCosts,
            packingNonstandardCosts,
            packagingStandardCosts,
            packagingNonstandardCosts,
            overheadRate,
            elementsScrapRate,
            cost: {
              use: readOnly,
              values: {
                fmc1cost: productCosts.cost?.fmc_eu_cost,
                fmc2cost: productCosts.cost?.fmc_us_cost,
              },
            },
          },
        });

        setCC_FinishedGoodOverhead({
          costComp: CostComponent.FinishedGoodOverhead,
          description: 'Finished goods overhead',
          currency: 'DKK',
          ...costPpoVariance,
        });
      } catch (error) {
        showInternalApplicationError();
      }
    })();
  }, [
    productId,
    revision,
    readOnly,
    overheadRate,
    fmcYear,
    productCosts,
    copyPpoToCost,
    biCosts,
    elementCosts,
    otherCost,
    packagingStandardCosts,
    packagingNonstandardCosts,
    packingStandardCosts,
    elementsScrapRate,
    packingNonstandardCosts,
    invalidRates,
  ]);

  return {
    cc_FinishedGoodOverhead: cc_FinishedGoodOverhead || {
      costComp: CostComponent.FinishedGoodOverhead,
      description: 'Finished goods overhead',
      currency: 'DKK',
    },
  };
}
