import { Button, Modal } from '@lego/klik-ui';

interface NCMessageModalProps {
  variant: string;
  header: string;
  body: React.ReactNode;
  isOpen?: boolean;
  size?: 'xl' | 'sm' | 'md' | 'lg' | '2xl' | 'xs' | '3xl' | '4xl' | '5xl' | '6xl' | 'full' | 'auto';
  onClose(): void;
}

export function NCMessageModal(props: NCMessageModalProps) {
  return (
    <Modal
      isOpen={props.isOpen === undefined ? true : props.isOpen}
      onClose={props.onClose}
      size={props.size ?? 'xl'}
      variant={props.variant ?? 'error'}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header fontSize="xl" style={{ lineHeight: undefined }}>
          {props.header}
        </Modal.Header>
        <Modal.CloseButton />
        <Modal.Body fontSize="md">{props.body}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onClose} size="sm">
            OK
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
