import { z } from 'zod';
import { defineEndpoint } from '@core/util/util.defineEndpoint';
import { PK } from '@core/types/types.pk';

export const AdministrationCreateProductEndpoint = defineEndpoint({
  path: '/administration/create-product',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
    description: z.string().optional(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
export type AdministrationCreateProductEndpointInput = z.infer<
  (typeof AdministrationCreateProductEndpoint)['input']
>;
export type AdministrationCreateProductEndpointResponse = z.infer<
  (typeof AdministrationCreateProductEndpoint)['response']
>;

export const AdministrationDeleteProductEndpoint = defineEndpoint({
  path: '/administration/delete-product',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
export type AdministrationDeleteProductEndpointInput = z.infer<
  (typeof AdministrationDeleteProductEndpoint)['input']
>;
export type AdministrationDeleteProductEndpointResponse = z.infer<
  (typeof AdministrationDeleteProductEndpoint)['response']
>;

export const AdministrationSimulateElementsCalculationEndpoint = defineEndpoint({
  path: '/administration/simulate-elements-calculation',
  mutation: true,
  input: z.object({
    productIds: z.array(z.number().int()),
    force: z.boolean(),
  }),
  response: z.array(
    z.object({
      productId: z.number(),
      noOpReason: z.string().optional(),
      error: z.string().optional(),
    }),
  ),
});
export type AdministrationSimulateElementsCalculationEndpointInput = z.infer<
  (typeof AdministrationSimulateElementsCalculationEndpoint)['input']
>;
export type AdministrationSimulateElementsCalculationEndpointResponse = z.infer<
  (typeof AdministrationSimulateElementsCalculationEndpoint)['response']
>;

export const AdministrationGetBOMEndpoint = defineEndpoint({
  path: '/administration/get-bom',
  input: z.object({
    productId: z.number(),
  }),
  response: z.object({
    bomNumber: z.number(),
    status: z.number(),
    materials: z.array(z.object({ materialId: z.number(), quantity: z.number() })),
    lastChanged: z.string(),
  }),
});
export type AdministrationGetBOMEndpointInput = z.infer<
  (typeof AdministrationGetBOMEndpoint)['input']
>;
export type AdministrationGetBOMEndpointResponse = z.infer<
  (typeof AdministrationGetBOMEndpoint)['response']
>;

export const AdministrationUpdateBOMEndpoint = defineEndpoint({
  path: '/administration/update-bom',
  mutation: true,
  input: z.object({
    productId: z.number(),
    bomNumber: z.number(),
    status: z.number(),
    materials: z.array(z.object({ materialId: z.number(), quantity: z.number() })),
  }),
  response: z.object({ ok: z.literal(true) }),
});
export type AdministrationUpdateBOMEndpointInput = z.infer<
  (typeof AdministrationUpdateBOMEndpoint)['input']
>;
export type AdministrationUpdateBOMEndpointResponse = z.infer<
  (typeof AdministrationUpdateBOMEndpoint)['response']
>;

const BOMItemSchema = z.object({
  materialId: z.number(),
  quantity: z.number(),
  description: z.string(),
  materialType: z.string(),
  materialGroup: z.union([z.number(), z.literal('')]),
  makeBuy: z.union([z.literal('M'), z.literal('B'), z.literal('X'), z.literal('')]),
  costingGlobalVendor: z.number().optional(),
  version: z.string(),
});
export type BOMItem = z.infer<typeof BOMItemSchema>;

export const AdministrationTestBOMConstructionEndpoint = defineEndpoint({
  path: '/administration/test-bom-construction',
  input: z.object({
    productId: z.number(),
  }),
  response: z.object({
    productId: z.number().int(),
    status: z.number().int(),
    materials: z.array(BOMItemSchema),
    ignored: z.array(z.object({ material: BOMItemSchema, reason: z.string() })),
    exploded: z.array(BOMItemSchema),
    overdose: z.array(BOMItemSchema),
  }),
});
export type AdministrationTestBOMConstructionEndpointInput = z.infer<
  (typeof AdministrationTestBOMConstructionEndpoint)['input']
>;
export type AdministrationTestBOMConstructionEndpointResponse = z.infer<
  (typeof AdministrationTestBOMConstructionEndpoint)['response']
>;

export const AdministrationTransferDataEndpoint = defineEndpoint({
  path: '/administration/transfer-data',
  mutation: true,
  input: z.object({ pk: z.nativeEnum(PK), from: z.enum(['development', 'test', 'production']) }),
  response: z.object({ ok: z.literal(true) }),
});
