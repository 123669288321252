import { useEffect, useMemo, useState } from 'react';
import { Button, Link, useDisclosure } from '@lego/klik-ui';
import { showErrorToast, showSuccessToast } from '../../common/lib/functions';
import { useLocation } from 'react-router-dom';
import { NCModal } from '../../common/components/NCModal';
import { ProductIdSelectionsContent } from '../../reporting/components/ProductIdSelectionsContent';
import { FInput } from '../../form/components/FInput';
import { REGEX_NONNEGATIVE_INTEGER_OR_EMPTY } from '@core/types/types.regex';
import { CrossBold } from '@lego/klik-ui/icons';
import { Tag } from '../../common/components/Tag';
import { ViewName, ViewNameEnum } from '@core/schemas/schema.common';
import { FSelect } from '../../form/components/FSelect';
import { callEndpoint } from '../../common/lib/callEndpoint';
import { FMCYearChangeEndpoint } from '@core/schemas/endpoint/schema.endpoint.fmcYearChange';

export function FMCYearChangeContent() {
  const [productId, setProductId] = useState('');
  const [productIds, setProductIds] = useState<number[]>([]);
  const [productIdRangeFrom, setProductIdRangeFrom] = useState('');
  const [productIdRangeTo, setProductIdRangeTo] = useState('');
  const [productIdRanges, setProductIdRanges] = useState<{ from: number; to: number }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedView, setSelectedView] = useState<ViewName>('elements');

  const { isOpen, onClose, onOpen } = useDisclosure();

  const location = useLocation();

  useEffect(() => {
    setProductId('');
    setProductIds([]);
    setProductIdRangeFrom('');
    setProductIdRangeTo('');
    setProductIdRanges([]);
  }, [location.pathname]);

  function addProductId(input: string) {
    setProductIds((curr) => curr.filter((pid) => pid !== Number(input)).concat(Number(input)));
  }

  function addCurrentProductId() {
    addProductId(productId);
    setProductId('');
  }

  function addProductIdRange() {
    if (!productIdRangeFrom || !productIdRangeTo) {
      return;
    }

    const from = Number(productIdRangeFrom);
    const to = Number(productIdRangeTo);

    const newRange = { from: from <= to ? from : to, to: from <= to ? to : from };

    setProductIdRanges((ranges) =>
      ranges.filter((range) => JSON.stringify(range) !== JSON.stringify(newRange)).concat(newRange),
    );
    setProductIdRangeFrom('');
    setProductIdRangeTo('');
  }

  function removeProductId(productId: number) {
    setProductIds((curr) => curr.filter((pid) => pid !== productId));
  }

  function removeProductIdRange(productIdRange: { from: number; to: number }) {
    setProductIdRanges((curr) =>
      curr.filter((range) => JSON.stringify(range) !== JSON.stringify(productIdRange)),
    );
  }

  const selections = useMemo(
    () => [...productIds, ...productIdRanges],
    [productIds, productIdRanges],
  );

  async function onOk() {
    if (productId || productIdRangeFrom || productIdRangeTo) {
      showErrorToast(
        'Processing FMC year change',
        'Product ID or product ID range has been filled but not added. Either add or remove the entry/entries',
      );
      return;
    }

    setIsLoading(true);
    const [err] = await callEndpoint({
      endpoint: FMCYearChangeEndpoint,
      input: { productIdRanges, productIds, view: selectedView },
      errorHandling: { header: 'Processing FMC year change' },
    });
    setIsLoading(false);

    if (err) {
      return;
    }

    showSuccessToast('FMC year change', 'Successfully processed FMC year change');
  }

  const InputComponent = () => (
    <div style={{ display: 'flex', columnGap: 8, alignItems: 'end', width: '100%' }}>
      <FInput
        topLabel="Product ID"
        editable
        align="start"
        regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
        disallowChangeOnInvalid
        onMultiValuePaste={(productIds) => productIds.map(addProductId)}
        disableSmartBehavior
        value={productId}
        disableBlurOnEnter
        onChange={setProductId}
        onEnter={addCurrentProductId}
        style={{ width: 'inherit' }}
      />
      <Button colorScheme="success" disabled={!productId} onClick={addCurrentProductId}>
        Add
      </Button>
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        rowGap: 24,
        columnGap: 16,
        alignItems: 'center',
      }}
    >
      <NCModal header="Product ID selections" isOpen={isOpen} onClose={onClose} width={600}>
        <ProductIdSelectionsContent
          productIds={productIds}
          productIdRanges={productIdRanges}
          onRemove={(selection) => {
            if (selection.type === 'single') {
              removeProductId(Number(selection.value));
            } else {
              const [from, to] = selection.value.split('-').map((v) => Number(v));
              removeProductIdRange({ from, to });
            }
          }}
          onMultiValuePaste={(productIds) => {
            productIds.map(addProductId);
            setTimeout(() => setProductId(''), 1);
          }}
          addCurrentProductId={addProductId}
        />
      </NCModal>
      <div style={{ width: '100%' }}>
        <InputComponent />
        <div>
          <div style={{ marginTop: 8, marginBottom: 4 }}>Product ID range</div>
          <div
            style={{
              display: 'flex',
              columnGap: 8,
              alignItems: 'end',
              width: '100%',
            }}
          >
            <FInput
              placeholder="From"
              editable
              align="start"
              regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
              disallowChangeOnInvalid
              disableSmartBehavior
              value={productIdRangeFrom}
              disableBlurOnEnter
              onChange={setProductIdRangeFrom}
              onEnter={addProductIdRange}
              style={{ width: 'inherit' }}
            />
            <div style={{ marginBottom: 6 }}>-</div>
            <FInput
              editable
              placeholder="To"
              align="start"
              regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
              disallowChangeOnInvalid
              disableSmartBehavior
              value={productIdRangeTo}
              disableBlurOnEnter
              onChange={setProductIdRangeTo}
              onEnter={addProductIdRange}
              style={{ width: 'inherit' }}
            />
            <Button
              colorScheme="success"
              size="sm"
              disabled={!productIdRangeFrom || !productIdRangeTo}
              onClick={addProductIdRange}
            >
              Add
            </Button>
          </div>
        </div>
        {selections.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: 4,
              rowGap: 4,
              marginTop: 16,
            }}
          >
            {selections.slice(0, 5).map((idOrRange) => (
              <Tag
                key={
                  typeof idOrRange === 'number' ? idOrRange : idOrRange.from + ',' + idOrRange.to
                }
                style={{
                  fontSize: 14,
                  height: 28,
                  padding: '0 8px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (typeof idOrRange === 'number') {
                    removeProductId(idOrRange);
                  } else {
                    removeProductIdRange(idOrRange);
                  }
                }}
              >
                {typeof idOrRange === 'number' ? idOrRange : idOrRange.from + '-' + idOrRange.to}
                <CrossBold style={{ fontSize: 10, marginLeft: 4, marginTop: -2 }} />
              </Tag>
            ))}
          </div>
        )}
        {selections.length > 5 && (
          <div style={{ marginTop: 8, textAlign: 'center' }}>
            And {selections.length - 5} more.
            <Link onClick={onOpen} style={{ marginLeft: 8 }} className="link-message">
              Show
            </Link>
          </div>
        )}
      </div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
        <FSelect
          topLabel="Revision(s)"
          containerStyle={{ width: '100%' }}
          value={selectedView}
          onChange={(v) => setSelectedView(v as ViewName)}
          editable
          options={[
            { text: 'Elements', value: ViewNameEnum.Enum.elements },
            { text: 'BI', value: ViewNameEnum.Enum.bi },
            { text: 'Packaging', value: ViewNameEnum.Enum.packaging },
            { text: 'Final costs', value: ViewNameEnum.Enum.finalcosts },
          ]}
          labelStyle={{ fontWeight: 'normal' }}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          size="sm"
          disabled={productIds.length === 0 && productIdRanges.length === 0}
          isLoading={isLoading}
          onClick={onOk}
        >
          OK
        </Button>
      </div>
    </div>
  );
}
