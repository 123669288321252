import { Button } from '@lego/klik-ui';
import { useEffect, useRef } from 'react';
import { callEndpoint } from '../lib/callEndpoint';
import { NotifyEndpoint } from '@core/schemas/endpoint/schema.endpoint.common';

interface ErrorBoundaryContentProps {
  onReset(): void;
  error: unknown;
}

export function ErrorBoundaryContent(props: ErrorBoundaryContentProps) {
  const hasPosted = useRef(false);

  useEffect(() => {
    if (hasPosted.current || window.location.protocol === 'http:') {
      return;
    }

    hasPosted.current = true;

    callEndpoint({
      endpoint: NotifyEndpoint,
      input: {
        type: 'error',
        message: 'Internal application error: Error boundary reached',
        payload: { error: (props.error as Error).message, stack: (props.error as Error).stack },
      },
    });
  }, [props.error]);

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
      <div
        style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%,-50%)' }}
      >
        <div>
          <div style={{ fontSize: 24, lineHeight: 'normal' }}>
            Oops, we were not supposed to get here...
          </div>
          <div style={{ marginTop: 12 }}>An unexpected error occurred.</div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 36 }}>
            <Button onClick={props.onReset}>Reload</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
