import { SchemaProperty } from '@core/types/types.schema';
import { Tag } from '@frontend/common/components/Tag';
import { CrossBold } from '@lego/klik-ui/icons';
import { useCallback } from 'react';
import { FSelect } from '@frontend/form/components/FSelect';
import {
  FilterExclude,
  FilterInclude,
} from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';

interface FilterSettingsEnumIncludeExcludeProps {
  property: SchemaProperty & { filter: FilterInclude | FilterExclude };
  onChange(newFilterValue: FilterInclude['value'] | FilterExclude['value']): void;
}

export function FilterSettingsEnumIncludeExclude(props: FilterSettingsEnumIncludeExcludeProps) {
  const { property, onChange } = props;

  const addToList = useCallback(
    (element: string) => {
      if (property.filter.value.includes(element)) {
        return;
      }

      onChange(property.filter.value.concat(element));
    },
    [property.filter.value, onChange],
  );

  const removeFromList = useCallback(
    (element: string) => onChange(property.filter.value.filter((e: string) => e !== element)),
    [property.filter.value, onChange],
  );

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 24 }}>
      <div>
        <FSelect
          placeholder={property.key + ' to include'}
          editable
          value=""
          options={props.property.enumValues?.map((v) => ({ text: v, value: v }))}
          align="start"
          onChange={(v) => addToList(v)}
          disabled={property.filter.value.length > 0}
        />
        {property.filter.value.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: 4,
              rowGap: 4,
              marginTop: 8,
            }}
          >
            {property.filter.value.map((element) => (
              <Tag
                key={element}
                style={{
                  fontSize: 14,
                  height: 28,
                  padding: '0 8px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => removeFromList(element)}
              >
                {element}
                <CrossBold style={{ fontSize: 10, marginLeft: 4, marginTop: -2 }} />
              </Tag>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
