import { Checkbox, FormControl } from '@lego/klik-ui';
import { CSSProperties, useMemo } from 'react';
import { FLabel } from './FLabel';

interface FCheckboxProps {
  label?: string;
  checked?: boolean;
  required?: boolean;
  onChange?(checked: boolean): void;
  editable?: boolean;
  align?: 'start' | 'end';
  mapping?: [string, string];
  labelStyle?: CSSProperties;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
}

export function FCheckbox(props: FCheckboxProps) {
  const canInput = useMemo(() => props.editable, [props.editable]);

  return (
    <div style={props.containerStyle}>
      <FormControl
        isRequired={props.required}
        size="sm"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: props.align === 'start' ? undefined : 'space-between',
        }}
        isReadOnly={!props.editable}
        data-can-paste={canInput}
        className="felement"
      >
        {props.label && (
          <FLabel label={props.label} required={props.required} style={props.labelStyle} />
        )}
        {props.editable ? (
          <Checkbox
            name="."
            isChecked={!!props.checked}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange(v.target.checked);
              }
            }}
            style={props.style}
          />
        ) : props.mapping ? (
          props.mapping[props.checked ? 1 : 0]
        ) : props.checked ? (
          'Yes'
        ) : (
          'No'
        )}
      </FormControl>
    </div>
  );
}
