import { defineEndpoint } from '@core/util/util.defineEndpoint';
import { z } from 'zod';
import { RevisionsModeEnum } from '../schema.common';

// Common report query
export const ReportCommonQueryInputSchema = z.object({
  productIds: z.array(z.number().int()),
  productIdRanges: z.array(z.object({ from: z.number(), to: z.number() })),
  year: z.object({ from: z.number().int().optional(), to: z.number().int().optional() }),
  revisionsMode: RevisionsModeEnum,
  convertProductIds: z.boolean().optional(),
});
export type ReportCommonQueryInput = z.infer<typeof ReportCommonQueryInputSchema>;

// Common report output
export const ReportCommonOutput = z.object({ url: z.string() });
export type ReportCommonOutput = z.infer<typeof ReportCommonQueryInputSchema>;

// ReportElementsCostsGetEndpoint
export const ReportElementsCostsGetEndpoint = defineEndpoint({
  path: '/report/elements-costs/get',
  input: ReportCommonQueryInputSchema,
  response: ReportCommonOutput,
});

export type ReportElementsCostsGetEndpointInput = z.infer<
  typeof ReportElementsCostsGetEndpoint.input
>;
export type ReportElementsCostsGetEndpointResponse = z.infer<
  typeof ReportElementsCostsGetEndpoint.response
>;

// ReportElementsErrorsGetEndpoint
export const ReportElementsErrorsGetEndpoint = defineEndpoint({
  path: '/report/elements-errors/get',
  input: ReportCommonQueryInputSchema,
  response: ReportCommonOutput,
});

export type ReportElementsErrorsGetEndpointInput = z.infer<
  typeof ReportElementsErrorsGetEndpoint.input
>;
export type ReportElementsErrorsGetEndpointResponse = z.infer<
  typeof ReportElementsErrorsGetEndpoint.response
>;

// ReportBIGetEndpoint
export const ReportBIGetEndpoint = defineEndpoint({
  path: '/report/bi/get',
  input: ReportCommonQueryInputSchema,
  response: ReportCommonOutput,
});

export type ReportBIGetEndpointInput = z.infer<typeof ReportBIGetEndpoint.input>;
export type ReportBIGetEndpointResponse = z.infer<typeof ReportBIGetEndpoint.response>;

// ReportBIGetEndpoint (LBX)
export const ReportBILBXGetEndpoint = defineEndpoint({
  path: '/report/bi-lbx/get',
  input: ReportCommonQueryInputSchema,
  response: ReportCommonOutput,
});

export type ReportBILBXGetEndpointInput = z.infer<typeof ReportBILBXGetEndpoint.input>;
export type ReportBILBXGetEndpointResponse = z.infer<typeof ReportBILBXGetEndpoint.response>;

// ReportPackagingGetEndpoint
export const ReportPackagingGetEndpoint = defineEndpoint({
  path: '/report/packaging/get',
  input: ReportCommonQueryInputSchema,
  response: ReportCommonOutput,
});

export type ReportPackagingGetEndpointInput = z.infer<typeof ReportPackagingGetEndpoint.input>;
export type ReportPackagingGetEndpointResponse = z.infer<
  typeof ReportPackagingGetEndpoint.response
>;

// ReportCostingStatusGetEndpoint
export const ReportCostingStatusGetEndpoint = defineEndpoint({
  path: '/report/costing-status/get',
  input: ReportCommonQueryInputSchema.omit({ revisionsMode: true }),
  response: ReportCommonOutput,
});

export type ReportCostingStatusGetEndpointInput = z.infer<
  typeof ReportCostingStatusGetEndpoint.input
>;
export type ReportCostingStatusGetEndpointResponse = z.infer<
  typeof ReportCostingStatusGetEndpoint.response
>;

// ReportFinalCostsGetEndpoint
export const ReportFinalCostsGetEndpoint = defineEndpoint({
  path: '/report/final-costs/get',
  input: ReportCommonQueryInputSchema,
  response: ReportCommonOutput,
});

export type ReportFinalCostsGetEndpointInput = z.infer<typeof ReportFinalCostsGetEndpoint.input>;
export type ReportFinalCostsGetEndpointResponse = z.infer<
  typeof ReportFinalCostsGetEndpoint.response
>;
