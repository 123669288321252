import { prettifyTimestamp } from '../lib/common.util';
import { CSSProperties, useContext, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import 'semantic-ui-css/components/step.min.css';
import 'semantic-ui-css/components/icon.min.css';
import { ProdHeadCore } from '@core/schemas/db/schema.db.prod';
import { MessageBubble } from '@lego/klik-ui/icons';
import { NCMessageModal } from './NCMessageModal';
import { UserDataContext } from '../lib/contexts';

interface TimestampsBarProps {
  prodHead: Pick<
    ProdHeadCore,
    | 'PK'
    | 'created_by'
    | 'created_timestamp'
    | 'changed_by'
    | 'changed_timestamp'
    | 'approved_by'
    | 'approved_timestamp'
    | 'approval_note'
  >;
  disableApprove?: boolean;
  style?: CSSProperties;
}

export function TimestampsBar(props: TimestampsBarProps) {
  const [shownApprovalNote, setShownApprovalNote] = useState('');

  const { getName } = useContext(UserDataContext);

  return (
    <div>
      <NCMessageModal
        isOpen={!!shownApprovalNote}
        header="Approval note"
        body={shownApprovalNote}
        variant="info"
        onClose={() => setShownApprovalNote('')}
      />
      <div style={{ display: 'flex', alignItems: 'center', columnGap: 16 }}>
        <div
          title={`Created by ${getName(props.prodHead.created_by)} at ${prettifyTimestamp(
            props.prodHead.created_timestamp,
          )}`}
        >
          <Icon name="file alternate" />
          {`${getName(props.prodHead.created_by)},
          ${prettifyTimestamp(props.prodHead.created_timestamp)}`}
        </div>
        <div
          title={`Changed by ${getName(props.prodHead.changed_by)} at ${prettifyTimestamp(
            props.prodHead.changed_timestamp,
          )}`}
        >
          {props.prodHead.changed_by ? (
            <div>
              <Icon name="edit" />
              {`${getName(props.prodHead.changed_by)},
              ${prettifyTimestamp(props.prodHead.changed_timestamp)}`}
            </div>
          ) : (
            ''
          )}
        </div>
        <div>
          {props.prodHead.approved_by ? (
            <div
              title={`Approved by ${getName(props.prodHead.approved_by)} at ${prettifyTimestamp(
                props.prodHead.approved_timestamp,
              )}`}
            >
              <Icon name="check" />
              {`${getName(props.prodHead.approved_by)},
              ${prettifyTimestamp(props.prodHead.approved_timestamp)}`}
              {props.prodHead.approved_by && props.prodHead.approval_note && (
                <MessageBubble
                  style={{ cursor: 'pointer', marginLeft: 8 }}
                  onClick={() => setShownApprovalNote(props.prodHead.approval_note || '')}
                />
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
