import { FinalCostsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.finalCosts';
import { BICosts } from '@frontend/bi/components/BiCosts';
import { useBooksAndRates } from '@frontend/bi/logic/useBooksAndRates';

interface FinalCostsBITabProps {
  productId: number | undefined;
  fmcYear: number | undefined;
  buildingInstructionsData: Exclude<FinalCostsGetEndpointResponse['biData'], null>;
}

export function FinalCostsBITab(props: FinalCostsBITabProps) {
  const { books } = useBooksAndRates({
    fmcYear: props.fmcYear,
    payloadBiCosts: props.buildingInstructionsData.biCosts,
    useQrCode: props.buildingInstructionsData.biQrCode,
    readOnly: true,
  });

  return (
    <BICosts
      productId={props.productId}
      readOnly
      fmcYear={props.fmcYear}
      books={books}
      hasQrCode={props.buildingInstructionsData.biQrCode}
    />
  );
}
