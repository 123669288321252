export function checkApproveBI(input: { biStatus: number | undefined; books: unknown[] }) {
  const { biStatus, books } = input;

  if (biStatus === 0) {
    throw new Error('BI status must be set');
  }

  if (books.length === 0) {
    throw new Error('At least one book must be added');
  }
}
