import { DBPackagingNonstandardCost } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { getFreshId } from '@core/util/util.geFreshId';

export const PackagingNonstandardCostExample: OmitDBRecord<DBPackagingNonstandardCost>[] = [
  {
    product_id: 1,
    revision: 1,
    row_id: getFreshId(),
    description: 'a',
    fmc1_quantity: 1,
    fmc2_quantity: 1,
    fmc1_material: 1,
    fmc2_material: undefined,
    fmc1_material_description: 'a',
    fmc2_material_description: '',
    fmc1_cost: 1.23,
    fmc2_cost: 1.23,
    fmc1_cost_plus_scrap: 1.4,
    fmc2_cost_plus_scrap: 1.4,
    currency: 'DKK',
  },
];
