import { ExcelConvertionColumn } from '@core/types/types.xlsxConvertion';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertToXlsxDataFormat<T extends Record<string, any>, S extends keyof T>(
  rows: T[],
  columns: ExcelConvertionColumn<T>[],
  mappers?: [S, (v: T[S]) => string | boolean | number][],
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any[] = [
    // Add header
    columns?.map((c) => ({
      value: (c.groupName ? c.groupName + ' - ' : '') + c.title,
      fontWeight: 'bold',
      backgroundColor: '#D9D9D9',
    })),
  ];

  const mappedRows = rows.map((r, i) => {
    const newR = structuredClone(r);
    columns.forEach(({ dataIndex, format }) => {
      if (dataIndex && format) {
        newR[dataIndex] = format(newR[dataIndex], newR, i, rows) as T[S];
      }
    });
    mappers?.forEach(([k, m]) => (newR[k] = m(newR[k]) as T[S]));
    return newR;
  });

  mappedRows.forEach((row, i) => {
    data.push(
      columns
        .filter((c) => !c.ignoreInExport)
        .map((c) => {
          // Get value
          let value =
            c.dataIndex !== ''
              ? row[c.dataIndex]
              : c.format
              ? c.format('', row, i, mappedRows)
              : '';
          let type: StringConstructor | NumberConstructor = String;

          if (typeof value === 'number') {
            type = Number;
          } else if (typeof value === 'boolean') {
            value = value ? 'true' : 'false';
          } else if (typeof value === 'undefined' || value === null) {
            // Convert to string
            value = '';
          } else if (typeof value !== 'string') {
            // Throw error
            console.log(c.dataIndex, typeof value, value);
            throw new Error('Convertion error');
          }

          return {
            type,
            value,
          };
        }),
    );
  });

  return data;
}
