import { Calculator } from '../calculator';

export function calculate_CC_Factor_sum(props: {
  leadPrice: { fmc1cost: number; fmc2cost: number };
  totalCosts: { fmc1cost: number; fmc2cost: number };
}): { fmc1factor: number | undefined; fmc2factor: number | undefined } {
  const { leadPrice, totalCosts } = props;

  const fmc1factor = Calculator.Common.Quotient(leadPrice.fmc1cost, totalCosts.fmc1cost);
  const fmc2factor = Calculator.Common.Quotient(leadPrice.fmc2cost, totalCosts.fmc2cost);

  return {
    fmc1factor,
    fmc2factor,
  };
}
