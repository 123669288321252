import { Button } from '@lego/klik-ui';
import { useContext, useEffect, useState } from 'react';
import { CircleSpinner } from '@frontend/common/components/CircleSpinner';
import { FInput } from '@frontend/form/components/FInput';
import { FSelect } from '@frontend/form/components/FSelect';
import { ConfigSetActiveFmcYearEndpoint } from '@core/schemas/endpoint/schema.endpoint.config';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showInternalApplicationError, showSuccessToast } from '@frontend/common/lib/functions';
import { ConfigContext } from '@frontend/common/lib/contexts';

const earliestYear = new Date().getFullYear();

export function SetActiveFmcYear() {
  const [selectedYear, setSelectedYear] = useState<number>();
  const [isUpdating, setIsUpdating] = useState(false);

  const { config, refresh, loading } = useContext(ConfigContext);

  useEffect(() => {
    setSelectedYear(config.fmc_active_year);
  }, [config]);

  async function updateActiveFmcYear() {
    if (!selectedYear) {
      showInternalApplicationError();
      return;
    }

    setIsUpdating(true);
    const [err] = await callEndpoint({
      endpoint: ConfigSetActiveFmcYearEndpoint,
      input: { year: selectedYear },
      errorHandling: { header: 'Updating active FMC year' },
    });
    setIsUpdating(false);

    if (err) {
      return;
    }

    showSuccessToast(`Updated active FMC year to ${selectedYear}`);
    refresh();
  }

  return loading ? (
    <CircleSpinner />
  ) : (
    <div>
      <FInput
        label="Current active FMC year"
        value={config.fmc_active_year}
        align="start"
        style={{ width: 300 }}
      />
      <div style={{ display: 'flex', alignItems: 'center', columnGap: 12 }}>
        <FSelect
          editable
          placeholder="Year"
          label="Select new active FMC year"
          options={Array.from(new Array(new Date().getFullYear() + 2 - earliestYear)).map(
            (_, i) => ({
              value: `${earliestYear + i}`,
              text: earliestYear + i,
            }),
          )}
          value={selectedYear?.toString()}
          onChange={(year) => setSelectedYear(Number(year))}
          inputStyle={{ width: 80 }}
        />
        <Button
          disabled={config.fmc_active_year === selectedYear || !selectedYear}
          size="sm"
          isLoading={isUpdating}
          onClick={updateActiveFmcYear}
        >
          Update
        </Button>
      </div>
    </div>
  );
}
