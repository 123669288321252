import { z } from 'zod';
import { defineEndpoint } from '@core/util/util.defineEndpoint';
import { PK } from '@core/types/types.pk';
import { ProdHeadCoreSchema } from '@core/schemas/db/schema.db.prod';
import { IsoDateSchema } from '../schema.util';

/**
 * RejectEndpoint
 */
export type RejectEndpointInput = z.infer<typeof RejectEndpoint.input>;
export type RejectEndpointResponse = z.infer<typeof RejectEndpoint.response>;
export const RejectEndpoint = defineEndpoint({
  path: '/reject',
  mutation: true,
  input: z.object({
    pk: ProdHeadCoreSchema.shape.PK.exclude([PK.ProdHeadFC]),
    productId: z.number().int(),
    revision: z.number().int(),
    note: z.string(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});

/**
 * ApproveEndpoint
 */
export type ApproveEndpointInput = z.infer<typeof ApproveEndpoint.input>;
export type ApproveEndpointResponse = z.infer<typeof ApproveEndpoint.response>;
export const ApproveEndpoint = defineEndpoint({
  path: '/approve',
  mutation: true,
  input: z.object({
    pk: ProdHeadCoreSchema.shape.PK,
    productId: z.number().int(),
    revision: z.number().int(),
    note: z.string(),
    domainUsername: z.string().optional(),
  }),
  response: z.object({
    ok: z.literal(true),
    newFinalCostRevision: z.number().optional(),
  }),
});

/**
 * HighestRevisionEndpoint
 */
export type HighestRevisionEndpointInput = z.infer<typeof HighestRevisionEndpoint.input>;
export type HighestRevisionEndpointResponse = z.infer<typeof HighestRevisionEndpoint.response>;
export const HighestRevisionEndpoint = defineEndpoint({
  path: '/highest-revision',
  input: z.object({
    pk: ProdHeadCoreSchema.shape.PK,
    productId: z.number().int(),
  }),
  response: z.object({
    highestRevision: z.number().int().or(z.null()),
  }),
});

/**
 * LockRevisionEndpoint
 */
export type LockRevisionEndpointInput = z.infer<typeof LockRevisionEndpoint.input>;
export type LockRevisionEndpointResponse = z.infer<typeof LockRevisionEndpoint.response>;
export const LockRevisionEndpoint = defineEndpoint({
  path: '/lock-revision',
  input: z.object({
    pk: ProdHeadCoreSchema.shape.PK,
    productId: z.number().int(),
    revision: z.number().int(),
  }),
  response: z.object({
    ok: z.boolean(),
    lockedBy: z.string().optional(),
    lockedTimestamp: IsoDateSchema.optional(),
  }),
});

/**
 * NotifyEndpoint
 */
export type NotifyEndpointInput = z.infer<typeof NotifyEndpoint.input>;
export type NotifyEndpointResponse = z.infer<typeof NotifyEndpoint.response>;
export const NotifyEndpoint = defineEndpoint({
  path: '/notify',
  mutation: true,
  input: z.object({
    type: z.literal('error'),
    message: z.string(),
    payload: z.record(z.unknown()).optional(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
