import { fiveDec, threeDec } from '@frontend/common/lib/functions';
import { TableColumn } from '@frontend/table/table';
import { ElementCostRow } from './types/elements.types';
import { ElementsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.elements';
import { Unpacked } from '@core/util/util.typing';
import { CostComponent } from '@core/types/types.costComponent';
import { TOTAL_KEY } from '@core/const/const.TOTAL_KEY';

export const getElementCostsColumns: (
  commaAsDecimalSeperator: boolean,
) => TableColumn<ElementCostRow>[] = (commaAsDecimalSeperator: boolean) => [
  {
    title: 'Material',
    dataIndex: 'material',
    sorted: 'ascending',
    align: 'end',
    style: (v) => ({ fontWeight: v === Number.MAX_SAFE_INTEGER ? 'bold' : undefined }),
    format: (v) => (v === Number.MAX_SAFE_INTEGER ? TOTAL_KEY : v),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: 'auto',
    style: () => ({ whiteSpace: 'nowrap' }),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'end',
    style: (v, r) => ({ fontWeight: r.material === Number.MAX_SAFE_INTEGER ? 'bold' : undefined }),
    format: (v) => threeDec(v, commaAsDecimalSeperator),
  },
  { title: 'UOM', dataIndex: 'uom' },
  {
    title: 'Total cost',
    dataIndex: 'total_cost',
    align: 'end',
    style: (v, r) => ({ fontWeight: r.material === Number.MAX_SAFE_INTEGER ? 'bold' : undefined }),
    format: (v) => fiveDec(v, commaAsDecimalSeperator),
  },
  {
    title: 'Cost source',
    dataIndex: 'selected_cost_source',
    format: (v) =>
      v === 'FMC'
        ? 'FMC'
        : v === 'GSIM'
        ? 'Simulated'
        : v === 'MEDIUM'
        ? 'Medium'
        : v === 'MAN'
        ? 'Manual'
        : v,
  },
  {
    title: 'Cost',
    dataIndex: 'selected_cost',
    format: (v, r) =>
      r.material === Number.MAX_SAFE_INTEGER || r.cost_comp === TOTAL_KEY
        ? ''
        : fiveDec(v, commaAsDecimalSeperator),
    align: 'end',
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
  },
];

export const elementErrorsColumns: TableColumn<
  Unpacked<ElementsGetEndpointResponse['elementErrors']> & { materialDescription: string }
>[] = [
  {
    title: 'Material',
    dataIndex: 'material',
    sorted: 'ascending',
    align: 'end',
  },
  {
    title: 'Type',
    dataIndex: 'cost_comp',
    format: (v: CostComponent) =>
      v === CostComponent.LEGOElements
        ? 'LEGO'
        : v === CostComponent.PurchasedElements
        ? 'Purchased'
        : v === CostComponent.OverdoseElements
        ? 'Overdose'
        : '',
  },
  {
    title: 'Material description',
    dataIndex: 'materialDescription',
  },
  {
    title: 'Error message',
    dataIndex: 'message',
    width: 'auto',
  },
];
