import { CalculatorCommonPPO, CostComponentFull } from '../calculator.types';
import { Calculator } from '../calculator';
import { calculate_CC_Packaging_sum } from './calculate.CC_Packaging.sum';
import {
  DBPackagingNonstandardCost,
  DBPackagingStandardCost,
} from '@core/schemas/db/schema.db.packaging';
import { round2relaxed } from '../calculator.util';

export function calculate_CC_Packaging_full(props: {
  packagingStandardCosts: Pick<DBPackagingStandardCost, 'fmc1_cost' | 'fmc2_cost'>[];
  packagingNonstandardCosts: Pick<
    DBPackagingNonstandardCost,
    'fmc1_cost_plus_scrap' | 'fmc2_cost_plus_scrap'
  >[];
  ppos: CalculatorCommonPPO;
  copyPpoToCost?: boolean;
}): CostComponentFull {
  const { ppos, copyPpoToCost, packagingStandardCosts, packagingNonstandardCosts } = props;

  if (copyPpoToCost) {
    return {
      fmc1cost: round2relaxed(ppos?.FMC1),
      fmc2cost: round2relaxed(ppos?.FMC2),
      fmc1ppo: round2relaxed(ppos?.FMC1),
      fmc2ppo: round2relaxed(ppos?.FMC2),
      fmc1variance: round2relaxed(ppos?.FMC1 === undefined ? undefined : 0),
      fmc2variance: round2relaxed(ppos?.FMC2 === undefined ? undefined : 0),
    };
  }

  const { fmc1cost, fmc2cost } = calculate_CC_Packaging_sum(
    packagingStandardCosts,
    packagingNonstandardCosts,
  );

  return {
    fmc1cost: round2relaxed(fmc1cost),
    fmc2cost: round2relaxed(fmc2cost),
    fmc1ppo: round2relaxed(ppos?.FMC1),
    fmc2ppo: round2relaxed(ppos?.FMC2),
    fmc1variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC1, fmc1cost)),
    fmc2variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC2, fmc2cost)),
  };
}
