import { z } from 'zod';
import { DBRecordSchema } from '@core/schemas/db/schema.db.common';
import { PK } from '@core/types/types.pk';
import { createDescription } from '@core/util/util.schemaPropDescription';

/**
 * DBHiddenColumn
 */
export const DBHiddenColumnSchema = DBRecordSchema.extend({
  PK: z.literal(PK.HiddenColumn),
  table_id: z.string().describe(createDescription({ isPartOfKey: true })),
  column_id: z.string().describe(createDescription({ isPartOfKey: true })),
});
export type DBHiddenColumn = z.infer<typeof DBHiddenColumnSchema>;
