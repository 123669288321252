import {
  Button,
  Text,
  SplitButton,
  Menu,
  useDisclosure,
  Textarea,
  IconButton,
} from '@lego/klik-ui';
import {
  SaveBold,
  CheckTickBold,
  NewspaperBold,
  ArrowDownBold,
  PlusBold,
  EditBold,
  ArrowLeftBold,
  RefreshBold,
  MessageBubble,
  MinusBold,
  ArrowRight,
} from '@lego/klik-ui/icons';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  ConfigContext,
  LoadingOverlayContext,
  ProductIndexContext,
  UserDataContext,
} from '@frontend/common/lib/contexts';
import { Message } from '@frontend/common/lib/models';
import { ApprovedRejectedTag } from '@frontend/common/components/ApprovedRejectedTag';
import { MessageStack } from '@frontend/common/components/MessageStack';
import { ProductIndexGuard } from '@frontend/common/components/ProductIndexGuard';
import {
  accessIsReadOnly,
  closeToast,
  isRevisionOutdated,
  isRevisionReadOnly,
  showErrorToast,
  showInfoToast,
  showInternalApplicationError,
  showSuccessToast,
  showWarningToast,
} from '@frontend/common/lib/functions';
import { GREEN_BUTTON_STYLES } from '@frontend/common/lib/common.styles';
import { AiFillCopy } from 'react-icons/ai';
import { prettifyTimestamp } from '../lib/common.util';
import { NCConfirmModal } from './NCConfirmModal';
import { ProdHeadCore } from '@core/schemas/db/schema.db.prod';
import { PK } from '@core/types/types.pk';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  ApproveEndpoint,
  HighestRevisionEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.common';
import { EndpointError } from '@core/types/types.endpoint';
import { TimestampsBar } from './TimestampsBar';
import { IconButtonIdentical } from './IconButtonIdentical';
import { getErrorMessage } from '@core/util/util.getErrorMessage';
import { RevisionError } from '@core/schemas/schema.common';
import { parseRevisionErrors } from '@core/util/util.parseRevisionErrors';
import { InlineMessage } from './InlineMessage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FInput } from '@frontend/form/components/FInput';
import { REGEX_PRODUCT_ID_OR_EMPTY } from '@core/types/types.regex';
import { revisionErrorToString } from '@core/util/util.revisionErrorToString';
import { AUTO_APPROVE_NOTE } from '@core/const/const.AUTO_APPROVE_NOTE';
import { useTryLockRevision } from '../lib/hooks/useTryLockRevision';
import { convertProductIdFrom8digits } from '@core/util/util.convertProductIdFrom8digits';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';
import { CircleSpinner } from './CircleSpinner';

interface GeneralPageProps<T> {
  title: string;
  pk?: ProdHeadCore['PK'];
  messages?: Message[];
  children?: React.ReactNode;
  prodHead?: Pick<
    ProdHeadCore,
    | 'PK'
    | 'product_id'
    | 'revision'
    | 'year'
    | 'rejected_by'
    | 'rejected_timestamp'
    | 'approved_by'
    | 'rejection_note'
    | 'approval_note'
    | 'approved_timestamp'
    | 'changed_by'
    | 'changed_timestamp'
    | 'created_by'
    | 'created_timestamp'
    | 'change_lock_by'
    | 'change_lock_timestamp'
    | 'errors'
  >;
  description?: string;
  maxRevision?: number;
  loading: boolean;
  onSave?: {
    preSaveCheck(): Promise<T & { revision: number }>;
    save(
      verifiedPayload: T,
    ): Promise<
      | [EndpointError, null, Response | null]
      | [
          null,
          { savedRevision?: number; ok?: true; revisionErrors?: RevisionError[] },
          Response | null,
        ]
    >;
    saveDisabled?: boolean;
  };
  onCreate?(productId: number): Promise<boolean>;
  preApproveCheck?(): Promise<void>;
  disableApprove?: boolean;
  onCalculate?(): Promise<unknown>;
  clearMessage?(id: string): void;
  refresh?(): Promise<void>;
  refreshNew?(): Promise<void>;
}

export function GeneralPage<T>(props: GeneralPageProps<T>) {
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isCalculateLoading, setIsCalculateLoading] = useState(false);
  const [approvalNote, setApprovalNote] = useState('');
  const [otherProductId, setOtherProductId] = useState('');
  const [isChangingProduct, setIsChangingProduct] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { clearProductIndex, setRevision, getProductIndex, setProductIndex } =
    useContext(ProductIndexContext);
  const { config } = useContext(ConfigContext);
  const { setIsLoading } = useContext(LoadingOverlayContext);
  const { getName, email, domainUsername, virtualGroups } = useContext(UserDataContext);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const rev = getProductIndex().revision;

  useEffect(() => {
    if (props.prodHead && !rev) {
      clearProductIndex();
    }
  }, [props.prodHead, props.loading, rev, clearProductIndex]);

  useEffect(() => {
    setIsLoading(isSaveLoading);
  }, [isSaveLoading, setIsLoading]);

  const readOnlyAccess = useMemo(
    () => !props.pk || accessIsReadOnly(virtualGroups, props.pk),
    [virtualGroups, props.pk],
  );

  const { refresh: propRefresh, prodHead, pk } = props;

  const handleApproveWithoutSave = useCallback(
    async (revisionToApprove: number) => {
      if (!prodHead || !pk) {
        showInternalApplicationError();
        return;
      }

      const [err, data] = await callEndpoint({
        endpoint: ApproveEndpoint,
        input: {
          pk: pk,
          productId: prodHead.product_id,
          revision: revisionToApprove,
          note: approvalNote,
          domainUsername,
        },
        errorHandling: { header: 'Approving revision' },
      });

      if (!data?.newFinalCostRevision && propRefresh) {
        propRefresh();
      }

      setApprovalNote('');
      onClose();

      if (err) {
        return;
      }

      showSuccessToast('Revision successfully approved');

      if (data.newFinalCostRevision) {
        setRevision(data.newFinalCostRevision);
      }
    },
    [approvalNote, onClose, propRefresh, prodHead, pk, setRevision, domainUsername],
  );

  useTryLockRevision({
    pk: props.prodHead?.PK,
    productId: props.prodHead?.product_id,
    revision: props.prodHead?.revision,
  });

  const outdatedRevision = useMemo(
    () => isRevisionOutdated({ prodHead: props.prodHead, year: config.fmc_active_year }),
    [config.fmc_active_year, props.prodHead],
  );

  const readOnly = useMemo(
    () => isRevisionReadOnly({ email, year: config.fmc_active_year, prodHead: props.prodHead }),
    [props.prodHead, config.fmc_active_year, email],
  );

  const handleApproveWithSave = useCallback(async () => {
    if (!props.onSave || !props.prodHead || !props.pk) {
      showInternalApplicationError();
      return;
    }

    closeToast('save-failed');

    let revisionToApprove = props.prodHead.revision;
    try {
      if (!readOnly) {
        const verifiedPayload = await props.onSave.preSaveCheck();

        const [saveError, data] = await props.onSave.save({
          ...verifiedPayload,
          revision: rev === 'new' ? 'NEW' : props.prodHead.revision,
        });
        if (saveError) {
          showErrorToast('Approving revision. Unable to save', saveError?.error, {
            requestId: saveError.requestId,
          });
          return;
        }
        if (!data.savedRevision) {
          showInternalApplicationError();
          return;
        }
        revisionToApprove = data.savedRevision;
        if (rev === 'new') {
          setRevision(data.savedRevision);
        }
      }

      await handleApproveWithoutSave(revisionToApprove);
    } catch (error) {
      showErrorToast('Unable to save', getErrorMessage(error), { id: 'save-failed' });
    }
  }, [
    props.prodHead,
    props.onSave,
    props.pk,
    handleApproveWithoutSave,
    readOnly,
    rev,
    setRevision,
  ]);

  async function handlePreApprove(withNote: boolean) {
    closeToast('save-failed');
    try {
      await props.onSave?.preSaveCheck();
      if (props.preApproveCheck) {
        await props.preApproveCheck();
      }
      if (withNote) {
        onOpen();
      } else {
        await handleApprove();
      }
    } catch (error) {
      showErrorToast('Unable to approve', getErrorMessage(error), { id: 'save-failed' });
    }
  }

  const handleApprove = useCallback(async () => {
    if (!props.prodHead) {
      showInternalApplicationError();
      return;
    }

    setIsApproveLoading(true);
    if (props.pk === PK.ProdHeadElem) {
      await handleApproveWithoutSave(props.prodHead?.revision);
    } else {
      await handleApproveWithSave();
    }
    setIsApproveLoading(false);
  }, [props.prodHead, handleApproveWithSave, handleApproveWithoutSave, props.pk]);

  const { onSave } = props;

  const handleSave = useCallback(
    async (options: { forceNewRevision: boolean; wasCopy?: boolean }) => {
      if (!onSave || onSave.saveDisabled) {
        showInternalApplicationError();
        return;
      }

      closeToast('save-failed');

      try {
        setIsSaveLoading(true);

        const verifiedPayload = await onSave.preSaveCheck();

        const [error, data] = await onSave.save({
          ...verifiedPayload,
          revision: options.forceNewRevision ? 'NEW' : verifiedPayload.revision,
        });

        if (error) {
          showErrorToast('Unable to save', error.error, {
            id: 'save-failed',
            requestId: error.requestId,
          });
          return;
        }

        if (options.forceNewRevision) {
          setRevision(data.savedRevision);
        } else if (propRefresh) {
          propRefresh();
        }

        const numberOfErrors = !data.revisionErrors ? 0 : data.revisionErrors.length;

        if (numberOfErrors > 0) {
          showWarningToast(
            `Revision successfully ${options.wasCopy ? 'copied' : 'saved'}`,
            `${options.wasCopy ? 'Copied' : 'Saved'} revision contained ${numberOfErrors} error${
              numberOfErrors === 1 ? '' : 's'
            }`,
          );
        } else {
          showSuccessToast(`Revision successfully ${options.wasCopy ? 'copied' : 'saved'}`);
        }
      } catch (error) {
        showErrorToast('Unable to save', getErrorMessage(error), { id: 'save-failed' });
      } finally {
        setIsSaveLoading(false);
      }
    },
    [onSave, propRefresh, setRevision],
  );

  async function handleCalculate() {
    if (!props.onCalculate) {
      showInternalApplicationError();
      return;
    }

    setIsCalculateLoading(true);
    await props.onCalculate();
    setIsCalculateLoading(false);
  }

  const messagesToShow: Message[] = useMemo(() => {
    let preMessagesToShow: Message[] = props.messages || [];

    // We don't wanna show read-only message for Elements since it is always read-only
    if (props.pk !== PK.ProdHeadElem) {
      if (
        props.prodHead?.approved_by &&
        !preMessagesToShow.find((m) => m.id === 'approved-revision')
      ) {
        preMessagesToShow = preMessagesToShow.concat({
          id: 'approved-revision',
          message: 'Revision is read-only',
          variant: 'info',
        });
      } else if (outdatedRevision && !preMessagesToShow.find((m) => m.id === 'outdated-revision')) {
        preMessagesToShow = preMessagesToShow.concat({
          id: 'outdated-revision',
          message: 'Revision is read-only',
          moreInformation: `This revision uses rates from ${props.prodHead?.year} but active FMC year is ${config.fmc_active_year}`,
          variant: 'info',
        });
      } else if (props.prodHead?.change_lock_by && props.prodHead?.change_lock_by !== email) {
        preMessagesToShow = preMessagesToShow.concat({
          id: 'locked-revision',
          message: `Revision is currently being revised by ${getName(
            props.prodHead.change_lock_by,
          )}`,
          variant: 'info',
        });
      }
    }

    if (
      props.prodHead?.rejected_by &&
      !preMessagesToShow.find((m) => m.id === 'rejected-revision')
    ) {
      preMessagesToShow = preMessagesToShow.concat({
        id: 'rejected-revision',
        message: `Revision was rejected by ${getName(
          props.prodHead.rejected_by,
        )} at ${prettifyTimestamp(props.prodHead.rejected_timestamp)}`,
        variant: 'warning',
        moreInformation: props.prodHead.rejection_note,
      });
    }

    const revisionErrors = parseRevisionErrors(props.prodHead?.errors);

    if (revisionErrors.length > 0) {
      preMessagesToShow = preMessagesToShow.concat({
        id: 'revision-errors',
        message: `Revision contained ${revisionErrors.length} error${
          revisionErrors.length === 1 ? '' : 's'
        } on latest save`,
        variant: 'warning',
        moreInformation: (
          <div style={{ display: 'grid', rowGap: 8 }}>
            {revisionErrors.map((revisionError, i) => (
              <InlineMessage
                key={i}
                variant="error"
                message={revisionErrorToString(revisionError)}
              />
            ))}
          </div>
        ),
      });
    }

    return preMessagesToShow.sort((a, b) => {
      if (a.variant === b.variant) {
        return 0;
      }

      return a.variant === 'info' ? -1 : 1;
    });
  }, [
    props.messages,
    outdatedRevision,
    props.prodHead?.year,
    config.fmc_active_year,
    props.prodHead?.approved_by,
    props.prodHead?.rejection_note,
    props.prodHead?.rejected_by,
    props.prodHead?.rejected_timestamp,
    props.prodHead?.errors,
    props.prodHead?.change_lock_by,
    props.pk,
    email,
    getName,
  ]);

  const approveDisabled = useMemo(
    () =>
      readOnly ||
      props.onSave?.saveDisabled ||
      parseRevisionErrors(props.prodHead?.errors).length > 0,
    [readOnly, props.prodHead, props.onSave?.saveDisabled],
  );

  useEffect(() => {
    const preventSave = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault(); // Prevent the browser's save dialog from showing up
      }
    };

    window.addEventListener('keydown', preventSave);

    return () => {
      window.removeEventListener('keydown', preventSave);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        if (!props.prodHead || !props.onSave || readOnly || props.onSave?.saveDisabled) {
          return;
        }

        if (!props.prodHead.revision || getProductIndex().revision === 'new') {
          handleSave({ forceNewRevision: getProductIndex().revision === 'new' });
        } else {
          handleSave({ forceNewRevision: false });
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    props.prodHead,
    getProductIndex,
    handleSave,
    props.onSave,
    readOnly,
    props.onSave?.saveDisabled,
  ]);

  async function changeProduct() {
    if (otherProductId === '' || !props.pk) {
      return;
    }

    setIsChangingProduct(true);
    const [err, data] = await callEndpoint({
      endpoint: HighestRevisionEndpoint,
      input: { pk: props.pk, productId: convertProductIdTo8digits(otherProductId) },
      errorHandling: { header: 'Changing product' },
    });
    setIsChangingProduct(false);

    if (err) {
      return;
    }

    if (!data.highestRevision) {
      showInfoToast('Changing product', `Product ${otherProductId} does not have a revision`);
      return;
    }

    setProductIndex(Number(otherProductId), data.highestRevision);
    setOtherProductId('');
  }

  return (
    <ProductIndexGuard title={props.title} pk={props.pk} onCreate={props.onCreate}>
      <div>
        <NCConfirmModal
          description={
            <div>
              <div style={{ marginBottom: 8 }}>Note:</div>
              <Textarea value={approvalNote} onChange={(e) => setApprovalNote(e.target.value)} />
            </div>
          }
          header={`Approve revision ${props.prodHead?.revision}`}
          isOpen={isOpen}
          variant="success"
          onCancel={() => {
            onClose();
            setApprovalNote('');
          }}
          onConfirm={handleApprove}
          confirmButtonProps={{
            loading: isApproveLoading,
            text: 'Approve',
          }}
        />
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              zIndex: 11,
            }}
          >
            <Text fontSize="2xl" fontWeight="bold">
              {`${props.title} - Product ${convertProductIdFrom8digits(
                getProductIndex().productId || 0,
              )}${
                props.prodHead?.revision ||
                (getProductIndex().revision && getProductIndex().revision !== 'new')
                  ? ` / Revision ${props.prodHead?.revision ?? getProductIndex().revision}`
                  : ''
              }${
                props.prodHead?.revision === props.maxRevision && !!props.prodHead?.revision
                  ? ' (latest)'
                  : ''
              }`}
            </Text>
            <div style={{ display: 'flex', columnGap: 4 }}>
              <FInput
                placeholder="Product ID..."
                editable
                align="start"
                value={otherProductId}
                disabled={isChangingProduct}
                onChange={setOtherProductId}
                disallowChangeOnInvalid
                regex={REGEX_PRODUCT_ID_OR_EMPTY}
                onEnter={changeProduct}
              />
              <Button
                size="sm"
                rightIcon={
                  isChangingProduct ? (
                    <CircleSpinner size={15} />
                  ) : (
                    <ArrowRight
                      style={{
                        fontSize: 16,
                      }}
                    />
                  )
                }
                variant="outline"
                className="icon-button"
                onClick={changeProduct}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 12,
              marginBottom: 8,
              zIndex: 11,
              position: 'relative',
              fontSize: 20,
              color: '#484848',
            }}
          >
            {props.description}
          </div>
          {props.prodHead && props.prodHead.created_by && (
            <TimestampsBar disableApprove={props.disableApprove} prodHead={props.prodHead} />
          )}
          {(!props.loading || props.prodHead) && (
            <div
              style={{
                marginTop: 16,
                marginBottom: 24,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', columnGap: 8 }}>
                <Button
                  size="sm"
                  variant="outline"
                  leftIcon={<ArrowLeftBold />}
                  colorScheme="error"
                  onClick={() => {
                    clearProductIndex();

                    const params = Object.fromEntries(searchParams);
                    const backRedirect = params['back-redirect'];
                    if (backRedirect) {
                      navigate(backRedirect);
                    }
                  }}
                >
                  Back
                </Button>
                {props.prodHead && props.onCalculate && !readOnlyAccess && (
                  <Button
                    size="sm"
                    variant="outline"
                    leftIcon={<NewspaperBold />}
                    isLoading={isCalculateLoading}
                    onClick={handleCalculate}
                    disabled={!props.onCalculate}
                  >
                    Calculate
                  </Button>
                )}
                {props.prodHead &&
                  !readOnlyAccess &&
                  props.onSave &&
                  (readOnly ? (
                    <Button
                      size="sm"
                      variant="outline"
                      leftIcon={<AiFillCopy />}
                      onClick={() => handleSave({ forceNewRevision: true, wasCopy: true })}
                      isLoading={isSaveLoading}
                      disabled={props.onSave?.saveDisabled}
                    >
                      Create copy
                    </Button>
                  ) : !props.prodHead.revision || getProductIndex().revision === 'new' ? (
                    <Button
                      size="sm"
                      variant="outline"
                      leftIcon={<SaveBold />}
                      onClick={() =>
                        handleSave({ forceNewRevision: getProductIndex().revision === 'new' })
                      }
                      isLoading={isSaveLoading}
                      disabled={props.onSave?.saveDisabled}
                    >
                      Save
                    </Button>
                  ) : (
                    <SplitButton className="fixbgroup">
                      <Button
                        size="sm"
                        variant="outline"
                        leftIcon={<SaveBold />}
                        onClick={() => handleSave({ forceNewRevision: false })}
                        isLoading={isSaveLoading}
                        disabled={props.onSave?.saveDisabled}
                      >
                        Save
                      </Button>
                      <Menu>
                        <Menu.Button
                          size="sm"
                          variant="outline"
                          as={IconButton}
                          isRound={false}
                          hasFixedIcon={true}
                          icon={<ArrowDownBold />}
                          disabled={props.onSave?.saveDisabled}
                        />
                        <Menu.List>
                          <Menu.Item
                            icon={<EditBold />}
                            onClick={() => handleSave({ forceNewRevision: false })}
                          >
                            Save in current revision
                          </Menu.Item>
                          <Menu.Item
                            icon={<PlusBold />}
                            onClick={() => handleSave({ forceNewRevision: true })}
                          >
                            Save in new revision
                          </Menu.Item>
                        </Menu.List>
                      </Menu>
                    </SplitButton>
                  ))}
                {props.prodHead &&
                  !props.disableApprove &&
                  !props.prodHead?.approved_by &&
                  !readOnlyAccess && (
                    <>
                      <SplitButton className="fixbgroup">
                        <Button
                          size="sm"
                          variant="outline"
                          leftIcon={<CheckTickBold />}
                          onClick={() => handlePreApprove(false)}
                          disabled={approveDisabled}
                          isLoading={isApproveLoading}
                          style={GREEN_BUTTON_STYLES}
                        >
                          Approve
                        </Button>
                        <Menu>
                          <Menu.Button
                            size="sm"
                            variant="outline"
                            as={IconButton}
                            isRound={false}
                            hasFixedIcon={true}
                            icon={<ArrowDownBold />}
                            disabled={approveDisabled}
                            style={GREEN_BUTTON_STYLES}
                          />
                          <Menu.List style={{ borderColor: GREEN_BUTTON_STYLES.borderColor }}>
                            <Menu.Item icon={<MinusBold />} onClick={() => handlePreApprove(false)}>
                              Approve without note
                            </Menu.Item>
                            <Menu.Item
                              icon={<MessageBubble />}
                              onClick={() => handlePreApprove(true)}
                            >
                              Approve with note
                            </Menu.Item>
                          </Menu.List>
                        </Menu>
                      </SplitButton>
                    </>
                  )}
                {props.refresh && (
                  <IconButtonIdentical
                    icon={<RefreshBold />}
                    onClick={() => {
                      if (getProductIndex().revision === 'new' && props.refreshNew) {
                        props.refreshNew();
                      } else if (props.refresh) {
                        props.refresh();
                      }
                    }}
                  />
                )}
              </div>
              {props.prodHead && !props.disableApprove && (
                <ApprovedRejectedTag
                  approved={
                    props.prodHead.approval_note === AUTO_APPROVE_NOTE
                      ? 'AUTO_APPROVED'
                      : !!props.prodHead?.approved_by
                  }
                  rejected={!!props.prodHead?.rejected_by}
                />
              )}
            </div>
          )}
          {messagesToShow.length > 0 && (
            <MessageStack
              messages={props.loading ? [] : messagesToShow}
              clearMessages={props.clearMessage}
              style={{ marginTop: 24 }}
            />
          )}
          {props.loading ? (
            props.prodHead && props.children
          ) : props.prodHead ? (
            props.children
          ) : (
            <div style={{ textAlign: 'center', fontSize: 18, marginTop: 60 }}>
              No data fetched...
            </div>
          )}
        </div>
      </div>
    </ProductIndexGuard>
  );
}
