import { z } from 'zod';
import { EndpointError, InternalEndpoint } from '@core/types/types.endpoint';
import { closeToast, getHeaders, showErrorToast } from '@frontend/common/lib/functions';
import { NotifyEndpoint } from '@core/schemas/endpoint/schema.endpoint.common';

export type ErrorHandlingParam = {
  header?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  customDescription?: 'An error occurred' | (string & {});
  disable?: boolean;
  handlers?: Partial<
    Record<200 | 201 | 204 | 400 | 401 | 403 | 404 | 500 | 502 | 504, (message: string) => void>
  >;
};

export async function callEndpoint<E extends InternalEndpoint = never>({
  endpoint,
  input,
  errorHandling,
}: {
  endpoint: E;
  input: z.infer<E['input']>;
  errorHandling?: ErrorHandlingParam;
}): Promise<[EndpointError, null, Response | null] | [null, z.infer<E['response']>, Response]> {
  try {
    const requestId = [endpoint.path, JSON.stringify(input)].join(';');

    closeToast(requestId);

    const r = await fetch('/api' + endpoint.path, {
      headers: await getHeaders(),
      method: 'POST',
      body: input ? JSON.stringify(input) : undefined,
    });

    const json = await r.json();

    if (json._payloadUrl) {
      const response = await fetch(json._payloadUrl);
      return [null, JSON.parse(await response.text()), r];
    }

    if (r.ok) {
      return [null, json, r];
    } else {
      if (!errorHandling?.disable) {
        const handler = errorHandling?.handlers?.[r.status as keyof typeof errorHandling.handlers];
        if (handler) {
          handler(json.error);
        } else {
          showErrorToast(
            errorHandling?.header || 'Request error',
            <div>
              {errorHandling?.customDescription ??
                ([500, 502, 504].includes(r.status) ? 'An error occurred.' : json.error)}
            </div>,
            { id: requestId, requestId: r.status >= 500 ? json.requestId : undefined },
          );

          if (r.status > 500) {
            callEndpoint({
              endpoint: NotifyEndpoint,
              input: {
                type: 'error',
                message: `Status ${r.status} from backend. Request ID: ${json.requestId || 'N/A'}`,
                payload: {
                  input,
                  endpoint: endpoint.path,
                  status: r.status,
                  statusText: r.statusText,
                  requestId: json.requestId || 'N/A',
                },
              },
              errorHandling: { disable: true },
            });
          }
        }
      }
      return [json, null, r];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return [{ error: e.message }, null, null];
  }
}
