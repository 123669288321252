import { Tabs } from '@lego/klik-ui';
import { useContext, useMemo } from 'react';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { Table } from '@frontend/table/table';
import { getElementCostsColumns } from '../elements.columns';
import { aggregateCosts } from '../logic/elements.aggregate.costs';
import { ElementCostRow } from '../types/elements.types';
import { ExportButton } from '@frontend/common/components/ExportButton';
import { ElementsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.elements';
import { CostComponent } from '@core/types/types.costComponent';
import { TOTAL_KEY } from '@core/const/const.TOTAL_KEY';

interface ElementCostsProps {
  elementCosts: ElementsGetEndpointResponse['elementCosts'];
  elementErrors: ElementsGetEndpointResponse['elementErrors'];
}

export function ElementCosts(props: ElementCostsProps) {
  const { userData } = useContext(UserDataContext);

  const legoElements = useMemo(() => {
    const elements: ElementCostRow[] =
      props.elementCosts.filter((elem) => elem.cost_comp === CostComponent.LEGOElements) || [];

    const totalRow: ElementCostRow = {
      material: Number.MAX_SAFE_INTEGER,
      description: CostComponent.LEGOElements,
      ...aggregateCosts(elements),
      cost_comp: TOTAL_KEY,
      uom: '',
      currency: '',
      selected_cost: 0,
      selected_cost_source: '',
      selected_cost_quantity: Number.MAX_SAFE_INTEGER,
    };

    return elements.concat(totalRow);
  }, [props.elementCosts]);

  const purchasedElements = useMemo(() => {
    const elements: ElementCostRow[] =
      props.elementCosts.filter((elem) => elem.cost_comp === CostComponent.PurchasedElements) || [];

    const totalRow: ElementCostRow = {
      material: Number.MAX_SAFE_INTEGER,
      description: CostComponent.PurchasedElements,
      ...aggregateCosts(elements),
      cost_comp: TOTAL_KEY,
      uom: '',
      currency: '',
      selected_cost: 0,
      selected_cost_source: '',
      selected_cost_quantity: Number.MAX_SAFE_INTEGER,
    };

    return elements.concat(totalRow);
  }, [props.elementCosts]);

  const overdoseElements = useMemo(() => {
    const elements: ElementCostRow[] =
      props.elementCosts.filter((elem) => elem.cost_comp === CostComponent.OverdoseElements) || [];

    const totalRow: ElementCostRow = {
      material: Number.MAX_SAFE_INTEGER,
      description: CostComponent.OverdoseElements,
      ...aggregateCosts(elements),
      cost_comp: TOTAL_KEY,
      uom: '',
      currency: '',
      selected_cost: 0,
      selected_cost_source: '',
      selected_cost_quantity: Number.MAX_SAFE_INTEGER,
    };

    return elements.concat(totalRow);
  }, [props.elementCosts]);

  const totalRow = useMemo(() => {
    const row: ElementCostRow = {
      material: Number.MAX_SAFE_INTEGER,
      description: 'All',
      ...aggregateCosts(props.elementCosts || []),
      cost_comp: TOTAL_KEY,
      uom: '',
      currency: '',
      selected_cost: 0,
      selected_cost_source: '',
      selected_cost_quantity: Number.MAX_SAFE_INTEGER,
    };
    return row;
  }, [props.elementCosts]);

  const columns = useMemo(
    () => getElementCostsColumns(!!userData?.comma_as_decimal_seperator),
    [userData?.comma_as_decimal_seperator],
  );

  const exportAllColumns = useMemo(() => {
    const columns = getElementCostsColumns(!!userData?.comma_as_decimal_seperator);
    return columns
      .slice(0, 5)
      .concat([{ title: 'Cost component', dataIndex: 'cost_comp' }])
      .concat(columns.slice(5));
  }, [userData?.comma_as_decimal_seperator]);

  return (
    <Tabs>
      <ExportButton
        rows={[...legoElements, ...purchasedElements, ...overdoseElements, totalRow]}
        style={{ marginBottom: 16 }}
        columns={exportAllColumns}
        mappers={[['material', (v) => (v === Number.MAX_SAFE_INTEGER ? TOTAL_KEY : v)]]}
      >
        Export all
      </ExportButton>
      <Tabs.TabList>
        <Tabs.Tab className="tab-header">LEGO elements</Tabs.Tab>
        <Tabs.Tab className="tab-header">Purchased elements</Tabs.Tab>
        <Tabs.Tab className="tab-header">Overdose elements</Tabs.Tab>
      </Tabs.TabList>
      <Tabs.TabPanels>
        <Tabs.TabPanel>
          <Table
            id="elements_costs_lego-elements"
            headerContent={
              <ExportButton
                rows={legoElements}
                columns={columns}
                mappers={[['material', (v) => (v === Number.MAX_SAFE_INTEGER ? TOTAL_KEY : v)]]}
              />
            }
            rows={legoElements}
            rowKey="material"
            columns={columns}
            noDataText="No LEGO elements..."
            removeInfoText
            removeSearch
            rowHasError={(elem) =>
              !!props.elementErrors.find((err) => err.material === elem.material)
            }
          />
        </Tabs.TabPanel>
        <Tabs.TabPanel>
          <Table
            id="elements_costs_purchased-elements"
            headerContent={
              <ExportButton
                rows={purchasedElements}
                columns={columns}
                mappers={[['material', (v) => (v === Number.MAX_SAFE_INTEGER ? TOTAL_KEY : v)]]}
              />
            }
            rows={purchasedElements}
            rowKey="material"
            columns={columns}
            noDataText="No purchased elements..."
            removeInfoText
            removeSearch
            rowHasError={(elem) =>
              !!props.elementErrors.find((err) => err.material === elem.material)
            }
          />
        </Tabs.TabPanel>
        <Tabs.TabPanel>
          <Table
            id="elements_costs_overdose-elements"
            headerContent={
              <ExportButton
                rows={overdoseElements}
                columns={columns}
                mappers={[['material', (v) => (v === Number.MAX_SAFE_INTEGER ? TOTAL_KEY : v)]]}
              />
            }
            rows={overdoseElements}
            rowKey="material"
            columns={columns}
            noDataText="No overdose elements..."
            removeInfoText
            removeSearch
            rowHasError={(elem) =>
              !!props.elementErrors.find((err) => err.material === elem.material)
            }
          />
        </Tabs.TabPanel>
      </Tabs.TabPanels>
    </Tabs>
  );
}
