import { FormControl, Select } from '@lego/klik-ui';
import { CSSProperties, useMemo } from 'react';
import { FSelectOption } from '../lib/form.types';
import { FLabel } from './FLabel';

interface FSelectProps {
  id?: string;
  label?: string;
  topLabel?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  isInvalid?: boolean;
  loading?: boolean;
  disabled?: boolean;
  editable?: boolean;
  size?: 'md';
  align?: 'start' | 'center' | 'end';
  readOnlyAlign?: 'start' | 'center' | 'end';
  options?: FSelectOption[];
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  autoFit?: boolean;
  inputRef?: React.RefObject<HTMLSelectElement>;
  onEnter?(): void;
  onChange?(value: string): void;
}

export function FSelect(props: FSelectProps) {
  const canInput = useMemo(
    () => props.editable && !props.disabled,
    [props.editable, props.disabled],
  );

  return (
    <div
      id={props.id}
      style={{
        ...(props.autoFit
          ? { display: 'grid', gridTemplateColumns: 'fit-content(1000px)', width: '100%' }
          : undefined),
        ...props.containerStyle,
      }}
      data-can-paste={canInput}
      data-disabled-options={JSON.stringify(
        (props.options || []).filter((o) => o.disabled).map((o) => o.value),
      )}
    >
      {props.topLabel && (
        <FLabel
          isTopLabel
          label={props.topLabel}
          required={props.required}
          style={props.labelStyle}
        />
      )}
      <FormControl
        isRequired={props.required}
        size={props.size || 'sm'}
        style={{
          height: props.inputStyle?.height,
          display: 'flex',
          alignItems: 'center',
          width: props.autoFit ? 'auto' : undefined,
          ...props.style,
        }}
        isReadOnly={!props.editable}
        className={
          'felement ' +
          (!props.editable ? 'fselect-disabled' : undefined) +
          (props.size === 'md' ? ' felement-revert-height' : '')
        }
      >
        {props.label && (
          <FLabel label={props.label} required={props.required} style={props.labelStyle} />
        )}
        {!props.editable ? (
          <div
            style={{
              width: '100%',
              textAlign: props.readOnlyAlign ?? (props.align || 'end'),
              whiteSpace: 'nowrap',
            }}
          >
            {props.options?.find((opt) => opt.value === props.value)?.text || props.value}
          </div>
        ) : (
          <Select
            name="."
            placeholder={props.placeholder}
            size={props.size || 'sm'}
            style={{ minWidth: 60, fontSize: 16, ...props.inputStyle }}
            value={props.loading ? 'Loading...' : props.value || ''}
            disabled={props.disabled}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && props.onEnter) {
                props.onEnter();
              }
            }}
            isInvalid={props.isInvalid}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange(v.target.value);
              }
            }}
            className="fselect"
          >
            {props.loading ? (
              <option value="Loading...">Loading...</option>
            ) : (
              <>
                {props.options?.map(({ text, value, disabled }) => (
                  <option key={value} value={value} disabled={disabled}>
                    {text}
                  </option>
                ))}
              </>
            )}
          </Select>
        )}
      </FormControl>
    </div>
  );
}
