import { DBPackingStandardFinalPackRate } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { PackSource } from '@core/types/types.packaging';

export const PackingStandardFinalPackRateExample: OmitDBRecord<DBPackingStandardFinalPackRate>[] = [
  {
    year: 1234,
    packing_key: 'A',
    packaging_facing: 213,
    packaging_size: 123,
    packing_region: 'EU',
    sales_region: 'EU',
    source: PackSource.Duplo,
    fmc_quantity: 1,
    bun: 'PC',
    cost: 1.23,
    currency: 'DKK',
  },
];
