import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Message } from '../models';
import { ProductIndexContext } from '../contexts';
import { showErrorToast } from '../functions';
import { WithOptional } from '@core/util/util.typing';

export type AddMessageFunction = (
  props: WithOptional<Message, 'variant'>,
  options?: { showErrorToast?: boolean },
) => void;
export type ClearMessagesFunction = (id?: string | RegExp) => void;
export type ClearAndAddMessagesFunction = (
  id: string | RegExp,
  messages: WithOptional<Message, 'variant'>[],
) => void;

export function useMessages(): {
  messages: Message[];
  addMessage: AddMessageFunction;
  clearMessages: ClearMessagesFunction;
  clearAndAddMessages: ClearAndAddMessagesFunction;
} {
  const { getProductIndex } = useContext(ProductIndexContext);

  const [messages, setMessages] = useState<Message[]>([]);

  const messagesRef = useRef<typeof messages>([]);

  useEffect(() => updateMessages([]), [getProductIndex]);

  const idCount = useRef(0);

  const addMessage = useCallback(
    (
      { message, id, moreInformation, variant }: WithOptional<Message, 'variant'>,
      options?: { showErrorToast?: boolean },
    ) => {
      if (!id) {
        idCount.current++;
      }

      const idToBeUsed = id || `${idCount.current}`;

      if (messagesRef.current.find((m) => m.id === idToBeUsed)) {
        return;
      }

      if (options?.showErrorToast) {
        showErrorToast(message, moreInformation);
      }

      const newMessages = messagesRef.current
        .filter((m) => m.id !== idToBeUsed)
        .concat({
          id: idToBeUsed,
          variant: variant || 'error',
          message,
          moreInformation,
        });

      if (JSON.stringify(newMessages) === JSON.stringify(messagesRef.current)) {
        return;
      }
      updateMessages(newMessages);
    },
    [],
  );

  const clearMessages = useCallback((id?: string | RegExp) => {
    if (id === undefined) {
      updateMessages([]);
      return;
    }

    const newMessages = messagesRef.current.filter((msg) =>
      typeof id === 'string' ? msg.id !== id : !id.test(msg.id),
    );

    updateMessages(newMessages);
  }, []);

  const clearAndAddMessages = useCallback(
    (id: string | RegExp, messages: WithOptional<Message, 'variant'>[]) => {
      let newMessages = messagesRef.current.filter((msg) =>
        typeof id === 'string' ? msg.id !== id : !id.test(msg.id),
      );

      messages.forEach(({ id, message, moreInformation, variant }) => {
        if (!id) {
          idCount.current++;
        }

        const idToBeUsed = id || `${idCount.current}`;
        newMessages = newMessages
          .filter((m) => m.id !== idToBeUsed)
          .concat({
            id: idToBeUsed,
            variant: variant || 'error',
            message,
            moreInformation,
          });
      });

      updateMessages(newMessages);
    },
    [],
  );

  function updateMessages(newMessages: WithOptional<Message, 'variant'>[]) {
    const newMsgs = newMessages.map((m) => ({ ...m, variant: m.variant || 'error' }));
    setMessages(newMsgs);
    messagesRef.current = newMsgs;
  }

  return {
    messages,
    addMessage,
    clearMessages,
    clearAndAddMessages,
  };
}
