import { NCConfirmModal } from '@frontend/common/components/NCConfirmModal';
import { showInternalApplicationError } from '@frontend/common/lib/functions';
import { Button } from '@lego/klik-ui';
import { FaFileImport } from 'react-icons/fa';
import { createRef, useContext, useEffect, useState } from 'react';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { HighestRevisionEndpoint } from '@core/schemas/endpoint/schema.endpoint.common';
import { PK } from '@core/types/types.pk';
import { FInput } from '@frontend/form/components/FInput';
import { ProductIndexContext } from '@frontend/common/lib/contexts';
import { REGEX_PRODUCT_ID_OR_EMPTY } from '@core/types/types.regex';
import { FSelect } from '@frontend/form/components/FSelect';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';
import { convertProductIdFrom8digits } from '@core/util/util.convertProductIdFrom8digits';

interface ImportFromRevisionButtonProps {
  productId: number;
  fetchRows(productId: number, revision: number): Promise<void>;
}

export function ImportFromRevisionButton(props: ImportFromRevisionButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isFetchingRevisionCount, setIsFetchingRevisionCount] = useState(false);
  const [revisions, setRevisions] = useState<number>();
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const [selectedRevision, setSelectedRevision] = useState<number>();
  const [productId, setProductId] = useState<number>();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setSelectedProductId(props.productId);
    setProductId(convertProductIdFrom8digits(props.productId));
  }, [props.productId]);

  const { suggestions } = useContext(ProductIndexContext);

  useEffect(() => {
    if (selectedProductId) {
      updateRevisionsCount(selectedProductId);
    }
  }, [selectedProductId]);

  async function updateRevisionsCount(productId: number) {
    setSelectedRevision(undefined);
    setIsFetchingRevisionCount(true);
    const [err, result] = await callEndpoint({
      endpoint: HighestRevisionEndpoint,
      input: { pk: PK.ProdHeadPack, productId: convertProductIdTo8digits(productId) },
      errorHandling: { header: 'Fetching revision count' },
    });
    setIsFetchingRevisionCount(false);

    if (err) {
      return;
    }

    if (!result.highestRevision) {
      setNotFound(true);
    } else {
      setRevisions(result.highestRevision);
    }
  }

  const divRef = createRef<HTMLDivElement>();

  return (
    <div>
      <Button
        size="sm"
        onClick={() => setIsOpen(true)}
        variant="outline"
        leftIcon={<FaFileImport />}
      >
        Import rows
      </Button>
      <NCConfirmModal
        isOpen={isOpen}
        header="Import rows"
        onCancel={() => {
          setIsOpen(false);
          setNotFound(false);
        }}
        confirmButtonProps={{ disabled: !selectedProductId || !selectedRevision, text: 'Import' }}
        variant="info"
        onConfirm={async () => {
          if (!selectedProductId || !selectedRevision) {
            showInternalApplicationError();
            return;
          }
          await props.fetchRows(selectedProductId, selectedRevision);
          setIsOpen(false);
        }}
        description={
          <div>
            <FInput
              suggestions={suggestions}
              topLabel="Product ID"
              editable
              align="start"
              value={productId}
              regex={REGEX_PRODUCT_ID_OR_EMPTY}
              isInvalid={notFound}
              onChange={(v) => {
                setProductId(!v ? undefined : Number(v));
                setNotFound(false);
                setRevisions(undefined);
              }}
              onSubmit={() => setSelectedProductId(productId)}
              onEnter={() => {
                divRef.current?.focus();
              }}
              disallowChangeOnInvalid
            />
            {notFound && (
              <div style={{ marginTop: 6, fontSize: 14, color: '#767674' }}>
                Product has no pack revisions
              </div>
            )}
            <FSelect
              containerStyle={{ marginTop: 12 }}
              topLabel="Revision"
              editable
              disabled={!revisions}
              value={`${selectedRevision}`}
              onChange={(v) => setSelectedRevision(Number(v))}
              placeholder={isFetchingRevisionCount ? 'Loading...' : 'Select revision'}
              options={Array.from(new Array(revisions))
                .map((_, i) => i + 1)
                .map((i) => ({ text: i, value: `${i}` }))}
            />
          </div>
        }
      />
    </div>
  );
}

/**
 * 
 * <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
      <Dropdown
        size="sm"
        placeholder={'Import rows'}
        listWidth="min-content"
        isOpen={isOpen}
        onOpen={() => setIsOpen((v) => !v)}
        closeOnSelect={false}
        closeOnBlur
        onClose={() => setIsOpen(false)}
        selectionIndicator="none"
      >
        {Array.from(new Array(props.revisions))
          .map((_, i) => i + 1)
          .reverse()
          .map((i) => (
            <Dropdown.Option
              style={{ whiteSpace: 'nowrap' }}
              key={i}
              onClick={async () => {
                setFetchingRevision(i);
                await props.fetchRows(i);
                setFetchingRevision(undefined);
                setIsOpen(false);
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', columnGap: 12, marginRight: 8 }}>
                <div style={{ width: 14, display: 'flex', alignItems: 'center', marginTop: -1 }}>
                  {i === fetchingRevision ? <CircleSpinner size={14} /> : <FilesDocument />}
                </div>
                <div>Revision {i}</div>
              </div>
            </Dropdown.Option>
          ))}
      </Dropdown>
    </div>
 */
