import {
  REGEX_POSITIVE_INTEGER_OR_EMPTY,
  get_REGEX_NONNEGATIVE_INTEGER_OR_1TO5DECIMAL_OR_EMPTY,
} from '@core/types/types.regex';
import { SchemaProperty } from '@core/types/types.schema';
import { useCallback, useContext } from 'react';
import { UserDataContext } from '@frontend/common/lib/contexts';
import {
  FilterExclude,
  FilterInclude,
} from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';
import { SelectionsContent } from './SelectionsContent';

interface FilterSettingsIncludeExcludeProps {
  property: SchemaProperty & { filter: FilterInclude | FilterExclude };
  onChange(newFilterValue: FilterInclude['value'] | FilterExclude['value']): void;
}

export function FilterSettingsIncludeExclude(props: FilterSettingsIncludeExcludeProps) {
  const { property, onChange } = props;

  const { userData } = useContext(UserDataContext);

  const addToList = useCallback(
    (input: string | string[]) => {
      const values = Array.isArray(input) ? input : [input];

      const filteredValues = values.filter((v) => !property.filter.value.includes(v));

      if (filteredValues.length === 0) {
        return;
      }

      onChange(property.filter.value.concat(...filteredValues));
    },
    [property.filter.value, onChange],
  );

  const removeFromList = useCallback(
    (element: string) => onChange(property.filter.value.filter((e: string) => e !== element)),
    [property.filter.value, onChange],
  );

  return (
    <SelectionsContent
      addCurrentValue={addToList}
      values={property.filter.value}
      onRemove={removeFromList}
      regex={
        property.inputType !== 'ZodNumber'
          ? undefined
          : property.int
          ? REGEX_POSITIVE_INTEGER_OR_EMPTY
          : get_REGEX_NONNEGATIVE_INTEGER_OR_1TO5DECIMAL_OR_EMPTY(
              !!userData?.comma_as_decimal_seperator,
            )
      }
    />
  );
}
