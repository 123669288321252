import { IoMdInformationCircleOutline } from 'react-icons/io';

interface InfoIconProps {
  noRightMargin?: boolean;
}

export function InfoIcon(props: InfoIconProps) {
  return (
    <IoMdInformationCircleOutline
      style={{
        marginRight: props.noRightMargin ? undefined : 6,
        fontSize: 22,
      }}
      className="clickable-icon"
    />
  );
}
