import { z } from 'zod';
import {
  DBElemCostSchema,
  DBElemErrorSchema,
  DBProdHeadElemSchema,
} from '@core/schemas/db/schema.db.elements';
import { defineEndpoint } from '@core/util/util.defineEndpoint';
import { CostComponent } from '@core/types/types.costComponent';

// ElementsGetEndpoint
export const ElementsGetEndpoint = defineEndpoint({
  path: '/elements/get',
  input: z.object({
    productId: z.number().int(),
    rev: z.number().int().optional(),
  }),
  response: z.object({
    prodHead: DBProdHeadElemSchema,
    description: z.string(),
    maxRevision: z.number().int(),
    elementCosts: z.array(
      DBElemCostSchema.merge(
        z.object({
          description: z.string().optional(),
        }),
      ),
    ),
    elementErrors: z.array(DBElemErrorSchema),
    productCosts: z.object({
      ppo: z.object({
        [CostComponent.LEGOElements]: z.object({
          FMC1: z.number().optional(),
          FMC2: z.number().optional(),
        }),
        [CostComponent.PurchasedElements]: z.object({
          FMC1: z.number().optional(),
          FMC2: z.number().optional(),
        }),
        [CostComponent.OverdoseElements]: z.object({
          FMC1: z.number().optional(),
          FMC2: z.number().optional(),
        }),
      }),
    }),
  }),
});
export type ElementsGetEndpointInput = z.infer<typeof ElementsGetEndpoint.input>;
export type ElementsGetEndpointResponse = z.infer<typeof ElementsGetEndpoint.response>;

// ElementsCalculateEndpoint
export const ElementsCalculateEndpoint = defineEndpoint({
  path: '/elements/calculate',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
  }),
  response: z.object({
    revisionNumber: z.number().int().optional(),
    noOpReason: z.string().optional(),
  }),
});
export type ElementsCalculateEndpointInput = z.infer<typeof ElementsCalculateEndpoint.input>;
export type ElementsCalculateEndpointResponse = z.infer<typeof ElementsCalculateEndpoint.response>;
