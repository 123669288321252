import { FinalCostsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.finalCosts';
import { PackagingSizesListEndpoint } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { NCAccordion } from '@frontend/common/components/NCAccordion';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import { PackagingPackagingCosts } from '@frontend/packaging/components/PackagingPackagingCosts';
import { PackagingPackingCosts } from '@frontend/packaging/components/PackagingPackingCosts';
import { PackagingSourceAndRegions } from '@frontend/packaging/components/PackagingSourceAndRegions';
import { usePackingStandardCostsRows } from '@frontend/packaging/lib/usePackingStandardCostsRows';
import { usePackagingAddOns } from '@frontend/packaging/lib/usePackagingAddOns';
import { usePackagingCostsRows } from '@frontend/packaging/lib/usePackagingCostsRows';
import { usePackingNonstandardCostsRows } from '@frontend/packaging/lib/usePackingNonstandardCostsRows';
import { useMemo } from 'react';

interface FinalCostsPackagingTabProps {
  packagingData: Exclude<FinalCostsGetEndpointResponse['packagingData'], null>;
  fmcYear: number | undefined;
  note: string;
}

export function FinalCostsPackagingTab(props: FinalCostsPackagingTabProps) {
  const { data: packagingSizes, loading: packagingSizesLoading } = useEndpoint({
    endpoint: PackagingSizesListEndpoint,
    input: {},
    errorHandling: { header: 'Fetching packaging sizes' },
  });

  const selectedPackagingSize = useMemo(
    () =>
      (packagingSizes || []).find(
        (ps) => ps.packaging_size === props.packagingData.prodHead.packaging_size,
      ),
    [props.packagingData.prodHead.packaging_size, packagingSizes],
  );

  const {
    packingStandardCostsRows,
    totals: packingStandardCostsTotals,
    messages: localPackingStandardCostsMessages,
  } = usePackingStandardCostsRows({
    year: props.fmcYear,
    packingStandardPrepackRates: props.packagingData.packingStandardPrepackRates,
    packingStandardCosts: props.packagingData.packingStandardCosts,
    selectedPackaging: selectedPackagingSize,
    selectedSource: props.packagingData.prodHead.source,
    packingStandardFinalPackRates: props.packagingData.packingStandardFinalPackRates,
    packingRegionEU: props.packagingData.prodHead.packing_region_eu,
    packingRegionUS: props.packagingData.prodHead.packing_region_us,
    salesRegionEU: props.packagingData.prodHead.sales_region_eu,
    salesRegionUS: props.packagingData.prodHead.sales_region_us,
    readOnly: true,
  });

  const {
    rows: packingNonstandardCostsRows,
    totals: packingNonstandardCostsTotals,
    messages: localPackingNonstandardCostsMessages,
    packingNonstandardKeys,
  } = usePackingNonstandardCostsRows({
    fmcYear: props.fmcYear,
    payloadPackingNonstandardCosts: props.packagingData.packingNonstandardCosts,
    packingNonstandardRates: props.packagingData.packingNonstandardRates,
    selectedSource: props.packagingData.prodHead.source,
    readOnly: true,
    packingNonstandardKeys: props.packagingData.packingNonstandardKeys,
  });

  const { addOns } = usePackagingAddOns(props.packagingData.prodHead);

  const {
    isStandard,
    nonstandardTotals: packagingNonstandardCostsTotals,
    standardTotals: packagingStandardCostsTotals,
    packagingStandardCostsRows,
    packagingNonstandardCostsRows,

    materials: packagingCostsMaterials,
    messages: localPackagingCostsMessages,
  } = usePackagingCostsRows({
    fmcYear: props.fmcYear,
    modelBagChecked: !!props.packagingData.prodHead.model_bag,
    packagingData: props.packagingData,
    selectedPackagingSize: props.packagingData.prodHead.packaging_size,
    packingRegionEU: props.packagingData.prodHead.packing_region_eu,
    packingRegionUS: props.packagingData.prodHead.packing_region_us,
    salesRegionEU: props.packagingData.prodHead.sales_region_eu,
    salesRegionUS: props.packagingData.prodHead.sales_region_us,
    packingStandardCostsRows,
    selectedAddOns: addOns,
    selectedSource: props.packagingData.prodHead.source,
    readOnly: true,
    productId: props.packagingData.prodHead.product_id,
    revision: props.packagingData.prodHead.revision,
  });

  return (
    <div>
      <NCAccordion
        sections={[
          {
            title: 'Source and regions',
            content: (
              <PackagingSourceAndRegions
                readOnly
                packingRegionEU={props.packagingData.prodHead.packing_region_eu}
                packingRegionUS={props.packagingData.prodHead.packing_region_us}
                salesRegionEU={props.packagingData.prodHead.sales_region_eu}
                salesRegionUS={props.packagingData.prodHead.sales_region_us}
                selectedSource={props.packagingData.prodHead.source}
                sourceOptions={props.packagingData.packagingSourceOptions}
              />
            ),
          },
          {
            title: 'Packing costs',
            content: (
              <PackagingPackingCosts
                approved
                ppoPackagingSize={props.packagingData.ppoPackagingSize}
                readOnly
                note={props.note}
                modelBagChecked={!!props.packagingData.prodHead.model_bag}
                prodHead={props.packagingData.prodHead}
                packagingSizesLoading={packagingSizesLoading}
                selectedPackaging={selectedPackagingSize}
                packagingSizes={packagingSizes || []}
                boxFillingDegree={props.packagingData.prodHead.box_filling_degree_pct}
                packingStandardCostsRows={packingStandardCostsRows}
                packingStandardCostsTotals={packingStandardCostsTotals}
                packingNonstandardCostsRows={packingNonstandardCostsRows}
                packingNonstandardCostsTotals={packingNonstandardCostsTotals}
                packingStandardCostsMessages={localPackingStandardCostsMessages}
                packingNonstandardCostsMessages={localPackingNonstandardCostsMessages}
                hideCostTable={false}
                packingNonstandardKeys={packingNonstandardKeys}
              />
            ),
          },
          {
            title: 'Packaging costs',
            content: (
              <PackagingPackagingCosts
                readOnly
                addOns={addOns}
                selectedPackagingSize={selectedPackagingSize}
                packagingStandardCostsRows={packagingStandardCostsRows}
                packagingNonstandardCostsRows={packagingNonstandardCostsRows}
                packagingStandardCostsTotal={packagingStandardCostsTotals}
                packagingNonstandardCostsTotal={packagingNonstandardCostsTotals}
                isStandard={isStandard}
                packagingNonstandardCostsMaterials={packagingCostsMaterials}
                hideCostTable={false}
                packagingCostsMessages={localPackagingCostsMessages}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
