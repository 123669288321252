import { Button } from '@lego/klik-ui';
import { useState } from 'react';

interface QueryPanelContainerProps {
  children: React.ReactNode;
  disabled: boolean;
  onOk(): Promise<void>;
}

export function QueryPanelContainer(props: QueryPanelContainerProps) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 300,
            rowGap: 24,
            columnGap: 16,
            alignItems: 'center',
          }}
        >
          {props.children}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 48 }}>
          <Button
            size="md"
            disabled={props.disabled}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await props.onOk();
              setIsLoading(false);
            }}
          >
            Download report
          </Button>
        </div>
      </div>
    </div>
  );
}
