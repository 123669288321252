import { useCallback, useEffect, useRef } from 'react';

// This hook will jump to the page where the selected item is on mount. However, sorting of the rows after mount
// screws up the pagination so we have to trick the system to jump to the page once the rows state has settled

export function useJumpToPage<T extends object>(
  payload: {
    paginateTo: string | undefined;
    itemsPerPage: number | undefined;
    getRowKey: (row: T) => string;
    sortedFilteredRows: T[];
  },
  setCurrentPage: (page: number) => void,
) {
  const hasPaginatedToItem = useRef(false);
  const jumpToPayload = useRef(payload);

  jumpToPayload.current = payload;

  const jumpToPage = useCallback(() => {
    if (
      jumpToPayload.current.paginateTo &&
      jumpToPayload.current.itemsPerPage &&
      !hasPaginatedToItem.current
    ) {
      const index = jumpToPayload.current.sortedFilteredRows.findIndex(
        (r) => jumpToPayload.current.getRowKey(r) === jumpToPayload.current.paginateTo,
      );
      if (index !== -1) {
        const targetPage = Math.floor(index / jumpToPayload.current.itemsPerPage) + 1;
        setCurrentPage(targetPage);
      }
    }
    hasPaginatedToItem.current = true;
  }, [setCurrentPage]);

  useEffect(() => {
    setTimeout(jumpToPage, 0);
  }, [jumpToPage]);
}
