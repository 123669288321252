import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { Region, RegionEnum } from '@core/schemas/schema.common';
import { PackSource } from '@core/types/types.packaging';
import { sortPackSource } from '@core/util/util.sortPackSource';
import { showInternalApplicationError } from '@frontend/common/lib/functions';
import { FSelect } from '@frontend/form/components/FSelect';

interface PackagingSourceAndRegionsProps {
  readOnly: boolean;
  sourceOptions: PackagingGetEndpointResponse['packagingSourceOptions'];
  selectedSource: PackSource | undefined;
  packingRegionEU: Region | undefined;
  packingRegionUS: Region | undefined;
  salesRegionEU: Region;
  salesRegionUS: Region;
  setPackingRegionEU?(region: Region | ''): void;
  setPackingRegionUS?(region: Region | ''): void;
  onSelectedSourceChange?(source: PackSource): void;
}

const REGION_OPTIONS = [
  { value: RegionEnum.Enum.EU, text: 'EU' },
  { value: RegionEnum.Enum.US, text: 'US' },
];

export function PackagingSourceAndRegions(props: PackagingSourceAndRegionsProps) {
  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 24 }}>
      <FSelect
        label="Source"
        value={props.selectedSource || ''}
        placeholder="Select source"
        onChange={(opt) => {
          if (!props.onSelectedSourceChange) {
            showInternalApplicationError();
            return;
          }
          props.onSelectedSourceChange(opt as PackSource);
        }}
        options={props.sourceOptions
          .map((opt) => ({
            value: opt.source,
            text: opt.description,
          }))
          .sort((a, b) => sortPackSource(a.value, b.value))}
        align="start"
        editable={!props.readOnly}
        autoFit
      />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'min-content fit-content(400px) 128px',
          columnGap: 24,
          rowGap: 12,
          alignItems: 'center',
        }}
      >
        <div />
        <div style={{ fontWeight: 'bold', marginBottom: -8 }}>Packing</div>
        <div style={{ fontWeight: 'bold', marginBottom: -8 }}>Sales</div>
        <div style={{ fontWeight: 'bold' }}>EU</div>
        <FSelect
          options={REGION_OPTIONS}
          value={props.packingRegionEU || ''}
          placeholder="Select region"
          editable={!props.readOnly}
          align="start"
          onChange={(v) => {
            if (!props.setPackingRegionEU) {
              showInternalApplicationError();
              return;
            }
            props.setPackingRegionEU(v as Region | '');
          }}
          autoFit
        />
        <FSelect options={REGION_OPTIONS} value={props.salesRegionEU} align="start" />
        <div style={{ fontWeight: 'bold' }}>US</div>
        <FSelect
          options={REGION_OPTIONS}
          value={props.packingRegionUS || ''}
          placeholder="Select region"
          editable={!props.readOnly}
          align="start"
          onChange={(v) => {
            if (!props.setPackingRegionUS) {
              showInternalApplicationError();
              return;
            }
            props.setPackingRegionUS(v as Region | '');
          }}
          autoFit
        />
        <FSelect options={REGION_OPTIONS} value={props.salesRegionUS} align="start" />
      </div>
    </div>
  );
}
