import { PackingKey, PackingKeyId } from '@core/types/types.packaging';

export type PackingType = 'FINAL_PACK' | 'PREPACK';
export const PACKING_KEY_ID_TO_TYPE: Record<PackingKeyId, PackingType> = {
  [PackingKeyId.FinalPack]: 'FINAL_PACK',
  [PackingKeyId.SpecialSingle]: 'PREPACK',
  [PackingKeyId.Singlebag]: 'PREPACK',
  [PackingKeyId.QSinglebag]: 'PREPACK',
  [PackingKeyId.Doublebag]: 'PREPACK',
  [PackingKeyId.QDoublebag]: 'PREPACK',
  [PackingKeyId.F99]: 'PREPACK',
  [PackingKeyId.Joker]: 'PREPACK',
  [PackingKeyId.GlobalBIPrepack]: 'PREPACK',
};

// Order in this array is reflected in frontend, so it is important
export const PACKING_KEYS: PackingKey[] = [
  { packingKey: PackingKeyId.FinalPack, description: 'Final Pack' },
  { packingKey: PackingKeyId.SpecialSingle, description: 'Special (Single)' },
  { packingKey: PackingKeyId.Singlebag, description: 'Singlebag' },
  { packingKey: PackingKeyId.QSinglebag, description: 'Q-Singlebag' },
  { packingKey: PackingKeyId.Doublebag, description: 'Doublebag' },
  { packingKey: PackingKeyId.QDoublebag, description: 'Q-Doublebag' },
  { packingKey: PackingKeyId.F99, description: 'F99' },
  { packingKey: PackingKeyId.Joker, description: 'Joker' },
  { packingKey: PackingKeyId.GlobalBIPrepack, description: 'Global BI Prepack' },
];
