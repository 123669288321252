import { DBElemCost } from '@core/schemas/db/schema.db.elements';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { now } from '@core/util/util.timestamp';
import { CostComponent } from '@core/types/types.costComponent';
import { ElementRateSource } from '@core/types/types.elements';

export const ElemCostExample: OmitDBRecord<DBElemCost>[] = [
  {
    product_id: 1234,
    revision: 1,
    cost_comp: CostComponent.LEGOElements,
    material: 123434,
    quantity: 1,
    total_cost: 1.12345,
    uom: 'PC',
    selected_cost: 1.12345,
    selected_cost_source: ElementRateSource.FMC,
    selected_cost_quantity: 1,
    price_unit: 1,
    valid_from_timestamp: now(),
    currency: 'DKK',
  },
];
