import { Calculator } from '../calculator';
import { CalculatorCommonPPO, CostComponentCost, CostComponentFull } from '../calculator.types';
import { round2relaxed } from '../calculator.util';
import { calculate_CC_sum } from './calculate.cc.sum';

export function calculate_CC_full(
  costComponentCosts: CostComponentCost[],
  ppos: CalculatorCommonPPO[],
): CostComponentFull {
  const { fmc1cost, fmc2cost } = calculate_CC_sum(costComponentCosts);

  const fmc1_ppo_sum = ppos.reduce<number | undefined>(
    (sum, curr) => Calculator.Common.Sum(sum, curr?.FMC1),
    undefined,
  );
  const fmc2_ppo_sum = ppos.reduce<number | undefined>(
    (sum, curr) => Calculator.Common.Sum(sum, curr?.FMC2),
    undefined,
  );

  return {
    fmc1ppo: round2relaxed(fmc1_ppo_sum),
    fmc2ppo: round2relaxed(fmc2_ppo_sum),
    fmc1cost: round2relaxed(fmc1cost),
    fmc2cost: round2relaxed(fmc2cost),
    fmc1variance: round2relaxed(Calculator.Common.Variance(fmc1_ppo_sum, fmc1cost)),
    fmc2variance: round2relaxed(Calculator.Common.Variance(fmc2_ppo_sum, fmc2cost)),
  };
}
