import { DBElementsScrapRate } from '@core/schemas/db/schema.db.common';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';

export const ElementsScrapRateExample: OmitDBRecord<DBElementsScrapRate>[] = [
  {
    year: 1234,
    type: 1,
    description: 'a',
    scrap_rate_percent: 10,
    disabled: false,
  },
];
