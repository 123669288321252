import { Table } from '@frontend/table/table';
import { packagingSizesColumns } from '../lib/packaging.columns';
import { PackagingSizesListEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { Unpacked } from '@core/util/util.typing';

interface PackagingSizesContentProps {
  packagingSizes: PackagingSizesListEndpointResponse;
  loading: boolean;
  selectedPackagingSize: Unpacked<PackagingSizesListEndpointResponse | undefined>;
  onPackagingSizeSelect(packSize: Unpacked<PackagingSizesListEndpointResponse>): void;
}

export function PackagingSizesContent(props: PackagingSizesContentProps) {
  return (
    <div style={{ width: 1000 }}>
      <Table
        id="packaging_sizes"
        rows={props.packagingSizes}
        rowKey="packaging_size"
        paginateTo={props.selectedPackagingSize?.packaging_size.toString()}
        columns={packagingSizesColumns}
        isRefreshing={props.loading}
        onRowClick={props.onPackagingSizeSelect}
        selectedRow={props.selectedPackagingSize}
        itemsPerPage={15}
        fixedHeight={460}
      />
    </div>
  );
}
