import { RejectEndpoint, RejectEndpointInput } from '@core/schemas/endpoint/schema.endpoint.common';
import { FinalCostsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.finalCosts';
import { PK } from '@core/types/types.pk';
import { convertProductIdFrom8digits } from '@core/util/util.convertProductIdFrom8digits';
import { NCConfirmModal } from '@frontend/common/components/NCConfirmModal';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { prettifyTimestamp } from '@frontend/common/lib/common.util';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { showInternalApplicationError, showSuccessToast } from '@frontend/common/lib/functions';
import { FInput } from '@frontend/form/components/FInput';
import { FSelect } from '@frontend/form/components/FSelect';
import { Button, Divider, Textarea } from '@lego/klik-ui';
import { useContext, useMemo, useState } from 'react';

interface FinalCostsProductHeaderProps {
  prodHead: FinalCostsGetEndpointResponse['prodHead'];
  maxRevision: number;
  latestApprovedRevisions: FinalCostsGetEndpointResponse['latestApprovedRevisions'];
  onRevisionSelect(revision: number): void;
  refresh(): void;
}

const RevisionTypeToPretty: Record<RejectEndpointInput['pk'] | '', string> = {
  '': '',
  PRODHEAD_BI: 'building instructions',
  PRODHEAD_ELEM: 'elements',
  PRODHEAD_PACK: 'packaging',
};

const RevisionTypeToIndex: Record<
  RejectEndpointInput['pk'],
  keyof FinalCostsProductHeaderProps['latestApprovedRevisions']
> = {
  PRODHEAD_BI: 'buildingInstructions',
  PRODHEAD_ELEM: 'elements',
  PRODHEAD_PACK: 'packaging',
};

export function FinalCostsProductHeader(props: FinalCostsProductHeaderProps) {
  const [revisionTypeToBeRejected, setRevisionTypeToBeRejected] = useState<
    RejectEndpointInput['pk'] | ''
  >('');
  const [isRejecting, setIsRejecting] = useState(false);
  const [rejectionNote, setRejectionNote] = useState('');

  const { getName } = useContext(UserDataContext);

  async function reject() {
    if (!revisionTypeToBeRejected) {
      showInternalApplicationError();
      return;
    }

    const a = RevisionTypeToIndex[revisionTypeToBeRejected];

    const revision = props.latestApprovedRevisions[a]?.revision;

    if (revision === undefined) {
      showInternalApplicationError();
      return;
    }

    setIsRejecting(true);
    const [err] = await callEndpoint({
      endpoint: RejectEndpoint,
      input: {
        revision,
        productId: props.prodHead.product_id,
        pk: revisionTypeToBeRejected,
        note: rejectionNote,
      },
      errorHandling: { header: 'Rejecting revision' },
    });
    setIsRejecting(false);

    if (err) {
      return;
    }

    showSuccessToast('Revision rejected');
    setRejectionNote('');
    setRevisionTypeToBeRejected('');
    props.refresh();
  }

  const modalHeader = useMemo(() => {
    if (!revisionTypeToBeRejected) {
      return '';
    }
    return `Reject ${RevisionTypeToPretty[revisionTypeToBeRejected]} revision ${
      props.latestApprovedRevisions[RevisionTypeToIndex[revisionTypeToBeRejected]]?.revision
    }`;
  }, [revisionTypeToBeRejected, props.latestApprovedRevisions]);

  return (
    <div>
      <NCConfirmModal
        description={
          <div>
            <div style={{ marginBottom: 8 }}>Note:</div>
            <Textarea value={rejectionNote} onChange={(e) => setRejectionNote(e.target.value)} />
          </div>
        }
        variant="warning"
        header={modalHeader}
        isOpen={!!revisionTypeToBeRejected}
        onCancel={() => {
          setRevisionTypeToBeRejected('');
          setRejectionNote('');
        }}
        onConfirm={reject}
        confirmButtonProps={{ loading: isRejecting, className: 'modal-confirm-button-warning' }}
      />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'min-content 36px min-content auto',
          columnGap: 24,
        }}
      >
        <FInput
          label="Product ID"
          value={convertProductIdFrom8digits(props.prodHead.product_id)}
          align="start"
        />
        <div />
        <div />
        <div />
        <FSelect
          label="Revision"
          value={`${props.prodHead.revision}`}
          editable
          onChange={(rev) => props.onRevisionSelect(Number(rev))}
          options={Array.from(new Array(props.maxRevision)).map((_, i) => ({
            text: i + 1 + (i + 1 === props.maxRevision ? ' (latest)' : ''),
            value: `${i + 1}`,
          }))}
          align="start"
          style={{ width: 200 }}
        />
        <div />
        <div />
        <div />
        <FInput label="FMC year" value={props.prodHead.year} align="start" />
      </div>
      <div style={{ marginTop: 36 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, min-content) auto min-content',
            rowGap: 8,
            columnGap: 44,
            alignItems: 'center',
          }}
        >
          <div style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginLeft: 6 }}>Type</div>
          <div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Revision</div>
          <div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Approval date</div>
          <div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Approved by</div>
          <div />
          <Divider style={{ gridColumn: 'span 5' }} />
          <div style={{ whiteSpace: 'nowrap', marginLeft: 6 }}>Elements</div>
          {props.latestApprovedRevisions.elements ? (
            <>
              <div style={{ textAlign: 'end' }}>
                {props.latestApprovedRevisions.elements?.revision}
              </div>
              <div style={{ whiteSpace: 'nowrap' }}>
                {prettifyTimestamp(props.latestApprovedRevisions.elements?.approved_timestamp)}
              </div>
              <div>{getName(props.latestApprovedRevisions.elements?.approved_by)}</div>
              <Button
                size="sm"
                colorScheme="error"
                style={{ marginRight: 6 }}
                onClick={() => setRevisionTypeToBeRejected(PK.ProdHeadElem)}
                disabled={
                  !props.latestApprovedRevisions.elements ||
                  props.latestApprovedRevisions.elements.rejection_lock
                }
              >
                Reject
              </Button>
            </>
          ) : (
            <div style={{ gridColumn: 'span 4', marginLeft: 100 }}>No revision approved</div>
          )}
          <Divider style={{ gridColumn: 'span 5' }} />
          <div style={{ whiteSpace: 'nowrap', marginLeft: 6 }}>Packaging</div>
          {props.latestApprovedRevisions.packaging ? (
            <>
              <div style={{ textAlign: 'end' }}>
                {props.latestApprovedRevisions.packaging?.revision}
              </div>
              <div style={{ whiteSpace: 'nowrap' }}>
                {prettifyTimestamp(props.latestApprovedRevisions.packaging?.approved_timestamp)}
              </div>
              <div>{getName(props.latestApprovedRevisions.packaging?.approved_by)}</div>
              <Button
                size="sm"
                colorScheme="error"
                style={{ marginRight: 6 }}
                onClick={() => setRevisionTypeToBeRejected(PK.ProdHeadPack)}
                disabled={
                  !props.latestApprovedRevisions.packaging ||
                  props.latestApprovedRevisions.packaging.rejection_lock
                }
              >
                Reject
              </Button>
            </>
          ) : (
            <div style={{ gridColumn: 'span 4', marginLeft: 100 }}>No revision approved</div>
          )}
          <Divider style={{ gridColumn: 'span 5' }} />
          <div style={{ whiteSpace: 'nowrap', marginLeft: 6 }}>Building instructions</div>
          {props.latestApprovedRevisions.buildingInstructions ? (
            <>
              <div style={{ textAlign: 'end' }}>
                {props.latestApprovedRevisions.buildingInstructions?.revision}
              </div>
              <div style={{ whiteSpace: 'nowrap' }}>
                {prettifyTimestamp(
                  props.latestApprovedRevisions.buildingInstructions?.approved_timestamp,
                )}
              </div>
              <div>{getName(props.latestApprovedRevisions.buildingInstructions?.approved_by)}</div>
              <Button
                size="sm"
                colorScheme="error"
                style={{ marginRight: 6 }}
                onClick={() => setRevisionTypeToBeRejected(PK.ProdHeadBI)}
                disabled={
                  !props.latestApprovedRevisions.buildingInstructions ||
                  props.latestApprovedRevisions.buildingInstructions.rejection_lock
                }
              >
                Reject
              </Button>
            </>
          ) : (
            <div style={{ gridColumn: 'span 4', marginLeft: 100 }}>No revision approved</div>
          )}
          <Divider style={{ gridColumn: 'span 5' }} />
        </div>
      </div>
    </div>
  );
}
