import { DataMaintenanceGetLOVConfigEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';
import { NCModal } from '@frontend/common/components/NCModal';
import { Checkbox } from '@lego/klik-ui';
import React, { useCallback, useMemo } from 'react';
import { Filter } from '../dataMaintenance.types';
import { FilterType } from '@core/types/types.es';

interface LOVModalProps {
  selectedProperty: string;
  values: DataMaintenanceGetLOVConfigEndpointResponse;
  filter: Filter;
  updateFilter(key: string, filter: Omit<Filter, 'isNumberType'>): void;
  onClose(): void;
}

export function LOVModal(props: LOVModalProps) {
  const { updateFilter } = props;

  const selectedValues = useMemo(() => {
    if (props.filter.type === FilterType.Include) {
      return props.filter.value;
    }
    return [];
  }, [props.filter]);

  const onValueClick = useCallback(
    (v: unknown) =>
      updateFilter(props.selectedProperty, {
        type: FilterType.Include,
        value: selectedValues.includes(v + '')
          ? selectedValues.filter((sv: unknown) => sv !== v + '')
          : selectedValues.concat(v + ''),
      }),
    [updateFilter, selectedValues, props.selectedProperty],
  );

  return (
    <NCModal header={`List of values for ${props.selectedProperty}`} onClose={props.onClose} isOpen>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'min-content auto min-content',
          alignItems: 'center',
          columnGap: 12,
        }}
      >
        <div />
        <div>Value</div>
        <div>Hits</div>
        {props.values.map(({ value, hits }) => (
          <React.Fragment key={value + ''}>
            <Checkbox
              isChecked={selectedValues.includes(value + '')}
              onChange={() => onValueClick(value)}
            />
            <div onClick={() => onValueClick(value)} style={{ cursor: 'pointer' }}>
              {value + ''}
            </div>
            <div
              onClick={() => onValueClick(value)}
              style={{ textAlign: 'end', cursor: 'pointer' }}
            >
              {hits}
            </div>
          </React.Fragment>
        ))}
      </div>
    </NCModal>
  );
}
