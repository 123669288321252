import React, { useContext } from 'react';
import { ProductIndexContext } from '@frontend/common/lib/contexts';
import { SelectProductIndexPage } from './SelectProductIndexPage';
import { ProdHeadCore } from '@core/schemas/db/schema.db.prod';

interface ProductIndexGuardProps {
  title: string;
  fetchHighestRevision?(productId: number): Promise<number | null>;
  children: React.ReactNode;
  pk?: ProdHeadCore['PK'];
  onCreate?(productId: number): Promise<boolean>;
}

export function ProductIndexGuard(props: ProductIndexGuardProps) {
  const { getProductIndex } = useContext(ProductIndexContext);

  return getProductIndex().productId && getProductIndex().revision ? (
    <>{props.children}</>
  ) : (
    <SelectProductIndexPage title={props.title} pk={props.pk} onCreate={props.onCreate} />
  );
}
