import { CostComponentCost } from '../calculator.types';
import { Calculator } from '../calculator';
import { DBPackingStandardCost } from '@core/schemas/db/schema.db.packaging';

export function calculate_CC_Packing_PackingStandardCosts_Sum(
  packingStandardCosts: Pick<DBPackingStandardCost, 'fmc1_cost' | 'fmc2_cost'>[],
): Required<CostComponentCost> {
  return {
    fmc1cost: Calculator.Common.SumListStrict(packingStandardCosts, 'fmc1_cost'),
    fmc2cost: Calculator.Common.SumListStrict(packingStandardCosts, 'fmc2_cost'),
  };
}
