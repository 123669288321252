import { DBBICost } from '@core/schemas/db/schema.db.bi';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';

export const BICostExample: OmitDBRecord<DBBICost>[] = [
  {
    product_id: 123,
    revision: 1,
    book_number: 1,
    fmc_region: 'FMC1',
    region: 'EU',
    super_design: 1234,
    number_of_pages: 10,
    number_of_colors_frontside: 10,
    number_of_colors_backside: 10,
    number_of_cover_pages: 10,
    paper_weight_gr: 10,
    cover_weight_gr: 10,
    cost: 1.23,
    note: '',
    currency: 'DKK',
  },
];
