import { TOTAL_KEY } from '@core/const/const.TOTAL_KEY';
import { BIRatesListEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.bi';
import { CostComponent } from '@core/types/types.costComponent';
import { Unpacked } from '@core/util/util.typing';
import { FMCREGION_TO_PRETTY } from '@frontend/common/lib/definitions';
import { twoDec } from '@frontend/common/lib/functions';
import { CostOverviewRow } from '@frontend/common/lib/models';
import { TableColumn } from '@frontend/table/table';

export const getBiRatesColumns: (
  commaAsDecimalSeperator: boolean,
) => TableColumn<Unpacked<BIRatesListEndpointResponse>>[] = (commaAsDecimalSeperator: boolean) => [
  {
    title: 'FMC year',
    dataIndex: 'year',
  },
  {
    title: 'Super design',
    dataIndex: 'super_design',
    sorted: 'ascending',
    width: 'auto',
  },
  { title: 'Descriptions', dataIndex: 'description' },
  {
    groupName: 'Number of pages',
    title: 'Total',
    dataIndex: 'total_number_of_pages',
    align: 'end',
  },
  {
    groupName: 'Number of pages',
    title: 'Cover',
    dataIndex: 'number_of_cover_pages',
    align: 'end',
  },
  {
    groupName: 'Number of colors',
    title: 'Frontside',
    dataIndex: 'number_of_colors_frontside',
    align: 'end',
  },
  {
    groupName: 'Number of colors',
    title: 'Backside',
    dataIndex: 'number_of_colors_backside',
    align: 'end',
  },
  {
    groupName: 'Weight (gr.)',
    title: 'Paper',
    dataIndex: 'paper_weight_gr',
    align: 'end',
  },
  {
    groupName: 'Weight (gr.)',
    title: 'Cover',
    dataIndex: 'cover_weight_gr',
    align: 'end',
  },
  {
    groupName: 'EU',
    title: 'Normal',
    dataIndex: 'fmc1_with_scrap',
    align: 'end',
    format: (v) => twoDec(v, commaAsDecimalSeperator),
  },
  {
    groupName: 'EU',
    title: 'QR',
    dataIndex: 'fmc1_qr_with_scrap',
    align: 'end',
    format: (v) => twoDec(v, commaAsDecimalSeperator),
  },
  {
    groupName: 'US',
    title: 'Normal',
    dataIndex: 'fmc2_with_scrap',
    align: 'end',
    format: (v) => twoDec(v, commaAsDecimalSeperator),
  },
  {
    groupName: 'US',
    title: 'QR',
    dataIndex: 'fmc2_qr_with_scrap',
    align: 'end',
    format: (v) => twoDec(v, commaAsDecimalSeperator),
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
  },
];

export function getBiProductCostsColumns(
  commaAsDecimalSeperator: boolean,
): TableColumn<CostOverviewRow>[] {
  return [
    {
      title: 'Book',
      dataIndex: 'costComp',
      sorted: 'ascending',
      format: (v: CostOverviewRow['costComp']) =>
        v === CostComponent.Instructions ? TOTAL_KEY : v,
      style: (v: CostOverviewRow['costComp']) => ({
        fontWeight: v === CostComponent.Instructions ? 'bold' : undefined,
      }),
    },
    {
      title: `PPO`,
      groupName: FMCREGION_TO_PRETTY.FMC1,
      align: 'end',
      dataIndex: 'fmc1ppo',
      format: (v, r) =>
        v === undefined && r.costComp === CostComponent.Instructions
          ? '—'
          : twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({
        fontWeight: r.costComp === CostComponent.Instructions ? 'bold' : undefined,
      }),
    },
    {
      title: `Cost`,
      groupName: FMCREGION_TO_PRETTY.FMC1,
      align: 'end',
      dataIndex: 'fmc1cost',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({
        fontWeight: r.costComp === CostComponent.Instructions ? 'bold' : undefined,
      }),
    },
    {
      title: `Variance`,
      groupName: FMCREGION_TO_PRETTY.FMC1,
      align: 'end',
      dataIndex: 'fmc1variance',
      format: (v, r) =>
        v === undefined && r.costComp === CostComponent.Instructions
          ? '—'
          : twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({
        fontWeight: r.costComp === CostComponent.Instructions ? 'bold' : undefined,
      }),
    },
    {
      title: `PPO`,
      groupName: FMCREGION_TO_PRETTY.FMC2,
      align: 'end',
      dataIndex: 'fmc2ppo',
      format: (v, r) =>
        v === undefined && r.costComp === CostComponent.Instructions
          ? '—'
          : twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({
        fontWeight: r.costComp === CostComponent.Instructions ? 'bold' : undefined,
      }),
    },
    {
      title: `Cost`,
      groupName: FMCREGION_TO_PRETTY.FMC2,
      align: 'end',
      dataIndex: 'fmc2cost',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({
        fontWeight: r.costComp === CostComponent.Instructions ? 'bold' : undefined,
      }),
    },
    {
      title: `Variance`,
      groupName: FMCREGION_TO_PRETTY.FMC2,
      align: 'end',
      dataIndex: 'fmc2variance',
      format: (v, r) =>
        v === undefined && r.costComp === CostComponent.Instructions
          ? '—'
          : twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({
        fontWeight: r.costComp === CostComponent.Instructions ? 'bold' : undefined,
      }),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      width: 'auto',
      style: (v, r) => ({
        fontWeight: r.costComp === CostComponent.Instructions ? 'bold' : undefined,
      }),
    },
  ];
}
