import { useContext, useMemo } from 'react';
import { Table } from '@frontend/table/table';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { getBiRatesColumns } from '../bi.columns';
import { DBBIRate } from '@core/schemas/db/schema.db.bi';

interface SelectBiRateModalContentProps {
  ratesToSelectFrom: (DBBIRate & { description: string })[];
  onRateSelected(rate: DBBIRate & { description: string }): void;
}

export function SelectBiRateModalContent(props: SelectBiRateModalContentProps) {
  const { userData } = useContext(UserDataContext);

  const columns = useMemo(
    () => getBiRatesColumns(!!userData?.comma_as_decimal_seperator),
    [userData?.comma_as_decimal_seperator],
  );

  return (
    <div>
      <div style={{ marginTop: 20, marginBottom: 40, fontSize: 18 }}>
        Multiple rates was found for super design <b>{props.ratesToSelectFrom[0].super_design}</b>{' '}
        and number of pages <b>{props.ratesToSelectFrom[0].total_number_of_pages}</b>. Please select
        which rate to apply:
      </div>
      <Table
        id="bi_select_rate"
        removeInfoText
        removeSearch
        rows={props.ratesToSelectFrom}
        rowKey="SK"
        columns={columns}
        onRowClick={props.onRateSelected}
        fixedHeight={460}
        itemType="super design"
      />
    </div>
  );
}
