import { Calculator } from '@core/calculator/calculator';
import { ScrapOHLicenseRowDescription } from './finalCosts.types';
import { DBProdCost, DBProdPPO } from '@core/schemas/db/schema.db.prod';
import { RegionFMC } from '@core/schemas/schema.common';
import { CostComponent } from '@core/types/types.costComponent';
import { CostOverviewRow } from '@frontend/common/lib/models';
import { useEffect, useMemo, useState } from 'react';
import { WithRequired } from '@core/util/util.typing';

export function useLicenseCostsOverviewRow(props: { ppo?: DBProdPPO; cost?: DBProdCost }): {
  cc_License: WithRequired<CostOverviewRow, 'fmc1cost' | 'fmc2cost'>;
  costs: { fmc1cost: number; fmc2cost: number };
  updateCost: (fmcRegion: RegionFMC, newCost: number) => void;
} {
  const { ppo, cost } = props;

  const [fmc1cost, setFmc1cost] = useState(0);
  const [fmc2cost, setFmc2cost] = useState(0);

  useEffect(() => {
    if (!cost) {
      return;
    }
    setFmc1cost(cost.fmc_eu_cost || 0);
    setFmc2cost(cost.fmc_us_cost || 0);
  }, [cost]);

  function updateCost(fmcRegion: RegionFMC, newCost: number) {
    if (fmcRegion === 'FMC1') {
      setFmc1cost(newCost);
    } else if (fmcRegion === 'FMC2') {
      setFmc2cost(newCost);
    }
  }

  const cc_License = useMemo(() => {
    const costPpoVariance = Calculator.CC_License({
      ppos: { FMC1: ppo?.fmc_eu_cost, FMC2: ppo?.fmc_us_cost },
      cost: { fmc_eu_cost: fmc1cost, fmc_us_cost: fmc2cost },
    });

    const r: WithRequired<CostOverviewRow, 'fmc1cost' | 'fmc2cost'> = {
      costComp: CostComponent.License,
      description: ScrapOHLicenseRowDescription.License,
      currency: 'DKK',
      ...costPpoVariance,
    };
    return r;
  }, [ppo, fmc1cost, fmc2cost]);

  return { cc_License, costs: { fmc1cost, fmc2cost }, updateCost };
}
