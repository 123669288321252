import { z } from 'zod';
import { DBRecordSchema } from '@core/schemas/db/schema.db.common';
import { defineEndpoint } from '@core/util/util.defineEndpoint';
import { PK } from '@core/types/types.pk';
import { FilterType } from '@core/types/types.es';

// DataMaintenanceGetEndpoint
const FilterExactSchema = z.object({
  type: z.literal(FilterType.Exact),
  isNumberType: z.boolean(),
  value: z.string(),
});
const FilterIncludeSchema = z.object({
  type: z.literal(FilterType.Include),
  isNumberType: z.boolean(),
  value: z.array(z.string()),
});
const FilterExcludeSchema = z.object({
  type: z.literal(FilterType.Exclude),
  isNumberType: z.boolean(),
  value: z.array(z.string()),
});
const FilterRangeSchema = z.object({
  type: z.literal(FilterType.Range),
  isNumberType: z.boolean(),
  value: z.object({
    min: z.string().optional(),
    max: z.string().optional(),
  }),
});
const FilterRegexSchema = z.object({
  type: z.literal(FilterType.Regex),
  isNumberType: z.literal(false),
  value: z.object({ and: z.array(z.string()).optional(), or: z.array(z.string()).optional() }),
});
const DataMaintenanceQuerySchema = z.record(
  z.string(),
  z.union([FilterExactSchema, FilterIncludeSchema, FilterExcludeSchema, FilterRangeSchema]),
);

export type FilterExact = z.infer<typeof FilterExactSchema>;
export type FilterInclude = z.infer<typeof FilterIncludeSchema>;
export type FilterExclude = z.infer<typeof FilterExcludeSchema>;
export type FilterRange = z.infer<typeof FilterRangeSchema>;
export type FilterRegex = z.infer<typeof FilterRegexSchema>;
export type FilterAny = FilterExact | FilterInclude | FilterExclude | FilterRange | FilterRegex;
export type DataMaintenanceQuery = z.infer<typeof DataMaintenanceQuerySchema>;

export const DataMaintenanceGetCountEndpoint = defineEndpoint({
  path: '/data-maintenance/get-count',
  input: z.object({
    pk: z.nativeEnum(PK),
  }),
  response: z.object({ count: z.number() }),
});
export type DataMaintenanceGetCountEndpointInput = z.infer<
  typeof DataMaintenanceGetCountEndpoint.input
>;
export type DataMaintenanceGetCountEndpointResponse = z.infer<
  typeof DataMaintenanceGetCountEndpoint.response
>;

export const DataMaintenanceGetEndpoint = defineEndpoint({
  path: '/data-maintenance/get',
  input: z.object({
    pk: z.nativeEnum(PK),
    query: DataMaintenanceQuerySchema,
    PaginationKey: z.string().optional(),
  }),
  response: z.object({
    items: z.array(DBRecordSchema),
    NextPaginationKey: z.string().optional(),
  }),
});
export type DataMaintenanceGetEndpointInput = z.infer<typeof DataMaintenanceGetEndpoint.input>;
export type DataMaintenanceGetEndpointResponse = z.infer<
  typeof DataMaintenanceGetEndpoint.response
>;

// DataMaintenanceDeleteEndpoint
export const DataMaintenanceDeleteEndpoint = defineEndpoint({
  path: '/data-maintenance/delete',
  mutation: true,
  input: z.object({
    pk: z.nativeEnum(PK),
    sk: z.string(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
export type DataMaintenanceDeleteEndpointInput = z.infer<
  typeof DataMaintenanceDeleteEndpoint.input
>;
export type DataMaintenanceDeleteEndpointResponse = z.infer<
  typeof DataMaintenanceDeleteEndpoint.response
>;

// DataMaintenanceUpdateEndpoint
export const DataMaintenanceUpdateEndpoint = defineEndpoint({
  path: '/data-maintenance/update',
  mutation: true,
  input: z.object({
    pk: z.nativeEnum(PK),
    sk: z.string(),
    item: z.record(z.unknown()),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
export type DataMaintenanceUpdateEndpointInput = z.infer<
  typeof DataMaintenanceUpdateEndpoint.input
>;
export type DataMaintenanceUpdateEndpointResponse = z.infer<
  typeof DataMaintenanceUpdateEndpoint.response
>;

// DataMaintenanceUploadEndpoint
export const DataMaintenanceUploadEndpoint = defineEndpoint({
  path: '/data-maintenance/upload',
  mutation: true,
  input: z.object({
    pk: z.nativeEnum(PK),
    items: z.array(z.record(z.unknown())),
    overwrite: z.boolean(),
    singleMode: z.boolean().optional(),
  }),
  response: z.object({
    uploadedItem: DBRecordSchema.optional(),
  }),
});
export type DataMaintenanceUploadEndpointInput = z.infer<
  typeof DataMaintenanceUploadEndpoint.input
>;
export type DataMaintenanceUploadEndpointResponse = z.infer<
  typeof DataMaintenanceUploadEndpoint.response
>;

// DataMaintenanceGetExampleExcelEndpoint
export const DataMaintenanceGetExampleExcelEndpoint = defineEndpoint({
  path: '/data-maintenance/get-example-excel',
  input: z.object({
    pk: z.nativeEnum(PK),
  }),
  response: z.array(z.record(z.any())),
});
export type DataMaintenanceGetExampleExcelEndpointInput = z.infer<
  typeof DataMaintenanceGetExampleExcelEndpoint.input
>;
export type DataMaintenanceGetExampleExcelEndpointResponse = z.infer<
  typeof DataMaintenanceGetExampleExcelEndpoint.response
>;

// DataMaintenanceGetDataAdminConfigEndpoint
export const DataMaintenanceGetDataAdminConfigEndpoint = defineEndpoint({
  path: '/data-maintenance/get-dataadmin-config',
  input: z.null().optional(),
  response: z.record(z.nativeEnum(PK), z.object({ read: z.boolean(), write: z.boolean() })),
});
export type DataMaintenanceGetDataAdminConfigEndpointInput = z.infer<
  typeof DataMaintenanceGetDataAdminConfigEndpoint.input
>;
export type DataMaintenanceGetDataAdminConfigEndpointResponse = z.infer<
  typeof DataMaintenanceGetDataAdminConfigEndpoint.response
>;

// DataMaintenanceGetLOVEndpoint
export const DataMaintenanceGetLOVEndpoint = defineEndpoint({
  path: '/data-maintenance/get-lov',
  input: z.object({ pk: z.nativeEnum(PK), field: z.string() }),
  response: z.array(z.object({ value: z.unknown(), hits: z.number().int() })),
});
export type DataMaintenanceGetLOVConfigEndpointInput = z.infer<
  typeof DataMaintenanceGetLOVEndpoint.input
>;
export type DataMaintenanceGetLOVConfigEndpointResponse = z.infer<
  typeof DataMaintenanceGetLOVEndpoint.response
>;
