import { TableColumn } from '@frontend/table/table';
import { DeleteBold } from '@lego/klik-ui/icons';

export interface ProductIDSelectionRow {
  type: 'single' | 'range';
  value: string;
}

export function getProductIDSelectionsColumns(
  onRemove: (selection: ProductIDSelectionRow) => void,
): TableColumn<ProductIDSelectionRow>[] {
  return [
    { title: 'Value', dataIndex: 'value' },
    {
      title: '',
      dataIndex: '',
      ignoreInExport: true,
      align: 'center',
      render: (_v, r) => (
        <DeleteBold onClick={() => onRemove(r)} className="clickable-icon clickable-icon__danger" />
      ),
    },
  ];
}
