import { Button } from '@lego/klik-ui';
import { useNavigate } from 'react-router-dom';

export function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div style={{ height: '100vh', position: 'relative' }}>
      <div
        style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%,-50%)' }}
      >
        <div>
          <div style={{ fontSize: 24, lineHeight: 'normal' }}>Page not found</div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 36 }}>
            <Button onClick={() => navigate(-1)}>Go back</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
