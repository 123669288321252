import { getApiToken } from './authProvider';
import { createStandaloneToast } from '@lego/klik-ui';
import { Book } from '@frontend/bi/logic/useBooksAndRates';
import { CalculationError } from '@core/calculator/calculator';
import { formatNumber } from '@core/util/util.formatNumber';
import { ProdHeadCore } from '@core/schemas/db/schema.db.prod';
import { NotifyEndpoint } from '@core/schemas/endpoint/schema.endpoint.common';
import { VIRTUAL_GROUPS, VirtualGroupName } from '@core/const/const.VIRTUAL_GROUPS';
import { PK } from '@core/types/types.pk';
import { FaRegCopy } from 'react-icons/fa';
import copy from 'copy-to-clipboard';
import { callEndpoint } from './callEndpoint';

export async function getHeaders() {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', await getApiToken());

  return headers;
}

export function showInternalApplicationError(description?: string) {
  if (isDev()) {
    console.trace('Internal application error');
  }

  const getStackTrace = () => {
    const obj = {};
    Error.captureStackTrace(obj, getStackTrace);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return obj.stack;
  };

  let functionName = '';

  try {
    functionName = getStackTrace().split('at ')[2].split(' ')[0];
  } catch {}

  callEndpoint({
    endpoint: NotifyEndpoint,
    input: {
      type: 'error',
      message: functionName
        ? `Internal application error in function '${functionName}'`
        : 'Internal application error' + (description ? `: ${description}` : ''),
      payload: { trace: getStackTrace() },
    },
  });

  showErrorToast('Internal application error', 'Developer has been notified');
}

export function closeToast(id: string) {
  const { toast } = createStandaloneToast();
  toast.close(id);
}

export function showErrorToast(
  title: string,
  description?: React.ReactNode,
  options?: {
    requestId?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    id?: 'save-failed' | (string & {});
  },
) {
  const { toast } = createStandaloneToast();

  const usedId = options?.id || title + description;

  if (toast.isActive(usedId)) {
    return;
  }

  const descriptionToShow = (
    <div>
      {description}
      {options?.requestId && (
        <div style={{ display: 'flex', columnGap: 8, alignItems: 'center' }}>
          {`Request ID: ${options.requestId}`}
          <FaRegCopy style={{ cursor: 'pointer' }} onClick={() => copy(options.requestId || '')} />
        </div>
      )}
    </div>
  );

  toast({
    id: usedId,
    variant: 'left-accent',
    status: 'error',
    title,
    description: descriptionToShow,
    position: 'top-right',
    isClosable: true,
    duration: 60 * 60 * 1000,
  });
}

function showToast(status: 'success' | 'info' | 'warning', title?: string, description?: string) {
  const { toast } = createStandaloneToast();

  toast({
    variant: 'left-accent',
    status,
    title,
    description,
    position: 'top-right',
    isClosable: true,
    duration: 5000,
  });
}

export function showSuccessToast(title: string, description?: string) {
  showToast('success', title, description);
}

export function showInfoToast(title?: string, description?: string) {
  showToast('info', title, description);
}

export function showWarningToast(title: string, description?: string) {
  showToast('warning', title, description);
}

export function logDev(...args: (string | number | boolean | object | undefined | null)[]) {
  if (!isProd()) {
    console.log(args.map((v) => (v === undefined ? '<undefined>' : v)).join(' '));
  }
}

export function isDev() {
  return STAGE === 'development';
}

export function isProd() {
  return STAGE === 'production';
}

export function stringify(value: string | number | undefined | null | unknown) {
  if (value === undefined || value === null) {
    return '';
  }

  return `${value}`;
}

export function twoDec(
  v: string | number | undefined | null | unknown,
  commaAsDecimalSeperator: boolean,
) {
  return formatNumber(v, commaAsDecimalSeperator, 2);
}

export function threeDec(
  v: string | number | undefined | null | unknown,
  commaAsDecimalSeperator: boolean,
) {
  return formatNumber(v, commaAsDecimalSeperator, 3);
}

export function fiveDec(
  v: string | number | undefined | null | unknown,
  commaAsDecimalSeperator: boolean,
) {
  return formatNumber(v, commaAsDecimalSeperator, 5);
}

export function getBookKey(book: Pick<Book, 'book_number' | 'fmc_region'>) {
  return book.book_number + ',' + book.fmc_region;
}

export function getErrorStatement(error: unknown) {
  if (error instanceof CalculationError) {
    return error.reportStatement;
  }

  return undefined;
}

export function isRevisionOutdated(input: {
  prodHead: Pick<ProdHeadCore, 'year' | 'approved_by' | 'change_lock_by'> | undefined;
  year: number | undefined;
}) {
  const { prodHead, year } = input;

  const outdatedRevision = !!year && prodHead?.year && year !== prodHead.year;

  return outdatedRevision;
}

export function isRevisionReadOnly(input: {
  prodHead: Pick<ProdHeadCore, 'year' | 'approved_by' | 'change_lock_by'> | undefined;
  year: number | undefined;
  email: string;
}) {
  const { prodHead, year, email } = input;

  const outdatedRevision = isRevisionOutdated({ prodHead, year });

  return !!(
    outdatedRevision ||
    prodHead?.approved_by ||
    (prodHead?.change_lock_by && prodHead.change_lock_by !== email)
  );
}

export function accessIsReadOnly(
  virtualGroupNames: VirtualGroupName[],
  pk: PK.ProdHeadElem | PK.ProdHeadPack | PK.ProdHeadBI | PK.ProdHeadFC,
) {
  const groupNames = VIRTUAL_GROUPS.filter((vg) => virtualGroupNames.includes(vg.name))
    .map((vg) => vg.groupNames)
    .flat();

  if (groupNames.includes('Administrators')) {
    return false;
  }

  if (pk === PK.ProdHeadElem) {
    return !groupNames.includes('User_Elements');
  }
  if (pk === PK.ProdHeadPack) {
    return !groupNames.includes('User_Packaging');
  }
  if (pk === PK.ProdHeadBI) {
    return !groupNames.includes('User_BI');
  }
  if (pk === PK.ProdHeadFC) {
    return !groupNames.includes('User_FinalCosts');
  }

  return true;
}

export function isPathAccesible(
  path: string,
  virtualGroups: VirtualGroupName[] | undefined,
): boolean {
  if (path === '/') {
    return true;
  }

  if (!virtualGroups) {
    return false;
  }

  const groupsProvidingAccess: VirtualGroupName[] = [
    VirtualGroupName.TheCostingGroup,
    VirtualGroupName.Administrators,
    VirtualGroupName.DisplayAll,
  ];

  if (path === '/elements') {
    groupsProvidingAccess.push(VirtualGroupName.ElementsUsers, VirtualGroupName.FinalCostsUsers);
  } else if (path === '/packaging') {
    groupsProvidingAccess.push(VirtualGroupName.PackagingUsers, VirtualGroupName.FinalCostsUsers);
  } else if (path === '/building-instructions') {
    groupsProvidingAccess.push(VirtualGroupName.BIUsers, VirtualGroupName.FinalCostsUsers);
  } else if (path === '/final-costs') {
    groupsProvidingAccess.push(VirtualGroupName.FinalCostsUsers);
  } else if (path === '/reporting') {
    groupsProvidingAccess.push(
      VirtualGroupName.ElementsUsers,
      VirtualGroupName.PackagingUsers,
      VirtualGroupName.BIUsers,
      VirtualGroupName.FinalCostsUsers,
    );
  } else if (path === '/reporting/elements-costs' || path === '/reporting/elements-errors') {
    groupsProvidingAccess.push(VirtualGroupName.ElementsUsers, VirtualGroupName.FinalCostsUsers);
  } else if (path === '/reporting/packaging') {
    groupsProvidingAccess.push(VirtualGroupName.PackagingUsers, VirtualGroupName.FinalCostsUsers);
  } else if (path === '/reporting/building-instructions') {
    groupsProvidingAccess.push(
      VirtualGroupName.BIUsers,
      VirtualGroupName.PackagingUsers,
      VirtualGroupName.FinalCostsUsers,
    );
  } else if (path === '/reporting/building-instructions-lbx') {
    groupsProvidingAccess.push(VirtualGroupName.BIUsers, VirtualGroupName.FinalCostsUsers);
  } else if (path === '/reporting/costing-status' || path === '/reporting/final-costs') {
    groupsProvidingAccess.push(VirtualGroupName.FinalCostsUsers);
  }

  return (
    groupsProvidingAccess.length === 0 ||
    !!virtualGroups.find((vgName) => groupsProvidingAccess.find((r) => r === vgName))
  );
}
