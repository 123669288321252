import {
  AdministrationSimulateElementsCalculationEndpoint,
  AdministrationSimulateElementsCalculationEndpointResponse,
} from '@core/schemas/endpoint/schema.endpoint.administration';
import { REGEX_NONNEGATIVE_INTEGER_OR_EMPTY } from '@core/types/types.regex';
import { InlineMessage } from '@frontend/common/components/InlineMessage';
import { Tag } from '@frontend/common/components/Tag';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showSuccessToast, showWarningToast } from '@frontend/common/lib/functions';
import { FCheckbox } from '@frontend/form/components/FCheckbox';
import { FInput } from '@frontend/form/components/FInput';
import { Button } from '@lego/klik-ui';
import { CrossBold } from '@lego/klik-ui/icons';
import { useState } from 'react';

export function SimulateElementsCalculation() {
  const [productId, setProductId] = useState('');
  const [productIds, setProductIds] = useState<number[]>([]);
  const [isSimulating, setIsSimulating] = useState(false);
  const [result, setResult] = useState<AdministrationSimulateElementsCalculationEndpointResponse>();
  const [force, setForce] = useState(false);

  function addProductId(val: string) {
    setProductIds((curr) => curr.filter((pid) => pid !== Number(val)).concat(Number(val)));
    setProductId('');
  }

  function removeProductId(productId: number) {
    setProductIds((curr) => curr.filter((pid) => pid !== productId));
  }

  async function simulate() {
    setResult(undefined);
    setIsSimulating(true);
    const [err, data] = await callEndpoint({
      endpoint: AdministrationSimulateElementsCalculationEndpoint,
      input: {
        productIds,
        force,
      },
      errorHandling: { header: 'Simulating elements calculation' },
    });
    setIsSimulating(false);

    if (err) {
      return;
    }

    setResult(data);

    const erroneousResult = data.find((v) => !!v.error);

    if (erroneousResult) {
      showWarningToast(
        'Simulating elements calculation',
        `Simulated elements calculation for ${productIds.length} product${
          productId.length === 1 ? '' : 's'
        }, some with error`,
      );
    } else {
      showSuccessToast(
        'Simulating elements calculation',
        `Successfully simulated elements calculation for ${productIds.length} product${
          productId.length === 1 ? '' : 's'
        }`,
      );
    }
  }

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 92 }}>
      <div>
        <div style={{ display: 'flex', columnGap: 8, alignItems: 'end' }}>
          <FInput
            topLabel="Product IDs"
            editable
            align="start"
            regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
            onMultiValuePaste={(values) => {
              values.forEach(addProductId);
              setProductId('');
            }}
            disallowChangeOnInvalid
            disableSmartBehavior
            value={productId}
            disableBlurOnEnter
            onChange={setProductId}
            onEnter={() => addProductId(productId)}
            style={{ width: 'inherit' }}
          />
          <Button
            colorScheme="success"
            size="sm"
            disabled={!productId}
            onClick={() => addProductId(productId)}
          >
            Add
          </Button>
        </div>
        {productIds.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: 4,
              rowGap: 4,
              marginTop: 8,
            }}
          >
            {productIds.map((pid) => (
              <Tag
                key={pid}
                style={{
                  fontSize: 14,
                  height: 28,
                  padding: '0 8px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => removeProductId(pid)}
              >
                {pid}
                <CrossBold style={{ fontSize: 10, marginLeft: 4, marginTop: -2 }} />
              </Tag>
            ))}
          </div>
        )}
        <div style={{ marginTop: 24 }}>
          <FCheckbox
            label="Force new revision"
            editable
            checked={force}
            onChange={setForce}
            align="start"
            containerStyle={{ marginTop: 16 }}
            labelStyle={{ fontWeight: 'unset' }}
          />
          <Button
            size="sm"
            style={{ marginTop: 36 }}
            disabled={productIds.length === 0}
            onClick={simulate}
            isLoading={isSimulating}
          >
            Calculate
          </Button>
        </div>
      </div>
      <div style={{ display: 'grid', rowGap: 4, gridAutoRows: 'min-content' }}>
        {result?.map((r) => (
          <InlineMessage
            key={r.productId}
            variant={r.noOpReason ? 'info' : r.error ? 'error' : 'success'}
            message={
              `Product ${r.productId}: ` +
              (r.noOpReason
                ? `No new revision to calculate: ${r.noOpReason}`
                : r.error
                ? r.error
                : 'Revision successfully calculated')
            }
          />
        ))}
      </div>
    </div>
  );
}
