import { DBElementsScrapRate, DBOHRate } from '@core/schemas/db/schema.db.common';
import {
  ElementsScrapRatesGetEndpoint,
  OverheadRatesGetEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.finalCosts';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showErrorToast, showInternalApplicationError } from '@frontend/common/lib/functions';

import { FSelectOption } from '@frontend/form/lib/form.types';
import { useEffect, useMemo, useState } from 'react';

export type ScrapType = undefined | 1 | 2 | 3 | 4;
export type OverheadType = undefined | 1 | 2 | 3 | 4;

export function useScrapAndOH(input: {
  scrapType: number | undefined;
  overheadType: number | undefined;
  year: number | undefined;
}) {
  const { overheadType, scrapType, year } = input;

  const [selectedScrapRate, setSelectedScrapRate] = useState<DBElementsScrapRate>();
  const [selectedOverheadRate, setSelectedOverheadRate] = useState<DBOHRate>();
  const [scrapRates, setScrapRates] = useState<DBElementsScrapRate[]>();
  const [overheadRates, setOverheadRates] = useState<DBOHRate[]>();

  const invalidRates = useMemo(() => {
    if (selectedScrapRate && selectedScrapRate.year !== year) {
      return true;
    }

    if (selectedOverheadRate && selectedOverheadRate.year !== year) {
      return true;
    }

    return false;
  }, [year, selectedScrapRate, selectedOverheadRate]);

  useEffect(() => {
    if (!year) {
      return;
    }

    (async () => {
      const [[, scrapRates], [, overheadRates]] = await Promise.all([
        callEndpoint({
          endpoint: ElementsScrapRatesGetEndpoint,
          input: { year },
          errorHandling: { header: 'Fetching scrap rates' },
        }),
        callEndpoint({
          endpoint: OverheadRatesGetEndpoint,
          input: { year },
          errorHandling: { header: 'Fetching overhead rates' },
        }),
      ]);

      if (scrapRates) {
        setScrapRates(scrapRates);
      }

      if (overheadRates) {
        setOverheadRates(overheadRates);
      }
    })();
  }, [year]);

  useEffect(() => {
    if (!scrapRates) {
      return;
    }

    if (!scrapType) {
      setSelectedScrapRate(undefined);
      return;
    }

    const scrapRate = scrapRates.find((sr) => sr.type === scrapType);

    if (!scrapRate) {
      showErrorToast('Unable to find selected scrap rate among options');
      return;
    }

    setSelectedScrapRate(scrapRate);
  }, [scrapType, scrapRates]);

  useEffect(() => {
    if (!overheadRates) {
      return;
    }

    if (!overheadType) {
      setSelectedOverheadRate(undefined);
      return;
    }

    const overheadRate = overheadRates.find((ohr) => ohr.type === overheadType);

    if (!overheadRate) {
      showErrorToast('Unable to find selected overhead rate among options');
      return;
    }

    setSelectedOverheadRate(overheadRate);
  }, [overheadType, overheadRates]);

  function updateScrapRate(type: ScrapType) {
    if (!type) {
      setSelectedScrapRate(undefined);
      return;
    }

    const scrapRate = scrapRates?.find((sr) => sr.type === type);

    if (!scrapRate) {
      showInternalApplicationError();
      return;
    }

    setSelectedScrapRate(scrapRate);
  }

  function updateOverheadRate(type: OverheadType) {
    if (!type) {
      setSelectedOverheadRate(undefined);
      return;
    }

    const overheadRate = overheadRates?.find((ohr) => ohr.type === type);

    if (!overheadRate) {
      showInternalApplicationError();
      return;
    }

    setSelectedOverheadRate(overheadRate);
  }

  const scrapOptions: FSelectOption[] = useMemo(
    () =>
      !scrapRates
        ? []
        : scrapRates.map<FSelectOption>((sr) => ({
            text: `${sr.type} - ${sr.description}`,
            value: `${sr.type}`,
            disabled: sr.disabled,
          })),
    [scrapRates],
  );
  const overheadOptions: FSelectOption[] = useMemo(
    () =>
      !overheadRates
        ? []
        : overheadRates.map<FSelectOption>((ohr) => ({
            text: `${ohr.type} - ${ohr.description}`,
            value: `${ohr.type}`,
            disabled: ohr.disabled,
          })),
    [overheadRates],
  );

  return {
    selectedOverheadRate,
    selectedScrapRate,
    updateOverheadRate,
    updateScrapRate,
    overheadOptions,
    scrapOptions,
    invalidRates,
  };
}
