import { RevisionError } from '@core/schemas/schema.common';
import { revisionErrorOriginToPretty } from '@core/schemas/schema.util';

export function revisionErrorToString(revisionError: RevisionError) {
  const { id, message, origin } = revisionError;

  let result = revisionErrorOriginToPretty(origin);

  if (id) {
    if (result) {
      result += ', ';
    }
    result += id;
  }

  if (message) {
    if (result) {
      result += ': ';
    }
    result += message;
  }

  return result;
}
