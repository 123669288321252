import { DBPackingStandardPrepackRate } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { PackSource } from '@core/types/types.packaging';

export const PackingStandardPrepackRateExample: OmitDBRecord<DBPackingStandardPrepackRate>[] = [
  {
    year: 1234,
    source: PackSource.Duplo,
    packing_key: 'DOUBL',
    cost: 1.23,
    currency: 'DKK',
  },
];
