import { Button, Modal } from '@lego/klik-ui';

interface NCModalProps {
  header: string;
  children: React.ReactNode;
  isOpen: boolean;
  width?: number;
  maxWidth?: number;
  onClose(): void;
  closeButtonProps?: {
    text?: string;
  };
}

export function NCModal(props: NCModalProps) {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnEsc
      closeOnOverlayClick
      blockScrollOnMount={false}
    >
      <Modal.Overlay />
      <Modal.Content
        maxW={props.maxWidth ?? props.width}
        width={props.width ?? 'auto'}
        className="ncmodal"
      >
        <div
          style={{
            display: 'grid',
            gridTemplateRows: '60px 1px 1fr 1px 50px',
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 24,
              display: 'flex',
              alignItems: 'center',
              margin: '0 20px',
              whiteSpace: 'nowrap',
            }}
          >
            {props.header}
          </div>
          <div
            style={{
              borderTop: '1px solid lightgray',
              margin: '0 12px',
            }}
          />
          <div
            style={{
              overflow: 'auto',
              padding: '12px 24px',
              maxHeight: 'calc(94vh - 112px)',
            }}
          >
            {props.children}
          </div>
          <div
            style={{
              borderTop: '1px solid lightgray',
              margin: '0 12px',
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginRight: 12,
            }}
          >
            <Button size="sm" onClick={props.onClose}>
              {props.closeButtonProps?.text ?? 'Close'}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
