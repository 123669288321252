import { CloseButton, InlineNotification, Link } from '@lego/klik-ui';
import { CSSProperties, useContext } from 'react';
import { ModalContext } from '@frontend/common/lib/contexts';

interface InlineMessageProps {
  variant: string;
  message?: string;
  moreInformation?: React.ReactNode;
  style?: CSSProperties;
  onClose?(): void;
}

export function InlineMessage(props: InlineMessageProps) {
  const setActiveModal = useContext(ModalContext);

  return (
    <InlineNotification variant={props.variant} style={props.style}>
      <InlineNotification.Content>
        <InlineNotification.Title
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            columnGap: 24,
          }}
        >
          <div>{props.message}</div>
          {props.moreInformation && (
            <Link
              onClick={() =>
                setActiveModal({
                  variant: props.variant,
                  header: props.message || '',
                  body: props.moreInformation,
                })
              }
              className="link-message"
            >
              More information
            </Link>
          )}
        </InlineNotification.Title>
      </InlineNotification.Content>
      {props.onClose && <CloseButton aria-label="Close" onClick={props.onClose} />}
    </InlineNotification>
  );
}
