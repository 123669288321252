import { z } from 'zod';

export const PackagingSizeSchema = z.object({
  packaging_size: z.number().int(),
  erect_code: z.string(),
  erect_length: z.number(),
  erect_width: z.number(),
  erect_depth: z.number(),
  packaging_facing: z.number().int(),
  status: z.number().int().optional(),
});
export type PackagingSize = z.infer<typeof PackagingSizeSchema>;

export const SuperThemeSchema = z.object({
  theme: z.number(),
  description: z.string(),
});
export type SuperTheme = z.infer<typeof SuperThemeSchema>;
