import { z } from 'zod';
import { DBBICostSchema, DBBIRateSchema, DBProdHeadBISchema } from '@core/schemas/db/schema.db.bi';
import { defineEndpoint } from '@core/util/util.defineEndpoint';
import {
  OMIT_DB_RECORD,
  OMIT_DB_RECORD_EXCEPT_PK,
} from '@core/schemas/const/schema.const.OMIT_DB_RECORD';
import { RevisionErrorSchema } from '../schema.common';

// BIGetEndpoint
const BIGetResponseSchema = z.object({
  prodHead: DBProdHeadBISchema.omit(OMIT_DB_RECORD_EXCEPT_PK),
  erectCode: z.string().optional(),
  description: z.string(),
  maxRevision: z.number().int(),
  biCosts: z.array(
    DBBICostSchema.extend({ description: z.string() }).omit({
      ...OMIT_DB_RECORD,
      product_id: true,
      revision: true,
    }),
  ),
  productCosts: z.object({
    ppo: z.object({
      FMC1: z.number().optional(),
      FMC2: z.number().optional(),
    }),
  }),
});

export const BIGetEndpoint = defineEndpoint({
  path: '/bi/get',
  input: z.object({
    productId: z.number().int(),
    rev: z.number().int().optional(),
  }),
  response: BIGetResponseSchema,
});
export type BIGetEndpointInput = z.infer<typeof BIGetEndpoint.input>;
export type BIGetEndpointResponse = z.infer<typeof BIGetEndpoint.response>;

// BINewGetEndpoint
export const BINewGetEndpoint = defineEndpoint({
  path: '/bi/new/get',
  input: z.object({
    productId: z.number().int(),
  }),
  response: BIGetResponseSchema,
});
export type BINewGetEndpointInput = z.infer<typeof BINewGetEndpoint.input>;
export type BINewGetEndpointResponse = z.infer<typeof BINewGetEndpoint.response>;

// BISaveEndpoint
export const BISaveEndpoint = defineEndpoint({
  path: '/bi/save',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
    revision: z.union([z.number().int(), z.literal('NEW')]),
    books: z.array(
      DBBICostSchema.omit({
        ...OMIT_DB_RECORD,
        product_id: true,
        revision: true,
        cost: true,
      }).merge(z.object({ _manualCost: z.number() })),
    ),
    biStatus: z.number().int(),
    copy: z.boolean().optional(),
  }),
  response: z.object({
    savedRevision: z.number().int(),
    revisionErrors: z.array(RevisionErrorSchema).optional(),
  }),
});
export type BISaveEndpointInput = z.infer<typeof BISaveEndpoint.input>;
export type BISaveEndpointResponse = z.infer<typeof BISaveEndpoint.response>;

// BIRatesListEndpoint
export const BIRatesListEndpoint = defineEndpoint({
  path: '/bi/rates-list',
  input: z.object({
    year: z.number().int(),
  }),
  response: z.array(DBBIRateSchema.extend({ description: z.string() })),
});
export type BIRatesListEndpointInput = z.infer<typeof BIRatesListEndpoint.input>;
export type BIRatesListEndpointResponse = z.infer<typeof BIRatesListEndpoint.response>;

// BIRatesGetEndpoint
export const BIRatesGetEndpoint = defineEndpoint({
  path: '/bi/rates-get',
  input: z.object({
    year: z.number().int(),
    superDesign: z.number().int(),
    pages: z.number().int(),
    coverPages: z.number().int(),
    colorsFrontside: z.number().int(),
    colorsBackside: z.number().int(),
    paperWeight: z.number().int(),
    coverWeight: z.number().int(),
  }),
  response: DBBIRateSchema.extend({ description: z.string() }),
});
export type BIRatesGetEndpointInput = z.infer<typeof BIRatesGetEndpoint.input>;
export type BIRatesGetEndpointResponse = z.infer<typeof BIRatesGetEndpoint.response>;

// BIRatesGetSmartEndpoint
export const BIRatesGetSmartEndpoint = defineEndpoint({
  path: '/bi/rates-get-smart',
  input: z.object({
    year: z.number().int(),
    superDesign: z.number().int(),
    pages: z.number().int(),
  }),
  response: z.array(DBBIRateSchema.extend({ description: z.string() })),
});
export type BIRatesGetSmartEndpointInput = z.infer<typeof BIRatesGetSmartEndpoint.input>;
export type BIRatesGetSmartEndpointResponse = z.infer<typeof BIRatesGetSmartEndpoint.response>;

// BIApproveEndpoint
export const BIApproveEndpoint = defineEndpoint({
  path: '/bi/approve',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
    revision: z.number().int(),
    _unapprove: z.boolean().optional(),
  }),
  response: z.object({
    approved: z.boolean(),
  }),
});
export type BIApproveEndpointInput = z.infer<typeof BIApproveEndpoint.input>;
export type BIApproveEndpointResponse = z.infer<typeof BIApproveEndpoint.response>;
