export function calculate_nullable_product(
  a: number | undefined,
  b: number | undefined,
): number | undefined {
  if (a === undefined && b === undefined) {
    return undefined;
  }

  if (a === undefined || b === undefined) {
    return 0;
  }

  return a * b;
}
