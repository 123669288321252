import { DBPackingStandardFinalPackRate } from '@core/schemas/db/schema.db.packaging';
import { Region, RegionFMC } from '@core/schemas/schema.common';
import { ReportStatement, getRateReportStatement } from '../calculator.util.report';
import { PackagingSize } from '@core/schemas/interface/schema.interface';
import { PackSource } from '@core/types/types.packaging';

export function calculate_PACKINGCOST_findFinalPackRates(props: {
  packingKey: string;
  packaging: PackagingSize;
  standardRates: DBPackingStandardFinalPackRate[] | undefined;
  source: PackSource;
  year: number;
  packingRegionEU: Region;
  packingRegionUS: Region;
  salesRegionEU: Region;
  salesRegionUS: Region;
}): Record<RegionFMC, DBPackingStandardFinalPackRate | undefined> & { report: ReportStatement[] } {
  const {
    packingKey,
    packaging,
    source,
    standardRates,
    year,
    packingRegionEU,
    packingRegionUS,
    salesRegionEU,
    salesRegionUS,
  } = props;

  // first try to find rates with given packSize
  let rates: DBPackingStandardFinalPackRate[] | undefined = standardRates?.filter(
    (rate) =>
      rate.year === year &&
      rate.packing_key === packingKey &&
      rate.packaging_facing === packaging.packaging_facing &&
      rate.source === source &&
      rate.packaging_size === packaging.packaging_size,
  );

  // else look for rates with packSize blank (0)
  if (!rates || rates.length === 0) {
    rates = standardRates?.filter(
      (rate) =>
        rate.year === year &&
        rate.packing_key === packingKey &&
        rate.packaging_facing === packaging.packaging_facing &&
        rate.source === source &&
        rate.packaging_size === 0,
    );
  }

  const fmc1rate = rates?.find(
    (rate) => rate.packing_region === packingRegionEU && rate.sales_region === salesRegionEU,
  );
  const fmc2rate = rates?.find(
    (rate) => rate.packing_region === packingRegionUS && rate.sales_region === salesRegionUS,
  );

  return {
    FMC1: fmc1rate,
    FMC2: fmc2rate,
    report: [
      getRateReportStatement({
        region: 'EU',
        rateType: 'Packing standard final pack rate',
        rate: fmc1rate?.cost,
        rateIdentifier: [
          fmc1rate,
          ['year', 'packing_key', 'packaging_size', 'packing_region', 'sales_region'],
        ],
      }),
      getRateReportStatement({
        region: 'US',
        rateType: 'Packing standard final pack rate',
        rate: fmc2rate?.cost,
        rateIdentifier: [
          fmc2rate,
          ['year', 'packing_key', 'packaging_size', 'packing_region', 'sales_region'],
        ],
      }),
    ],
  };
}
