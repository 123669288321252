import { Region, RegionEnum, RegionFMC, RegionFMCEnum } from '@core/schemas/schema.common';
import {
  CalculatePackagingStandardCostAddOns,
  CalculatePackagingStandardCostReturnValue,
  CalculatePackagingStandardCostRow,
} from './calculate.PackagingCosts';
import {
  DBPackagingAddonRate,
  DBPackagingFoilRate,
  DBPackagingRate,
  DBPackingStandardCost,
} from '@core/schemas/db/schema.db.packaging';
import { Calculate_FetchQuery } from '../calculator.types';
import {
  ReportStatement,
  getCostCustomReportStatement,
  getCostReportStatement,
  getRateReportStatement,
  getReportStatement,
} from '../calculator.util.report';
import { CalculationError, Calculator, useCacheOrFetchQuery } from '../calculator';
import { PK } from '@core/types/types.pk';
import {
  PackSource,
  PackagingAddOnKey,
  PackagingPackingKey,
  PackingKeyId,
} from '@core/types/types.packaging';
import { PACKING_KEY_ID_TO_TYPE } from '@core/const/const.PACKING_KEYS';
import { round2 } from '../calculator.util';

type SubPackingStandardCost = Pick<
  DBPackingStandardCost,
  'packing_key' | 'fmc1_quantity' | 'fmc2_quantity'
>;

export async function calculate_packagingStandardCost(props: {
  input: {
    productId: number;
    revision: number;
    packagingStandardCost: CalculatePackagingStandardCostRow;
    addOns: CalculatePackagingStandardCostAddOns;
    packingRegionEU: Region;
    packingRegionUS: Region;
    salesRegionEU: Region;
    salesRegionUS: Region;
    packagingSize: number;
    source: PackSource;
    modelBag: boolean;
    fmcYear: number;
  };
  cache?: {
    packagingRates?: DBPackagingRate[];
    packingStandardCosts?: SubPackingStandardCost[];
    packagingFoilRates?: DBPackagingFoilRate[];
    packagingAddOnRates?: DBPackagingAddonRate[];
    cost?: {
      use?: boolean;
      values: {
        fmc1cost?: number;
        fmc2cost?: number;
      };
    };
  };
  query?: {
    query?: Calculate_FetchQuery;
  };
}): Promise<CalculatePackagingStandardCostReturnValue> {
  const { input, cache, query } = props;

  if (props.cache?.cost?.use) {
    return handleCostCache(props.cache.cost.values);
  }

  if (input.packagingStandardCost.custom) {
    return handleCostCache({
      fmc1cost: input.packagingStandardCost.fmc1manualCost,
      fmc2cost: input.packagingStandardCost.fmc2manualCost,
    });
  }

  const packagingFoilRates = await useCacheOrFetchQuery({
    cachedItems: cache?.packagingFoilRates,
    fetchQuery: query?.query,
    fetchQueryArgs: {
      pk: PK.PackagingFoilRate,
      query: Calculator.Pack.Packaging.StandardCost.Filter.PackagingFoilRates({
        year: input.fmcYear,
      }),
    },
    dataTypeDescription: 'packaging foil rates',
  });

  const packingStandardCosts = await useCacheOrFetchQuery<
    DBPackingStandardCost,
    SubPackingStandardCost
  >({
    cachedItems: cache?.packingStandardCosts,
    fetchQuery: query?.query,
    fetchQueryArgs: {
      pk: PK.PackingStandardCost,
      query: Calculator.Pack.Packaging.StandardCost.Filter.PackingStandardCosts({
        productId: input.productId,
        revision: input.revision,
      }),
    },
    dataTypeDescription: 'packing standard costs',
  });

  const addOnRates = await useCacheOrFetchQuery({
    cachedItems: cache?.packagingAddOnRates,
    fetchQuery: query?.query,
    fetchQueryArgs: {
      pk: PK.PackagingAddOnRate,
      query: Calculator.Pack.Packaging.StandardCost.Filter.PackagingAddOnRates({
        year: input.fmcYear,
      }),
    },
    dataTypeDescription: 'addon rates',
  });

  const packagingRatesFilter = Calculator.Pack.Packaging.StandardCost.Filter.PackagingRates({
    packagingSize: input.packagingSize,
    source: input.source,
    year: input.fmcYear,
  });
  let packagingRates = await useCacheOrFetchQuery({
    cachedItems: cache?.packagingRates,
    fetchQuery: query?.query,
    fetchQueryArgs: {
      pk: PK.PackagingRate,
      query: packagingRatesFilter,
    },
    dataTypeDescription: 'packaging rates',
    cacheFilter: packagingRatesFilter,
  });

  function calculateCostRegional(
    fmcRegion: RegionFMC,
    meta: { year: number },
  ): {
    cost: number;
    report: ReportStatement[];
  } {
    const report: ReportStatement[] = [];

    const asRegion: Region =
      fmcRegion === RegionFMCEnum.enum.FMC1 ? RegionEnum.Enum.EU : RegionEnum.Enum.US;

    if (input.packagingStandardCost.key === PackagingPackingKey.Foil) {
      let subCosts: { type: PackingKeyId; cost: number }[] = [];

      packingStandardCosts
        .filter((pc) => PACKING_KEY_ID_TO_TYPE[pc.packing_key] === 'PREPACK')
        .forEach((pc) => {
          const quantity = fmcRegion === 'FMC1' ? pc.fmc1_quantity : pc.fmc2_quantity;
          const packagingFoilRate = packagingFoilRates.find(
            (fr) =>
              fr.source === input.source &&
              fr.model_bag === input.modelBag &&
              (fr.packing_key as unknown as PackingKeyId) === pc.packing_key,
          );

          const rate = packagingFoilRate?.rate || 0;

          report.push(
            getRateReportStatement({
              region: asRegion,
              rateType: 'Packaging foil rate',
              rate: packagingFoilRate ? rate : 'Not found',
              isError: false,
              rateIdentifier: [
                {},
                [
                  {
                    year: meta.year,
                    source: input.source,
                    model_bag: input.modelBag,
                    packing_key: pc.packing_key,
                  },
                ],
              ],
            }),
          );

          const subCost = quantity * rate;

          subCosts.push({ type: pc.packing_key, cost: subCost });

          report.push(
            getCostReportStatement({
              region: asRegion,
              extraTag: `(${pc.packing_key})`,
              quantity,
              rate,
              cost: subCost,
            }),
          );
        });

      const total = subCosts.reduce((sum, curr) => sum + curr.cost, 0);

      report.push(
        getCostCustomReportStatement({
          region: asRegion,
          cost: total,
          description: subCosts.map(({ type, cost }) => `${type} (${cost})`).join(' + '),
        }),
      );

      return {
        cost: total,
        report,
      };
    }

    const quantity =
      fmcRegion === 'FMC1'
        ? input.packagingStandardCost.fmc1quantity
        : input.packagingStandardCost.fmc2quantity;

    const defaultCost = quantity === 0 ? 0 : undefined;

    if (defaultCost !== undefined) {
      return {
        cost: defaultCost,
        report: [
          ...report,
          getRateReportStatement({ region: asRegion, rateType: 'default_from_quantity' }),
        ],
      };
    }

    const relevantPackingRegion = asRegion === 'EU' ? input.packingRegionEU : input.packingRegionUS;
    const relevantSalesRegion = asRegion === 'EU' ? input.salesRegionEU : input.salesRegionUS;

    const packagingRate = packagingRates.find(
      (r) =>
        r.packing_key === input.packagingStandardCost.key &&
        r.packing_region === relevantPackingRegion &&
        r.sales_region === relevantSalesRegion,
    );

    if (!packagingRate) {
      throw new CalculationError({
        report: getRateReportStatement({
          region: asRegion,
          rateType: 'Packaging rate',
          rateIdentifier: [
            {},
            [
              {
                year: input.fmcYear,
                packaging_size: input.packagingSize,
                source: input.source,
                packing_key: input.packagingStandardCost.key,
                packing_region: relevantPackingRegion,
                salesRegion: relevantSalesRegion,
              },
            ],
          ],
          rate: 'Not found',
        }),
      });
    }

    report.push(
      getRateReportStatement({
        region: asRegion,
        rateType: 'Packaging rate',
        rate: packagingRate.cost,
        rateIdentifier: [
          packagingRate,
          ['year', 'packaging_size', 'source', 'packing_key', 'packing_region', 'sales_region'],
        ],
      }),
    );

    let addonRate = 100;

    if (input.packagingStandardCost.key === PackagingPackingKey.Box && addOnRates) {
      const propToKey: Record<
        Exclude<keyof (typeof input)['addOns'], 'partialUvLacquer'>,
        { key: PackagingAddOnKey; prettyName: string }
      > = {
        embossing: { key: PackagingAddOnKey.Embossing, prettyName: 'Embossing' },
        hotfoil: { key: PackagingAddOnKey.Hotfoil, prettyName: 'Hotfoil' },
        uvLacquer: { key: PackagingAddOnKey.UVLacquer, prettyName: 'UV lacquer' },
        windowCutout: { key: PackagingAddOnKey.WindowCutout, prettyName: 'Window cutout' },
        windowFoil: { key: PackagingAddOnKey.WindowFoil, prettyName: 'Window foil' },
      };

      for (const [prop, { key, prettyName }] of Object.entries(propToKey)) {
        if (input.addOns[prop as keyof (typeof input)['addOns']]) {
          const addonRateItem = addOnRates.find(
            (r) => r.packing_key === key && r.packing_region === asRegion,
          );

          if (!addonRateItem) {
            throw new CalculationError({
              report: getRateReportStatement({
                region: asRegion,
                rateType: 'Addon rate',
                rateIdentifier: [
                  {},
                  [
                    {
                      year: input.fmcYear,
                      packing_region: relevantPackingRegion,
                      packing_key: key,
                    },
                  ],
                ],
                rate: 'Not found',
              }),
            });
          }

          report.push(
            getRateReportStatement({
              region: asRegion,
              rateType: 'Addon rate',
              rateTypeAdditionl: prettyName,
              rate: addonRateItem.rate_percent,
              unit: '%',
              rateIdentifier: [addonRateItem, ['year', 'packing_key', 'packing_region']],
            }),
          );

          addonRate += addonRateItem.rate_percent;
        }
      }

      if (input.addOns.partialUvLacquer) {
        addonRate += 0;
        report.push(
          getReportStatement({
            region: asRegion,
            description: 'Partial UV lacquer addon selected, not adding to cost',
            value: 0,
            unit: '%',
          }),
        );
      }
    }

    addonRate /= 100;

    const rate = packagingRate.cost;

    const cost = quantity * rate * addonRate;

    report.push(
      getCostCustomReportStatement({
        region: asRegion,
        description: `Quantity (${quantity}) · Packaging rate (${rate}) + Addon rate (${(
          (addonRate - 1) *
          100
        ).toFixed(2)}%)`,
        cost,
      }),
    );

    return {
      cost,
      report,
    };
  }

  const { cost: fmc1_cost, report: fmc1_report } = calculateCostRegional('FMC1', {
    year: input.fmcYear,
  });
  const { cost: fmc2_cost, report: fmc2_report } = calculateCostRegional('FMC2', {
    year: input.fmcYear,
  });

  return {
    fmc1_cost: round2(fmc1_cost),
    fmc2_cost: round2(fmc2_cost),
    report: [...fmc1_report, ...fmc2_report],
  };
}

function handleCostCache(cache: {
  fmc1cost?: number;
  fmc2cost?: number;
}): CalculatePackagingStandardCostReturnValue {
  if (cache.fmc1cost === undefined) {
    throw new CalculationError({
      region: 'EU',
      message: 'Cost was provided but fmc1cost was missing',
    });
  }

  if (cache.fmc2cost === undefined) {
    throw new CalculationError({
      region: 'US',
      message: 'Cost was provided but fmc2cost was missing',
    });
  }

  return {
    fmc1_cost: round2(cache.fmc1cost),
    fmc2_cost: round2(cache.fmc2cost),
    report: [
      getCostReportStatement({ region: 'EU', cost: cache.fmc1cost, cache: true }),
      getCostReportStatement({ region: 'US', cost: cache.fmc2cost, cache: true }),
    ],
  };
}
