import { CostComponentCost } from '../calculator.types';

export function calculate_CC_Others_sum(props: {
  cost: { fmc_eu_cost: number | undefined; fmc_us_cost: number | undefined };
}): CostComponentCost {
  return {
    fmc1cost: props.cost.fmc_eu_cost,
    fmc2cost: props.cost.fmc_us_cost,
  };
}
