import { CostComponentCost } from '../calculator.types';
import { Calculator } from '../calculator';
import {
  DBPackagingNonstandardCost,
  DBPackagingStandardCost,
} from '@core/schemas/db/schema.db.packaging';

export function calculate_CC_Packaging_sum(
  packagingStandardCosts: Pick<DBPackagingStandardCost, 'fmc1_cost' | 'fmc2_cost'>[],
  packagingNonstandardCosts: Pick<
    DBPackagingNonstandardCost,
    'fmc1_cost_plus_scrap' | 'fmc2_cost_plus_scrap'
  >[],
): CostComponentCost {
  // We assume that when looking at old, imported data, if it is the case that it has both standard and non-standard costs,
  // it will always be blank non-standard costs and therefore we always use the standard ones
  const isStandard = packagingStandardCosts?.length > 0;

  if (isStandard) {
    return {
      fmc1cost: Calculator.Common.SumListStrict(packagingStandardCosts, 'fmc1_cost'),
      fmc2cost: Calculator.Common.SumListStrict(packagingStandardCosts, 'fmc2_cost'),
    };
  } else {
    return {
      fmc1cost: Calculator.Common.SumListStrict(packagingNonstandardCosts, 'fmc1_cost_plus_scrap'),
      fmc2cost: Calculator.Common.SumListStrict(packagingNonstandardCosts, 'fmc2_cost_plus_scrap'),
    };
  }
}
