export function round5(number: number) {
  return Number(number.toFixed(5));
}

export function round2(number: number) {
  return Number(number.toFixed(2));
}

export function round2relaxed(number: number | undefined) {
  if (number === undefined) {
    return undefined;
  }

  return Number(number.toFixed(2));
}

export function calculator_doFilter_query<T>(
  items: T[] | undefined,
  query: Partial<T>,
): T[] | undefined {
  if (!items) {
    return undefined;
  }

  if (Object.keys(query).length === 0) {
    throw new Error('Filter should contain at least one field');
  }

  let resultingItems = items;

  resultingItems = items.filter((i) => {
    let hit = true;

    Object.entries(query).forEach(([k, v]) => {
      if (i[k as keyof typeof i] !== v) {
        hit = false;
      }
    });

    return hit;
  });

  return resultingItems;
}

export function calculator_doFilter_single<T>(item: T, query: Partial<T>): T | undefined {
  if (Object.keys(query).length === 0) {
    throw new Error('Filter should contain at least one field');
  }

  let resultingItem: T | undefined = item;

  Object.entries(query).forEach(([k, v]) => {
    if (item && item[k as keyof typeof item] !== v) {
      resultingItem = undefined;
    }
  });

  return resultingItem;
}
