import { REGEX_INTEGER } from '@core/types/types.regex';

export function convertProductIdTo8digits(productId: number | string | undefined) {
  const productIdAsString = `${productId}`;

  if (typeof productId === 'string' && !REGEX_INTEGER.test(productId)) {
    throw new Error(`Product ID '${productId}' was not an integer`);
  }

  if (productIdAsString.length > 8) {
    throw new Error(
      `Can't convert malformed product ID '${productId}'. Product ID must be at most 8 digits long`,
    );
  }

  if (productIdAsString.length === 8) {
    if (!productIdAsString.startsWith('5')) {
      throw new Error(
        `Can't convert malformed product ID '${productId}'. If product ID is 8 digits long it should start with '5'`,
      );
    }

    return Number(productIdAsString);
  }

  return Number(`5${productIdAsString.padStart(7, '0')}`);
}
