import { Calculator } from '@core/calculator/calculator';
import {
  DBPackagingNonstandardCost,
  DBPackagingStandardCost,
} from '@core/schemas/db/schema.db.packaging';

export function checkApprovePack(input: {
  packagingStandardCosts: Pick<DBPackagingStandardCost, 'fmc1_cost' | 'fmc2_cost'>[];
  packagingNonstandardCosts: Pick<
    DBPackagingNonstandardCost,
    'fmc1_cost_plus_scrap' | 'fmc2_cost_plus_scrap'
  >[];
}) {
  const { packagingNonstandardCosts, packagingStandardCosts } = input;

  const { fmc1cost, fmc2cost } = Calculator.Pack.CC_Packaging.Sum(
    packagingStandardCosts,
    packagingNonstandardCosts,
  );

  if (fmc1cost === 0) {
    throw new Error('Packaging costs must not be 0 (EU)');
  }

  if (fmc2cost === 0) {
    throw new Error('Packaging costs must not be 0 (US)');
  }
}
