import { DBPackingNonstandardCost } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { PackingNonstandardKey } from '@core/types/types.packaging';
import { getFreshId } from '@core/util/util.geFreshId';

export const PackingNonstandardCostExample: OmitDBRecord<DBPackingNonstandardCost>[] = [
  {
    product_id: 1,
    revision: 1,
    row_id: getFreshId(),
    packing_type: PackingNonstandardKey.BIPrepack,
    description: 'a',
    fmc1_number_of_operators: 1,
    fmc2_number_of_operators: 1,
    fmc1_machine_time: 1,
    fmc2_machine_time: 1,
    fmc1_quantity: 1,
    fmc2_quantity: 1,
    bun: 'a',
    fmc1_cost: 1.23,
    fmc2_cost: 1.23,
    currency: 'a',
  },
];
