import { IoMdInformationCircleOutline } from 'react-icons/io';
import { NCMessageModal } from './NCMessageModal';
import { useDisclosure } from '@lego/klik-ui';

interface InfoIconClickableProps {
  title?: string;
  description: string;
  noLeftMargin?: boolean;
  hide?: boolean;
}

export function InfoIconClickable(props: InfoIconClickableProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <NCMessageModal
        isOpen={isOpen}
        onClose={onClose}
        variant="info"
        header={props.title || 'Information'}
        body={props.description}
      />
      {!props.hide && (
        <IoMdInformationCircleOutline
          onClick={onOpen}
          style={{ marginLeft: props.noLeftMargin ? undefined : 6, fontSize: 18 }}
          className="clickable-icon"
        />
      )}
    </>
  );
}
