import { Icon, FileUploader, Text, Link, Button } from '@lego/klik-ui';
import { ArrowLongUp, CloudUpload, DeleteBold } from '@lego/klik-ui/icons';

import React, { useMemo, useState } from 'react';
import { showErrorToast, showSuccessToast } from '@frontend/common/lib/functions';

import { Table } from '@frontend/table/table';
import { getAttachmentsColumns } from '../lib/common.columns';
import { CircleSpinner } from './CircleSpinner';
import { NCConfirmModal } from './NCConfirmModal';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  AttachmentsDeleteEndpoint,
  AttachmentsListEndpoint,
  AttachmentsUploadEndpoint,
  AttachmentsUploadEndpointInput,
} from '@core/schemas/endpoint/schema.endpoint.attachment';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';

interface NCAttachmentsProps {
  productId: AttachmentsUploadEndpointInput['productId'];
  view: AttachmentsUploadEndpointInput['view'];
  readOnly: boolean;
}

export function NCAttachments(props: NCAttachmentsProps) {
  const {
    data: attachments,
    loading,
    queryFunction: fetchAttachmentsList,
  } = useEndpoint({
    endpoint: AttachmentsListEndpoint,
    input: { productId: props.productId, view: props.view },
    errorHandling: { header: 'Fetching attachments' },
  });
  const [isInDropArea, setIsInDropArea] = useState(false);
  const [filesToBeDeleted, setFilesToBeDeleted] = useState<string[]>([]);
  const [selected, setSelected] = useState<Exclude<typeof attachments, null>>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [pendingFileNames, setPendingFileNames] = useState<string[]>([]);

  function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(true);
  }

  function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(false);
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(true);
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsInDropArea(false);
    upload(e.dataTransfer.files);
  }

  async function upload(inputFiles: FileList) {
    const files = [...inputFiles].map((f) => f);
    const fileNames = [...inputFiles].map((f) => f.name);

    setPendingFileNames(fileNames);

    const results = await Promise.allSettled(
      [...inputFiles].map((f) =>
        callEndpoint({
          endpoint: AttachmentsUploadEndpoint,
          input: { productId: props.productId, view: props.view, fileName: f.name },
          errorHandling: { disable: true },
        }),
      ),
    );

    const uploadResults = await Promise.allSettled(
      results.map(async (result, i) => {
        if (result.status === 'rejected') {
          showErrorToast(
            'Uploading document',
            `Upload of '${fileNames[i]}' failed: ${result.reason}`,
          );
          return;
        }

        const [error, signedUrlResponse] = result.value;
        if (error || !signedUrlResponse) {
          throw new Error(error?.error);
        }

        const formData = new FormData();
        Object.entries(signedUrlResponse.fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append('file', files[i]);

        await fetch(signedUrlResponse.url, {
          body: formData,
          method: 'POST',
          mode: 'no-cors',
        });
      }),
    );

    uploadResults.forEach((r, i) => {
      if (r.status === 'rejected') {
        showErrorToast(
          'Upload failed',
          `File '${fileNames[i]}' could not be uploaded: ${r.reason}`,
        );
      }
    });

    const succesfullyUploaded = uploadResults.filter((r) => r.status === 'fulfilled').length;

    if (succesfullyUploaded > 0) {
      showSuccessToast(
        `Upload file${succesfullyUploaded === 1 ? '' : 's'}`,
        `Successfully uploaded ${succesfullyUploaded} file${succesfullyUploaded === 1 ? '' : 's'}`,
      );
    }

    // give s3 bucket to properly update its content
    setPendingFileNames([]);
    fetchAttachmentsList();
  }

  async function handleDeleteAttachments(fullFileNames: string[]) {
    const fileNames = fullFileNames.map((fullFileName) => fullFileName.split('/').pop() + '');

    setIsDeleting(true);
    const results = await Promise.allSettled(
      fileNames.map(
        async (fileName) =>
          await callEndpoint({
            endpoint: AttachmentsDeleteEndpoint,
            input: { fileName, productId: props.productId, view: props.view },
            errorHandling: { header: `Deleting attachment ${fileName}` },
          }),
      ),
    );

    results.forEach((r, i) => {
      if (r.status === 'rejected') {
        showErrorToast('Delete failed', `File '${fileNames[i]}' could not be deleted: ${r.reason}`);
      }
    });

    const succesfullyDeleted = results.filter((r) => r.status === 'fulfilled').length;

    if (succesfullyDeleted > 0) {
      showSuccessToast(
        `Delete file${succesfullyDeleted === 1 ? '' : 's'}`,
        `Successfully deleted ${succesfullyDeleted} file${succesfullyDeleted === 1 ? '' : 's'}`,
      );
    }

    setIsDeleting(false);
    setFilesToBeDeleted([]);
    fetchAttachmentsList();
  }

  const renderContent = () => {
    if (isInDropArea) {
      return (
        <React.Fragment>
          <Icon as={ArrowLongUp} />
          <Text fontSize="md">Drop to upload</Text>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Icon as={CloudUpload} />
        <Text fontSize="md">Drag and drop file(s)</Text>
        <Text fontSize="xs">
          or{' '}
          <Link isInline={true} size="xs">
            browse
          </Link>{' '}
          to choose file(s)
        </Text>
      </React.Fragment>
    );
  };

  const columns = useMemo(
    () => getAttachmentsColumns(props.productId, props.view),
    [props.productId, props.view],
  );

  return (
    <div>
      <NCConfirmModal
        header="Delete attachment"
        description={`Are you sure you want to delete ${filesToBeDeleted
          .map((name) => `'${name.split('/').pop()}'`)
          .join(', ')}?`}
        isOpen={filesToBeDeleted.length > 0}
        onCancel={() => setFilesToBeDeleted([])}
        onConfirm={() => handleDeleteAttachments(filesToBeDeleted)}
        confirmButtonProps={{ loading: isDeleting, colorScheme: 'error' }}
        variant="error"
      />
      {attachments && attachments.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <Table
            id="attachments"
            rows={attachments}
            rowKey="fullFileName"
            headerContent={
              <Button
                size="sm"
                colorScheme="error"
                leftIcon={<DeleteBold />}
                onClick={() => setFilesToBeDeleted(selected.map((f) => f.fullFileName))}
                variant="outline"
                disabled={selected.length === 0}
              >
                Delete
              </Button>
            }
            removeInfoText
            multiSelection={{ selected, setSelected }}
            removeSearch
            noDataText=""
            columns={columns}
            isRefreshing={loading}
          />
        </div>
      )}
      {(!attachments || attachments.length === 0) && props.readOnly && <div>No attachments</div>}
      {!props.readOnly && (
        <div style={{ display: 'grid', gridTemplateColumns: '400px auto', columnGap: 48 }}>
          <FileUploader
            isInDropArea={isInDropArea}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              accept="*"
              multiple={true}
              value=""
              onChange={({ target: { files } }) => {
                if (files === null) {
                  return;
                }
                upload(files);
              }}
              type="file"
            />
            {renderContent()}
          </FileUploader>
          <div
            style={{
              display: 'grid',
              gridAutoRows: 'min-content',
              gridTemplateColumns: 'min-content auto',
              columnGap: 8,
              rowGap: 8,
            }}
          >
            {pendingFileNames.map((fileName) => (
              <React.Fragment key={fileName}>
                <CircleSpinner />
                <div>{fileName}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
