import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { getErrorStatement, showErrorToast, showInfoToast } from '@frontend/common/lib/functions';
import { PackagingStandardCostsRow } from './packaging.types';
import { Calculator } from '@core/calculator/calculator';
import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { Region, RegionFMC } from '@core/schemas/schema.common';
import { Unpacked } from '@core/util/util.typing';
import { getErrorMessage } from '@core/util/util.getErrorMessage';
import { UsePackingStandardCostsRowsReturnValue } from './usePackingStandardCostsRows';
import { useMessages } from '@frontend/common/lib/hooks/useMessages';
import { Message } from '@frontend/common/lib/models';
import { PasteChanges } from '@frontend/table/lib';
import { DBPackagingRate, DBPackagingStandardCost } from '@core/schemas/db/schema.db.packaging';
import { PackSource, PackagingPackingKey } from '@core/types/types.packaging';
import { PACKAGING_KEY_TO_DESCRIPTION } from '@core/const/const.PACKAGING_KEY_TO_DESCRIPTION';
import { getFreshId } from '@core/util/util.geFreshId';

type FMCNumbers = {
  fmc1: number;
  fmc2: number;
};

export type UsePackagingStandardCostsReturnValue = {
  packagingStandardCostsRows: PackagingStandardCostsRow[];
  reset: () => void;
  reinitialize: (input: {
    filteredPackagingRates: DBPackagingRate[];
    packingRegionEU: Region;
    packingRegionUS: Region;
  }) => void;
  handlers: {
    changeDescription: (key: string, newDescription: string) => void;
    changeQuantity: (key: string, fmcRegion: RegionFMC, newQuantity: number) => void;
    changeCost: (key: string, fmcRegion: RegionFMC, newCost: number) => void;
    addRow: () => void;
    deleteRow: (key: string) => void;
    onPaste: (updates: PasteChanges<PackagingStandardCostsRow>) => void;
    importRows: (rows: DBPackagingStandardCost[]) => void;
  };
  totals: {
    cost: FMCNumbers;
    quantity: FMCNumbers;
  };
  messages: Message[];
};

export function usePackagingStandardCosts(props: {
  packagingData:
    | Pick<
        PackagingGetEndpointResponse,
        | 'packagingAddOnRates'
        | 'packagingScrapRates'
        | 'packagingFoilRates'
        | 'packagingStandardCosts'
        | 'packagingRates'
      >
    | undefined;
  packingStandardCostsRows: UsePackingStandardCostsRowsReturnValue['packingStandardCostsRows'];
  packingRegionEU: Region | undefined;
  packingRegionUS: Region | undefined;
  salesRegionEU: Region;
  salesRegionUS: Region;
  selectedSource: PackSource | undefined;
  selectedPackagingSize: number | undefined;
  selectedAddOns: {
    embossing: boolean;
    uvLacquer: boolean;
    windowCutout: boolean;
    hotfoil: boolean;
    partialUvLacquer: boolean;
    windowFoil: boolean;
  };
  modelBagChecked: boolean;
  productId: number | undefined;
  revision: number | undefined;
  fmcYear: number | undefined;
  readOnly?: boolean;
}): UsePackagingStandardCostsReturnValue {
  const {
    fmcYear,
    modelBagChecked,
    packagingData,
    packingRegionEU,
    packingRegionUS,
    selectedPackagingSize,
    salesRegionEU,
    salesRegionUS,
    selectedAddOns,
    selectedSource,
    packingStandardCostsRows,
    readOnly,
    productId,
    revision,
  } = props;

  const [quantities, setQuantities] = useState<Record<string, Record<RegionFMC, number>>>({});
  const [descriptions, setDescriptions] = useState<Record<string, string>>({});
  const [manualCosts, setManualCosts] = useState<Record<string, Record<RegionFMC, number>>>({});
  const [keys, setKeys] = useState<{ key: string; custom: boolean }[]>([]);
  const [
    packagingStandardCostsRowsWithoutDescription,
    setPackagingStandardCostsRowsWithoutDescription,
  ] = useState<Omit<PackagingStandardCostsRow, 'description'>[]>([]);

  const {
    addMessage: addLocalMessage,
    clearMessages: clearLocalMessages,
    messages: localMessages,
  } = useMessages();

  const {
    packagingScrapRates,
    packagingAddOnRates,
    packagingFoilRates,
    packagingStandardCosts,
    packagingRates,
  } = packagingData || {};

  const updateRowsFromCostInputs = useCallback(
    (
      costInputs: (Partial<
        Omit<OmitDBRecord<DBPackagingStandardCost>, 'product_id' | 'revision' | 'bun' | 'currency'>
      > & { packing_key: string; description: string })[],
    ) => {
      const preQuantities: typeof quantities = {};
      const preDescriptions: typeof descriptions = {};
      const preManualCosts: typeof manualCosts = {};

      costInputs.forEach((r) => {
        preQuantities[r.packing_key] = {
          FMC1: r.fmc1_quantity || 0,
          FMC2: r.fmc2_quantity || 0,
        };
        preDescriptions[r.packing_key] =
          r.description ||
          PACKAGING_KEY_TO_DESCRIPTION[r.packing_key as PackagingPackingKey] ||
          r.packing_key;

        if (r.custom) {
          preManualCosts[r.packing_key] = {
            FMC1: r.fmc1_cost || 0,
            FMC2: r.fmc2_cost || 0,
          };
        }
      });

      setQuantities(preQuantities);
      setDescriptions(preDescriptions);
      setManualCosts(preManualCosts);
      setKeys(
        costInputs.map((cost) => ({
          key: cost.packing_key,
          custom: !!cost.custom,
        })),
      );
    },
    [],
  );

  const reinitialize = useCallback(
    (input: {
      filteredPackagingRates: DBPackagingRate[];
      packingRegionEU: Region;
      packingRegionUS: Region;
    }) => {
      hasRunInitialSetup.current = true;

      const firstRate = input.filteredPackagingRates[0]; // We take a shortcut and use first rate to grab year, source and pack size

      if (!firstRate) {
        updateRowsFromCostInputs([]);
        return;
      }

      Calculator.Pack.Packaging.StandardCost.GetRows.Run({
        input: {
          year: firstRate.year,
          source: firstRate.source,
          packagingSize: firstRate.packaging_size,
          packingRegionEu: input.packingRegionEU,
          packingRegionUs: input.packingRegionUS,
          salesRegionEu: salesRegionEU,
          salesRegionUs: salesRegionUS,
        },
        cache: { packagingRates: input.filteredPackagingRates },
      }).then((inputs) =>
        updateRowsFromCostInputs(
          inputs.map((input) => ({
            packing_key: input.key,
            description: PACKAGING_KEY_TO_DESCRIPTION[input.key],
            fmc1_quantity: input.fmc1quantity,
            fmc2_quantity: input.fmc2quantity,
          })),
        ),
      );
    },
    [updateRowsFromCostInputs, salesRegionEU, salesRegionUS],
  );

  // first time hook
  const hasRunInitialSetup = useRef(false);
  useEffect(() => {
    if (hasRunInitialSetup.current) {
      return;
    }

    if (!packagingStandardCosts || !selectedPackagingSize || !selectedSource) {
      return;
    }

    if (packagingStandardCosts.length === 0) {
      hasRunInitialSetup.current = true;
    } else {
      updateRowsFromCostInputs(packagingStandardCosts);
      hasRunInitialSetup.current = true;
    }
  }, [
    updateRowsFromCostInputs,
    reinitialize,
    packagingStandardCosts,
    selectedPackagingSize,
    selectedSource,
  ]);

  useEffect(() => {
    if (
      !fmcYear ||
      !selectedSource ||
      !packingRegionEU ||
      !packingRegionUS ||
      !salesRegionEU ||
      !salesRegionUS ||
      !selectedPackagingSize ||
      !productId ||
      !revision
    ) {
      setPackagingStandardCostsRowsWithoutDescription([]);
      return;
    }

    (async () => {
      try {
        const packagingCostInputs = keys.map(({ key, custom }) => ({
          custom,
          fmc1quantity: quantities[key]?.FMC1,
          fmc2quantity: quantities[key]?.FMC2,
          fmc1manualCost: manualCosts[key]?.FMC1,
          fmc2manualCost: manualCosts[key]?.FMC2,
          key,
        }));

        const prePackagingStandardCostRows: typeof packagingStandardCostsRowsWithoutDescription =
          await Promise.all(
            packagingCostInputs.map(async (pci) => {
              const existingPackagingCost = packagingStandardCosts?.find(
                (pc) => pc.packing_key === pci.key,
              );
              try {
                const { fmc1_cost, fmc2_cost, report } =
                  await Calculator.Pack.Packaging.StandardCost.Calculate({
                    input: {
                      productId,
                      revision,
                      addOns: selectedAddOns,
                      modelBag: modelBagChecked,
                      packagingStandardCost: pci,
                      packingRegionEU,
                      packingRegionUS,
                      packagingSize: selectedPackagingSize,
                      salesRegionEU,
                      salesRegionUS,
                      source: selectedSource,
                      fmcYear: fmcYear,
                    },
                    cache: {
                      packagingAddOnRates,
                      packagingFoilRates,
                      packingStandardCosts: packingStandardCostsRows.map((frr) => ({
                        fmc1_quantity: frr.fmc1quantity,
                        fmc2_quantity: frr.fmc2quantity,
                        packing_key: frr.packingKey,
                      })),
                      packagingRates,
                      cost: {
                        use: readOnly,
                        values: {
                          fmc1cost: existingPackagingCost?.fmc1_cost,
                          fmc2cost: existingPackagingCost?.fmc2_cost,
                        },
                      },
                    },
                  });

                const row: Unpacked<typeof packagingStandardCostsRowsWithoutDescription> = {
                  key: pci.key,
                  fmc1quantity: quantities[pci.key]?.FMC1,
                  fmc2quantity: quantities[pci.key]?.FMC2,
                  fmc1cost: fmc1_cost,
                  fmc2cost: fmc2_cost,
                  currency: 'DKK',
                  custom: pci.custom,
                  fmc1manualCost: pci.fmc1manualCost,
                  fmc2manualCost: pci.fmc2manualCost,
                  report: pci.custom ? report.filter((r) => r.type === 'Cost') : report,
                };

                return row;
              } catch (error) {
                const errorStatement = getErrorStatement(error);

                const row: Unpacked<typeof packagingStandardCostsRowsWithoutDescription> = {
                  key: pci.key,
                  fmc1quantity: quantities[pci.key]?.FMC1,
                  fmc2quantity: quantities[pci.key]?.FMC2,
                  fmc1cost: 0,
                  fmc2cost: 0,
                  currency: 'DKK',
                  custom: pci.custom,
                  fmc1manualCost: pci.fmc1manualCost,
                  fmc2manualCost: pci.fmc2manualCost,
                  report: errorStatement ? [errorStatement] : [],
                };

                return row;
              }
            }),
          );

        prePackagingStandardCostRows.sort((a, b) => {
          if (a.custom && b.custom) {
            return 0;
          }

          return a.custom ? 1 : -1 - (b.custom ? 1 : -1);
        });

        setPackagingStandardCostsRowsWithoutDescription(prePackagingStandardCostRows);
      } catch (error) {
        showErrorToast('Setting up packaging costs', getErrorMessage(error));
      }
    })();
  }, [
    revision,
    productId,
    keys,
    quantities,
    manualCosts,
    packagingStandardCosts,
    packagingAddOnRates,
    fmcYear,
    packagingFoilRates,
    packagingRates,
    modelBagChecked,
    packingStandardCostsRows,
    packingRegionEU,
    packingRegionUS,
    salesRegionEU,
    salesRegionUS,
    readOnly,
    selectedPackagingSize,
    packagingScrapRates,
    selectedAddOns,
    selectedSource,
  ]);

  const packagingStandardCostsRows = useMemo(
    () =>
      packagingStandardCostsRowsWithoutDescription.map((r) => ({
        ...r,
        description: descriptions[r.key] || '',
      })),
    [packagingStandardCostsRowsWithoutDescription, descriptions],
  );

  const totalCost = useMemo(
    () => ({
      fmc1: Calculator.Common.SumListStrict(
        packagingStandardCostsRowsWithoutDescription,
        'fmc1cost',
      ),
      fmc2: Calculator.Common.SumListStrict(
        packagingStandardCostsRowsWithoutDescription,
        'fmc2cost',
      ),
    }),
    [packagingStandardCostsRowsWithoutDescription],
  );

  const totalQuantity = useMemo(
    () => ({
      fmc1:
        Calculator.Common.SumList(packagingStandardCostsRowsWithoutDescription, 'fmc1quantity') ||
        0,
      fmc2:
        Calculator.Common.SumList(packagingStandardCostsRowsWithoutDescription, 'fmc2quantity') ||
        0,
    }),
    [packagingStandardCostsRowsWithoutDescription],
  );

  function changeDescription(key: string, newDescription: string) {
    setDescriptions((curr) => ({ ...curr, [key]: newDescription }));
  }

  function changeQuantity(key: string, fmcRegion: RegionFMC, newQuantity: number) {
    setQuantities((curr) => ({ ...curr, [key]: { ...curr[key], [fmcRegion]: newQuantity } }));
  }

  function changeCost(key: string, fmcRegion: RegionFMC, newCost: number) {
    setManualCosts((curr) => ({ ...curr, [key]: { ...curr[key], [fmcRegion]: newCost } }));
  }

  function addRow() {
    const key = getFreshId();

    setKeys((curr) => curr.concat({ key, custom: true }));

    setDescriptions((curr) => ({ ...curr, [key]: '' }));
    setQuantities((curr) => ({ ...curr, [key]: { FMC1: 0, FMC2: 0 } }));
    setManualCosts((curr) => ({ ...curr, [key]: { FMC1: 0, FMC2: 0 } }));
  }

  function deleteRow(key: string) {
    setKeys((curr) => curr.filter((k) => k.key !== key));

    setDescriptions((curr) => {
      const newCurr = { ...curr };
      delete curr[key];
      return newCurr;
    });
    setQuantities((curr) => {
      const newCurr = { ...curr };
      delete curr[key];
      return newCurr;
    });
    setManualCosts((curr) => {
      const newCurr = { ...curr };
      delete curr[key];
      return newCurr;
    });
  }

  useEffect(() => {
    clearLocalMessages(/packaging-standard-cost.*/);

    packagingStandardCostsRowsWithoutDescription.forEach((psc) => {
      psc.report
        .filter((report) => report.isError)
        .forEach((report) => {
          addLocalMessage({
            id: `packaging-standard-cost_error_${psc.key}`,
            message: `${descriptions[psc.key]}: ${report.shortDescription}`,
          });
        });
    });
  }, [
    packagingStandardCostsRowsWithoutDescription,
    clearLocalMessages,
    addLocalMessage,
    descriptions,
  ]);

  function onPaste(updates: PasteChanges<PackagingStandardCostsRow>) {
    updates.forEach(({ row, changes }) => {
      if (typeof changes.description === 'string') changeDescription(row.key, changes.description);
      if (typeof changes.fmc1quantity === 'number')
        changeQuantity(row.key, 'FMC1', changes.fmc1quantity);
      if (typeof changes.fmc2quantity === 'number')
        changeQuantity(row.key, 'FMC2', changes.fmc2quantity);
      if (typeof changes.fmc1cost === 'number') changeCost(row.key, 'FMC1', changes.fmc1cost);
      if (typeof changes.fmc2cost === 'number') changeCost(row.key, 'FMC2', changes.fmc2cost);
    });
  }

  const reset = useCallback(() => {
    hasRunInitialSetup.current = false;
  }, []);

  function importRows(rows: DBPackagingStandardCost[]) {
    if (rows.length === 0) {
      showInfoToast('No packaging standard costs to import');
      return;
    }

    updateRowsFromCostInputs(rows);
  }

  return {
    packagingStandardCostsRows,
    reinitialize,
    reset,
    handlers: {
      changeDescription,
      changeQuantity,
      changeCost,
      addRow,
      deleteRow,
      onPaste,
      importRows,
    },
    totals: { cost: totalCost, quantity: totalQuantity },
    messages: localMessages,
  };
}
