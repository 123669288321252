import { Stack } from '@chakra-ui/layout';
import { Message } from '@frontend/common/lib/models';
import { InlineMessage } from './InlineMessage';
import { CSSProperties } from 'react';

interface MessageStackProps {
  messages: Message[];
  clearMessages?(id: string): void;
  style?: CSSProperties;
}

export function MessageStack(props: MessageStackProps) {
  return props.messages.length === 0 ? null : (
    <Stack style={{ marginBottom: 24, ...props.style }}>
      {props.messages.map((message) => (
        <InlineMessage
          key={message.id}
          variant={message.variant}
          message={message.message}
          moreInformation={message.moreInformation}
          onClose={
            !props.clearMessages
              ? undefined
              : () => {
                  if (!props.clearMessages) {
                    return;
                  }
                  props.clearMessages(message.id);
                }
          }
        />
      ))}
    </Stack>
  );
}
