import { UserDataContext } from '@frontend/common/lib/contexts';
import { Table } from '@frontend/table/table';
import { useContext, useMemo } from 'react';
import { getFinalProductCostColumns } from '../lib/finalCosts.columns';
import { useFinalCostsOverviewRows } from '../lib/useFinalCostsOverviewRows';

import { CostOverviewRow } from '@frontend/common/lib/models';
import { ExportButton } from '@frontend/common/components/ExportButton';
import { FinalCostsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.finalCosts';

interface FinalCostsOverviewProps {
  readOnly: boolean;
  elements: {
    data: FinalCostsGetEndpointResponse['elementsData'];
    scrapRatePercent: number | undefined;
  };
  bi: FinalCostsGetEndpointResponse['biData'];
  packaging: FinalCostsGetEndpointResponse['packagingData'];
  otherProductCosts: FinalCostsGetEndpointResponse['otherProductCosts'];
  productCosts: FinalCostsGetEndpointResponse['productCosts'];
  cc_FinishedGoodOverhead: CostOverviewRow;
  cc_Others: CostOverviewRow;
  cc_License: CostOverviewRow;
  latestApprovedRevisions: FinalCostsGetEndpointResponse['latestApprovedRevisions'];
}

export function FinalCostsOverview(props: FinalCostsOverviewProps) {
  const { userData } = useContext(UserDataContext);

  const { finalCostsRows } = useFinalCostsOverviewRows(props);

  const columns = useMemo(
    () => getFinalProductCostColumns(!!userData?.comma_as_decimal_seperator),
    [userData?.comma_as_decimal_seperator],
  );

  return (
    <Table
      id="final-costs_product-costs"
      rows={finalCostsRows}
      headerContent={<ExportButton rows={finalCostsRows} columns={columns} />}
      rowKey="costComp"
      removeInfoText
      removeSearch
      columns={columns}
    />
  );
}
