import { DBPackagingStandardCost } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';

export const PackagingStandardCostExample: OmitDBRecord<DBPackagingStandardCost>[] = [
  {
    product_id: 1,
    revision: 1,
    packing_key: 'a',
    description: 'a',
    fmc1_quantity: 1,
    fmc2_quantity: 1,
    fmc1_cost: 1.23,
    fmc2_cost: 1.23,
    currency: 'DKK',
    custom: false,
  },
];
