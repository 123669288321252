import { Calculator } from '@core/calculator/calculator';
import { TOTAL_KEY } from '@core/const/const.TOTAL_KEY';
import {
  DBPackagingNonstandardCost,
  DBPackagingStandardCost,
  DBPackingNonstandardCost,
  DBPackingStandardCost,
} from '@core/schemas/db/schema.db.packaging';
import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { CostComponent } from '@core/types/types.costComponent';

import { CostOverviewRow } from '@frontend/common/lib/models';
import { useMemo } from 'react';

export function usePackagingCostsOverviewRows(props: {
  productCosts?: PackagingGetEndpointResponse['productCosts'];
  packingStandardCosts?: Pick<DBPackingStandardCost, 'fmc1_cost' | 'fmc2_cost'>[];
  packingNonstandardCosts?: Pick<DBPackingNonstandardCost, 'fmc1_cost' | 'fmc2_cost'>[];
  packagingStandardCosts?: Pick<DBPackagingStandardCost, 'fmc1_cost' | 'fmc2_cost'>[];
  packagingNonstandardCosts?: Pick<
    DBPackagingNonstandardCost,
    'fmc1_cost_plus_scrap' | 'fmc2_cost_plus_scrap'
  >[];
  copyPpoToCost?: boolean;
}): {
  cc_Packing: CostOverviewRow;
  cc_Packaging: CostOverviewRow;
  total: CostOverviewRow;
} {
  const {
    packingStandardCosts,
    packagingStandardCosts,
    packagingNonstandardCosts,
    productCosts,
    packingNonstandardCosts,
    copyPpoToCost,
  } = props;

  const cc_Packing = useMemo(() => {
    const costPpoVariance = Calculator.Pack.CC_Packing.Full({
      packingStandardCosts: packingStandardCosts || [],
      packingNonstandardCosts: packingNonstandardCosts || [],
      ppos: productCosts?.ppo[CostComponent.Packing],
      copyPpoToCost: copyPpoToCost,
    });

    const r: CostOverviewRow = {
      costComp: CostComponent.Packing,
      description: 'Packing',
      currency: 'DKK',
      ...costPpoVariance,
    };
    return r;
  }, [packingStandardCosts, packingNonstandardCosts, productCosts, copyPpoToCost]);

  const cc_Packaging = useMemo(() => {
    const costPpoVariance = Calculator.Pack.CC_Packaging.Full({
      packagingStandardCosts: packagingStandardCosts || [],
      packagingNonstandardCosts: packagingNonstandardCosts || [],
      ppos: productCosts?.ppo[CostComponent.Packaging],
      copyPpoToCost: copyPpoToCost,
    });

    const r: CostOverviewRow = {
      costComp: CostComponent.Packaging,
      description: 'Packaging',
      currency: 'DKK',
      ...costPpoVariance,
    };

    return r;
  }, [packagingStandardCosts, packagingNonstandardCosts, productCosts, copyPpoToCost]);

  const total: CostOverviewRow = useMemo(() => {
    const allRows = [cc_Packing, cc_Packaging];

    const costPpoVariance = Calculator.Common.CC.Full(
      allRows,
      allRows.map((r) => ({ FMC1: r.fmc1ppo, FMC2: r.fmc2ppo })),
    );

    const row: CostOverviewRow = {
      costComp: TOTAL_KEY,
      description: 'Total',
      currency: 'DKK',
      ...costPpoVariance,
    };

    return row;
  }, [cc_Packing, cc_Packaging]);

  return { cc_Packing: cc_Packing, cc_Packaging: cc_Packaging, total };
}
