export function checkApproveFinalCost(input: {
  overheadType: number | undefined;
  scrapType: number | undefined;
}) {
  const { overheadType, scrapType } = input;

  if (!overheadType) {
    throw new Error('Overhead type must be specified');
  }

  if (!scrapType) {
    throw new Error('Scrap type must be specified');
  }
}
