import { Button, Stack, useDisclosure } from '@lego/klik-ui';
import { SeoSearch } from '@lego/klik-ui/icons';
import { createRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ProductIndexContext } from '@frontend/common/lib/contexts';
import { showErrorToast, showInternalApplicationError } from '@frontend/common/lib/functions';
import { FInput } from '@frontend/form/components/FInput';
import { FSelect } from '@frontend/form/components/FSelect';
import { NCModal } from '@frontend/common/components/NCModal';
import { ProductOverviewModalContent } from './ProductOverviewModalContent';
import { REGEX_PRODUCT_ID_OR_EMPTY } from '@core/types/types.regex';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { ProdHeadCore } from '@core/schemas/db/schema.db.prod';
import { HighestRevisionEndpoint } from '@core/schemas/endpoint/schema.endpoint.common';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';
import { safe } from '@core/util/util.safe';
import { MaraGetEndpoint } from '@core/schemas/endpoint/schema.endpoint.mara';
import { MaterialType } from '@core/types/types.mara';

const NOT_FOUND_TEXT = 'Product was not found';

interface SelectProductIndexPageProps {
  title: string;
  onCreate?(productId: number): Promise<boolean>;
  pk?: ProdHeadCore['PK'];
}

export function SelectProductIndexPage(props: SelectProductIndexPageProps) {
  const [productId, setProductId] = useState<number>();
  const [revision, setRevision] = useState<number>();
  const [fetchedProductDescription, setFetchedProductDescription] = useState('');
  const [maxRevision, setMaxRevision] = useState<number>();
  const [isFetchingMaxRevision, setIsFetchingMaxRevision] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const { suggestedProductId, setSuggestedProductId, setProductIndex, addSuggestion, suggestions } =
    useContext(ProductIndexContext);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const productFound = useMemo(
    () => fetchedProductDescription && fetchedProductDescription !== NOT_FOUND_TEXT,
    [fetchedProductDescription],
  );
  const canCreateFirstRevision = useMemo(
    () => props.pk && productFound && !isFetchingMaxRevision && !maxRevision,
    [maxRevision, isFetchingMaxRevision, productFound, props.pk],
  );

  const fetchHighestRevision = useCallback(
    async (productId: number) => {
      if (!props.pk) {
        return;
      }

      const [err, formattedProductId] = safe(() => convertProductIdTo8digits(productId));

      if (err) {
        showErrorToast(productId + ' is not a valid product ID');
        return;
      }

      const [, data] = await callEndpoint({
        endpoint: HighestRevisionEndpoint,
        input: { pk: props.pk, productId: formattedProductId },
        errorHandling: { header: 'Fetching highest revision number' },
      });

      return data?.highestRevision;
    },
    [props.pk],
  );

  const updateMaxRevision = useCallback(
    (productId: number) => {
      setIsFetchingMaxRevision(true);
      fetchHighestRevision(productId)
        .then((maxRevision) => {
          setMaxRevision(maxRevision || undefined);
          setRevision(maxRevision || undefined);
        })
        .finally(() => setIsFetchingMaxRevision(false));
    },
    [fetchHighestRevision],
  );

  const fetchProductDescription = useCallback(async (pid: number) => {
    setFetchedProductDescription('');
    setMaxRevision(undefined);
    setRevision(undefined);
    const [err, product, response] = await callEndpoint({
      endpoint: MaraGetEndpoint,
      input: { materialId: pid, type: MaterialType.Product },
      errorHandling: { disable: true },
    });
    if (response?.status === 404) {
      setFetchedProductDescription(NOT_FOUND_TEXT);
      return;
    }
    if (err) {
      showErrorToast('Fetching product information', err.error);
      return;
    }

    if (product) {
      setFetchedProductDescription(product.description || 'No product description');
    }
  }, []);

  const onProductIdSubmit = useCallback(
    (inputPid: string | number) => {
      if (inputPid === '') {
        return;
      }

      const [err, pid] = safe(() => convertProductIdTo8digits(inputPid));

      if (err) {
        showErrorToast(inputPid + ' is not a valid product ID');
        return;
      }

      fetchProductDescription(Number(pid));
      updateMaxRevision(Number(pid));
    },
    [updateMaxRevision, fetchProductDescription],
  );

  useEffect(() => {
    if (suggestedProductId) {
      setProductId(suggestedProductId);
      onProductIdSubmit(suggestedProductId);
      setSuggestedProductId(undefined);
    }
  }, [suggestedProductId, onProductIdSubmit, setSuggestedProductId]);

  async function onOk() {
    if (productId === undefined) {
      showInternalApplicationError(
        'productId was undefined when pressing OK in SelectProductIndexPage',
      );
      return;
    }

    addSuggestion(`${productId}`);

    if (canCreateFirstRevision && props.onCreate) {
      setIsCreating(true);

      const ok = await props.onCreate(productId);
      setIsCreating(false);

      if (!ok) {
        return;
      }
    }

    if (!canCreateFirstRevision && !maxRevision) {
      showInternalApplicationError(
        "No max revision could be fetched and 'canCreateFirstRevision' was false",
      );
      return;
    }

    setProductIndex(
      productId,
      canCreateFirstRevision ? 'new' : revision === undefined ? maxRevision : revision,
    );
  }

  const divRef = createRef<HTMLDivElement>();

  const buttonDisabled = useMemo(
    () => !productFound || (!canCreateFirstRevision && maxRevision === undefined),
    [productFound, canCreateFirstRevision, maxRevision],
  );

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', outline: 'none' }}
      tabIndex={1}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !buttonDisabled) {
          onOk();
        }
      }}
      ref={divRef}
    >
      <NCModal header="Product overview" isOpen={isOpen} onClose={onClose} width={1000}>
        <ProductOverviewModalContent
          onProductSelect={(productId, description) => {
            onClose();
            setProductId(productId);
            setFetchedProductDescription(description || 'No product description');
            updateMaxRevision(productId);
          }}
        />
      </NCModal>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ margin: '12px 0 48px', fontSize: 30, fontWeight: 'bold' }}>{props.title}</div>
        <Stack spacing="16px">
          <div style={{ display: 'flex', columnGap: 8, alignItems: 'end' }}>
            <FInput
              suggestions={suggestions}
              topLabel="Product ID"
              required
              editable
              align="start"
              value={productId}
              regex={REGEX_PRODUCT_ID_OR_EMPTY}
              onChange={(v) => {
                setProductId(!v ? undefined : Number(v));
                setFetchedProductDescription('');
                setRevision(undefined);
                setMaxRevision(undefined);
              }}
              onSubmit={onProductIdSubmit}
              onEnter={() => {
                divRef.current?.focus();
              }}
              isInvalid={fetchedProductDescription === NOT_FOUND_TEXT}
              size="md"
              disallowChangeOnInvalid
              style={{ width: 192 }}
            />
            <Button
              rightIcon={<SeoSearch />}
              variant="outline"
              className="icon-button"
              onClick={onOpen}
            />
          </div>
          <div
            style={{
              height: 20,
              lineHeight: 'normal',
              marginTop: 10,
              color: '#767674',
              width: 192,
              whiteSpace: 'nowrap',
              fontSize: 14,
            }}
          >
            {fetchedProductDescription}
          </div>
          {props.pk && (
            <FSelect
              topLabel="Revision"
              editable
              loading={isFetchingMaxRevision}
              disabled={!productFound}
              placeholder={productFound && maxRevision === undefined ? 'No revisions' : undefined}
              align="start"
              value={revision ? `${revision}` : ''}
              onChange={(v) => setRevision(Number(v))}
              size="md"
              options={
                !maxRevision
                  ? []
                  : Array.from(new Array(maxRevision)).map((_, i) => ({
                      text: i + 1 + (maxRevision === i + 1 ? ' (latest)' : ''),
                      value: `${i + 1}`,
                    }))
              }
              style={{ width: 192 }}
            />
          )}
        </Stack>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 36 }}>
          <Button disabled={buttonDisabled || isCreating} onClick={onOk} isLoading={isCreating}>
            {canCreateFirstRevision ? 'Create first revision' : 'OK'}
          </Button>
        </div>
      </div>
    </div>
  );
}
