import { Calculator } from '../calculator';

export function calculate_nullable_sum_list<T>(
  objects: T[] | undefined,
  key: keyof T,
): number | undefined {
  const result = Calculator.Common.SumListPrimitive(objects?.map((o) => o[key] as number));

  if (result === undefined) {
    return undefined;
  }

  return result;
}
