import { Button, Spinner, useDisclosure } from '@lego/klik-ui';
import { DeleteBold, EditBold, PlusBold, Refresh } from '@lego/klik-ui/icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NCConfirmModal } from '@frontend/common/components/NCConfirmModal';
import { NCModal } from '@frontend/common/components/NCModal';
import { FSelect } from '@frontend/form/components/FSelect';
import { closeToast, showErrorToast, showSuccessToast } from '@frontend/common/lib/functions';
import { Table, TableColumn } from '@frontend/table/table';
import { bold, getColumnFormatters } from '../dataMaintenance.util';
import { EditItemModelContent } from './EditItemModalContent';
import { ExcelSheetUploader } from './ExcelSheetUploader';
import { skToPrettyKey } from '@frontend/common/lib/common.util';
import { PK } from '@core/types/types.pk';
import { callElasticSearchEndpoint } from '@frontend/common/lib/callElasticSearchEndpoint';
import writeXlsxFile from 'write-excel-file';
import {
  DataMaintenanceDeleteEndpoint,
  DataMaintenanceGetCountEndpoint,
  DataMaintenanceGetDataAdminConfigEndpoint,
  DataMaintenanceGetEndpointResponse,
  DataMaintenanceGetExampleExcelEndpoint,
  DataMaintenanceQuery,
} from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { PK_TO_SCHEMA } from '@core/schemas/db/schema.db.lib';
import {
  getDescription,
  getSchemaProperties,
  getSchemaProperty,
} from '@core/util/util.schemaPropDescription';
import { OMIT_DB_RECORD } from '@core/schemas/const/schema.const.OMIT_DB_RECORD';
import { DBRecord } from '@core/schemas/db/schema.db.common';
import { ExportButton } from '@frontend/common/components/ExportButton';
import { FilterPanel } from './FilterPanel';
import { GREEN_BUTTON_STYLES } from '@frontend/common/lib/common.styles';
import { EXAMPLE } from '@core/examples/examples.get';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import { Filter } from '../dataMaintenance.types';
import { FilterType } from '@core/types/types.es';
import { convertToXlsxDataFormat } from '@core/util/util.convertToXlsxFormat';
import { PK_TO_PRETTY_NAME } from '@core/const/const.PK_TO_PRETTY_NAME';

export function DataMaintenance() {
  const [selectedPk, setSelectedPk] = useState<PK | ''>('');
  const [loading, setLoading] = useState(false);
  const [fetchedItems, setFetchedItems] = useState<DataMaintenanceGetEndpointResponse['items']>([]);
  const [selectedRow, setSelectedRow] = useState<{ SK: string }>();
  const [fetchedItemCount, setFetchedItemCount] = useState(0);
  const { isOpen: isDeleteOpen, onClose: onDeleteClose, onOpen: onDeleteOpen } = useDisclosure();
  const [editItemModalMode, setEditItemModalMode] = useState<'' | 'edit' | 'create'>('');
  const [filters, setFilters] = useState<DataMaintenanceQuery>({});
  const { data: permissions, loading: fetchingPermissions } = useEndpoint({
    endpoint: DataMaintenanceGetDataAdminConfigEndpoint,
    input: null,
    errorHandling: { header: 'Fetching data admin permissions' },
  });
  const [items, setItems] = useState<DBRecord[]>([]);
  const {
    data: count,
    loading: countLoading,
    queryFunction: fetchCount,
  } = useEndpoint({
    endpoint: DataMaintenanceGetCountEndpoint,
    input: { pk: selectedPk as PK },
    condition: !!selectedPk,
  });

  const abortController = useRef<AbortController | undefined>();

  const fetchItems = useCallback(
    async (pk: PK) => {
      setFetchedItemCount(0);
      if (abortController.current) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      if (!pk) {
        return;
      }

      setLoading(true);
      closeToast('dm-fetch-items');
      const [err, records] = await callElasticSearchEndpoint(
        { pk, query: filters },
        { onNewBatchFetched: setFetchedItemCount, abortController: abortController.current },
      );

      if (!err && !records) {
        // request was aborted
        return;
      }

      setLoading(false);

      if (err) {
        setItems([]);
        showErrorToast(`Fetching items for PK ${selectedPk}`, err?.error, {
          id: 'dm-fetch-items',
          requestId: err.requestId,
        });
        return;
      }

      setFetchedItems(records);
    },
    [filters, selectedPk],
  );

  useEffect(() => {
    setItems(fetchedItems);
  }, [fetchedItems]);

  const schema = useMemo(
    () => (!selectedPk ? undefined : PK_TO_SCHEMA[selectedPk].omit(OMIT_DB_RECORD)),
    [selectedPk],
  );

  useEffect(() => {
    if (!schema) {
      return;
    }

    const keys = getSchemaProperties(schema).filter(({ isDbRecordProp }) => !isDbRecordProp);
    const preValues: Record<string, Filter> = {};
    keys.forEach(
      ({ key, inputType }) =>
        (preValues[key] = {
          type: FilterType.Exact,
          value: '',
          isNumberType: inputType === 'ZodNumber',
        }),
    );
    setFilters(preValues);
  }, [schema]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: TableColumn<any>[] = useMemo(() => {
    if (!selectedPk) {
      return [];
    }

    const schema = PK_TO_SCHEMA[selectedPk];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cols = Object.keys(schema.omit(OMIT_DB_RECORD).shape).map<TableColumn<any>>((key) => {
      const injectedFormatting =
        getColumnFormatters[selectedPk]?.[key] || getColumnFormatters.ALL?.[key];

      return {
        dataIndex: key,
        title: key,
        render: injectedFormatting?.render,
        format: injectedFormatting?.format,
        style: (v, r, i, rs) => ({
          ...(injectedFormatting?.style ? injectedFormatting?.style(v, r, i, rs) : {}),
          fontWeight: getDescription(schema, key).isPartOfKey ? 'bold' : undefined,
        }),
        headerRender: getDescription(schema, key).isPartOfKey ? bold : undefined,
        align: getSchemaProperty(schema, key)?.inputType === 'ZodNumber' ? 'end' : undefined,
      };
    });

    const c = cols.at(-1);
    if (c) {
      c.width = 'auto';
    }

    return cols;
  }, [selectedPk]);

  async function deleteItem(sk: string) {
    if (!selectedPk) {
      return;
    }

    const [err] = await callEndpoint({
      endpoint: DataMaintenanceDeleteEndpoint,
      input: { pk: selectedPk, sk },
    });

    if (err) {
      throw new Error(err.error);
    }
  }

  function updateFilter(key: string, filter: Omit<Filter, 'isNumberType'>) {
    setFilters((curr) => {
      const newValues = structuredClone(curr);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newValues[key] = { ...filter, isNumberType: curr[key].isNumberType };
      return newValues;
    });
  }

  function mimicDelete(sk: string) {
    setItems((curr) => curr.filter((r) => r.SK !== sk));
  }

  function mimicChange(updatedItem: DBRecord) {
    setItems((curr) => curr.map((r) => (r.SK === updatedItem.SK ? updatedItem : r)));
  }

  return (
    <div style={{ position: 'relative' }}>
      {loading && <Spinner style={{ position: 'absolute', right: 0, top: -12 }} />}
      {selectedRow && (
        <NCConfirmModal
          header="Delete item"
          isOpen={isDeleteOpen}
          variant="error"
          onCancel={() => {
            onDeleteClose();
          }}
          confirmButtonProps={{ colorScheme: 'error' }}
          onConfirm={async () => {
            if (!selectedPk) {
              return;
            }

            try {
              await deleteItem(selectedRow.SK);
              mimicDelete(selectedRow.SK);
              onDeleteClose();
              showSuccessToast('Item successfully deleted');
            } catch (e) {
              showErrorToast('Delete item', (e as Error).message);
            }
          }}
          description={`Are you sure you want to delete item with key: ${skToPrettyKey(
            selectedRow.SK,
          )}`}
        />
      )}
      {selectedRow && selectedPk && editItemModalMode && (
        <NCModal
          header={editItemModalMode === 'create' ? 'Add item' : 'Edit item'}
          isOpen={!!editItemModalMode}
          onClose={() => setEditItemModalMode('')}
          width={600}
        >
          <EditItemModelContent
            item={selectedRow}
            mode={editItemModalMode}
            selectedPk={selectedPk}
            onSuccess={(item) => {
              setEditItemModalMode('');

              if (editItemModalMode === 'edit') {
                setSelectedRow(item as DBRecord);
                mimicChange(item);
              }

              showSuccessToast(
                `Item successfully ${editItemModalMode === 'create' ? 'created' : 'updated'}`,
              );
            }}
          />
        </NCModal>
      )}

      <div style={{ marginBottom: 24 }}>
        <FSelect
          topLabel="Item type:"
          placeholder={fetchingPermissions ? 'Loading...' : 'Select item type'}
          options={
            !permissions
              ? []
              : [
                  ...Object.entries(PK_TO_PRETTY_NAME)
                    .filter(([pk]) => permissions[pk as PK])
                    .map(([pk, description]) => ({
                      value: pk,
                      text: description + (!permissions[pk as PK]?.write ? ' (Read only)' : ''),
                    }))
                    .sort(({ text: textA }, { text: textB }) => {
                      if (textA.includes('(Read only)') && !textB.includes('(Read only)')) {
                        return 1;
                      } else if (!textA.includes('(Read only)') && textB.includes('(Read only)')) {
                        return -1;
                      }

                      return textA.localeCompare(textB);
                    }),
                ]
          }
          labelStyle={{ fontSize: 18, fontWeight: 'bold' }}
          editable
          value={selectedPk}
          onChange={(v) => {
            setItems([]);
            setFetchedItemCount(0);
            setSelectedPk(v as typeof selectedPk);
          }}
          style={{ width: 500 }}
        />
      </div>

      {schema && selectedPk && (
        <div>
          {selectedPk && (
            <div style={{ marginBottom: 16, display: 'flex' }}>
              {countLoading
                ? 'Fetching item count...'
                : `${count?.count.toLocaleString('en-US')} item${
                    count?.count === 1 ? '' : 's'
                  }`}{' '}
              {!countLoading && (
                <div>
                  <Refresh
                    style={{ marginLeft: 8, fontSize: 18, cursor: 'pointer' }}
                    onClick={fetchCount}
                  />
                </div>
              )}
            </div>
          )}
          <FilterPanel
            onFetch={() => {
              fetchItems(selectedPk);
              setSelectedRow(undefined);
            }}
            filters={filters}
            selectedPk={selectedPk}
            updateFilter={updateFilter}
          />
          <div
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              visibility: loading ? undefined : 'hidden',
              marginBottom: 16,
            }}
          >
            Fetching items... Current item count: {fetchedItemCount}
          </div>
          <Table
            id="_"
            disabledColumnFiltering
            rows={items || []}
            rowKey="SK"
            onRefresh={() => fetchItems(selectedPk)}
            headerContent={
              <div style={{ display: 'flex', columnGap: 12 }}>
                <Button
                  leftIcon={<PlusBold />}
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    setEditItemModalMode('create');
                    setSelectedRow({ ...EXAMPLE[selectedPk][0], SK: '' });
                  }}
                  style={GREEN_BUTTON_STYLES}
                >
                  Add
                </Button>
                <Button
                  leftIcon={<EditBold />}
                  disabled={!selectedRow}
                  size="sm"
                  variant="outline"
                  onClick={() => setEditItemModalMode('edit')}
                >
                  Edit
                </Button>
                <Button
                  leftIcon={<DeleteBold />}
                  disabled={!selectedRow}
                  size="sm"
                  variant="outline"
                  colorScheme="error"
                  onClick={onDeleteOpen}
                >
                  Delete
                </Button>
                <ExportButton rows={items} columns={columns} />
              </div>
            }
            onRowClick={setSelectedRow}
            selectedRow={selectedRow}
            isRefreshing={loading}
            columns={columns}
            itemsPerPage={15}
            fixedHeight={430}
          />
        </div>
      )}
      {selectedPk && (
        <>
          <div style={{ marginTop: 24 }}>
            <ExcelSheetUploader
              selectedPk={selectedPk}
              onSuccess={() => {
                showSuccessToast('Items successfully uploaded');
                fetchItems(selectedPk);
              }}
            />
          </div>
          <Button
            onClick={async () => {
              const [, exampleRows] = await callEndpoint({
                endpoint: DataMaintenanceGetExampleExcelEndpoint,
                input: { pk: selectedPk },
                errorHandling: { header: 'Fetch .xlsx example' },
              });

              if (exampleRows) {
                await writeXlsxFile(convertToXlsxDataFormat(exampleRows, columns), {
                  fileName: `${selectedPk}.xlsx`,
                });
              }
            }}
            variant="ghost"
            style={{ marginTop: 24 }}
          >
            Download example .xlsx file
          </Button>
        </>
      )}
    </div>
  );
}
