import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { LIGHT_GRAY } from './lib';
import { SortInfo, TableColumn } from './table';

interface ColumnHeaderProps<T extends object> {
  column: TableColumn<T>;
  sortInfo?: SortInfo;
  sticky?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  onMetaClick?(): void;
  setSortInfo?(sortInfo: SortInfo): void;
}

export function ColumnHeader<T extends object>(props: ColumnHeaderProps<T>) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'end',
        lineHeight: 'normal',
        padding: '6px 12px',
        height: '100%',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        boxSizing: 'border-box',
        cursor: props.setSortInfo ? 'pointer' : undefined,
        borderBottom: `2px solid ${LIGHT_GRAY}`,
        borderLeft: props.borderLeft ? '1px solid #e3e3e1' : undefined,
        borderRight: props.borderRight ? '1px solid #e3e3e1' : undefined,
        borderTop: props.sticky ? `1px solid ${LIGHT_GRAY}` : undefined,
        ...(props.sticky ? { position: 'sticky', background: 'white', top: '0px', zIndex: 1 } : {}),
      }}
      className="table-columnheader"
      onClick={(e) => {
        if (e.metaKey && props.onMetaClick) {
          props.onMetaClick();
          return;
        }

        if (props.setSortInfo) {
          if (props.sortInfo?.col !== props.column.title) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            props.setSortInfo!({
              col: props.column.title,
              direction: 'ascending',
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            props.setSortInfo!({
              col: props.column.title,
              direction: props.sortInfo.direction === 'ascending' ? 'descending' : 'ascending',
            });
          }
        }
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          marginRight: '6px',
          userSelect: 'none',
          justifyContent: props.column.titleAlign,
          marginTop: 1,
        }}
        title={props.column.title}
      >
        {props.column.title !== '__actions__'
          ? props.column.headerRender
            ? props.column.headerRender(props.column.title)
            : props.column.title
          : ''}
      </div>
      {props.setSortInfo && (
        <div
          style={{
            cursor: 'pointer',
            alignSelf: 'center',
          }}
        >
          {props.sortInfo?.col !== props.column.title ? null : props.sortInfo.direction ===
            'ascending' ? (
            <AiOutlineCaretUp />
          ) : (
            <AiOutlineCaretDown />
          )}
        </div>
      )}
    </div>
  );
}
