import { LEGOLogo, Text, Navbar, Spacer } from '@lego/klik-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { LF_LAST_VISITED, pageRoutes } from '@frontend/common/lib/definitions';
import { ProfileItem } from './ProfileItem';
import * as localforage from 'localforage';
import { isPathAccesible, isProd } from '../lib/functions';
import { useContext } from 'react';
import { UserDataContext } from '../lib/contexts';

export function NavigationBar() {
  const { virtualGroups } = useContext(UserDataContext);

  const location = useLocation();
  const navigate = useNavigate();

  function doNavigate(to: string) {
    navigate(to);
    localforage.setItem(LF_LAST_VISITED, to);
  }

  function convertName(name: string) {
    if (name === 'Product administration') {
      return 'Administration';
    }

    return name;
  }

  return (
    <Navbar>
      <Navbar.Brand onClick={() => doNavigate(pageRoutes.Elements)}>
        <LEGOLogo mr="24px" />
        <Text>
          Novelty Costing{' '}
          {!isProd() ? `(${STAGE}${REFERENCE_NUMBER === 0 ? '' : `, ${REFERENCE_NUMBER}`})` : ''}
        </Text>
      </Navbar.Brand>
      <Navbar.Segment display={{ base: 'none', lg: 'flex' }}>
        {Object.entries(pageRoutes)
          .filter(([name]) => name !== 'Administration')
          .filter(([, path]) => typeof path !== 'string' || isPathAccesible(path, virtualGroups))
          .map(([name, path]) =>
            typeof path === 'string' ? (
              <Navbar.Item
                key={path}
                isActive={location.pathname === path}
                onClick={() => doNavigate(path)}
              >
                {convertName(name)}
              </Navbar.Item>
            ) : virtualGroups.length === 0 ? null : (
              <Navbar.Item
                key={name}
                hasMenu={true}
                isActive={Object.values(path).includes(location.pathname)}
                icon={MdKeyboardArrowDown}
                title={name}
              >
                {Object.entries(path)
                  .filter(([, path]) => isPathAccesible(path, virtualGroups))
                  .map(([name, path]) => (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Navbar.Option
                      key={path}
                      isOpen={location.pathname === path}
                      onClick={() => doNavigate(path)}
                    >
                      {name}
                    </Navbar.Option>
                  ))}
              </Navbar.Item>
            ),
          )}
      </Navbar.Segment>
      <Spacer />
      <Navbar.Segment display={{ base: 'none', md: 'flex' }}>
        <ProfileItem />
      </Navbar.Segment>
      <Navbar.Burger display={{ base: 'block', lg: 'none' }} />
    </Navbar>
  );
}
