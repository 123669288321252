import { z } from 'zod';
import { defineEndpoint } from '@core/util/util.defineEndpoint';
import { DBUserSettingsSchema } from '@core/schemas/db/schema.db.userSettings';
import { VirtualGroupName } from '@core/const/const.VIRTUAL_GROUPS';

/**
 * UserMeEndpoint
 */
export type UserMeEndpointInput = z.infer<typeof UserMeEndpoint.input>;
export type UserMeEndpointResponse = z.infer<typeof UserMeEndpoint.response>;
export const UserMeEndpoint = defineEndpoint({
  path: '/user/me',
  input: z.object({}),
  response: DBUserSettingsSchema.merge(
    z.object({ virtualGroups: z.array(z.nativeEnum(VirtualGroupName)) }),
  ),
});

/**
 * UserSettingsSetEndpoint
 */
export type UserSettingsSetEndpointInput = z.infer<typeof UserSettingsSetEndpoint.input>;
export type UserSettingsSetEndpointResponse = z.infer<typeof UserSettingsSetEndpoint.response>;
export const UserSettingsSetEndpoint = defineEndpoint({
  path: '/user-settings/set',
  mutation: true,
  input: z.object({ commaAsDecimalSeperator: z.boolean() }),
  response: z.object({ ok: z.literal(true) }),
});

/**
 * EmailNameMapEndpoint
 */
export type EmailNameMapEndpointInput = z.infer<typeof EmailNameMapEndpoint.input>;
export type EmailNameMapEndpointResponse = z.infer<typeof EmailNameMapEndpoint.response>;
export const EmailNameMapEndpoint = defineEndpoint({
  path: '/email-name-map/get',
  input: z.object({}),
  response: z.object({ map: z.record(z.string(), z.string()) }),
});
