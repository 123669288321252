export enum PK {
  AMLog = 'AMLOG',
  AMMembership = 'AMMEMBERSHIP',
  BICost = 'BICOST',
  BIRate = 'BIRATE',
  BOM = 'BOM',
  Config = 'CONFIG',
  ElemCost = 'ELEMCOST',
  ElemError = 'ELEMERROR',
  ElementsScrapRate = 'ELEMENTSSCRAPRATE',
  HiddenColumn = 'HIDDENCOLUMN',
  MARA = 'MARA',
  OHRate = 'OHRATE',
  PackSource = 'PACKSOURCE',
  PackingNonstandardCost = 'PACKINGNONSTANDARDCOST',
  PackingNonstandardKey = 'PACKINGNONSTANDARDKEY',
  PackingNonstandardRate = 'PACKINGNONSTANDARDRATE',
  PackingStandardPrepackRate = 'PACKINGSTANDARDPREPACKRATE',
  PackingStandardCost = 'PACKINGSTANDARDCOST',
  PackingStandardFinalPackRate = 'PACKINGSTANDARDFINALPACKRATE',
  PackagingAddOnRate = 'PACKAGINGADDONRATE',
  PackagingStandardCost = 'PACKAGINGSTANDARDCOST',
  PackagingNonstandardCost = 'PACKAGINGNONSTANDARDCOST',
  PackagingFoilRate = 'PACKAGINGFOILRATE',
  PackagingRate = 'PACKAGINGRATE',
  PackagingScrapRate = 'PACKAGINGSCRAPRATE',
  ProdCost = 'PRODCOST',
  ProdHeadBI = 'PRODHEAD_BI',
  ProdHeadElem = 'PRODHEAD_ELEM',
  ProdHeadFC = 'PRODHEAD_FC',
  ProdHeadPack = 'PRODHEAD_PACK',
  ProdParams = 'PRODPARAMS',
  ProdPPO = 'PRODPPO',
  UserSettings = 'USERSETTINGS',
}
