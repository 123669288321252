import { DBProdParams } from '@core/schemas/db/schema.db.prod';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { now } from '@core/util/util.timestamp';

export const ProdParamsExample: OmitDBRecord<DBProdParams>[] = [
  {
    product_id: 123123,
    scrap_type: 1,
    overhead_type: undefined,
    bi_qr_code: true,
    created_timestamp: now(),
    created_by: 'casper.aagaard.laursen@lego.com',
    changed_timestamp: now(),
    changed_by: '',
  },
];
