import { PackingNonstandardKey } from '@core/types/types.packaging';

export function getPackingNonstandardKeyDescription(
  packingNonstandardKey: PackingNonstandardKey | '',
) {
  if (packingNonstandardKey === '') {
    return '';
  }

  const lookup: Record<PackingNonstandardKey, string> = {
    [PackingNonstandardKey.BIPrepack]: 'BI prepack',
    [PackingNonstandardKey.FinalPackCollectablesDisplay]: 'Final pack/Collectables/Display',
    [PackingNonstandardKey.FinalPackBucket]: 'Final pack/Bucket (brickbox)',
    [PackingNonstandardKey.FinalPackManual]: 'Final pack/Manual',
    [PackingNonstandardKey.BoxInBox]: 'Box in box',
    [PackingNonstandardKey.FinalPackDynamicCardboardSpecialPH]:
      'Final pack/Dynamic cardboard/Special PH',
    [PackingNonstandardKey.PrepackBoosterCollectableAuto]: 'Prepack/Booster/Collectable auto.',
    [PackingNonstandardKey.PrepackManualCollectableMan]: 'Prepack manual/Incl collectable man.',
  };

  const description = lookup[packingNonstandardKey];

  if (!description) {
    throw new Error(`Provided packing nonstandard key not recognized ${packingNonstandardKey}`);
  }

  return description;
}

export function getPackingNonstandardKeyIsFinalPack(
  packingNonstandardKey: PackingNonstandardKey | '',
) {
  return [
    PackingNonstandardKey.FinalPackBucket,
    PackingNonstandardKey.FinalPackCollectablesDisplay,
    PackingNonstandardKey.FinalPackDynamicCardboardSpecialPH,
    PackingNonstandardKey.FinalPackManual,
  ].includes(packingNonstandardKey as PackingNonstandardKey);
}
