import { Link, Tabs } from '@lego/klik-ui';
import { FinalCostsElementsTab } from './FinalCostsElementsTab';
import { FinalCostsPackagingTab } from './FinalCostsPackagingTab';
import { FinalCostsBITab } from './FinalCostsBITab';
import { ScrapOHLicense, ScrapOHLicenseProps } from './ScrapOHLicense';
import { DBProdHeadElem } from '@core/schemas/db/schema.db.elements';
import { DBProdHeadBI } from '@core/schemas/db/schema.db.bi';
import { DBProdHeadPack } from '@core/schemas/db/schema.db.packaging';
import { FinalCostsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.finalCosts';
import { pageRoutes } from '@frontend/common/lib/definitions';
import { TbExternalLink } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { DBProdHeadFC } from '@core/schemas/db/schema.db.prod';

interface FinalCostsDetailsCostsProps {
  prodHead: Pick<DBProdHeadFC, 'product_id' | 'revision'>;
  latestApprovedRevisions: {
    elements: DBProdHeadElem | null;
    buildingInstructions: DBProdHeadBI | null;
    packaging: DBProdHeadPack | null;
  };
  elementsData: FinalCostsGetEndpointResponse['elementsData'];
  buildingInstructionsData: FinalCostsGetEndpointResponse['biData'];
  packagingData: FinalCostsGetEndpointResponse['packagingData'];
  scrapOhLicenseProps: ScrapOHLicenseProps;
}

export function FinalCostsDetailedCosts(props: FinalCostsDetailsCostsProps) {
  const navigate = useNavigate();

  function LinkToRevision(innerProps: { view: 'elements' | 'packaging' | 'bi' }) {
    const route = {
      elements: pageRoutes.Elements,
      packaging: pageRoutes.Packaging,
      bi: pageRoutes.Instructions,
    }[innerProps.view];

    const rev = {
      elements: props.latestApprovedRevisions.elements?.revision,
      packaging: props.latestApprovedRevisions.packaging?.revision,
      bi: props.latestApprovedRevisions.buildingInstructions?.revision,
    }[innerProps.view];

    return (
      <Link
        onClick={() =>
          navigate(
            route +
              `?productId=${
                props.prodHead.product_id
              }&revision=${rev}&back-redirect=${encodeURIComponent(
                `${pageRoutes['Final costs']}?productId=${props.prodHead.product_id}&revision=${props.prodHead.revision}`,
              )}`,
          )
        }
        style={{ marginBottom: 12, display: 'flex', alignItems: 'center', columnGap: 6 }}
      >
        <TbExternalLink />
        Go to revision
      </Link>
    );
  }

  return (
    <div>
      <Tabs>
        <Tabs.TabList>
          <Tabs.Tab className="tab-header">Elements</Tabs.Tab>
          <Tabs.Tab className="tab-header">Packaging</Tabs.Tab>
          <Tabs.Tab className="tab-header">Building instructions</Tabs.Tab>
          <Tabs.Tab className="tab-header">Scrap, overhead & license</Tabs.Tab>
        </Tabs.TabList>
        <Tabs.TabPanels>
          <Tabs.TabPanel>
            {!props.elementsData ? (
              'No approved elements revision'
            ) : (
              <div>
                <LinkToRevision view="elements" />
                <FinalCostsElementsTab
                  bomStatus={props.latestApprovedRevisions.elements?.bom_status}
                  elementsData={props.elementsData}
                  elementErrors={[]}
                />
              </div>
            )}
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            {!props.packagingData ? (
              'No approved packaging revision'
            ) : (
              <div>
                <LinkToRevision view="packaging" />
                <FinalCostsPackagingTab
                  fmcYear={props.latestApprovedRevisions.packaging?.year}
                  packagingData={props.packagingData}
                  note={props.latestApprovedRevisions.packaging?.note || ''}
                />
              </div>
            )}
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            {!props.buildingInstructionsData ? (
              'No approved building instructions revision'
            ) : (
              <div>
                <LinkToRevision view="bi" />
                <FinalCostsBITab
                  productId={props.latestApprovedRevisions.buildingInstructions?.product_id}
                  fmcYear={props.latestApprovedRevisions.buildingInstructions?.year}
                  buildingInstructionsData={props.buildingInstructionsData}
                />
              </div>
            )}
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <ScrapOHLicense {...props.scrapOhLicenseProps} />
          </Tabs.TabPanel>
        </Tabs.TabPanels>
      </Tabs>
    </div>
  );
}
