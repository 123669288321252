import { z } from 'zod';
import { DBConfigSchema } from '@core/schemas/db/schema.db.config';
import { defineEndpoint } from '@core/util/util.defineEndpoint';

// ConfigGetEndpoint
export const ConfigGetEndpoint = defineEndpoint({
  path: '/config/get',
  input: z.object({}),
  response: DBConfigSchema,
});
export type ConfigGetEndpointInput = z.infer<typeof ConfigGetEndpoint.input>;
export type ConfigGetEndpointResponse = z.infer<typeof ConfigGetEndpoint.response>;

// ConfigSetActiveFmcYearEndpoint
export const ConfigSetActiveFmcYearEndpoint = defineEndpoint({
  path: '/config/fmc-year/set',
  mutation: true,
  input: z.object({
    year: z.number().int(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
export type ConfigSetActiveFmcYearEndpointInput = z.infer<
  typeof ConfigSetActiveFmcYearEndpoint.input
>;
export type ConfigSetActiveFmcYearEndpointResponse = z.infer<
  typeof ConfigSetActiveFmcYearEndpoint.response
>;
