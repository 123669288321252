const compose = (...regexes: RegExp[]) =>
  new RegExp(regexes.map((regex) => regex.source).join('|'));

const get_REGEX_NONNEGATIVE_DECIMAL = (
  numberOfDecimalsFrom: number,
  numberOfDecimalsTo: number,
  commaAsDecimalSeperator: boolean,
) => {
  return new RegExp(
    `^\\d+${commaAsDecimalSeperator ? ',' : '\\.'}$|^\\d*${
      commaAsDecimalSeperator ? ',' : '\\.'
    }\\d{${numberOfDecimalsFrom},${numberOfDecimalsTo}}$`,
  );
};

export const REGEX_EMPTY = new RegExp(/^$/);
export const REGEX_INTEGER = new RegExp(/^-?\d+$/);
export const REGEX_POSITIVE_INTEGER = new RegExp(/^[1-9]\d{0,10}$/);
export const REGEX_POSITIVE_INTEGER_OR_EMPTY = compose(REGEX_POSITIVE_INTEGER, REGEX_EMPTY);
export const REGEX_NONNEGATIVE_INTEGER = new RegExp(/^\d{1,10}$/);
export const REGEX_CUSTOM_PRODUCT_ID = new RegExp(/^1\d{6}$/);
export const REGEX_PRODUCT_ID = new RegExp(/^5\d{7}$|^\d{1,7}$/);
export const REGEX_PRODUCT_ID_STRICT = new RegExp(/^5\d{7}$/);
export const REGEX_8DIGITS = new RegExp(/^\d{8}$/);
export const REGEX_8DIGITS_OR_EMPTY = compose(REGEX_8DIGITS, REGEX_EMPTY);
export const REGEX_0_TO_100 = new RegExp(/^[0-9]$|^[1-9][0-9]$|^100$/);
export const REGEX_0_TO_100_OR_EMPTY = compose(REGEX_0_TO_100, REGEX_EMPTY);
export const REGEX_NONNEGATIVE_INTEGER_OR_EMPTY = compose(REGEX_NONNEGATIVE_INTEGER, REGEX_EMPTY);
export const REGEX_CUSTOM_PRODUCT_ID_OR_EMPTY = compose(REGEX_CUSTOM_PRODUCT_ID, REGEX_EMPTY);
export const REGEX_PRODUCT_ID_OR_EMPTY = compose(REGEX_PRODUCT_ID, REGEX_EMPTY);
export const get_REGEX_NONNEGATIVE_2DECIMAL = (commaAsDecimalSeperator: boolean) =>
  get_REGEX_NONNEGATIVE_DECIMAL(2, 2, commaAsDecimalSeperator);
export const get_REGEX_NONNEGATIVE_3DECIMAL = (commaAsDecimalSeperator: boolean) =>
  get_REGEX_NONNEGATIVE_DECIMAL(3, 3, commaAsDecimalSeperator);
export const get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL = (commaAsDecimalSeperator: boolean) =>
  compose(REGEX_NONNEGATIVE_INTEGER, get_REGEX_NONNEGATIVE_DECIMAL(1, 2, commaAsDecimalSeperator));
export const get_REGEX_NONNEGATIVE_INTEGER_OR_1TO3DECIMAL = (commaAsDecimalSeperator: boolean) =>
  compose(REGEX_NONNEGATIVE_INTEGER, get_REGEX_NONNEGATIVE_DECIMAL(1, 3, commaAsDecimalSeperator));
export const get_REGEX_NONNEGATIVE_INTEGER_OR_1TO5DECIMAL = (commaAsDecimalSeperator: boolean) =>
  compose(REGEX_NONNEGATIVE_INTEGER, get_REGEX_NONNEGATIVE_DECIMAL(1, 5, commaAsDecimalSeperator));
export const get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL_OR_EMPTY = (
  commaAsDecimalSeperator: boolean,
) => compose(get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL(commaAsDecimalSeperator), REGEX_EMPTY);
export const get_REGEX_NONNEGATIVE_INTEGER_OR_1TO5DECIMAL_OR_EMPTY = (
  commaAsDecimalSeperator: boolean,
) => compose(get_REGEX_NONNEGATIVE_INTEGER_OR_1TO5DECIMAL(commaAsDecimalSeperator), REGEX_EMPTY);
export const REGEX_EMAIL = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
