import { z } from 'zod';
import { PK } from '@core/types/types.pk';
import { DBRecordSchema } from '@core/schemas/db/schema.db.common';
import { createDescription } from '@core/util/util.schemaPropDescription';
import { IsoDateSchema } from '../schema.util';

/**
 * DBMara
 */
export const DBMaraSchema = DBRecordSchema.extend({
  PK: z.literal(PK.MARA),
  material_type: z.string().describe(createDescription({ isPartOfKey: true, supportLov: true })),
  material_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  description: z.string(),
  super_theme: z.number().optional(),
  material_group: z.number(),
  make_buy_mark: z.string(),
  costing_global_vendor: z.number().optional(),
  market_exit_date: z.union([IsoDateSchema, z.literal('')]),
});
export type DBMara = z.infer<typeof DBMaraSchema>;
