import { useCallback, useEffect, useState } from 'react';
import { PackagingNonstandardCostsRow, PackagingStandardCostsRow } from './packaging.types';
import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { Region, RegionFMC } from '@core/schemas/schema.common';
import { UsePackingStandardCostsRowsReturnValue } from './usePackingStandardCostsRows';
import { Message } from '@frontend/common/lib/models';
import {
  UsePackagingNonstandardCostsReturnValue,
  usePackagingNonstandardCosts,
} from './usePackagingNonstandardCosts';
import {
  UsePackagingStandardCostsReturnValue,
  usePackagingStandardCosts,
} from './usePackagingStandardCosts';
import { PackSource, PackagingCostMaterial } from '@core/types/types.packaging';
import { Calculator } from '@core/calculator/calculator';

export type UsePackagingCostsRowsReturnValue = {
  packagingStandardCostsRows: PackagingStandardCostsRow[];
  packagingNonstandardCostsRows: PackagingNonstandardCostsRow[];
  isStandard: boolean;
  reset: () => void;
  reinitialize: (input: {
    source?: PackSource;
    packingRegionEU?: Region;
    packingRegionUS?: Region;
    packagingSize?: number;
  }) => Promise<void>;
  standardHandlers: UsePackagingStandardCostsReturnValue['handlers'];
  nonstandardHandlers: UsePackagingNonstandardCostsReturnValue['handlers'];
  standardTotals: UsePackagingStandardCostsReturnValue['totals'];
  nonstandardTotals: UsePackagingNonstandardCostsReturnValue['totals'];
  materials: Record<string, Record<RegionFMC, PackagingCostMaterial | undefined>>;
  messages: Message[];
};

export function usePackagingCostsRows(props: {
  packagingData:
    | Pick<
        PackagingGetEndpointResponse,
        | 'packagingAddOnRates'
        | 'packagingScrapRates'
        | 'packagingFoilRates'
        | 'packagingStandardCosts'
        | 'packagingNonstandardCosts'
        | 'packagingRates'
      >
    | undefined;
  packingStandardCostsRows: UsePackingStandardCostsRowsReturnValue['packingStandardCostsRows'];
  packingRegionEU: Region | undefined;
  packingRegionUS: Region | undefined;
  salesRegionEU: Region;
  salesRegionUS: Region;
  selectedSource: PackSource | undefined;
  selectedPackagingSize: number | undefined;
  selectedAddOns: {
    embossing: boolean;
    uvLacquer: boolean;
    windowCutout: boolean;
    hotfoil: boolean;
    partialUvLacquer: boolean;
    windowFoil: boolean;
  };
  modelBagChecked: boolean;
  fmcYear: number | undefined;
  readOnly?: boolean;
  revision: number | undefined;
  productId: number | undefined;
}): UsePackagingCostsRowsReturnValue {
  const { fmcYear } = props;

  const [isStandard, setIsStandard] = useState(false);
  const [invalidConfiguration, setInvalidConfiguration] = useState(false);

  const getFilteredPackagingRates = useCallback(
    (input: { source: PackSource; packagingSize: number }) => {
      if (!props.packagingData?.packagingRates || !fmcYear) {
        return [];
      }

      return props.packagingData.packagingRates.filter(
        (r) =>
          r.year === fmcYear &&
          r.source === input.source &&
          r.packaging_size === input.packagingSize,
      );
    },
    [props.packagingData?.packagingRates, fmcYear],
  );

  const {
    handlers: standardHandlers,
    messages: standardMessages,
    packagingStandardCostsRows,
    reinitialize: standardReinitialize,
    reset: standardReset,
    totals: standardTotals,
  } = usePackagingStandardCosts(props);

  const {
    handlers: nonstandardHandlers,
    materials,
    messages: nonstandardMessages,
    packagingNonstandardCostsRows,
    reinitialize: nonstandardReinitialize,
    reset: nonstandardReset,
    totals: nonstandardTotals,
  } = usePackagingNonstandardCosts(props);

  useEffect(() => {
    if (!props.selectedPackagingSize || !props.selectedSource) {
      return;
    }

    // IF we are in read only mode and IF either standard or nonstandard costs are missing, then
    // we determine standard/nonstandard case from this fact alone
    if (props.readOnly && props.packagingData) {
      const scl = props.packagingData.packagingStandardCosts.length;
      const nscl = props.packagingData.packagingNonstandardCosts.length;
      if ((scl === 0 && nscl !== 0) || (scl !== 0 && nscl === 0)) {
        setIsStandard(scl > nscl);
        return;
      }
    }

    const filteredPackagingRates = getFilteredPackagingRates({
      packagingSize: props.selectedPackagingSize,
      source: props.selectedSource,
    });

    Calculator.Pack.Packaging.IsStandard.Run({
      input: {
        year: props.fmcYear,
        packagingSize: props.selectedPackagingSize,
        source: props.selectedSource,
      },
      cache: { packagingRates: filteredPackagingRates },
    }).then(setIsStandard);
  }, [
    props.fmcYear,
    props.selectedPackagingSize,
    props.selectedSource,
    getFilteredPackagingRates,
    props.packagingData,
    props.readOnly,
  ]);

  const reset = useCallback(() => {
    standardReset();
    nonstandardReset();
  }, [standardReset, nonstandardReset]);

  const reinitialize = useCallback(
    async (input: {
      source?: PackSource;
      packingRegionEU?: Region;
      packingRegionUS?: Region;
      packagingSize?: number;
    }) => {
      const source = input.source || props.selectedSource;
      const packingRegionEU = input.packingRegionEU || props.packingRegionEU;
      const packingRegionUS = input.packingRegionUS || props.packingRegionUS;
      const packagingSize = input.packagingSize || props.selectedPackagingSize;

      if (!packingRegionEU || !packingRegionUS || !source || !packagingSize) {
        setInvalidConfiguration(true);
        return;
      } else {
        setInvalidConfiguration(false);
      }

      const filteredPackagingRates = getFilteredPackagingRates({ packagingSize, source });

      standardReinitialize({ filteredPackagingRates, packingRegionEU, packingRegionUS });
      nonstandardReinitialize();
    },
    [
      standardReinitialize,
      nonstandardReinitialize,
      getFilteredPackagingRates,
      props.packingRegionEU,
      props.packingRegionUS,
      props.selectedPackagingSize,
      props.selectedSource,
    ],
  );

  return {
    isStandard,
    materials,
    packagingStandardCostsRows: invalidConfiguration
      ? []
      : isStandard
      ? packagingStandardCostsRows
      : [],
    packagingNonstandardCostsRows: invalidConfiguration
      ? []
      : isStandard
      ? []
      : packagingNonstandardCostsRows,
    messages: isStandard ? standardMessages : nonstandardMessages,
    reset,
    reinitialize,
    standardTotals,
    nonstandardTotals,
    nonstandardHandlers,
    standardHandlers,
  };
}
