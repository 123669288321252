import {
  CalculatorBICCInstructionsBooks,
  CalculatorCommonPPO,
  CostComponentFull,
} from '../calculator.types';
import { Calculator } from '../calculator';
import { calculate_CC_Instructions_sum } from './calculate.CC_Instructions.sum';
import { round2, round2relaxed } from '../calculator.util';

export function calculate_CC_Instructions_full(props: {
  books: CalculatorBICCInstructionsBooks;
  ppos: CalculatorCommonPPO;
  copyPpoToCost?: boolean;
}): CostComponentFull {
  const { ppos, books, copyPpoToCost } = props;

  if (copyPpoToCost) {
    return {
      fmc1cost: ppos?.FMC1,
      fmc2cost: ppos?.FMC2,
      fmc1ppo: ppos?.FMC1,
      fmc2ppo: ppos?.FMC2,
      fmc1variance: ppos?.FMC1 === undefined ? undefined : 0,
      fmc2variance: ppos?.FMC2 === undefined ? undefined : 0,
    };
  }

  const { fmc1cost, fmc2cost } = calculate_CC_Instructions_sum(books);

  return {
    fmc1ppo: round2relaxed(ppos?.FMC1),
    fmc2ppo: round2relaxed(ppos?.FMC2),
    fmc1cost: round2(fmc1cost),
    fmc2cost: round2(fmc2cost),
    fmc1variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC1, fmc1cost)),
    fmc2variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC2, fmc2cost)),
  };
}
