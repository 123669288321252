import {
  DBPackingStandardCost,
  DBPackingNonstandardCost,
} from '@core/schemas/db/schema.db.packaging';
import { Calculator } from '../calculator';
import { CostComponentCost } from '../calculator.types';

export function calculate_CC_Packing_sum(
  packingStandardCosts: Pick<DBPackingStandardCost, 'fmc1_cost' | 'fmc2_cost'>[],
  packingNonstandardCosts: Pick<DBPackingNonstandardCost, 'fmc1_cost' | 'fmc2_cost'>[],
): Required<CostComponentCost> {
  const { fmc1cost: fmc1CostPackingStandardCosts, fmc2cost: fmc2CostPackingStandardCosts } =
    Calculator.Pack.CC_Packing.PackingStandardCosts.Sum(packingStandardCosts);

  const fmc1CostPackingNonstandardCosts = Calculator.Common.SumListStrict(
    packingNonstandardCosts,
    'fmc1_cost',
  );

  const fmc1cost = Calculator.Common.SumStrict(
    fmc1CostPackingStandardCosts,
    fmc1CostPackingNonstandardCosts,
  );

  const fmc2CostPackingNonstandardCosts = Calculator.Common.SumListStrict(
    packingNonstandardCosts,
    'fmc2_cost',
  );

  const fmc2cost = Calculator.Common.SumStrict(
    fmc2CostPackingStandardCosts,
    fmc2CostPackingNonstandardCosts,
  );

  return {
    fmc1cost,
    fmc2cost,
  };
}
