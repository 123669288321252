import { PACKING_KEY_ID_TO_TYPE } from '@core/const/const.PACKING_KEYS';
import {
  DBPackingNonstandardCost,
  DBPackingStandardCost,
} from '@core/schemas/db/schema.db.packaging';
import { getPackingNonstandardKeyIsFinalPack } from '@core/util/util.getPackingNonstandardKeyInfo';

export function calculate_packing_numberOfPrepacks(
  packingStandardCosts:
    | Pick<DBPackingStandardCost, 'packing_key' | 'fmc1_quantity' | 'fmc2_quantity'>[]
    | undefined,
  packingNonstandardCosts:
    | Pick<DBPackingNonstandardCost, 'packing_type' | 'fmc1_quantity' | 'fmc2_quantity'>[]
    | undefined,
): { fmc1: number; fmc2: number } {
  const fmc1numberOfPrepacksStandard =
    packingStandardCosts?.reduce(
      (sum, pc) =>
        sum + (PACKING_KEY_ID_TO_TYPE[pc.packing_key] === 'PREPACK' ? pc.fmc1_quantity : 0),
      0,
    ) || 0;

  const fmc2numberOfPrepacksStandard =
    packingStandardCosts?.reduce(
      (sum, pc) =>
        sum + (PACKING_KEY_ID_TO_TYPE[pc.packing_key] === 'PREPACK' ? pc.fmc2_quantity : 0),
      0,
    ) || 0;

  const fmc1numberOfPrepacksNonstandard =
    packingNonstandardCosts?.reduce(
      (sum, pc) =>
        sum + (!getPackingNonstandardKeyIsFinalPack(pc.packing_type) ? pc.fmc1_quantity : 0),
      0,
    ) || 0;

  const fmc2numberOfPrepacksNonstandard =
    packingNonstandardCosts?.reduce(
      (sum, pc) =>
        sum + (!getPackingNonstandardKeyIsFinalPack(pc.packing_type) ? pc.fmc2_quantity : 0),
      0,
    ) || 0;

  const fmc1 = fmc1numberOfPrepacksStandard + fmc1numberOfPrepacksNonstandard;
  const fmc2 = fmc2numberOfPrepacksStandard + fmc2numberOfPrepacksNonstandard;

  return { fmc1, fmc2 };
}
