interface RequiredAsterixProps {
  hide?: boolean;
}

export function RequiredAsterix({ hide }: RequiredAsterixProps) {
  return (
    <div
      style={{
        marginLeft: 4,
        color: '#f55b5b',
        display: 'inline-block',
      }}
    >
      {hide ? '' : '*'}
    </div>
  );
}
