export function calculate_nullable_quotient(
  a: number | undefined,
  b: number | undefined,
): number | undefined {
  if (a === undefined || b === undefined || b === 0) {
    return undefined;
  }

  return a / b;
}
