import { DBRecord } from '@core/schemas/db/schema.db.common';
import { EndpointError } from '@core/types/types.endpoint';
import { getHeaders } from '@frontend/common/lib/functions';
import {
  DataMaintenanceGetEndpoint,
  DataMaintenanceGetEndpointInput,
  DataMaintenanceGetEndpointResponse,
} from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';

export async function callElasticSearchEndpoint<T extends DBRecord>(
  input: Omit<DataMaintenanceGetEndpointInput, 'PaginationKey'>,
  params?: { onNewBatchFetched?: (itemCount: number) => void; abortController?: AbortController },
): Promise<
  [EndpointError, null, Response | null] | [null, T[], Response | null] | [null, null, null]
> {
  try {
    const items: DataMaintenanceGetEndpointResponse['items'] = [];

    let PaginationKey: DataMaintenanceGetEndpointInput['PaginationKey'] = undefined;

    do {
      const body: DataMaintenanceGetEndpointInput = {
        pk: input.pk,
        query: input.query,
        PaginationKey: PaginationKey,
      };

      const r = await fetch('/api' + DataMaintenanceGetEndpoint.path, {
        headers: await getHeaders(),
        method: 'POST',
        body: body ? JSON.stringify(body) : undefined,
        signal: params?.abortController?.signal,
      });

      let json = await r.json();

      if (!r.ok) {
        return [json as unknown as EndpointError, null, r];
      }

      if (json._payloadUrl) {
        const response = await fetch(json._payloadUrl);
        json = JSON.parse(await response.text());
      }

      items.push(...(json as DataMaintenanceGetEndpointResponse).items);
      PaginationKey = (json as DataMaintenanceGetEndpointResponse).NextPaginationKey;

      if (params?.onNewBatchFetched) {
        params.onNewBatchFetched(items.length);
      }
    } while (PaginationKey);

    return [null, items as T[], null];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (params?.abortController?.signal.aborted) {
      return [null, null, null];
    }
    return [{ error: e.message }, null, null];
  }
}
