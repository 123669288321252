export enum ElementRateSource {
  FMC = 'FMC',
  GSIM = 'GSIM',
  MEDIUM = 'MEDIUM',
  MAN = 'MAN',
  STD = 'STD',
  PP2 = 'PP2',
}

export type ElementPrice = {
  materialId: number;
  fmc_price?: number;
  fmc_price_timestamp?: string;
  uom: string;
  planned_price_1?: number;
  planned_price_1_timestamp?: string;
  planned_price_2?: number;
  planned_price_2_timestamp?: string;
  planned_price_3?: number;
  planned_price_3_timestamp?: string;
  changed_timestamp?: string;
};
