export function safe<T>(fn: () => T): [Error, null] | [null, T] {
  try {
    return [null, fn()];
  } catch (e) {
    return [e as Error, null];
  }
}

export async function safeAsync<T>(fn: () => Promise<T>): Promise<[Error, null] | [null, T]> {
  try {
    return [null, await fn()];
  } catch (e) {
    return [e as Error, null];
  }
}
