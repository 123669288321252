import { COLOR_SELECTED_BLUE, FMCREGION_TO_PRETTY } from '@frontend/common/lib/definitions';
import { twoDec } from '@frontend/common/lib/functions';
import { CostOverviewRow } from '@frontend/common/lib/models';
import { TableColumn } from '@frontend/table/table';

import { prettifyTimestamp } from './common.util';

import {
  AttachmentsGetEndpoint,
  AttachmentsListEndpointResponse,
} from '@core/schemas/endpoint/schema.endpoint.attachment';
import { MaraListEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.mara';
import { ViewName } from '@core/schemas/schema.common';
import { Unpacked } from '@core/util/util.typing';
import { ReportStatement } from '@core/calculator/calculator.util.report';
import { sortCostComponents } from '@core/util/util.sortCostComponents';
import { convertProductIdFrom8digits } from '@core/util/util.convertProductIdFrom8digits';
import { TOTAL_KEY } from '@core/const/const.TOTAL_KEY';
import { callEndpoint } from './callEndpoint';

export function getAttachmentsColumns(
  productId: number,
  view: ViewName,
): TableColumn<Unpacked<AttachmentsListEndpointResponse>>[] {
  return [
    {
      title: 'File name',
      dataIndex: 'fullFileName',
      render: (fullFileName: Unpacked<AttachmentsListEndpointResponse>['fullFileName']) => (
        <div
          style={{ fontSize: 14, lineHeight: 'normal', color: COLOR_SELECTED_BLUE }}
          className="link"
          onClick={async () => {
            const [, signedUrl] = await callEndpoint({
              endpoint: AttachmentsGetEndpoint,
              input: { fileName: fullFileName.split('/').pop() + '', productId, view },
              errorHandling: { header: 'Preparing example .xlsx download' },
            });

            if (signedUrl) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.open(signedUrl.url, '_blank').focus();
            }
          }}
        >
          {fullFileName.split('/').pop()}
        </div>
      ),
    },
    { title: 'Uploaded by', dataIndex: 'uploadedBy' },
    {
      title: 'Uploaded at',
      dataIndex: 'uploadedAt',
      width: 'auto',
      sorted: 'descending',
      format: (v) => prettifyTimestamp(v),
    },
  ];
}

export function getProductCostsOverviewColumns(
  commaAsDecimalSeperator: boolean,
): TableColumn<CostOverviewRow>[] {
  return [
    {
      title: 'Component',
      dataIndex: 'costComp',
      sorted: 'ascending',
      format: (v: CostOverviewRow['costComp']) => (v === TOTAL_KEY ? '' : v),
      style: (v) => ({ fontWeight: v === Number.MAX_SAFE_INTEGER ? 'bold' : undefined }),
      sorter: (a, b) => sortCostComponents(a.costComp, b.costComp),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      style: (v) => ({ fontWeight: v === TOTAL_KEY ? 'bold' : undefined }),
    },
    {
      title: 'PPO',
      groupName: FMCREGION_TO_PRETTY.FMC1,
      align: 'end',
      dataIndex: 'fmc1ppo',
      format: (v) => (v === undefined ? '—' : twoDec(v, commaAsDecimalSeperator)),
      style: (v, r) => ({
        fontWeight: r.costComp === TOTAL_KEY ? 'bold' : undefined,
      }),
    },
    {
      title: `Cost`,
      groupName: FMCREGION_TO_PRETTY.FMC1,
      align: 'end',
      dataIndex: 'fmc1cost',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({
        fontWeight: r.costComp === TOTAL_KEY ? 'bold' : undefined,
      }),
    },
    {
      title: `Variance`,
      groupName: FMCREGION_TO_PRETTY.FMC1,
      align: 'end',
      dataIndex: 'fmc1variance',
      format: (v) => (v === undefined ? '—' : twoDec(v, commaAsDecimalSeperator)),
      style: (v, r) => ({
        fontWeight: r.costComp === TOTAL_KEY ? 'bold' : undefined,
      }),
    },
    {
      title: 'PPO',
      groupName: FMCREGION_TO_PRETTY.FMC2,
      align: 'end',
      dataIndex: 'fmc2ppo',
      format: (v) => (v === undefined ? '—' : twoDec(v, commaAsDecimalSeperator)),
      style: (v, r) => ({
        fontWeight: r.costComp === TOTAL_KEY ? 'bold' : undefined,
      }),
    },
    {
      title: `Cost`,
      groupName: FMCREGION_TO_PRETTY.FMC2,
      align: 'end',
      dataIndex: 'fmc2cost',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({
        fontWeight: r.costComp === TOTAL_KEY ? 'bold' : undefined,
      }),
    },
    {
      title: `Variance`,
      groupName: FMCREGION_TO_PRETTY.FMC2,
      align: 'end',
      dataIndex: 'fmc2variance',
      format: (v) => (v === undefined ? '—' : twoDec(v, commaAsDecimalSeperator)),
      style: (v, r) => ({
        fontWeight: r.costComp === TOTAL_KEY ? 'bold' : undefined,
      }),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      width: 'auto',
      style: (v, r) => ({
        fontWeight: r.costComp === TOTAL_KEY ? 'bold' : undefined,
      }),
    },
  ];
}

export const ProductOverviewColumns: TableColumn<Unpacked<MaraListEndpointResponse>>[] = [
  {
    title: 'Product ID',
    dataIndex: 'material_id',
    sorted: 'ascending',
    format: convertProductIdFrom8digits,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: 'auto',
  },
];

export function getReportStatementsColumns(
  commaAsDecimalSeperator: boolean,
): TableColumn<ReportStatement>[] {
  function formatNumbers(text: string | number | undefined) {
    if (!commaAsDecimalSeperator || text === undefined) {
      return text;
    }

    const regex = /[0-9]+.[0-9]+/g;

    const matches: { oldString: string; newString: string }[] = [];

    let match;

    while ((match = regex.exec(text.toString()))) {
      matches.push({ oldString: match[0], newString: match[0].replace('.', ',') });
    }

    let newText = text.toString();

    matches.forEach(
      ({ oldString, newString }) => (newText = newText.replaceAll(oldString, newString)),
    );

    return newText;
  }

  return [
    { title: 'Type', dataIndex: 'type' },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 'auto',
      format: (v: ReportStatement['description']) => formatNumbers(v),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      align: 'end',
      format: (v: ReportStatement['value']) => formatNumbers(v),
    },
    { title: 'Unit', dataIndex: 'unit' },
  ];
}
