import { defineEndpoint } from '@core/util/util.defineEndpoint';
import { z } from 'zod';
import { ViewNameEnum } from '../schema.common';

// FMCYearChangeEndpoint
export const FMCYearChangeEndpoint = defineEndpoint({
  path: '/fmc-year-change',
  mutation: true,
  input: z.object({
    productIds: z.array(z.number().int()),
    productIdRanges: z.array(z.object({ from: z.number(), to: z.number() })),
    view: ViewNameEnum,
    domainUsername: z.string().optional(),
  }),
  response: z.object({ ok: z.literal(true) }),
});
