import { DBBICost, DBBIRate } from '@core/schemas/db/schema.db.bi';
import { RegionEnum } from '@core/schemas/schema.common';
import { Calculate_FetchGetOrNull } from '../calculator.types';
import { InternalCalculatorError, useCacheOrFetchSingle } from '../calculator';
import { PK } from '@core/types/types.pk';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';

type SubBiRate = Pick<
  DBBIRate,
  'fmc1_with_scrap' | 'fmc1_qr_with_scrap' | 'fmc2_qr_with_scrap' | 'fmc2_with_scrap'
>;

export async function calculate_BICOST_calculate(props: {
  input: {
    year: number;
    biCost: Pick<
      OmitDBRecord<DBBICost>,
      | 'cover_weight_gr'
      | 'number_of_colors_backside'
      | 'number_of_colors_frontside'
      | 'paper_weight_gr'
      | 'number_of_cover_pages'
      | 'super_design'
      | 'number_of_pages'
      | 'book_number'
      | 'region'
    >;
    hasQrCode: boolean;
    manualCost?: number | undefined;
  };
  cache?: {
    biRate: SubBiRate;
  };
  query?: {
    getOrNull?: Calculate_FetchGetOrNull;
  };
}): Promise<number> {
  const { biCost, manualCost, hasQrCode, year } = props.input;

  const eu = biCost.region === RegionEnum.enum.EU;

  // If no super design, we use the manually input cost
  if (!biCost.super_design) {
    if (manualCost === undefined) {
      throw new InternalCalculatorError('Manual cost used but was undefined');
    }
    return manualCost;
  }

  const biRate = await useCacheOrFetchSingle<DBBIRate, SubBiRate>({
    cachedItem: props.cache?.biRate,
    fetchQuery: props.query?.getOrNull,
    fetchQueryArgs: {
      pk: PK.BIRate,
      query: {
        cover_weight_gr: biCost.cover_weight_gr,
        number_of_colors_backside: biCost.number_of_colors_backside,
        number_of_colors_frontside: biCost.number_of_colors_frontside,
        number_of_cover_pages: biCost.number_of_cover_pages,
        paper_weight_gr: biCost.paper_weight_gr,
        super_design: biCost.super_design,
        total_number_of_pages: biCost.number_of_pages,
        year,
      },
    },
    dataTypeDescription: 'BI rate',
    strict: false,
  });

  // If product is QR code eligable and it's the first book, we use QR rate
  if (hasQrCode && biCost.book_number === 1) {
    return eu ? biRate.fmc1_qr_with_scrap : biRate.fmc2_qr_with_scrap;
  }

  return eu ? biRate.fmc1_with_scrap : biRate.fmc2_with_scrap;
}
