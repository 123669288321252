import { Button } from '@lego/klik-ui';
import { CSSProperties } from 'react';

interface IconButtonIdenticalProps {
  variant?: 'solid' | 'outline';
  icon?: React.ReactElement;
  onClick?(): void;
  style?: CSSProperties;
}

export function IconButtonIdentical(props: IconButtonIdenticalProps) {
  return (
    <Button
      size="sm"
      variant={props.variant || 'outline'}
      leftIcon={props.icon}
      onClick={props.onClick}
      className="icon-button-identical"
      style={props.style}
    />
  );
}
