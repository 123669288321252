import { useState } from 'react';
import { FCheckbox } from '@frontend/form/components/FCheckbox';
import { FInput } from '@frontend/form/components/FInput';
import { showInternalApplicationError } from '@frontend/common/lib/functions';
import { PackagingAddOnType } from '../lib/packaging.types';
import { UsePackagingCostsRowsReturnValue } from '../lib/usePackagingCostsRows';
import { PackagingSizesListEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { Unpacked } from '@core/util/util.typing';
import { ReportStatement } from '@core/calculator/calculator.util.report';
import { ReportModal } from '@frontend/common/components/ReportModal';
import { MessageStack } from '@frontend/common/components/MessageStack';
import { Message } from '@frontend/common/lib/models';
import { PackagingStandardCostsTable } from './PackagingStandardCostsTable';
import { PackagingNonstandardCostsTable } from './PackagingNonstandardCostsTable';
import { InfoIcon } from '@frontend/common/components/InfoIcon';

interface PackagingPackagingCostsProps {
  productId?: number;
  revision?: number;
  maxRevision?: number;
  readOnly: boolean;
  addOns: Record<PackagingAddOnType, boolean>;
  updateAddOn?: (addOn: PackagingAddOnType, selected: boolean) => void;
  selectedPackagingSize: Unpacked<PackagingSizesListEndpointResponse> | undefined;
  packagingStandardCostsRows: UsePackagingCostsRowsReturnValue['packagingStandardCostsRows'];
  packagingNonstandardCostsRows: UsePackagingCostsRowsReturnValue['packagingNonstandardCostsRows'];
  packagingStandardCostsTotal: UsePackagingCostsRowsReturnValue['standardTotals'];
  packagingNonstandardCostsTotal: UsePackagingCostsRowsReturnValue['nonstandardTotals'];
  packagingNonstandardCostsMaterials: UsePackagingCostsRowsReturnValue['materials'];
  packagingStandardCostsHandlers?: UsePackagingCostsRowsReturnValue['standardHandlers'];
  packagingNonstandardCostsHandlers?: UsePackagingCostsRowsReturnValue['nonstandardHandlers'];
  isStandard: UsePackagingCostsRowsReturnValue['isStandard'];
  hideCostTable: boolean;
  packagingCostsMessages: Message[];
}

export function PackagingPackagingCosts(props: PackagingPackagingCostsProps) {
  const [report, setReport] = useState<{ header: string; statements: ReportStatement[] }>();

  return props.hideCostTable ? (
    <div style={{ marginTop: 12, display: 'flex', alignItems: 'center' }}>
      <InfoIcon />
      Please select a source, packing regions and a packaging size before maintaining packaging
      costs
    </div>
  ) : (
    <div>
      <ReportModal report={report} onClose={() => setReport(undefined)} />
      {props.isStandard && (
        <>
          <div style={{ fontSize: 18, fontWeight: 'bold' }}>Box addons</div>
          <div style={{ display: 'flex', columnGap: 24, marginBottom: 16 }}>
            <FCheckbox
              label="Embossing"
              editable={!props.readOnly}
              checked={props.addOns.embossing}
              onChange={(v) => {
                if (!props.updateAddOn) {
                  showInternalApplicationError();
                  return;
                }
                props.updateAddOn('embossing', v);
              }}
              labelStyle={{ fontWeight: 'normal', marginRight: 2 }}
            />
            <FCheckbox
              label="UV lacquer"
              editable={!props.readOnly}
              checked={props.addOns.uvLacquer}
              onChange={(v) => {
                if (!props.updateAddOn) {
                  showInternalApplicationError();
                  return;
                }
                props.updateAddOn('uvLacquer', v);
              }}
              labelStyle={{ fontWeight: 'normal', marginRight: 2 }}
            />
            <FCheckbox
              label="Window cutout"
              editable={!props.readOnly}
              checked={props.addOns.windowCutout}
              onChange={(v) => {
                if (!props.updateAddOn) {
                  showInternalApplicationError();
                  return;
                }
                props.updateAddOn('windowCutout', v);
              }}
              labelStyle={{ fontWeight: 'normal', marginRight: 2 }}
            />
            <FCheckbox
              label="Hotfoil"
              editable={!props.readOnly}
              checked={props.addOns.hotfoil}
              onChange={(v) => {
                if (!props.updateAddOn) {
                  showInternalApplicationError();
                  return;
                }
                props.updateAddOn('hotfoil', v);
              }}
              labelStyle={{ fontWeight: 'normal', marginRight: 2 }}
            />
            <FCheckbox
              label="Part UV lacquer"
              editable={!props.readOnly}
              checked={props.addOns.partialUvLacquer}
              onChange={(v) => {
                if (!props.updateAddOn) {
                  showInternalApplicationError();
                  return;
                }
                props.updateAddOn('partialUvLacquer', v);
              }}
              labelStyle={{ fontWeight: 'normal', marginRight: 2 }}
            />
            <FCheckbox
              label="Window foil"
              editable={!props.readOnly}
              checked={props.addOns.windowFoil}
              onChange={(v) => {
                if (!props.updateAddOn) {
                  showInternalApplicationError();
                  return;
                }
                props.updateAddOn('windowFoil', v);
              }}
              labelStyle={{ fontWeight: 'normal', marginRight: 2 }}
            />
          </div>
        </>
      )}
      <div style={{ marginBottom: 12 }}>
        <FInput
          label="Packaging size"
          align="start"
          value={
            props.selectedPackagingSize
              ? props.selectedPackagingSize.packaging_size +
                ', ' +
                props.selectedPackagingSize.erect_code
              : ''
          }
        />
      </div>
      <MessageStack messages={props.packagingCostsMessages} />
      {props.isStandard ? (
        <PackagingStandardCostsTable
          maxRevision={props.maxRevision}
          productId={props.productId}
          revision={props.revision}
          packagingStandardCostsHandlers={props.packagingStandardCostsHandlers}
          packagingStandardCostsRows={props.packagingStandardCostsRows}
          packagingStandardCostsTotal={props.packagingStandardCostsTotal}
          readOnly={props.readOnly}
          setReport={setReport}
        />
      ) : (
        <PackagingNonstandardCostsTable
          maxRevision={props.maxRevision}
          productId={props.productId}
          revision={props.revision}
          packagingNonstandardCostsHandlers={props.packagingNonstandardCostsHandlers}
          packagingNonstandardCostsMaterials={props.packagingNonstandardCostsMaterials}
          packagingNonstandardCostsRows={props.packagingNonstandardCostsRows}
          packagingNonstandardCostsTotal={props.packagingNonstandardCostsTotal}
          readOnly={props.readOnly}
          setReport={setReport}
        />
      )}
    </div>
  );
}
