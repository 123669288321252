import { Table } from '@frontend/table/table';
import { ProductOverviewColumns } from '../lib/common.columns';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import { MaraListEndpoint } from '@core/schemas/endpoint/schema.endpoint.mara';
import { MaterialType } from '@core/types/types.mara';

interface ProductOverviewModalContentProps {
  onProductSelect(productId: number, description: string): void;
}

export function ProductOverviewModalContent(props: ProductOverviewModalContentProps) {
  const { data, loading } = useEndpoint({
    endpoint: MaraListEndpoint,
    input: { type: MaterialType.Product },
    errorHandling: { header: 'Fetching product overview' },
  });

  return (
    <Table
      id="product-overview"
      rows={data || []}
      rowKey="material_id"
      columns={ProductOverviewColumns}
      itemType="product"
      itemsPerPage={15}
      fixedHeight={430}
      isRefreshing={loading}
      onRowClick={(r) => props.onProductSelect(r.material_id, r.description)}
    />
  );
}
