interface ProfileItemMenuItem {
  text: string;
  icon: React.ReactElement;
  onClick(): void;
}

export function ProfileItemMenuItem(props: ProfileItemMenuItem) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '4px 16px',
        whiteSpace: 'nowrap',
      }}
      className="profile-item-menu-item"
      onClick={props.onClick}
    >
      <div>{props.text}</div>
      <div style={{ marginLeft: 24 }}>{props.icon}</div>
    </div>
  );
}
