export function calculate_variance(
  ppo: number | undefined,
  cost: number | undefined,
): number | undefined {
  if (ppo === undefined || cost === undefined) {
    return undefined;
  }

  return cost - ppo;
}
