import { CreateProduct } from './CreateProduct';
import { DeleteProduct } from './DeleteProduct';

export function CreateDeleteProduct() {
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        Product IDs in the range from 1000000 to 1999999 is reserved for custom products
      </div>
      <CreateProduct />
      <div style={{ marginTop: 48 }} />
      <DeleteProduct />
    </div>
  );
}
