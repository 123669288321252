import { Radio } from '@lego/klik-ui';
import { useContext, useRef } from 'react';
import { CircleSpinner } from '@frontend/common/components/CircleSpinner';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { twoDec } from '@frontend/common/lib/functions';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { UserSettingsSetEndpoint } from '@core/schemas/endpoint/schema.endpoint.userSettings';

export function UserPreferences() {
  const { userData, refresh, isRefreshing } = useContext(UserDataContext);

  const isUpdating = useRef(false);

  async function updateDecimalSeperator(commaAsSeperator: boolean) {
    if (isUpdating.current) {
      return;
    }

    isUpdating.current = true;
    const [err] = await callEndpoint({
      endpoint: UserSettingsSetEndpoint,
      input: { commaAsDecimalSeperator: commaAsSeperator },
      errorHandling: { header: 'Updating seperator preference' },
    });
    isUpdating.current = false;

    if (err) {
      return;
    }

    refresh();
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>Decimal seperator:</div>
        <div style={{ marginLeft: 8, columnGap: 16, display: 'flex' }}>
          <div onClick={() => updateDecimalSeperator(true)} style={{ cursor: 'pointer' }}>
            <Radio isChecked={!!userData?.comma_as_decimal_seperator}>{"Comma ','"}</Radio>
          </div>
          <div onClick={() => updateDecimalSeperator(false)} style={{ cursor: 'pointer' }}>
            <Radio isChecked={!userData?.comma_as_decimal_seperator}>{"Period '.'"}</Radio>
          </div>
          <div style={{ marginLeft: 24, display: 'flex', columnGap: 16 }}>
            <div>Examples:</div>
            <div>{twoDec(1.4, !!userData?.comma_as_decimal_seperator)}</div>
            <div>{twoDec(12345.67, !!userData?.comma_as_decimal_seperator)}</div>
          </div>
          {isRefreshing && <CircleSpinner />}
        </div>
      </div>
    </div>
  );
}
