import { CalculationError } from '../calculator';

export function calculate_nullable_sum_list_strict<T>(
  objects: T[] | undefined,
  key: keyof T,
): number {
  if (!objects) {
    throw new CalculationError({ message: "List of elements is 'undefined'" });
  }

  let sum = 0;
  objects.forEach((o, i) => {
    const value = o[key];

    if (typeof value !== 'number') {
      throw new CalculationError({ message: `Value was 'undefined' at index ${i}` });
    }

    sum += value;
  });

  return sum;
}
