export function convertBiStatusToText(biStatus: number) {
  if (biStatus === 40) {
    return 'Preliminary approved';
  }
  if (biStatus === 43) {
    return 'Status 43 approved';
  }

  return `Status ${biStatus}`;
}
