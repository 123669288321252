export interface ScrapOHLicenseRow {
  description: string;
  fmc1rate?: number;
  fmc2rate?: number;
}

export enum ScrapOHLicenseRowDescription {
  License = 'License',
  Other = 'Other',
}
