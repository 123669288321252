import React, { CSSProperties, MouseEventHandler, useMemo } from 'react';
import { getProp, LIGHT_GRAY } from './lib';
import { TableColumn } from './table';

interface RowProps<T extends object> {
  row: T;
  rows: T[];
  columns: TableColumn<T>[];
  hoveredrowkey?: string;
  index: number;
  renderBottomLine: boolean;
  enableRowColoring: boolean;
  isDisabled: boolean;
  isSelected?: boolean;
  multiSelection?: {
    selected: T[];
    setSelected: React.Dispatch<React.SetStateAction<T[]>>;
    disabled?(row: T): boolean;
  };
  hasError?: boolean;
  dontShrinkInput?: boolean;
  onMouseUp: MouseEventHandler<HTMLDivElement>;
  getRowKey(r: T): string;
  sethoveredrowkey?(key: string): void;
  onRowClick?: (() => React.ReactNode | void) | (() => Promise<React.ReactNode | void>);
}

export function Row<T extends object>(props: RowProps<T>) {
  const {
    multiSelection,
    getRowKey,
    row,
    onRowClick,
    hoveredrowkey,
    isSelected: isSelectedProp,
  } = props;

  const isSelected = useMemo(
    () => multiSelection && !!multiSelection.selected.find((r) => getRowKey(r) === getRowKey(row)),
    [row, getRowKey, multiSelection],
  );

  const isHoveredOrSelected = useMemo(
    () => (isSelectedProp ? '#abd7fd' : onRowClick && hoveredrowkey === getRowKey(row)),
    [getRowKey, row, hoveredrowkey, onRowClick, isSelectedProp],
  );

  const getCellStyle: (col?: TableColumn<T>) => CSSProperties = (col?: TableColumn<T>) => ({
    textAlign: col?.align,
    padding: '4px 12px',
    display: 'flex',
    color: props.hasError && isHoveredOrSelected ? 'red' : undefined,
    alignItems: col?.alignVertically || 'center',
    justifyContent: col?.align,
    height: '100%',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    boxSizing: 'border-box',
    // minWidth: '0px',
    background: props.isSelected
      ? '#abd7fd'
      : props.onRowClick && props.hoveredrowkey === props.getRowKey(props.row)
      ? '#dbedfd'
      : props.hasError
      ? '#fb8080'
      : props.index % 2 === 1 && props.enableRowColoring
      ? '#f3f3f3'
      : undefined,
    cursor: props.onRowClick && !col?.ignoreRowClick ? 'pointer' : undefined,
  });

  async function handleRowClick() {
    if (props.onRowClick) {
      await props.onRowClick();
    }
  }

  function safeToString(value: unknown): string {
    if (value === undefined || value === null) {
      return '';
    }

    if ((typeof value === 'object' && value !== null) || typeof value === 'boolean') {
      return JSON.stringify(value);
    }

    return value + '';
  }

  const checkboxDisabled = useMemo(() => {
    if (props.multiSelection?.disabled) {
      return props.multiSelection.disabled(props.row);
    }
    return false;
  }, [props.multiSelection, props.row]);

  return (
    <>
      {props.multiSelection && (
        <div
          style={{
            ...getCellStyle(),
            display: 'flex',
            alignItems: 'center',
            paddingRight: '2px',
          }}
          className={'table-cell-checkbox' + (props.isDisabled ? ' table-cell-disabled' : '')}
        >
          <input
            type="checkbox"
            disabled={checkboxDisabled}
            onChange={() => {
              if (isSelected) {
                props.multiSelection?.setSelected((selected) =>
                  selected.filter((r) => props.getRowKey(r) !== props.getRowKey(props.row)),
                );
              } else {
                props.multiSelection?.setSelected((selected) => selected.concat(props.row));
              }
            }}
            checked={isSelected}
          />
        </div>
      )}
      {props.columns.map((col, i) => {
        const val = getProp(props.row, String(col.dataIndex));
        return (
          <div
            key={col.title + String(col.dataIndex)}
            style={getCellStyle(col)}
            data-paste-index={`${props.index},${i}`}
            className={'table-cell ' + (props.isDisabled ? ' table-cell-disabled' : '')}
            onMouseUp={props.onMouseUp}
            onClick={col.ignoreRowClick ? undefined : handleRowClick}
            title={col.cellTitle ? col.cellTitle(val, props.row, props.index) : safeToString(val)}
            onMouseEnter={() =>
              props.sethoveredrowkey && props.sethoveredrowkey(props.getRowKey(props.row))
            }
            onMouseLeave={() => props.sethoveredrowkey && props.sethoveredrowkey('')}
          >
            {col.render ? (
              col.render(val, props.row, props.index, props.rows)
            ) : (
              <div
                style={col.style ? col.style(val, props.row, props.index, props.rows) : undefined}
              >
                {safeToString(
                  col.format ? col.format(val, props.row, props.index, props.rows) : val,
                )}
              </div>
            )}
          </div>
        );
      })}
      {props.renderBottomLine && (
        <div
          style={{
            gridColumn: `span ${props.columns.length + (props.multiSelection ? 1 : 0)}`,
            borderBottom: `1px solid ${LIGHT_GRAY}`,
          }}
        />
      )}
    </>
  );
}
