import { useContext, useEffect, useRef, useState } from 'react';
import { MdLogout, MdSecurity, MdSettings } from 'react-icons/md';
import { Popover, Portal } from '@lego/klik-ui';
import { useMsal } from '@azure/msal-react';
import { getMsGraphToken } from '@frontend/common/lib/authProvider';
import { useNavigate } from 'react-router-dom';
import { allRoutes } from '@frontend/common/lib/definitions';
import { ProfileItemMenuItem } from './ProfileItemMenuItem';
import { UserDataContext } from '../lib/contexts';
import { VirtualGroupName } from '@core/const/const.VIRTUAL_GROUPS';

export function ProfileItem() {
  const [image, setImage] = useState<Blob>();
  const [isFetchingImage, setIsFetchingImage] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { instance } = useMsal();

  const { virtualGroups } = useContext(UserDataContext);

  const navigate = useNavigate();
  const fromBlur = useRef(false);

  useEffect(() => {
    if (image) {
      return;
    }
    fetchImage();
  }, [image]);

  async function fetchImage() {
    setIsFetchingImage(true);
    try {
      const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: { Authorization: await getMsGraphToken() },
      });
      if (response.ok) {
        setImage(await response.blob());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingImage(false);
    }
  }

  const url = image && URL.createObjectURL(image);

  return (
    <Popover
      variant="popover"
      closeOnBlur
      autoFocus
      placement="bottom-end"
      closeOnEsc
      isOpen={isOpen}
    >
      <Popover.Trigger>
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            if (fromBlur.current) {
              setIsOpen(false);
              fromBlur.current = false;
            } else {
              setIsOpen(true);
            }
          }}
        >
          {isFetchingImage ? (
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 100,
                border: '1px solid lightgray',
              }}
            />
          ) : (
            <img
              style={{
                width: '48px',
                height: '50px',
                clipPath: 'circle(24px at center)',
                objectFit: 'cover',
              }}
              src={url}
              alt=""
            />
          )}
        </div>
      </Popover.Trigger>
      <Portal>
        <div
          className="profile-item-menu"
          onKeyDown={(e) => {
            if (e.code === 'Escape') {
              setIsOpen(false);
            }
          }}
          onBlur={() => {
            setIsOpen(false);
            fromBlur.current = true;
            setTimeout(() => (fromBlur.current = false), 1000);
          }}
        >
          <Popover.Content
            style={{
              marginRight: 12,
              marginTop: 3,
              padding: '12px 0',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 0px 0px',
            }}
          >
            <ProfileItemMenuItem
              text="Settings"
              icon={<MdSettings />}
              onClick={() => {
                navigate(allRoutes.Settings);
                setIsOpen(false);
              }}
            />
            {(virtualGroups.includes(VirtualGroupName.Administrators) ||
              virtualGroups.includes(VirtualGroupName.TheCostingGroup)) && (
              <ProfileItemMenuItem
                text="Administrate"
                icon={<MdSecurity />}
                onClick={() => {
                  navigate(allRoutes.Administration);
                  setIsOpen(false);
                }}
              />
            )}
            <ProfileItemMenuItem
              text="Log out"
              icon={<MdLogout />}
              onClick={() => {
                instance.logout();
                setIsOpen(false);
              }}
            />
          </Popover.Content>
        </div>
      </Portal>
    </Popover>
  );
}
