import { DBMara } from '@core/schemas/db/schema.db.mara';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { now } from '@core/util/util.timestamp';
import { MaterialType } from '@core/types/types.mara';

export const MARAExample: OmitDBRecord<DBMara>[] = [
  {
    material_id: 123132,
    material_type: MaterialType.Product,
    description: 'abc',
    material_group: 120,
    make_buy_mark: 'M',
    costing_global_vendor: 123456,
    super_theme: 10,
    market_exit_date: now(),
  },
];
