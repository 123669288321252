import { DBPackingNonstandardRate } from '@core/schemas/db/schema.db.packaging';
import {
  Calculate_FetchQuery,
  CalculatorPackagingPackingNonstandardCost,
} from '../calculator.types';
import { C, CalculationError, Calculator, useCacheOrFetchQuery } from '../calculator';
import { PK } from '@core/types/types.pk';
import {
  ReportStatement,
  getCostCustomReportStatement,
  getCostReportStatement,
  getRateReportStatement,
} from '../calculator.util.report';
import { Region, RegionEnum } from '@core/schemas/schema.common';
import { PackSource } from '@core/types/types.packaging';
import { round2 } from '../calculator.util';

export async function calculate_PackingNonstandardCost(props: {
  input: {
    packingNonstandardCost: CalculatorPackagingPackingNonstandardCost;
    source: PackSource;
    fmcYear: number;
  };
  cache?: {
    packingNonstandardRates?: DBPackingNonstandardRate[];
    cost?: { fmc1_cost?: number; fmc2_cost?: number };
  };
  query?: { query?: Calculate_FetchQuery };
}): Promise<{ fmc1_cost: number; fmc2_cost: number; report: ReportStatement[] }> {
  const { input, cache } = props;
  const { packingNonstandardCost, source, fmcYear } = input;

  const report: ReportStatement[] = [];

  if (cache?.cost) {
    if (cache.cost.fmc1_cost === undefined) {
      throw new CalculationError({
        region: 'EU',
        message: 'Cost was provided as cache but fmc1_cost was missing',
      });
    }

    if (cache.cost.fmc2_cost === undefined) {
      throw new CalculationError({
        region: 'US',
        message: 'Cost was provided as cache but fmc2_cost was missing',
      });
    }

    return {
      fmc1_cost: round2(cache.cost.fmc1_cost),
      fmc2_cost: round2(cache.cost.fmc2_cost),
      report: [
        getCostReportStatement({
          region: RegionEnum.Enum.EU,
          cache: true,
          cost: cache.cost.fmc1_cost,
        }),
        getCostReportStatement({
          region: RegionEnum.Enum.US,
          cache: true,
          cost: cache.cost.fmc2_cost,
        }),
      ],
    };
  }

  const defaultFmc1 =
    packingNonstandardCost.fmc1quantity === 0 &&
    packingNonstandardCost.fmc1machineTime === 0 &&
    packingNonstandardCost.fmc1operators === 0
      ? 0
      : undefined;
  const defaultFmc2 =
    packingNonstandardCost.fmc2quantity === 0 &&
    packingNonstandardCost.fmc2machineTime === 0 &&
    packingNonstandardCost.fmc2operators === 0
      ? 0
      : undefined;

  if (defaultFmc1 !== undefined && defaultFmc2 !== undefined) {
    return {
      fmc1_cost: round2(defaultFmc1),
      fmc2_cost: round2(defaultFmc1),
      report: [
        getRateReportStatement({ region: 'EU', rateType: 'default_from_quantity' }),
        getRateReportStatement({ region: 'US', rateType: 'default_from_quantity' }),
      ],
    };
  }

  const packingNonstandardRates = await useCacheOrFetchQuery({
    cachedItems: props.cache?.packingNonstandardRates,
    fetchQuery: props.query?.query,
    fetchQueryArgs: {
      pk: PK.PackingNonstandardRate,
      query: Calculator.Pack.Packing.NonstandardCost.Filter.PackingNonstandardRates({
        year: fmcYear,
      }),
    },
    dataTypeDescription: 'packing non-standard rate',
  });

  const fmc1rate = packingNonstandardRates.find(
    (rate) =>
      rate.source === source &&
      rate.packing_type === packingNonstandardCost.packingType &&
      rate.packing_region === 'EU',
  );
  const fmc2rate = packingNonstandardRates.find(
    (rate) =>
      rate.source === source &&
      rate.packing_type === packingNonstandardCost.packingType &&
      rate.packing_region === 'US',
  );

  if (fmc1rate === undefined) {
    throw new CalculationError({
      report: getRateReportStatement({
        region: 'EU',
        rateType: 'Packing non-standard rate',
        rate: 'Not found',
        rateIdentifier: [
          {},
          [
            {
              year: fmcYear,
              source,
              packing_type: packingNonstandardCost.packingType,
              packing_region: 'EU',
            },
          ],
        ],
      }),
    });
  }

  report.push(
    getRateReportStatement({
      region: 'EU',
      rateType: 'Packing non-standard rate',
      rateTypeAdditionl: 'machine',
      rate: fmc1rate.machine_rate,
      rateIdentifier: [fmc1rate, ['year', 'source', 'packing_type', 'packing_region']],
    }),
  );
  report.push(
    getRateReportStatement({
      region: 'EU',
      rateType: 'Packing non-standard rate',
      rateTypeAdditionl: 'operator',
      rate: fmc1rate.operator_rate,
      rateIdentifier: [fmc1rate, ['year', 'source', 'packing_type', 'packing_region']],
    }),
  );

  if (fmc2rate === undefined) {
    throw new CalculationError({
      report: getRateReportStatement({
        region: 'US',
        rateType: 'Packing non-standard rate',
        rate: 'Not found',
        rateIdentifier: [
          {},
          [
            {
              year: fmcYear,
              source,
              packing_type: packingNonstandardCost.packingType,
              packing_region: 'US',
            },
          ],
        ],
      }),
    });
  }

  report.push(
    getRateReportStatement({
      region: 'US',
      rateType: 'Packing non-standard rate',
      rateTypeAdditionl: 'machine',
      rate: fmc2rate.machine_rate,
      rateIdentifier: [fmc2rate, ['year', 'source', 'packing_type', 'packing_region']],
    }),
  );
  report.push(
    getRateReportStatement({
      region: 'US',
      rateType: 'Packing non-standard rate',
      rateTypeAdditionl: 'operator',
      rate: fmc2rate.operator_rate,
      rateIdentifier: [fmc2rate, ['year', 'source', 'packing_type', 'packing_region']],
    }),
  );

  const { cost: fmc1_cost, report: fmc1_report } = calculateCost(
    packingNonstandardCost.fmc1quantity,
    packingNonstandardCost.fmc1machineTime,
    fmc1rate?.machine_rate,
    packingNonstandardCost.fmc1operators,
    fmc1rate?.operator_rate,
    { region: RegionEnum.Enum.EU },
  );

  const { cost: fmc2_cost, report: fmc2_report } = calculateCost(
    packingNonstandardCost.fmc2quantity,
    packingNonstandardCost.fmc2machineTime,
    fmc2rate?.machine_rate,
    packingNonstandardCost.fmc2operators,
    fmc2rate?.operator_rate,
    { region: RegionEnum.Enum.US },
  );

  return {
    fmc1_cost: round2(fmc1_cost),
    fmc2_cost: round2(fmc2_cost),
    report: report.concat(...fmc1_report, ...fmc2_report),
  };
}

function calculateCost(
  quantity: number,
  machineTime: number,
  machineTimeRate: number,
  operators: number,
  operatorRate: number,
  meta: { region: Region },
): { cost: number; report: ReportStatement[] } {
  const report: ReportStatement[] = [];

  const machineCosts = C.QuotientStrict(machineTime, 3600) * machineTimeRate;
  report.push(
    getCostCustomReportStatement({
      region: meta.region,
      cost: machineCosts,
      extraTag: 'machine',
      description: `(Machine time (${machineTime}) / 3600) · Machine rate (${machineTimeRate})`,
    }),
  );

  const operatorCosts = C.QuotientStrict(machineTime * operators, 3600) * operatorRate;
  report.push(
    getCostCustomReportStatement({
      region: meta.region,
      cost: operatorCosts,
      extraTag: 'operator',
      description: `((Machine time (${machineTime}) · Operators (${operators})) / 3600) · Operator rate (${operatorRate})`,
    }),
  );

  const cost = quantity * (machineCosts + operatorCosts);
  report.push(
    getCostCustomReportStatement({
      region: meta.region,
      cost: cost,
      description: `Quantity (${quantity}) · (Machine cost (${machineCosts.toFixed(
        2,
      )}) + Operator cost (${operatorCosts.toFixed(2)}))`,
    }),
  );

  return { cost, report };
}
