import { useEffect, useState } from 'react';
import * as localForage from 'localforage';
import { LF_SUGGESTIONS } from '../definitions';

export function useSuggestions(): {
  addSuggestion: (suggestion: string) => void;
  suggestions: string[];
} {
  const [suggestions, setSuggestions] = useState<string[]>([]);

  useEffect(() => {
    localForage.getItem(LF_SUGGESTIONS).then((savedSuggestions) => {
      if (!Array.isArray(savedSuggestions)) {
        setSuggestions([]);
        return;
      }

      setSuggestions(savedSuggestions);
    });
  }, []);

  useEffect(() => {
    localForage.setItem(LF_SUGGESTIONS, suggestions);
  }, [suggestions]);

  function addSuggestion(suggestion: string) {
    setSuggestions((curr) =>
      [suggestion, ...curr.filter((sug) => sug !== suggestion)].slice(0, 10),
    );
  }

  return { addSuggestion, suggestions };
}
