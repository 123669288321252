export function WelcomePage() {
  return (
    <div
      style={{
        fontSize: 36,
        marginTop: 80,
        textAlign: 'center',
      }}
    >
      Welcome to Novelty Costing!
    </div>
  );
}
