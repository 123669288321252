import { WithRequired } from '@core/util/util.typing';
import { Calculator } from '../calculator';
import { CalculatorCommonPPO, CostComponentFull } from '../calculator.types';
import { round2, round2relaxed } from '../calculator.util';

export function calculate_CC_License(props: {
  cost: { fmc_eu_cost: number; fmc_us_cost: number };
  ppos: CalculatorCommonPPO;
}): WithRequired<CostComponentFull, 'fmc1cost' | 'fmc2cost'> {
  const { ppos, cost } = props;

  return {
    fmc1cost: round2(cost.fmc_eu_cost),
    fmc2cost: round2(cost.fmc_us_cost),
    fmc1ppo: round2relaxed(ppos?.FMC1),
    fmc2ppo: round2relaxed(ppos?.FMC2),
    fmc1variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC1, cost.fmc_eu_cost)),
    fmc2variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC2, cost.fmc_us_cost)),
  };
}
