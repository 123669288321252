import { AdministrationCreateProductEndpoint } from '@core/schemas/endpoint/schema.endpoint.administration';
import { REGEX_CUSTOM_PRODUCT_ID, REGEX_CUSTOM_PRODUCT_ID_OR_EMPTY } from '@core/types/types.regex';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showSuccessToast } from '@frontend/common/lib/functions';

import { FInput } from '@frontend/form/components/FInput';
import { Button } from '@lego/klik-ui';
import { useState } from 'react';

export function CreateProduct() {
  const [productId, setProductId] = useState('');
  const [description, setDescription] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  async function createProduct() {
    setIsCreating(true);
    const [err] = await callEndpoint({
      endpoint: AdministrationCreateProductEndpoint,
      input: { productId: Number(productId), description },
      errorHandling: { header: 'Creating product' },
    });
    setIsCreating(false);

    if (err) {
      return;
    }

    showSuccessToast(
      'Created product',
      `Successfully created product '${convertProductIdTo8digits(productId)}'`,
    );
    setProductId('');
    setDescription('');
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
      <FInput
        label="Product ID"
        value={productId}
        onChange={setProductId}
        align="start"
        regex={REGEX_CUSTOM_PRODUCT_ID_OR_EMPTY}
        placeholder="1234567"
        editable
        inputStyle={{ width: 250 }}
      />
      <FInput
        label="Description"
        value={description}
        align="start"
        onChange={setDescription}
        placeholder="Description"
        editable
        inputStyle={{ width: 250 }}
      />
      <div>
        <Button
          size="sm"
          isLoading={isCreating}
          onClick={createProduct}
          disabled={!productId || !REGEX_CUSTOM_PRODUCT_ID.test(productId)}
        >
          Create
        </Button>
      </div>
    </div>
  );
}
