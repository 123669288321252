import { CalculationError } from '@core/calculator/calculator';

export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
}

export function getCalculationErrorShortDescription(error: unknown) {
  if (error instanceof CalculationError) {
    return error.reportStatement.shortDescription;
  }

  return getErrorMessage(error);
}
