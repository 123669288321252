import { CostComponent } from '@core/types/types.costComponent';

export function sortCostComponents(a: CostComponent | string, b: CostComponent | string) {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  const vals = Object.values(CostComponent);

  const indexA = vals.findIndex((cc) => cc === a);
  const indexB = vals.findIndex((cc) => cc === b);

  // some is a cost component
  if (indexA !== -1 || indexB !== -1) {
    // make sure the unknowns is sorted last
    if (indexA === -1) {
      return 1;
    } else if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  }

  return a.localeCompare(b);
}
