import { Button, ButtonGroup, Modal } from '@lego/klik-ui';
import { CSSProperties, useState } from 'react';

interface ButtonProps {
  text?: string;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'success' | 'info' | 'warning' | 'error';
  loading?: boolean;
  colorScheme?: 'error';
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

interface NCConfirmModalProps {
  header: string;
  description: React.ReactNode;
  isOpen: boolean;
  onCancel(): void;
  onConfirm(): void | Promise<void>;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  variant: 'success' | 'info' | 'warning' | 'error';
  size?: 'full' | 'xl';
}

export function NCConfirmModal(props: NCConfirmModalProps) {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      hideIcon={true}
      isOpen={props.isOpen}
      onClose={props.onCancel}
      variant={props.variant}
      size={props.size}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header fontSize="xl">{props.header}</Modal.Header>
        <Modal.CloseButton />
        <Modal.Body fontSize="md">{props.description}</Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button
              colorScheme={
                props.confirmButtonProps?.colorScheme ||
                (props.variant === 'success' ? 'success' : undefined)
              }
              size={props.confirmButtonProps?.size || 'sm'}
              onClick={async () => {
                setLoading(true);
                await props.onConfirm();
                setLoading(false);
              }}
              variant={props.confirmButtonProps?.variant}
              isLoading={props.confirmButtonProps?.loading || loading}
              style={props.confirmButtonProps?.style}
              disabled={props.confirmButtonProps?.disabled}
              className={
                props.confirmButtonProps?.className +
                (props.variant === 'warning' ? ' modal-confirm-button-warning' : '')
              }
            >
              {props.confirmButtonProps?.text || 'Confirm'}
            </Button>
            <Button
              colorScheme={props.cancelButtonProps?.colorScheme}
              onClick={props.onCancel}
              size={props.cancelButtonProps?.size || 'sm'}
              variant={props.cancelButtonProps?.variant || 'outline'}
              style={props.cancelButtonProps?.style}
              className={props.cancelButtonProps?.className}
            >
              {props.cancelButtonProps?.text || 'Cancel'}
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
