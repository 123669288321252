import { CheckTickBold, CrossBold } from '@lego/klik-ui/icons';
import { Tag } from './Tag';

interface ApprovedRejectedTagProps {
  approved?: boolean | 'AUTO_APPROVED';
  rejected?: boolean;
}

export function ApprovedRejectedTag(props: ApprovedRejectedTagProps) {
  return (
    <Tag
      icon={
        props.rejected ? (
          <CrossBold style={{ marginTop: -2 }} />
        ) : props.approved ? (
          <CheckTickBold />
        ) : undefined
      }
      style={{ background: props.rejected ? '#f2a705' : props.approved ? '#00be3e' : '#a4a4a4' }}
    >
      {props.rejected
        ? 'Rejected'
        : props.approved === 'AUTO_APPROVED'
        ? 'Auto approved'
        : props.approved
        ? 'Approved'
        : 'Not approved'}
    </Tag>
  );
}
