export function skToPrettyKey(sk: string) {
  const elements = sk.split('|');
  return '(' + elements.map((elem) => elem.replace('#', ': ')).join(', ') + ')';
}

export function prettifyUsername(username: string | undefined | unknown) {
  if (typeof username !== 'string') {
    return '';
  }

  if (username.includes('@')) {
    return username.toLowerCase().split('@')[0];
  }

  return username;
}

export function prettifyTimestamp(
  timestamp: string | null | undefined | unknown,
  options?: {
    timeMode: 'SECONDS' | 'MILLISECONDS' | 'HIDE';
  },
) {
  const { timeMode } = options || {};

  if (typeof timestamp !== 'string' || timestamp === '') {
    return '';
  }

  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  const time =
    `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}` +
    (timeMode === 'SECONDS' || timeMode === 'MILLISECONDS'
      ? ':' + seconds.toString().padStart(2, '0')
      : '') +
    (timeMode === 'MILLISECONDS' ? '.' + milliseconds.toString().padStart(3, '0') : '');

  return `${day}/${month}-${year}${timeMode === 'HIDE' ? '' : ` ${time}`}`;
}

export class UnableToSaveError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UnableToSave';
  }
}
