import { TableColumn } from '@frontend/table/table';
import { twoDec } from '@frontend/common/lib/functions';
import { CostOverviewRow } from '@frontend/common/lib/models';
import { ScrapOHLicenseRow, ScrapOHLicenseRowDescription } from './finalCosts.types';
import { FInput } from '@frontend/form/components/FInput';
import { get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL } from '@core/types/types.regex';
import { RegionFMC } from '@core/schemas/schema.common';
import { CostComponent } from '@core/types/types.costComponent';

export function getFinalProductCostColumns(
  commaAsDecimalSeperator: boolean,
): TableColumn<CostOverviewRow>[] {
  return [
    {
      title: 'Cost component',
      dataIndex: 'costComp',
      style: (v: CostOverviewRow['costComp']) => ({
        fontWeight: (
          [CostComponent.LeadPrice, CostComponent.Factor, CostComponent.TotalCosts] as string[]
        ).includes(v)
          ? 'bold'
          : undefined,
      }),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 'auto',
      style: (v, r) => ({
        fontWeight: (
          [CostComponent.LeadPrice, CostComponent.Factor, CostComponent.TotalCosts] as string[]
        ).includes(r.costComp)
          ? 'bold'
          : undefined,
        whiteSpace: 'nowrap',
      }),
    },
    {
      groupName: 'EU',
      title: 'PPO',
      dataIndex: 'fmc1ppo',
      align: 'end',
      style: (v, r) => ({
        fontWeight: (
          [CostComponent.LeadPrice, CostComponent.Factor, CostComponent.TotalCosts] as string[]
        ).includes(r.costComp)
          ? 'bold'
          : undefined,
        whiteSpace: 'nowrap',
      }),
      format: (v) => (v === undefined ? '—' : twoDec(v, commaAsDecimalSeperator)),
    },
    {
      groupName: 'EU',
      title: 'Cost',
      dataIndex: 'fmc1cost',
      align: 'end',
      style: (v, r) => ({
        fontWeight: (
          [CostComponent.LeadPrice, CostComponent.Factor, CostComponent.TotalCosts] as string[]
        ).includes(r.costComp)
          ? 'bold'
          : undefined,
        whiteSpace: 'nowrap',
      }),
      format: (v) => (v === undefined ? '—' : twoDec(v, commaAsDecimalSeperator)),
    },
    {
      groupName: 'EU',
      title: 'Variance',
      dataIndex: 'fmc1variance',
      align: 'end',
      style: (v, r) => ({
        fontWeight: (
          [CostComponent.LeadPrice, CostComponent.Factor, CostComponent.TotalCosts] as string[]
        ).includes(r.costComp)
          ? 'bold'
          : undefined,
        whiteSpace: 'nowrap',
      }),
      format: (v) => (v === undefined ? '—' : twoDec(v, commaAsDecimalSeperator)),
    },
    {
      groupName: 'US',
      title: 'PPO',
      dataIndex: 'fmc2ppo',
      align: 'end',
      style: (v, r) => ({
        fontWeight: (
          [CostComponent.LeadPrice, CostComponent.Factor, CostComponent.TotalCosts] as string[]
        ).includes(r.costComp)
          ? 'bold'
          : undefined,
        whiteSpace: 'nowrap',
      }),
      format: (v) => (v === undefined ? '—' : twoDec(v, commaAsDecimalSeperator)),
    },
    {
      groupName: 'US',
      title: 'Cost',
      dataIndex: 'fmc2cost',
      align: 'end',
      style: (v, r) => ({
        fontWeight: (
          [CostComponent.LeadPrice, CostComponent.Factor, CostComponent.TotalCosts] as string[]
        ).includes(r.costComp)
          ? 'bold'
          : undefined,
        whiteSpace: 'nowrap',
      }),
      format: (v) => (v === undefined ? '—' : twoDec(v, commaAsDecimalSeperator)),
    },
    {
      groupName: 'US',
      title: 'Variance',
      dataIndex: 'fmc2variance',
      align: 'end',
      style: (v, r) => ({
        fontWeight: (
          [CostComponent.LeadPrice, CostComponent.Factor, CostComponent.TotalCosts] as string[]
        ).includes(r.costComp)
          ? 'bold'
          : undefined,
        whiteSpace: 'nowrap',
      }),
      format: (v) => (v === undefined ? '—' : twoDec(v, commaAsDecimalSeperator)),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      style: (v, r) => ({
        fontWeight: (
          [CostComponent.LeadPrice, CostComponent.Factor, CostComponent.TotalCosts] as string[]
        ).includes(r.costComp)
          ? 'bold'
          : undefined,
        whiteSpace: 'nowrap',
      }),
    },
  ];
}

export function getScrapOhLicenseColumns(
  commaAsDecimalSeperator: boolean,
  updateOthersCost: (fmcRegion: RegionFMC, newCost: number) => void,
  updateLicenseCost: (fmcRegion: RegionFMC, newCost: number) => void,
  readOnly: boolean,
): TableColumn<ScrapOHLicenseRow>[] {
  return [
    {
      dataIndex: 'description',
      title: 'Description',
    },
    {
      dataIndex: 'fmc1rate',
      title: 'EU',
      align: 'end',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v: ScrapOHLicenseRow['fmc1rate'], r) => (
        <FInput
          value={v}
          editable={!readOnly}
          style={{ width: 120 }}
          inputStyle={{ fontSize: 14, height: 24 }}
          format={(v) => twoDec(v, commaAsDecimalSeperator)}
          regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL(commaAsDecimalSeperator)}
          emptyValueReplacement={0}
          disallowChangeOnInvalid
          onSubmit={(v) => {
            if (r.description === ScrapOHLicenseRowDescription.Other) {
              updateOthersCost('FMC1', Number(v));
            } else {
              updateLicenseCost('FMC1', Number(v));
            }
          }}
        />
      ),
    },
    {
      dataIndex: 'fmc2rate',
      title: 'US',
      align: 'end',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v: ScrapOHLicenseRow['fmc2rate'], r) => (
        <FInput
          value={v}
          editable={!readOnly}
          style={{ width: 120 }}
          inputStyle={{ fontSize: 14, height: 24 }}
          format={(v) => twoDec(v, commaAsDecimalSeperator)}
          regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL(commaAsDecimalSeperator)}
          emptyValueReplacement={0}
          disallowChangeOnInvalid
          onSubmit={(v) => {
            if (r.description === ScrapOHLicenseRowDescription.Other) {
              updateOthersCost('FMC2', Number(v));
            } else {
              updateLicenseCost('FMC2', Number(v));
            }
          }}
        />
      ),
    },
  ];
}
