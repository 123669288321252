import { DBElemCost } from '@core/schemas/db/schema.db.elements';
import { Calculator } from '../calculator';
import { CostComponentFull, CalculatorCommonPPO } from '../calculator.types';
import { CostComponent } from '@core/types/types.costComponent';
import { round2, round2relaxed } from '../calculator.util';

export function calculate_CC_Elements_full(props: {
  elements: DBElemCost[];
  ppos: CalculatorCommonPPO;
  copyPpoToCost?: boolean;
  costComponent:
    | CostComponent.LEGOElements
    | CostComponent.PurchasedElements
    | CostComponent.OverdoseElements;
}): CostComponentFull {
  const { ppos, elements, copyPpoToCost, costComponent } = props;

  if (copyPpoToCost) {
    return {
      fmc1cost: round2relaxed(ppos?.FMC1),
      fmc2cost: round2relaxed(ppos?.FMC2),
      fmc1ppo: round2relaxed(ppos?.FMC1),
      fmc2ppo: round2relaxed(ppos?.FMC2),
      fmc1variance: round2relaxed(ppos?.FMC1 === undefined ? undefined : 0),
      fmc2variance: round2relaxed(ppos?.FMC2 === undefined ? undefined : 0),
    };
  }

  const cost = Calculator.Elements.CC_Elements.Sum(elements, costComponent);

  return {
    fmc1cost: round2(cost),
    fmc2cost: round2(cost),
    fmc1ppo: round2relaxed(ppos?.FMC1),
    fmc2ppo: round2relaxed(ppos?.FMC2),
    fmc1variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC1, cost)),
    fmc2variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC2, cost)),
  };
}
