import { DBElemCost } from '@core/schemas/db/schema.db.elements';
import { Calculator } from '../calculator';
import { CostComponent } from '@core/types/types.costComponent';
import { round2 } from '../calculator.util';

export function calculate_CC_Elements_sum(
  elements: Pick<DBElemCost, 'total_cost' | 'cost_comp'>[],
  costComponent:
    | CostComponent.LEGOElements
    | CostComponent.PurchasedElements
    | CostComponent.OverdoseElements,
  options?: { noRounding?: boolean },
): number {
  const filteredElements = elements.filter((e) => e.cost_comp === costComponent);

  const sum = Calculator.Common.SumListStrict(filteredElements, 'total_cost');

  if (options?.noRounding) {
    return sum;
  }

  return round2(sum);
}
