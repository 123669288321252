import { useContext, useMemo } from 'react';
import { getProductCostsOverviewColumns } from '@frontend/common/lib/common.columns';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { Table } from '../../table/table';
import { ExportButton } from '@frontend/common/components/ExportButton';
import { usePackagingCostsOverviewRows } from '../lib/usePackagingCostsOverviewRows';
import { UsePackingStandardCostsRowsReturnValue } from '../lib/usePackingStandardCostsRows';
import { UsePackingNonstandardCostsRowsReturnValue } from '../lib/usePackingNonstandardCostsRows';
import { UsePackagingCostsRowsReturnValue } from '../lib/usePackagingCostsRows';
import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';

interface PackagingCostOverviewProps {
  productCosts: PackagingGetEndpointResponse['productCosts'];
  packingStandardCosts: UsePackingStandardCostsRowsReturnValue['packingStandardCostsRows'];
  packingNonstandardCosts: UsePackingNonstandardCostsRowsReturnValue['rows'];
  packagingStandardCost: UsePackagingCostsRowsReturnValue['packagingStandardCostsRows'];
  packagingNonstandardCost: UsePackagingCostsRowsReturnValue['packagingNonstandardCostsRows'];
}

export function PackagingCostOverview(props: PackagingCostOverviewProps) {
  const { userData } = useContext(UserDataContext);

  const {
    cc_Packing: cc50,
    cc_Packaging: cc60,
    total,
  } = usePackagingCostsOverviewRows({
    packingStandardCosts: props.packingStandardCosts.map((psc) => ({
      fmc1_cost: psc.fmc1cost,
      fmc2_cost: psc.fmc2cost,
    })),
    packingNonstandardCosts: props.packingNonstandardCosts.map((pnsc) => ({
      fmc1_cost: pnsc.fmc1cost,
      fmc2_cost: pnsc.fmc2cost,
    })),
    packagingStandardCosts: props.packagingStandardCost.map((psc) => ({
      fmc1_cost: psc.fmc1cost,
      fmc2_cost: psc.fmc2cost,
    })),
    packagingNonstandardCosts: props.packagingNonstandardCost.map((pnsc) => ({
      fmc1_cost_plus_scrap: pnsc.fmc1costPlusScrap,
      fmc2_cost_plus_scrap: pnsc.fmc2costPlusScrap,
    })),
    productCosts: props.productCosts,
  });

  const shownRows = useMemo(() => [cc50, cc60, total], [cc50, cc60, total]);

  const columns = useMemo(
    () => getProductCostsOverviewColumns(!!userData?.comma_as_decimal_seperator),
    [userData?.comma_as_decimal_seperator],
  );

  return (
    <Table
      id="packaging_product-costs"
      rows={shownRows}
      headerContent={<ExportButton rows={shownRows} columns={columns} />}
      removeInfoText
      removeSearch
      removeLastRowBottomBorder
      stackHeaderTitles
      rowKey="costComp"
      columns={columns}
    />
  );
}
