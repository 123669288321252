import { z } from 'zod';
import { defineEndpoint } from '@core/util/util.defineEndpoint';
import { VirtualGroupMemberSchema } from '@core/schemas/schema.common';
import { DBAMLogSchema } from '@core/schemas/db/schema.db.common';

// AMAddMembersToVirtualGroupsEndpoint
export const AMAddMembersToVirtualGroupsEndpoint = defineEndpoint({
  path: '/am/add-members-to-virtual-groups',
  mutation: true,
  input: z.object({
    emails: z.array(z.string()),
    groupNames: z.array(z.string()),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
export type AMAddMembersToVirtualGroupsEndpointInput = z.infer<
  typeof AMAddMembersToVirtualGroupsEndpoint.input
>;
export type AMAddMembersToVirtualGroupsEndpointResponse = z.infer<
  typeof AMAddMembersToVirtualGroupsEndpoint.response
>;

// AMRemoveVirtualMemberEndpoint
export const AMRemoveVirtualMembersEndpoint = defineEndpoint({
  path: '/am/remove-virtual-members',
  mutation: true,
  input: z.object({
    emails: z.array(z.string()),
    groupName: z.string(),
    notGroupNames: z.array(z.string()).optional(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
export type AMRemoveVirtualMembersEndpointInput = z.infer<
  typeof AMRemoveVirtualMembersEndpoint.input
>;
export type AMRemoveVirtualMembersEndpointResponse = z.infer<
  typeof AMRemoveVirtualMembersEndpoint.response
>;

// AMGetVirtualGroupMembersEndpoint
export const AMGetVirtualGroupMembersEndpoint = defineEndpoint({
  path: '/am/get-virtualgroup-members',
  input: z.object({
    groupName: z.string(),
  }),
  response: z.array(VirtualGroupMemberSchema),
});
export type AMGetVirtualGroupMembersEndpointInput = z.infer<
  typeof AMGetVirtualGroupMembersEndpoint.input
>;
export type AMGetVirtualGroupMembersEndpointResponse = z.infer<
  typeof AMGetVirtualGroupMembersEndpoint.response
>;

// AMGetLogsForVirtualGroupEndpoint
export const AMGetLogsForVirtualGroupEndpoint = defineEndpoint({
  path: '/am/get-logs-for-virtual-group',
  input: z.object({
    groupName: z.string(),
  }),
  response: z.array(DBAMLogSchema),
});
export type AMGetLogsForVirtualGroupEndpointInput = z.infer<
  typeof AMGetLogsForVirtualGroupEndpoint.input
>;
export type AMGetLogsForVirtualGroupEndpointResponse = z.infer<
  typeof AMGetLogsForVirtualGroupEndpoint.response
>;

// AMGetAllUsersEndpoint
export const AMGetAllUsersEndpoint = defineEndpoint({
  path: '/am/get-all-users',
  input: z.object({}),
  response: z.array(
    z.object({
      email: z.string(),
      virtualGroups: z.array(z.string()),
    }),
  ),
});
export type AMGetAllUsersEndpointInput = z.infer<typeof AMGetAllUsersEndpoint.input>;
export type AMGetAllUsersEndpointResponse = z.infer<typeof AMGetAllUsersEndpoint.response>;
