import { FSelect } from '@frontend/form/components/FSelect';
import { OverheadType, ScrapType } from '../lib/useScrapAndOH';
import { stringify } from '@frontend/common/lib/functions';
import { FSelectOption } from '@frontend/form/lib/form.types';
import { useContext, useMemo } from 'react';
import { getScrapOhLicenseColumns } from '../lib/finalCosts.columns';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { Table } from '@frontend/table/table';
import { ScrapOHLicenseRowDescription } from '../lib/finalCosts.types';
import { DBElementsScrapRate, DBOHRate } from '@core/schemas/db/schema.db.common';
import { RegionFMC } from '@core/schemas/schema.common';
import { DBProdHeadFC } from '@core/schemas/db/schema.db.prod';

export interface ScrapOHLicenseProps {
  readOnly: boolean;
  prodHead: Pick<DBProdHeadFC, 'overhead_type' | 'scrap_type'> | undefined;
  selectedOverheadRate: DBOHRate | undefined;
  selectedScrapRate: DBElementsScrapRate | undefined;
  updateOverheadRate(type: OverheadType): void;
  updateScrapRate(type: ScrapType): void;
  updateOthersCost(fmcRegion: RegionFMC, newCost: number): void;
  updateLicenseCost(fmcRegion: RegionFMC, newCost: number): void;
  overheadOptions: FSelectOption[];
  scrapOptions: FSelectOption[];
  licenseCosts: { fmc1cost: number; fmc2cost: number };
  othersCosts: { fmc1cost: number; fmc2cost: number };
}

export function ScrapOHLicense(props: ScrapOHLicenseProps) {
  const { userData } = useContext(UserDataContext);

  const columns = useMemo(
    () =>
      getScrapOhLicenseColumns(
        !!userData?.comma_as_decimal_seperator,
        props.updateOthersCost,
        props.updateLicenseCost,
        props.readOnly,
      ),
    [
      userData?.comma_as_decimal_seperator,
      props.updateOthersCost,
      props.updateLicenseCost,
      props.readOnly,
    ],
  );

  return (
    <div>
      <div
        style={{
          marginBottom: 24,
          display: 'grid',
          columnGap: 16,
          rowGap: 8,
          gridTemplateColumns: 'min-content fit-content(1000px) auto',
          alignItems: 'center',
        }}
      >
        <div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Scrap type:</div>
        <FSelect
          value={stringify(props.selectedScrapRate?.type || props.prodHead?.scrap_type)}
          readOnlyAlign="start"
          placeholder="Select scrap type"
          editable={!props.readOnly}
          onChange={(v) => {
            if (v === undefined) {
              props.updateScrapRate(undefined);
            } else {
              props.updateScrapRate(Number(v) as ScrapType);
            }
          }}
          style={{ width: 'auto' }}
          options={props.scrapOptions}
        />
        <div>
          {!props.selectedScrapRate ? '' : `${props.selectedScrapRate.scrap_rate_percent}%`}
        </div>
        <div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Overhead type:</div>
        <FSelect
          value={stringify(props.selectedOverheadRate?.type || props.prodHead?.overhead_type)}
          editable={!props.readOnly}
          readOnlyAlign="start"
          placeholder="Select overhead type"
          onChange={(v) => {
            if (v === undefined) {
              props.updateOverheadRate(undefined);
            } else {
              props.updateOverheadRate(Number(v) as OverheadType);
            }
          }}
          options={props.overheadOptions}
          style={{ width: 'auto' }}
        />
        <div>
          {!props.selectedOverheadRate
            ? ''
            : `${props.selectedOverheadRate.oh_rate_percent}%, Finished goods: ${props.selectedOverheadRate.planning_oh_finished_goods_dkk} DKK, Prepacks: ${props.selectedOverheadRate.planning_oh_prepacks_dkk} DKK`}
        </div>
      </div>
      <Table
        id="final-costs_scrap-oh-license"
        rows={[
          {
            description: ScrapOHLicenseRowDescription.License,
            fmc1rate: props.licenseCosts.fmc1cost,
            fmc2rate: props.licenseCosts.fmc2cost,
          },
          {
            description: ScrapOHLicenseRowDescription.Other,
            fmc1rate: props.othersCosts.fmc1cost,
            fmc2rate: props.othersCosts.fmc2cost,
          },
        ]}
        columns={columns}
        removeInfoText
        removeSearch
        rowKey="description"
      />
    </div>
  );
}
