import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { TableColumn } from './table';

interface RowGridProps<T extends object> {
  children: React.ReactNode;
  multiSelection?: {
    selected: T[];
    setSelected: React.Dispatch<React.SetStateAction<T[]>>;
  };
  columns: TableColumn<T>[];
  stickyHeaderContainerHeight?: number;
  fixedHeight: number | undefined;
  isRefreshing: boolean | undefined;
  innerRef: MutableRefObject<HTMLDivElement | null>;
}

export function RowGrid<T extends object>(props: RowGridProps<T>) {
  const [hoveredRowKey, setHoveredRowKey] = useState('');
  const [scrollbarPresent, setScrollbarPresent] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);

  const checkForScrollbar = () => {
    if (!divRef.current) {
      return;
    }

    if (divRef.current.scrollWidth > divRef.current.clientWidth) {
      setScrollbarPresent(true);
    } else {
      setScrollbarPresent(false);
    }
  };

  useEffect(() => {
    const mutationObserver = new MutationObserver(checkForScrollbar);
    const resizeObserver = new ResizeObserver(checkForScrollbar);

    if (divRef.current) {
      mutationObserver.observe(divRef.current, { childList: true, subtree: true });
      resizeObserver.observe(divRef.current);
    }

    // Cleanup
    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={divRef}
      style={{
        height:
          !props.isRefreshing && props.fixedHeight
            ? props.fixedHeight + (scrollbarPresent ? 16 : 0)
            : undefined,
        gridAutoRows: 'min-content',
        display: 'grid',
        gridTemplateColumns:
          (props.multiSelection ? 'min-content ' : '') +
          props.columns.map((col) => col.width || 'max-content').join(' '),
        fontSize: '14px',
        overflow: 'auto',
        alignItems: 'center',
        maxHeight: props.stickyHeaderContainerHeight,
      }}
      className="table-rows-container"
      // ref={props.innerRef}
    >
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child) && typeof child.type !== 'string') {
          return React.cloneElement(child, {
            hoveredrowkey: hoveredRowKey,
            sethoveredrowkey: setHoveredRowKey,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any);
        }

        return child;
      })}
    </div>
  );
}
