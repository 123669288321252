import { CSSProperties } from 'react';

interface TagProps {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  style?: CSSProperties;
  onClick?(): void;
}

export function Tag(props: TagProps) {
  return (
    <div onClick={props.onClick}>
      <div
        style={{
          padding: '0 12px',
          borderRadius: 100,
          height: 32,
          background: '#3b7e9b',
          display: 'flex',
          alignItems: 'center',
          color: 'white',
          fontWeight: 500,
          width: 'min-content',
          ...props.style,
        }}
      >
        {props.icon && <div style={{ marginRight: 8 }}>{props.icon}</div>}
        <div style={{ whiteSpace: 'nowrap' }}>{props.children}</div>
      </div>
    </div>
  );
}
