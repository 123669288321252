import { FSelect } from '@frontend/form/components/FSelect';
import { Button } from '@lego/klik-ui';
import { showInternalApplicationError } from '@frontend/common/lib/functions';
import { Filter } from '../dataMaintenance.types';
import { FilterSettingsRange } from './FilterSettingsRange';
import { FilterType } from '@core/types/types.es';
import { SchemaProperty } from '@core/types/types.schema';
import {
  FilterExclude,
  FilterInclude,
  FilterRange,
} from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';
import { FilterSettingsIncludeExclude } from './FilterSettingsIncludeExclude';
import { FilterSettingsEnumIncludeExclude } from './FilterSettingsEnumIncludeExclude';

interface FilterPanelModalContentProps {
  property: SchemaProperty & { filter: Filter };
  setFilterValue(value: Omit<Filter, 'isNumberType'>): void;
  onClose(): void;
}

export function FilterPanelModalContent(props: FilterPanelModalContentProps) {
  return (
    <div>
      <FSelect
        topLabel="Filter type"
        editable
        value={props.property.filter.type}
        onChange={(v: FilterType) => {
          if (v === FilterType.Include || v === FilterType.Exclude) {
            props.setFilterValue({ type: v, value: [] });
          } else if (v === FilterType.Range) {
            props.setFilterValue({
              type: FilterType.Range,
              value: { min: undefined, max: undefined },
            });
          } else {
            showInternalApplicationError();
          }
        }}
        options={[
          { value: FilterType.Include, text: FilterType.Include },
          { value: FilterType.Exclude, text: FilterType.Exclude },
          {
            value: FilterType.Range,
            text: FilterType.Range,
            disabled: !!props.property.enumValues,
          },
        ]}
        autoFit
        style={{ marginBottom: 24 }}
      />
      {props.property.filter.type === FilterType.Include ? (
        !!props.property.enumValues ? (
          <FilterSettingsEnumIncludeExclude
            property={props.property as typeof props.property & { filter: FilterInclude }}
            onChange={(v) => props.setFilterValue({ type: FilterType.Include, value: v })}
          />
        ) : (
          <FilterSettingsIncludeExclude
            property={props.property as typeof props.property & { filter: FilterInclude }}
            onChange={(v) => props.setFilterValue({ type: FilterType.Include, value: v })}
          />
        )
      ) : props.property.filter.type === FilterType.Exclude ? (
        !!props.property.enumValues ? (
          <FilterSettingsEnumIncludeExclude
            property={props.property as typeof props.property & { filter: FilterExclude }}
            onChange={(v) => props.setFilterValue({ type: FilterType.Exclude, value: v })}
          />
        ) : (
          <FilterSettingsIncludeExclude
            property={props.property as typeof props.property & { filter: FilterExclude }}
            onChange={(v) => props.setFilterValue({ type: FilterType.Exclude, value: v })}
          />
        )
      ) : props.property.filter.type === FilterType.Range ? (
        <FilterSettingsRange
          property={props.property as typeof props.property & { filter: FilterRange }}
          onChange={(v) => props.setFilterValue({ type: FilterType.Range, value: v })}
        />
      ) : (
        <div />
      )}
      <div style={{ marginTop: 24, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="sm"
          onClick={() => {
            props.onClose();
            props.setFilterValue({ type: FilterType.Exact, value: '' });
          }}
        >
          Clear filter
        </Button>
      </div>
    </div>
  );
}
