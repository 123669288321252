import { Calculator } from '@core/calculator/calculator';

import { CostOverviewRow } from '@frontend/common/lib/models';
import { useMemo } from 'react';
import { CalculatorBICCInstructionsBooks } from '@core/calculator/calculator.types';

import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { BIGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.bi';
import { DBBICost } from '@core/schemas/db/schema.db.bi';
import { CostComponent } from '@core/types/types.costComponent';

export function useBiCostsOverviewRow(props: {
  books:
    | Omit<OmitDBRecord<DBBICost & { _manualCost: number }>, 'product_id' | 'revision'>[]
    | undefined;
  productCosts: BIGetEndpointResponse['productCosts'] | undefined;
  copyPpoToCost?: boolean;
}): {
  rows: CostOverviewRow[] | undefined;
  cc_Instructions: CostOverviewRow;
} {
  const { books, productCosts, copyPpoToCost } = props;

  const rows: CostOverviewRow[] | undefined = useMemo(() => {
    if (!books) {
      return undefined;
    }

    const allRows: CostOverviewRow[] = [];

    const numberOfBooks = books.reduce((max, curr) => Math.max(max, curr.book_number), 0);

    for (let i = 1; i <= numberOfBooks; i++) {
      allRows.push({
        costComp: `${i}`,
        description: `${i}`,
        fmc1cost: Calculator.BI.BICost.SelectRelevantCost(
          books.find((biCost) => biCost.book_number === i && biCost.fmc_region === 'FMC1'),
        ),
        fmc1ppo: undefined,
        fmc1variance: undefined,
        fmc2cost: Calculator.BI.BICost.SelectRelevantCost(
          books.find((biCost) => biCost.book_number === i && biCost.fmc_region === 'FMC2'),
        ),
        fmc2ppo: undefined,
        fmc2variance: undefined,
        currency: 'DKK',
      });
    }

    return allRows;
  }, [books]);

  const cc_Instructions = useMemo(() => {
    const books: CalculatorBICCInstructionsBooks = [];

    rows?.forEach((r) => {
      books.push({ cost: r.fmc1cost, fmcRegion: 'FMC1' });
      books.push({ cost: r.fmc2cost, fmcRegion: 'FMC2' });
    });

    const costPpoVariance = Calculator.BI.CC_Instructions.Full({
      books,
      ppos: productCosts?.ppo,
      copyPpoToCost: copyPpoToCost,
    });

    const r: CostOverviewRow = {
      costComp: CostComponent.Instructions,
      description: 'Building instructions',
      currency: 'DKK',
      ...costPpoVariance,
    };

    return r;
  }, [rows, productCosts, copyPpoToCost]);

  return { rows, cc_Instructions };
}
