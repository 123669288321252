interface QueryLandingPageProps {
  title: string;
  children: React.ReactNode;
}

export function QueryLandingPage(props: QueryLandingPageProps) {
  return (
    <div>
      <div style={{ margin: '12px 0 48px', fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>
        {props.title + ' report'}
      </div>
      {props.children}
    </div>
  );
}
