import { QueryLandingPage } from '@frontend/reporting/components/QueryLandingPage';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { CommonReportQueryPanel } from './CommonReportQueryPanel';
import { InternalEndpoint } from '@core/types/types.endpoint';
import { ReportCommonQueryInput } from '@core/schemas/endpoint/schema.endpoint.reporting';
import { showInfoToast } from '@frontend/common/lib/functions';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';
import { REGEX_PRODUCT_ID_STRICT } from '@core/types/types.regex';

interface CommonReportPageProps {
  type:
    | 'Elements costs'
    | 'Elements errors'
    | 'Building instructions'
    | 'Building instructions (LBX)'
    | 'Packaging'
    | 'Costing status'
    | 'Final costs';
  endpoint: InternalEndpoint;
}

export function CommonReportPage(props: CommonReportPageProps) {
  async function downloadReport(query: ReportCommonQueryInput) {
    let convertProductIds = true;

    if (query.productIds.find((pid) => REGEX_PRODUCT_ID_STRICT.test(`${pid}`))) {
      convertProductIds = false;
    } else if (
      query.productIdRanges.find(
        ({ from, to }) =>
          REGEX_PRODUCT_ID_STRICT.test(`${from}`) || REGEX_PRODUCT_ID_STRICT.test(`${to}`),
      )
    ) {
      convertProductIds = false;
    }

    const correctedProductIds: ReportCommonQueryInput = {
      ...query,
      productIds: query.productIds.map(convertProductIdTo8digits),
      productIdRanges: query.productIdRanges.map((pidr) => ({
        ...pidr,
        from: convertProductIdTo8digits(pidr.from),
        to: convertProductIdTo8digits(pidr.to),
      })),
      convertProductIds,
    };
    const [err, data] = await callEndpoint({
      endpoint: props.endpoint,
      input: correctedProductIds,
      errorHandling: {
        header: 'Generating report',
        handlers: {
          404: (error) => showInfoToast('Generating report', error),
          504: () => showInfoToast('Generating report', 'Report link will be sent via mail'),
        },
      },
    });

    if (err) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.open(data.url, '_blank', 'noopener,noreferrer');
  }

  return (
    <QueryLandingPage title={props.type}>
      <CommonReportQueryPanel onOk={downloadReport} />
    </QueryLandingPage>
  );
}
