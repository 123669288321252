import { FInput } from '@frontend/form/components/FInput';
import { FSelect } from '@frontend/form/components/FSelect';
import { showInternalApplicationError, threeDec, twoDec } from '@frontend/common/lib/functions';
import { TableColumn } from '@frontend/table/table';
import {
  PackingStandardCostsRow,
  PackingNonstandardCostsNumberKey,
  PackingNonstandardCostsRow,
  PackagingStandardCostsRow,
  PackagingNonstandardCostsRow,
} from './packaging.types';
import { UsePackagingCostsRowsReturnValue } from './usePackagingCostsRows';
import {
  REGEX_NONNEGATIVE_INTEGER,
  REGEX_NONNEGATIVE_INTEGER_OR_EMPTY,
  get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL,
  get_REGEX_NONNEGATIVE_INTEGER_OR_1TO3DECIMAL,
} from '@core/types/types.regex';
import { RegionFMC } from '@core/schemas/schema.common';
import { DBPackingNonstandardKey } from '@core/schemas/db/schema.db.packaging';
import { ReportSetter, getReportColumn } from '@frontend/common/lib/reportColumn';
import { PackagingSize } from '@core/schemas/interface/schema.interface';
import { PackingKeyId, PackingNonstandardKey } from '@core/types/types.packaging';
import { TOTAL_KEY } from '../../../../core/src/const/const.TOTAL_KEY';
import { MATERIAL_NOT_FOUND_TEXT } from '@core/const/const.MATERIAL_NOT_FOUND_TEXT';

export function getPackingStandardCostsColumns(input: {
  onQuantityChange:
    | ((packingKey: PackingKeyId, fmcRegion: RegionFMC, newQuantity: number) => void)
    | undefined;
  onReportSelect: ReportSetter;
  commaAsDecimalSeperator: boolean;
  showReport: boolean;
}): TableColumn<PackingStandardCostsRow>[] {
  const { commaAsDecimalSeperator, onQuantityChange, onReportSelect, showReport } = input;

  return [
    {
      title: 'Key',
      dataIndex: 'packingKey',
      disableSorting: true,
      style: (v, r) => ({ fontWeight: r.description === TOTAL_KEY ? 'bold' : undefined }),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 'auto',
      disableSorting: true,
      style: (v, r) => ({ fontWeight: r.description === TOTAL_KEY ? 'bold' : undefined }),
    },
    {
      groupName: 'Quantity',
      title: 'EU',
      dataIndex: 'fmc1quantity',
      pasteParseType: 'number',
      disableSorting: true,
      align: 'end',
      render: (v: PackingStandardCostsRow['fmc1quantity'], r) =>
        r.description === TOTAL_KEY ? (
          <div style={{ fontWeight: 'bold', marginRight: onQuantityChange ? 8 : 0 }}>{v}</div>
        ) : (
          <FInput
            value={v}
            editable={!!onQuantityChange}
            style={{ width: 60 }}
            inputStyle={{ fontSize: 14, height: 24 }}
            regex={REGEX_NONNEGATIVE_INTEGER}
            emptyValueReplacement={0}
            disallowChangeOnInvalid
            onSubmit={(v) => {
              if (!onQuantityChange) {
                showInternalApplicationError();
                return;
              }
              onQuantityChange(r.packingKey, 'FMC1', Number(v));
            }}
          />
        ),
    },
    {
      groupName: 'Quantity',
      title: 'US',
      dataIndex: 'fmc2quantity',
      pasteParseType: 'number',
      disableSorting: true,
      align: 'end',
      render: (v: PackingStandardCostsRow['fmc2quantity'], r) =>
        r.description === TOTAL_KEY ? (
          <div style={{ fontWeight: 'bold', marginRight: onQuantityChange ? 8 : 0 }}>{v}</div>
        ) : (
          <FInput
            value={v}
            editable={!!onQuantityChange}
            style={{ width: 60 }}
            inputStyle={{ fontSize: 14, height: 24 }}
            regex={REGEX_NONNEGATIVE_INTEGER}
            emptyValueReplacement={0}
            disallowChangeOnInvalid
            onSubmit={(v) => {
              if (!onQuantityChange) {
                showInternalApplicationError();
                return;
              }
              onQuantityChange(r.packingKey, 'FMC2', Number(v));
            }}
          />
        ),
    },
    {
      groupName: 'Cost',
      title: 'EU',
      dataIndex: 'fmc1cost',
      disableSorting: true,
      align: 'end',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({ fontWeight: r.description === TOTAL_KEY ? 'bold' : undefined }),
    },
    {
      groupName: 'Cost',
      title: 'US',
      dataIndex: 'fmc2cost',
      disableSorting: true,
      align: 'end',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({ fontWeight: r.description === TOTAL_KEY ? 'bold' : undefined }),
    },
    {
      title: 'BUn',
      dataIndex: 'bun',
      disableSorting: true,
      style: (v, r) => ({ fontWeight: r.description === TOTAL_KEY ? 'bold' : undefined }),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      disableSorting: true,
      style: (v, r) => ({ fontWeight: r.description === TOTAL_KEY ? 'bold' : undefined }),
    },
    getReportColumn<PackingStandardCostsRow>('description', onReportSelect, showReport),
  ];
}

export function getPackingNonstandardCostsColumns(input: {
  packingNonstandardCostsHandlers:
    | {
        changePackingType: (rowId: string, newPackingType: PackingNonstandardKey) => void;
        changeDescription: (rowId: string, newDescription: string) => void;
        changeNumber: (
          rowId: string,
          key: keyof Pick<PackingNonstandardCostsRow, PackingNonstandardCostsNumberKey>,
          newValue: number,
        ) => void;
      }
    | undefined;
  onReportSelect: ReportSetter;
  commaAsDecimalSeperator: boolean;
  showReport: boolean;
  packingNonstandardKeys: DBPackingNonstandardKey[];
}): TableColumn<PackingNonstandardCostsRow>[] {
  const {
    commaAsDecimalSeperator,
    onReportSelect,
    packingNonstandardCostsHandlers,
    showReport,
    packingNonstandardKeys,
  } = input;

  function getNumberChangeCell(
    r: PackingNonstandardCostsRow,
    key: PackingNonstandardCostsNumberKey,
  ) {
    if (r.rowId === TOTAL_KEY) {
      return (
        <div style={{ fontWeight: 'bold', marginRight: packingNonstandardCostsHandlers ? 8 : 0 }}>
          {twoDec(r[key], commaAsDecimalSeperator)}
        </div>
      );
    }

    return (
      <FInput
        editable={!!packingNonstandardCostsHandlers}
        value={r[key]}
        format={(v) => twoDec(v, commaAsDecimalSeperator)}
        regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL(commaAsDecimalSeperator)}
        emptyValueReplacement={0}
        disallowChangeOnInvalid
        onSubmit={(v) => {
          if (!packingNonstandardCostsHandlers) {
            showInternalApplicationError();
            return;
          }
          packingNonstandardCostsHandlers.changeNumber(r.rowId, key, Number(v));
        }}
        style={{ width: 80 }}
        inputStyle={{ fontSize: 14, height: 24 }}
      />
    );
  }

  return [
    {
      title: 'Packing type',
      dataIndex: 'packingType',
      disableSorting: true,
      format: (v, r) => (r.rowId === TOTAL_KEY ? TOTAL_KEY : v),
      render: (v: PackingNonstandardCostsRow['packingType'], r) =>
        r.rowId === TOTAL_KEY ? (
          <div style={{ fontWeight: 'bold' }}>Total</div>
        ) : (
          <FSelect
            inputStyle={{ fontSize: 14, height: 24 }}
            placeholder="Select packing type"
            value={v}
            options={packingNonstandardKeys.map(({ key, description }) => ({
              value: key,
              text: description,
            }))}
            onChange={(v) => {
              if (!packingNonstandardCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packingNonstandardCostsHandlers.changePackingType(
                r.rowId,
                v as PackingNonstandardKey,
              );
            }}
            editable={!!packingNonstandardCostsHandlers}
          />
        ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      disableSorting: true,
      format: (v, r) => (r.rowId === TOTAL_KEY ? '' : v),
      render: (v: string, r) =>
        r.rowId === TOTAL_KEY ? (
          ''
        ) : (
          <FInput
            value={v}
            inputStyle={{ fontSize: 14, height: 24 }}
            align="start"
            onChange={(v) => {
              if (!packingNonstandardCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packingNonstandardCostsHandlers.changeDescription(r.rowId, v);
            }}
            editable={!!packingNonstandardCostsHandlers}
          />
        ),
    },
    {
      groupName: 'EU',
      title: 'Quantity',
      dataIndex: 'fmc1quantity',
      pasteParseType: 'number',
      align: 'end',
      disableSorting: true,
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v, r) => getNumberChangeCell(r, 'fmc1quantity'),
    },
    {
      groupName: 'EU',
      title: 'Machine time (s)',
      dataIndex: 'fmc1machineTime',
      pasteParseType: 'number',
      align: 'end',
      disableSorting: true,
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v, r) => getNumberChangeCell(r, 'fmc1machineTime'),
    },
    {
      groupName: 'EU',
      title: 'Operators',
      dataIndex: 'fmc1operators',
      pasteParseType: 'number',
      align: 'end',
      disableSorting: true,
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v, r) => getNumberChangeCell(r, 'fmc1operators'),
    },
    {
      groupName: 'US',
      title: 'Quantity',
      dataIndex: 'fmc2quantity',
      pasteParseType: 'number',
      align: 'end',
      disableSorting: true,
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v, r) => getNumberChangeCell(r, 'fmc2quantity'),
    },
    {
      groupName: 'US',
      title: 'Machine time (s)',
      dataIndex: 'fmc2machineTime',
      pasteParseType: 'number',
      align: 'end',
      disableSorting: true,
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v, r) => getNumberChangeCell(r, 'fmc2machineTime'),
    },
    {
      groupName: 'US',
      title: 'Operators',
      dataIndex: 'fmc2operators',
      pasteParseType: 'number',
      align: 'end',
      disableSorting: true,
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v, r) => getNumberChangeCell(r, 'fmc2operators'),
    },
    {
      groupName: 'Cost',
      title: 'EU',
      dataIndex: 'fmc1cost',
      align: 'end',
      disableSorting: true,
      style: (v, r) => ({
        fontWeight: r.rowId === TOTAL_KEY ? 'bold' : undefined,
      }),
      format: (v) => twoDec(v, commaAsDecimalSeperator),
    },
    {
      groupName: 'Cost',
      title: 'US',
      dataIndex: 'fmc2cost',
      align: 'end',
      disableSorting: true,
      style: (v, r) => ({
        fontWeight: r.rowId === TOTAL_KEY ? 'bold' : undefined,
      }),
      format: (v) => twoDec(v, commaAsDecimalSeperator),
    },
    {
      title: 'BUn',
      dataIndex: 'bun',
      disableSorting: true,
      style: (v, r) => ({
        fontWeight: r.rowId === TOTAL_KEY ? 'bold' : undefined,
      }),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      width: 'auto',
      disableSorting: true,
      style: (v, r) => ({
        fontWeight: r.rowId === TOTAL_KEY ? 'bold' : undefined,
      }),
    },
    getReportColumn('rowId', onReportSelect, showReport),
  ];
}

export function getPackagingStandardCostsColumns(input: {
  packagingStandardCostsHandlers:
    | Pick<
        UsePackagingCostsRowsReturnValue['standardHandlers'],
        'changeDescription' | 'changeQuantity' | 'changeCost'
      >
    | undefined;
  onReportSelect: ReportSetter;
  commaAsDecimalSeperator: boolean;
  showReport: boolean;
}): TableColumn<PackagingStandardCostsRow>[] {
  const { commaAsDecimalSeperator, onReportSelect, packagingStandardCostsHandlers, showReport } =
    input;

  return [
    {
      title: 'ID',
      dataIndex: 'key',
      disableSorting: true,
      style: (v) => ({ fontWeight: v === TOTAL_KEY ? 'bold' : undefined }),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 'auto',
      disableSorting: true,
      render: (v: PackagingStandardCostsRow['description'], r) =>
        r.custom ? (
          <FInput
            editable={!!packagingStandardCostsHandlers}
            value={v}
            onChange={(v) => {
              if (!packagingStandardCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packagingStandardCostsHandlers.changeDescription(r.key, v);
            }}
            style={{ width: 200 }}
            align="start"
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ) : (
          <div
            style={{
              whiteSpace: 'nowrap',
              fontWeight: r.key === TOTAL_KEY ? 'bold' : undefined,
            }}
          >
            {v}
          </div>
        ),
    },
    {
      groupName: 'Quantity',
      title: 'EU',
      dataIndex: 'fmc1quantity',
      disableSorting: true,
      pasteParseType: 'number',
      format: (v, r) =>
        r.key === TOTAL_KEY || (!r.custom && r.key === 'FOIL')
          ? ''
          : threeDec(v, commaAsDecimalSeperator),
      render: (v: PackagingStandardCostsRow['fmc1quantity'], r) =>
        r.key === TOTAL_KEY || (!r.custom && r.key === 'FOIL') ? (
          ''
        ) : (
          <FInput
            editable={!!packagingStandardCostsHandlers}
            value={v}
            regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1TO3DECIMAL(commaAsDecimalSeperator)}
            emptyValueReplacement={0}
            disallowChangeOnInvalid
            format={(v) => threeDec(v, commaAsDecimalSeperator)}
            onSubmit={(quantity) => {
              if (!packagingStandardCostsHandlers) {
                showInternalApplicationError();
                return;
              }

              packagingStandardCostsHandlers.changeQuantity(r.key, 'FMC1', Number(quantity));
            }}
            style={{ width: 60 }}
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ),
    },
    {
      groupName: 'Quantity',
      title: 'US',
      dataIndex: 'fmc2quantity',
      disableSorting: true,
      pasteParseType: 'number',
      format: (v, r) =>
        r.key === TOTAL_KEY || (!r.custom && r.key === 'FOIL')
          ? ''
          : threeDec(v, commaAsDecimalSeperator),
      render: (v: PackagingStandardCostsRow['fmc2quantity'], r) =>
        r.key === TOTAL_KEY || (!r.custom && r.key === 'FOIL') ? (
          ''
        ) : (
          <FInput
            editable={!!packagingStandardCostsHandlers}
            value={v}
            regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1TO3DECIMAL(commaAsDecimalSeperator)}
            emptyValueReplacement={0}
            disallowChangeOnInvalid
            format={(v) => threeDec(v, commaAsDecimalSeperator)}
            onSubmit={(quantity) => {
              if (!packagingStandardCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packagingStandardCostsHandlers.changeQuantity(r.key, 'FMC2', Number(quantity));
            }}
            style={{ width: 60 }}
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ),
    },
    {
      title: 'BUn',
      dataIndex: 'bun',
      disableSorting: true,
      style: (v, r) => ({ fontWeight: r.key === TOTAL_KEY ? 'bold' : undefined }),
      format: (v, r) => (!r.custom && r.key === 'FOIL' ? '' : v),
    },
    {
      groupName: 'Cost',
      title: 'EU',
      dataIndex: 'fmc1cost',
      align: 'end',
      disableSorting: true,
      pasteParseType: 'number',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v: PackagingStandardCostsRow['fmc1cost'], r) =>
        r.custom ? (
          <FInput
            editable={!!packagingStandardCostsHandlers}
            value={v}
            format={(v) => twoDec(v, commaAsDecimalSeperator)}
            regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL(commaAsDecimalSeperator)}
            emptyValueReplacement={0}
            disallowChangeOnInvalid
            onSubmit={(v) => {
              if (!packagingStandardCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packagingStandardCostsHandlers.changeCost(r.key, 'FMC1', Number(v));
            }}
            style={{ width: 60 }}
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ) : (
          <div style={{ fontWeight: r.key === TOTAL_KEY ? 'bold' : undefined }}>
            {twoDec(v, commaAsDecimalSeperator)}
          </div>
        ),
    },
    {
      groupName: 'Cost',
      title: 'US',
      dataIndex: 'fmc2cost',
      align: 'end',
      disableSorting: true,
      pasteParseType: 'number',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v: PackagingStandardCostsRow['fmc2cost'], r) =>
        r.custom ? (
          <FInput
            editable={!!packagingStandardCostsHandlers}
            value={v}
            format={(v) => twoDec(v, commaAsDecimalSeperator)}
            regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL(commaAsDecimalSeperator)}
            emptyValueReplacement={0}
            disallowChangeOnInvalid
            onSubmit={(v) => {
              if (!packagingStandardCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packagingStandardCostsHandlers.changeCost(r.key, 'FMC2', Number(v));
            }}
            style={{ width: 60 }}
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ) : (
          <div style={{ fontWeight: r.key === TOTAL_KEY ? 'bold' : undefined }}>
            {twoDec(v, commaAsDecimalSeperator)}
          </div>
        ),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      disableSorting: true,
      style: (v, r) => ({ fontWeight: r.key === TOTAL_KEY ? 'bold' : undefined }),
    },
    getReportColumn('description', onReportSelect, showReport),
  ];
}

export function getPackagingNonstandardCostsColumns(input: {
  packagingNonstandardCostsHandlers:
    | Pick<
        UsePackagingCostsRowsReturnValue['nonstandardHandlers'],
        'changeDescription' | 'changeQuantity' | 'changeCost'
      >
    | undefined;
  updateRowWithMaterial: (
    key: string,
    fmcRegion: RegionFMC,
    materialId: number | undefined,
  ) => Promise<void>;
  onReportSelect: ReportSetter;
  packagingCostsMaterials: UsePackagingCostsRowsReturnValue['materials'];
  commaAsDecimalSeperator: boolean;
  showReport: boolean;
}): TableColumn<PackagingNonstandardCostsRow>[] {
  const {
    commaAsDecimalSeperator,
    onReportSelect,
    packagingNonstandardCostsHandlers: packagingCostsHandlers,
    packagingCostsMaterials,
    showReport,
    updateRowWithMaterial,
  } = input;

  return [
    {
      title: 'ID',
      dataIndex: 'rowId',
      disableSorting: true,
      style: (v) => ({ fontWeight: v === TOTAL_KEY ? 'bold' : undefined }),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 'auto',
      disableSorting: true,
      pasteParseType: 'string',
      render: (v: PackagingNonstandardCostsRow['description'], r) =>
        r.rowId === TOTAL_KEY ? (
          <div
            style={{
              whiteSpace: 'nowrap',
              fontWeight: r.rowId === TOTAL_KEY ? 'bold' : undefined,
            }}
          >
            {v}
          </div>
        ) : (
          <FInput
            editable={!!packagingCostsHandlers}
            value={v}
            onChange={(v) => {
              if (!packagingCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packagingCostsHandlers.changeDescription(r.rowId, v);
            }}
            style={{ width: 200 }}
            inputStyle={{ fontSize: 14, height: 24 }}
            align="start"
          />
        ),
    },
    {
      groupName: 'EU',
      title: 'Quantity',
      dataIndex: 'fmc1quantity',
      disableSorting: true,
      align: 'end',
      pasteParseType: 'number',
      format: (v) => threeDec(v, commaAsDecimalSeperator),
      render: (v: PackagingNonstandardCostsRow['fmc1quantity'], r) =>
        r.rowId === TOTAL_KEY ? (
          <div style={{ marginRight: packagingCostsHandlers ? 9 : undefined, fontWeight: 'bold' }}>
            {threeDec(v, commaAsDecimalSeperator)}
          </div>
        ) : r.fmc1materialDescription === MATERIAL_NOT_FOUND_TEXT ? (
          <div />
        ) : (
          <FInput
            editable={!!packagingCostsHandlers}
            value={v}
            regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1TO3DECIMAL(commaAsDecimalSeperator)}
            emptyValueReplacement={0}
            disallowChangeOnInvalid
            containPaddingOnReadOnly={!!packagingCostsHandlers}
            format={(v) => threeDec(v, commaAsDecimalSeperator)}
            onSubmit={(quantity) => {
              if (!packagingCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packagingCostsHandlers.changeQuantity(r.rowId, 'FMC1', Number(quantity));
            }}
            style={{ width: 60 }}
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ),
    },
    {
      groupName: 'EU',
      title: 'Material ID',
      dataIndex: 'fmc1material',
      disableSorting: true,
      align: 'end',
      pasteParseType: 'number',
      format: (v, r) => (r.rowId === TOTAL_KEY ? '' : v),
      render: (v: PackagingNonstandardCostsRow['fmc1material'], r) =>
        r.rowId === TOTAL_KEY ? (
          ''
        ) : (
          <FInput
            editable={!!packagingCostsHandlers}
            value={v}
            regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
            disallowChangeOnInvalid
            align="start"
            onSubmit={(materialId) =>
              updateRowWithMaterial(
                r.rowId,
                'FMC1',
                materialId === '' ? undefined : Number(materialId),
              )
            }
            style={{ width: 100 }}
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ),
    },
    {
      groupName: 'EU',
      title: 'Mat. description',
      dataIndex: 'fmc1materialDescription',
      disableSorting: true,
    },
    {
      groupName: 'EU',
      title: 'Cost',
      dataIndex: 'fmc1cost',
      align: 'end',
      disableSorting: true,
      pasteParseType: 'number',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v: PackagingNonstandardCostsRow['fmc1cost'], r) =>
        r.rowId === TOTAL_KEY ? (
          <div
            style={{
              fontWeight: r.rowId === TOTAL_KEY ? 'bold' : undefined,
              marginRight: packagingCostsHandlers ? 8 : undefined,
            }}
          >
            {twoDec(v, commaAsDecimalSeperator)}
          </div>
        ) : r.fmc1materialDescription === MATERIAL_NOT_FOUND_TEXT ? (
          <div />
        ) : (
          <FInput
            editable={
              !!packagingCostsHandlers && packagingCostsMaterials[r.rowId]?.FMC1?.cost === undefined
            }
            value={v}
            format={(v) => twoDec(v, commaAsDecimalSeperator)}
            regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL(commaAsDecimalSeperator)}
            emptyValueReplacement={0}
            disallowChangeOnInvalid
            onSubmit={(v) => {
              if (!packagingCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packagingCostsHandlers.changeCost(r.rowId, 'FMC1', Number(v));
            }}
            containPaddingOnReadOnly={!!packagingCostsHandlers}
            style={{ width: 60 }}
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ),
    },
    {
      groupName: 'EU',
      title: 'Cost + scrap',
      align: 'end',
      dataIndex: 'fmc1costPlusScrap',
      disableSorting: true,
      format: (v, r) =>
        r.fmc1materialDescription === MATERIAL_NOT_FOUND_TEXT
          ? ''
          : twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({ fontWeight: r.rowId === TOTAL_KEY ? 'bold' : undefined }),
    },
    {
      groupName: 'US',
      title: 'Quantity',
      dataIndex: 'fmc2quantity',
      align: 'end',
      disableSorting: true,
      pasteParseType: 'number',
      format: (v) => threeDec(v, commaAsDecimalSeperator),
      render: (v: PackagingNonstandardCostsRow['fmc2quantity'], r) =>
        r.rowId === TOTAL_KEY ? (
          <div style={{ marginRight: packagingCostsHandlers ? 9 : undefined, fontWeight: 'bold' }}>
            {threeDec(v, commaAsDecimalSeperator)}
          </div>
        ) : r.fmc2materialDescription === MATERIAL_NOT_FOUND_TEXT ? (
          <div />
        ) : (
          <FInput
            editable={!!packagingCostsHandlers}
            containPaddingOnReadOnly={!!packagingCostsHandlers}
            value={v}
            regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1TO3DECIMAL(commaAsDecimalSeperator)}
            emptyValueReplacement={0}
            disallowChangeOnInvalid
            format={(v) => threeDec(v, commaAsDecimalSeperator)}
            onSubmit={(quantity) => {
              if (!packagingCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packagingCostsHandlers.changeQuantity(r.rowId, 'FMC2', Number(quantity));
            }}
            style={{ width: 60 }}
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ),
    },
    {
      groupName: 'US',
      title: 'Material ID',
      dataIndex: 'fmc2material',
      disableSorting: true,
      align: 'end',
      pasteParseType: 'number',
      format: (v, r) => (r.rowId === TOTAL_KEY ? '' : v),
      render: (v: PackagingNonstandardCostsRow['fmc2material'], r) =>
        r.rowId === TOTAL_KEY ? (
          ''
        ) : (
          <FInput
            editable={!!packagingCostsHandlers}
            value={v}
            regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
            disallowChangeOnInvalid
            align="start"
            onSubmit={(materialId) =>
              updateRowWithMaterial(
                r.rowId,
                'FMC2',
                materialId === '' ? undefined : Number(materialId),
              )
            }
            style={{ width: 100 }}
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ),
    },
    {
      groupName: 'US',
      title: 'Mat. description',
      disableSorting: true,
      dataIndex: 'fmc2materialDescription',
    },
    {
      groupName: 'US',
      title: 'Cost',
      dataIndex: 'fmc2cost',
      align: 'end',
      disableSorting: true,
      pasteParseType: 'number',
      format: (v) => twoDec(v, commaAsDecimalSeperator),
      render: (v: PackagingNonstandardCostsRow['fmc2cost'], r) =>
        r.rowId === TOTAL_KEY ? (
          <div
            style={{
              fontWeight: r.rowId === TOTAL_KEY ? 'bold' : undefined,
              marginRight: packagingCostsHandlers ? 8 : undefined,
            }}
          >
            {twoDec(v, commaAsDecimalSeperator)}
          </div>
        ) : r.fmc2materialDescription === MATERIAL_NOT_FOUND_TEXT ? (
          <div />
        ) : (
          <FInput
            editable={!!packagingCostsHandlers && !packagingCostsMaterials[r.rowId]?.FMC2?.cost}
            containPaddingOnReadOnly={!!packagingCostsHandlers}
            value={v}
            format={(v) => twoDec(v, commaAsDecimalSeperator)}
            regex={get_REGEX_NONNEGATIVE_INTEGER_OR_1OR2DECIMAL(commaAsDecimalSeperator)}
            emptyValueReplacement={0}
            disallowChangeOnInvalid
            onSubmit={(v) => {
              if (!packagingCostsHandlers) {
                showInternalApplicationError();
                return;
              }
              packagingCostsHandlers.changeCost(r.rowId, 'FMC2', Number(v));
            }}
            style={{ width: 60 }}
            inputStyle={{ fontSize: 14, height: 24 }}
          />
        ),
    },
    {
      groupName: 'US',
      title: 'Cost + scrap',
      dataIndex: 'fmc2costPlusScrap',
      disableSorting: true,
      align: 'end',
      format: (v, r) =>
        r.fmc2materialDescription === MATERIAL_NOT_FOUND_TEXT
          ? ''
          : twoDec(v, commaAsDecimalSeperator),
      style: (v, r) => ({ fontWeight: r.rowId === TOTAL_KEY ? 'bold' : undefined }),
    },
    getReportColumn<PackagingNonstandardCostsRow>('description', onReportSelect, showReport),
  ];
}

export const packagingSizesColumns: TableColumn<PackagingSize>[] = [
  { title: 'Size', dataIndex: 'packaging_size', align: 'end', sorted: 'ascending' },
  {
    groupName: 'Erect',
    title: 'Code',
    dataIndex: 'erect_code',
    width: 'auto',
    style: () => ({ whiteSpace: 'nowrap' }),
  },
  { groupName: 'Erect', title: 'Length', dataIndex: 'erect_length', align: 'end' },
  { groupName: 'Erect', title: 'Width', dataIndex: 'erect_width', align: 'end' },
  { groupName: 'Erect', title: 'Depth', dataIndex: 'erect_depth', align: 'end' },
  { title: 'Pack facing', dataIndex: 'packaging_facing', align: 'end' },
  { title: 'Status', dataIndex: 'status', align: 'end' },
];
