import { DBPackingStandardCost } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { PackingKeyId } from '@core/types/types.packaging';

export const PackingStandardCostExample: OmitDBRecord<DBPackingStandardCost>[] = [
  {
    product_id: 123,
    revision: 1,
    packing_key: PackingKeyId.Doublebag,
    fmc1_quantity: 1,
    fmc2_quantity: 1,
    bun: 'PC',
    fmc1_cost: 1.23,
    fmc2_cost: 1.23,
    currency: 'DKK',
  },
];
