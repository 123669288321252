import { DBBICost } from '@core/schemas/db/schema.db.bi';

export function calculate_BICOST_extractManualCost(
  biCost: Pick<DBBICost, 'cost' | 'super_design'>,
): number {
  // Super design was filled, manual cost must be 0
  if (biCost.super_design) {
    return 0;
  }

  // Super design was not specified, we output the embedded cost as the manual cost
  return biCost.cost;
}
