import { DBPackagingRate } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { PackSource, PackagingPackingKey } from '@core/types/types.packaging';

export const PackagingRateExample: OmitDBRecord<DBPackagingRate>[] = [
  {
    year: 1233,
    packaging_size: 1,
    packing_key: PackagingPackingKey.BIPrepack,
    packing_region: 'EU',
    sales_region: 'EU',
    source: PackSource.Duplo,
    fmc_quantity: 1.23,
    bun: 'PC',
    cost: 1.23,
    currency: 'DKK',
  },
];
