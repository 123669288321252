import { useContext, useMemo, useState } from 'react';
import { Table } from '@frontend/table/table';
import { FInput } from '@frontend/form/components/FInput';
import { ConfigContext, UserDataContext } from '@frontend/common/lib/contexts';
import { getBiRatesColumns } from '../bi.columns';
import { Book, BookKey } from '../logic/useBooksAndRates';
import { DBBIRate } from '@core/schemas/db/schema.db.bi';
import { REGEX_NONNEGATIVE_INTEGER_OR_EMPTY } from '@core/types/types.regex';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import { BIRatesListEndpoint } from '@core/schemas/endpoint/schema.endpoint.bi';
import { Region } from '@core/schemas/schema.common';

interface BIRatesModalContentProps {
  books: Book[];
  onRateSelected(selectedBookKey: BookKey, rate: DBBIRate & { region: Region }): void;
}

export function BIRatesModalContent(props: BIRatesModalContentProps) {
  const { config } = useContext(ConfigContext);
  const { data, loading } = useEndpoint({
    endpoint: BIRatesListEndpoint,
    input: { year: config.fmc_active_year },
    errorHandling: { header: 'Fetching buildin instruction rates' },
  });

  const [superDesign, setSuperDesign] = useState('');
  const [pages, setPages] = useState('');
  const [coverPages, setCoverPages] = useState('');
  const [colorsFront, setColorsFront] = useState('');
  const [colorsBack, setColorsBack] = useState('');
  const [paperWeight, setPaperWeight] = useState('');
  const [coverWeight, setCoverWeight] = useState('');

  const { userData } = useContext(UserDataContext);

  const filteredData = useMemo(
    () =>
      (data || []).filter(
        (rate) =>
          (!superDesign || rate.super_design === Number(superDesign)) &&
          (!pages || rate.total_number_of_pages === Number(pages)) &&
          (!coverPages || rate.number_of_cover_pages === Number(coverPages)) &&
          (!colorsFront || rate.number_of_colors_frontside === Number(colorsFront)) &&
          (!colorsBack || rate.number_of_colors_backside === Number(colorsBack)) &&
          (!paperWeight || rate.paper_weight_gr === Number(paperWeight)) &&
          (!coverWeight || rate.cover_weight_gr === Number(coverWeight)),
      ),
    [data, superDesign, pages, coverPages, colorsFront, colorsBack, paperWeight, coverWeight],
  );

  const columns = useMemo(
    () => getBiRatesColumns(!!userData?.comma_as_decimal_seperator),
    [userData?.comma_as_decimal_seperator],
  );

  function applyRateToBooks(rate: DBBIRate) {
    props.books.forEach((b) => props.onRateSelected(b, { ...rate, region: b.region }));
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          columnGap: 8,
          marginBottom: 16,
        }}
      >
        <FInput
          topLabel="Superdesign"
          placeholder="Superdesign"
          value={superDesign}
          onChange={(value) => setSuperDesign(value)}
          style={{ width: 120 }}
          editable
          align="start"
          disallowChangeOnInvalid
          regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
          labelStyle={{ fontSize: 14 }}
        />
        <FInput
          topLabel="Total pages"
          placeholder="Pages"
          value={pages}
          onChange={(value) => setPages(value)}
          style={{ width: 100 }}
          editable
          align="start"
          disallowChangeOnInvalid
          regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
          labelStyle={{ fontSize: 14 }}
        />
        <FInput
          topLabel="Cover pages"
          placeholder="Pages"
          value={coverPages}
          onChange={(value) => setCoverPages(value)}
          style={{ width: 100 }}
          editable
          align="start"
          disallowChangeOnInvalid
          regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
          labelStyle={{ fontSize: 14 }}
        />
        <FInput
          topLabel="Colors front"
          placeholder="Colors"
          value={colorsFront}
          onChange={(value) => setColorsFront(value)}
          style={{ width: 100 }}
          editable
          align="start"
          disallowChangeOnInvalid
          regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
          labelStyle={{ fontSize: 14 }}
        />
        <FInput
          topLabel="Colors back"
          placeholder="Colors"
          value={colorsBack}
          onChange={(value) => setColorsBack(value)}
          style={{ width: 100 }}
          editable
          align="start"
          disallowChangeOnInvalid
          regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
          labelStyle={{ fontSize: 14 }}
        />
        <FInput
          topLabel="Paper weight"
          placeholder="Weight"
          value={paperWeight}
          onChange={(value) => setPaperWeight(value)}
          style={{ width: 100 }}
          editable
          align="start"
          disallowChangeOnInvalid
          regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
          labelStyle={{ fontSize: 14 }}
        />
        <FInput
          topLabel="Cover weight"
          placeholder="Weight"
          value={coverWeight}
          onChange={(value) => setCoverWeight(value)}
          style={{ width: 100 }}
          editable
          align="start"
          disallowChangeOnInvalid
          regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
          labelStyle={{ fontSize: 14 }}
        />
      </div>
      <Table
        id="bi_rates"
        rows={filteredData}
        rowKey="SK"
        columns={columns}
        onRowClick={props.books.length === 0 ? undefined : applyRateToBooks}
        isRefreshing={loading}
        itemsPerPage={15}
        fixedHeight={460}
        itemType="super design"
      />
    </div>
  );
}
