import { calculate_CC_Instructions_full } from './bi/calculate.CC_Instructions.full';
import { calculate_CC_Instructions_sum } from './bi/calculate.CC_Instructions.sum';
import { calculate_nullable_sum } from './common/calculate.nullableSum';
import { calculate_variance } from './common/calculate.variance';
import { calculate_PackingNonstandardCost } from './packaging/calculate.PackingNonstandardCost';
import { calculate_PackingStandardCost } from './packaging/calculate.PackingStandardCost';
import { calculate_CC_Packing_PackingStandardCosts_Sum } from './packaging/calculate.CC_Packing.PackingStandardCosts.sum';
import { calculate_CC_Packing_sum } from './packaging/calculate.CC_Packing.sum';
import { calculate_CC_Packing_full } from './packaging/calculate.CC_Packing.full';
import { calculate_CC_Packaging_sum } from './packaging/calculate.CC_Packaging.sum';
import { calculate_CC_Packaging_full } from './packaging/calculate.CC_Packaging.full';
import { calculate_CC_sum } from './common/calculate.cc.sum';
import { calculate_CC_full } from './common/calculate.cc.full';
import { Calculate_FetchGetOrNull, Calculate_FetchQuery } from './calculator.types';
import { calculate_BICOST_selectRelevantCost } from './bi/calculate.BICOST.selectRelevantCost';
import { calculate_nullable_product } from './common/calculate.nullableProduct';
import { calculate_nullable_quotient } from './common/calculate.nullableQoutient';
import { calculate_nullable_sum_list } from './common/calculate.nullableSumList';
import { calculate_nullable_sum_list_primitive } from './common/calculate.nullableSumListPrimitive';
import { calculate_nullable_sum_list_strict } from './common/calculate.sumListStrict';
import { calculate_CC_ElementsScrap_sum } from './elements/calculate.cc_ElementsScrap.sum';
import { calculate_Elements_Total } from './elements/calculate.Total';
import { calculate_CC_FinishedGoodOverhead } from './other/calculate.cc_FinishedGoodOverhead';
import { calculate_CC_Others_sum } from './other/calculate.cc_Others.sum';
import { calculate_CC_ElementsScrap_full } from './elements/calculate.cc_ElementsScrap.full';
import { calculate_CC_TotalCosts } from './other/calculate.cc_TotalCosts';
import { calculate_CC_Others_full } from './other/calculate.cc_Others.full';
import { calculate_PACKINGCOST_findFinalPackRates } from './packaging/calculate.PackingStandardCost.findFinalPackRates';
import { ReportStatement, getErrorReportStatement } from './calculator.util.report';
import { calculate_selectPrice } from './elements/calculate.selectPrice';
import { Region } from '@core/schemas/schema.common';
import { calculate_BICOST_calculate } from './bi/calculate.BICOST.calculate';
import { uppercaseFirstLetter } from '@core/util/util.uppercaseFirstLetter';
import { calculate_packagingStandardCost } from './packaging/calculate.PackagingStandardCost';
import { calculate_packagingNonstandardCost } from './packaging/calculate.PackagingNonstandardCost';
import { calculate_PiecePrice } from './elements/calculate.piecePrice';
import { calculate_quotient } from './common/calculate.qoutient';
import { calculate_BICOST_extractManualCost } from './bi/calculate.BICOST.extractManualCost';
import { calculate_packing_numberOfPrepacks } from './packaging/calculate.packing.numberOfPrepacks';
import { calculate_CC_License } from './other/calculate.cc_License.full';
import { calculate_packing_numberOfFinalPacks } from './packaging/calculate.packing.numberOfFinalPacks';
import { calculate_sum_strict } from './common/calculate.sumStrict';
import { calculate_CC_Factor_full } from './other/calculate.cc_Factor.full';
import { calculate_CC_Factor_sum } from './other/calculate.cc_Factor.sum';
import { DBRecord } from '@core/schemas/db/schema.db.common';
import { IS_TEST } from '@core/const/const.IS_TEST';
import { calculate_packaging_nonStandardCost_GetRows } from './packaging/calculate.packaging.nonStandardCost.getRows';
import { calculate_packaging_isStandard } from './packaging/calculate.packaging.isStandard';
import { calculate_packaging_standardCost_GetRows } from './packaging/calculate.packaging.standardCost.getRows';
import {
  Calculator_Packaging_NonstandardCost_PackagingScrapRates_filter,
  Calculator_Packaging_PackagingRates_filter,
  Calculator_Packaging_StandardCost_PackagingAddOnRates_filter,
  Calculator_Packaging_StandardCost_PackagingFoilRates_filter,
  Calculator_Packaging_StandardCost_PackingStandardCosts_filter,
  Calculator_Packing_NonstandardCost_PackingNonstandardRates_filter,
  Calculator_Packing_StandardCost_PackingStandardFinalPackRates_filter,
  Calculator_Packing_StandardCost_PackingStandardPrepackRates_filter,
} from './packaging/filters/calculate.packaging.standardCost.filters';
import { calculator_doFilter_query, calculator_doFilter_single } from './calculator.util';
import { calculate_CC_Elements_full } from './elements/calculate.cc_Elements.full';
import { calculate_CC_Elements_sum } from './elements/calculate.cc_Elements.sum';

type ManualInput = { region?: Region | 'other'; message: string };
type ReportInput = { report?: ReportStatement };

export class InternalCalculatorError extends Error {
  constructor(message: string) {
    super(message);
  }

  static getErrorMessageMissingQueryFunction(dataTypeDescription: string): string {
    return `Missing query function: Expected query function fetch ${dataTypeDescription} since no cache was provided`;
  }
}

export class CalculationError extends Error {
  public reportStatement: ReportStatement;

  constructor(input: ManualInput | ReportInput) {
    const manualInput = input as ManualInput;
    const reportInput = input as ReportInput;

    let message = '';

    if (manualInput.message) {
      if (manualInput.region && manualInput.region !== 'other') {
        message += `${manualInput.region}, `;
      }
      message += manualInput.message;
    } else {
      if (reportInput.report?.region !== 'other') {
        message += `${reportInput.report?.region}, `;
      }
      if (reportInput.report) {
        message += `${reportInput.report?.type}, ${reportInput.report?.description}`;
      }
    }

    super(message);

    if (manualInput.message) {
      this.reportStatement = getErrorReportStatement(
        manualInput.region || 'other',
        manualInput.message,
      );
    } else if (reportInput.report) {
      this.reportStatement = reportInput.report;
    } else {
      throw new Error('Calculator error constructed incorrectly');
    }

    /* c8 ignore start */
    if (!IS_TEST) {
      console.log(this.reportStatement);
      console.trace();
    }
    /* c8 ignore end */
  }
}

export const C = {
  Variance: calculate_variance,
  Sum: calculate_nullable_sum,
  SumStrict: calculate_sum_strict,
  SumListPrimitive: calculate_nullable_sum_list_primitive,
  SumList: calculate_nullable_sum_list,
  SumListStrict: calculate_nullable_sum_list_strict,
  Product: calculate_nullable_product,
  ProductStrict: calculate_nullable_product,
  Quotient: calculate_nullable_quotient,
  QuotientStrict: calculate_quotient,
};

export const Calculator = {
  Common: {
    CC: {
      Sum: calculate_CC_sum,
      Full: calculate_CC_full,
    },
    ...C,
  },
  Elements: {
    CC_Elements: { Full: calculate_CC_Elements_full, Sum: calculate_CC_Elements_sum },
    CC_ElementsScrap: {
      Full: calculate_CC_ElementsScrap_full,
      Sum: calculate_CC_ElementsScrap_sum,
    },
    Total: calculate_Elements_Total,
    SelectPrice: calculate_selectPrice,
    PiecePrice: calculate_PiecePrice,
  },
  DoFilter: { Single: calculator_doFilter_single, Query: calculator_doFilter_query },
  Pack: {
    Packing: {
      StandardCost: {
        Calculate: calculate_PackingStandardCost,
        Filter: {
          PackingStandardFinalPackRate:
            Calculator_Packing_StandardCost_PackingStandardFinalPackRates_filter,
          PackingStandardPrepackRate:
            Calculator_Packing_StandardCost_PackingStandardPrepackRates_filter,
        },
        FindFinalPackRates: calculate_PACKINGCOST_findFinalPackRates,
      },
      NonstandardCost: {
        Filter: {
          PackingNonstandardRates:
            Calculator_Packing_NonstandardCost_PackingNonstandardRates_filter,
        },
        Calculate: calculate_PackingNonstandardCost,
      },
      NumberOfPrepacks: calculate_packing_numberOfPrepacks,
      NumberOfFinalPacks: calculate_packing_numberOfFinalPacks,
    },
    Packaging: {
      StandardCost: {
        Filter: {
          PackagingFoilRates: Calculator_Packaging_StandardCost_PackagingFoilRates_filter,
          PackingStandardCosts: Calculator_Packaging_StandardCost_PackingStandardCosts_filter,
          PackagingAddOnRates: Calculator_Packaging_StandardCost_PackagingAddOnRates_filter,
          PackagingRates: Calculator_Packaging_PackagingRates_filter,
        },
        Calculate: calculate_packagingStandardCost,
        GetRows: {
          Filter: {
            PackagingRates: Calculator_Packaging_PackagingRates_filter,
          },
          Run: calculate_packaging_standardCost_GetRows,
        },
      },
      NonstandardCost: {
        Filter: {
          PackagingScrapRates: Calculator_Packaging_NonstandardCost_PackagingScrapRates_filter,
        },
        Calculate: calculate_packagingNonstandardCost,
        GetRows: calculate_packaging_nonStandardCost_GetRows,
      },
      IsStandard: {
        Filter: { PackagingRates: Calculator_Packaging_PackagingRates_filter },
        Run: calculate_packaging_isStandard,
      },
    },
    CC_Packing: {
      PackingStandardCosts: { Sum: calculate_CC_Packing_PackingStandardCosts_Sum },
      Sum: calculate_CC_Packing_sum,
      Full: calculate_CC_Packing_full,
    },
    CC_Packaging: {
      Sum: calculate_CC_Packaging_sum,
      Full: calculate_CC_Packaging_full,
    },
  },
  BI: {
    BICost: {
      Calculate: calculate_BICOST_calculate,
      SelectRelevantCost: calculate_BICOST_selectRelevantCost,
      ExtractManualCost: calculate_BICOST_extractManualCost,
    },
    CC_Instructions: {
      Sum: calculate_CC_Instructions_sum,
      Full: calculate_CC_Instructions_full,
    },
  },
  CC_FinishedGoodOverhead: calculate_CC_FinishedGoodOverhead,
  CC_Others: { Sum: calculate_CC_Others_sum, Full: calculate_CC_Others_full },
  CC_License: calculate_CC_License,
  CC_Factor: { Sum: calculate_CC_Factor_sum, Full: calculate_CC_Factor_full },
  CC_TotalCosts: calculate_CC_TotalCosts,
};

export async function useCacheOrFetchQuery<T extends DBRecord, U = never>(input: {
  cachedItems: (U extends never ? T : U)[] | undefined;
  fetchQuery: Calculate_FetchQuery | undefined;
  fetchQueryArgs: {
    pk: T['PK'];
    query: Partial<T>;
  };
  dataTypeDescription: string;
  cacheFilter?: Partial<U extends never ? T : U>;
}): Promise<(U extends never ? T : U | T)[]> {
  const { cachedItems, dataTypeDescription, fetchQuery, fetchQueryArgs, cacheFilter } = input;

  let items = cachedItems;

  if (items === undefined) {
    if (!fetchQuery) {
      throw new InternalCalculatorError(
        InternalCalculatorError.getErrorMessageMissingQueryFunction(dataTypeDescription),
      );
    }

    items = await fetchQuery<any>(fetchQueryArgs.pk, fetchQueryArgs.query);
  }

  if (cachedItems && cacheFilter) {
    items = Calculator.DoFilter.Query(cachedItems, cacheFilter) as (U extends never ? T : U)[];
  }

  return items;
}

export async function useCacheOrFetchSingle<T extends DBRecord, U = never>(input: {
  cachedItem: (U extends never ? T : U) | undefined;
  fetchQuery: Calculate_FetchGetOrNull | undefined;
  fetchQueryArgs: {
    pk: T['PK'];
    query: Partial<T>;
  };
  dataTypeDescription: string;
  strict: boolean; // false: error -> user error. true: error -> internal calculator error
}): Promise<U extends never ? T : U | T> {
  const { cachedItem, dataTypeDescription, fetchQuery, fetchQueryArgs, strict } = input;

  let item = cachedItem;

  if (!item) {
    if (!fetchQuery) {
      throw new InternalCalculatorError(
        InternalCalculatorError.getErrorMessageMissingQueryFunction(dataTypeDescription),
      );
    }

    item = await fetchQuery<any>(fetchQueryArgs.pk, fetchQueryArgs.query);
  }

  if (!item) {
    if (strict) {
      throw new InternalCalculatorError(
        'No cached item was provided and no item was fetched from given query',
      );
    } else {
      throw new CalculationError({
        report: getErrorReportStatement(
          'other',
          uppercaseFirstLetter(dataTypeDescription) + ' could not be found',
        ),
      });
    }
  }

  return item as U extends never ? T : U | T;
}
