import { z } from 'zod';

import { defineEndpoint } from '@core/util/util.defineEndpoint';
import { ViewNameEnum } from '@core/schemas/schema.common';

// AttachmentsUploadEndpoint
export const AttachmentsUploadEndpoint = defineEndpoint({
  path: '/attachments/upload',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
    view: ViewNameEnum,
    fileName: z.string(),
  }),
  response: z.object({
    url: z.string(),
    fields: z.record(z.string()),
  }),
});

export type AttachmentsUploadEndpointInput = z.infer<(typeof AttachmentsUploadEndpoint)['input']>;
export type AttachmentsUploadEndpointResponse = z.infer<typeof AttachmentsUploadEndpoint.response>;

// AttachmentsListEndpoint
export const AttachmentsListEndpoint = defineEndpoint({
  path: '/attachments/list',
  input: z.object({
    productId: z.number().int(),
    view: ViewNameEnum,
  }),
  response: z.array(
    z.object({
      fullFileName: z.string(),
      uploadedAt: z.number().int(),
      uploadedBy: z.string(),
    }),
  ),
});
export type AttachmentsListEndpointInput = z.infer<typeof AttachmentsListEndpoint.input>;
export type AttachmentsListEndpointResponse = z.infer<typeof AttachmentsListEndpoint.response>;

// AttachmentsDeleteEndpoint
export const AttachmentsDeleteEndpoint = defineEndpoint({
  path: '/attachments/delete',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
    view: ViewNameEnum,
    fileName: z.string(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
export type AttachmentsDeleteEndpointInput = z.infer<typeof AttachmentsDeleteEndpoint.input>;
export type AttachmentsDeleteEndpointResponse = z.infer<typeof AttachmentsDeleteEndpoint.response>;

// AttachmentsGetEndpoint
export const AttachmentsGetEndpoint = defineEndpoint({
  path: '/attachments/get',
  input: z.object({
    productId: z.number().int(),
    view: ViewNameEnum,
    fileName: z.string(),
  }),
  response: z.object({
    url: z.string(),
  }),
});
export type AttachmentsGetEndpointInput = z.infer<typeof AttachmentsGetEndpoint.input>;
export type AttachmentsGetEndpointResponse = z.infer<typeof AttachmentsGetEndpoint.response>;
