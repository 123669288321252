import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { convertProductIdFrom8digits } from '@core/util/util.convertProductIdFrom8digits';
import { FInput } from '@frontend/form/components/FInput';
import { FSelect } from '@frontend/form/components/FSelect';

interface PackaginProductHeaderProps {
  prodHead: PackagingGetEndpointResponse['prodHead'];
  maxRevision: number;
  onRevisionSelect(revision: number): void;
}

export function PackagingProductHeader(props: PackaginProductHeaderProps) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'min-content 36px min-content auto',
        columnGap: 24,
      }}
    >
      <FInput
        label="Product ID"
        value={convertProductIdFrom8digits(props.prodHead.product_id)}
        align="start"
      />
      <div />
      <FInput
        label="Pacmac revision"
        value={
          (props.prodHead.estimation_code || '') +
          (props.prodHead.estimation_code && props.prodHead.alternative_code ? ' - ' : '') +
          (props.prodHead.alternative_code || '')
        }
        blankDash
      />
      <div />
      <FSelect
        label="Revision"
        value={`${props.prodHead.revision}`}
        editable
        onChange={(rev) => props.onRevisionSelect(Number(rev))}
        options={Array.from(new Array(props.maxRevision)).map((_, i) => ({
          text: i + 1 + (i + 1 === props.maxRevision ? ' (latest)' : ''),
          value: `${i + 1}`,
        }))}
        align="start"
        style={{ width: 200 }}
      />
      <div />
      <div />
      <div />
      <FInput label="FMC year" value={props.prodHead.year} align="start" />
    </div>
  );
}
