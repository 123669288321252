import { ReportStatement } from '@core/calculator/calculator.util.report';
import { PackagingNonstandardCostsRow } from '../lib/packaging.types';
import { UsePackagingCostsRowsReturnValue } from '../lib/usePackagingCostsRows';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Unpacked } from '@core/util/util.typing';
import { getPackagingNonstandardCostsColumns } from '../lib/packaging.columns';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { RegionFMC } from '@core/schemas/schema.common';
import {
  showErrorToast,
  showInfoToast,
  showInternalApplicationError,
} from '@frontend/common/lib/functions';
import {
  MaterialGetEndpoint,
  PackagingNonstandardCostsEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.packaging';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { Table } from '@frontend/table/table';
import { GREEN_BUTTON_STYLES } from '@frontend/common/lib/common.styles';
import { ExportButton } from '@frontend/common/components/ExportButton';
import { Button } from '@lego/klik-ui';
import { DeleteBold, PlusBold } from '@lego/klik-ui/icons';
import { ImportFromRevisionButton } from './ImportFromRevisionButton';
import { TOTAL_KEY } from '@core/const/const.TOTAL_KEY';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';
import { MATERIAL_NOT_FOUND_TEXT } from '@core/const/const.MATERIAL_NOT_FOUND_TEXT';

interface PackagingNonstandardCostsTableProps {
  productId: number | undefined;
  revision: number | undefined;
  maxRevision: number | undefined;
  packagingNonstandardCostsRows: PackagingNonstandardCostsRow[];
  packagingNonstandardCostsTotal: UsePackagingCostsRowsReturnValue['nonstandardTotals'];
  packagingNonstandardCostsHandlers:
    | UsePackagingCostsRowsReturnValue['nonstandardHandlers']
    | undefined;
  packagingNonstandardCostsMaterials: UsePackagingCostsRowsReturnValue['materials'];
  readOnly: boolean;
  setReport(report: { header: string; statements: ReportStatement[] } | undefined): void;
}

export function PackagingNonstandardCostsTable(props: PackagingNonstandardCostsTableProps) {
  const [selectedRow, setSelectedRow] = useState<PackagingNonstandardCostsRow>();

  const { userData, showReport } = useContext(UserDataContext);

  const updateRowWithMaterial = useCallback(
    async (key: string, fmcRegion: RegionFMC, materialId: number | undefined) => {
      if (!props.packagingNonstandardCostsHandlers?.changeMaterial) {
        showInternalApplicationError();
        return;
      }

      if (materialId === undefined) {
        props.packagingNonstandardCostsHandlers.changeMaterial(key, fmcRegion, undefined);
        return;
      }

      props.packagingNonstandardCostsHandlers.changeMaterial(key, fmcRegion, {
        materialId,
        description: 'Loading...',
        cost: undefined,
      });

      const material = await fetchMaterial(materialId);
      if (material) {
        props.packagingNonstandardCostsHandlers.changeMaterial(key, fmcRegion, {
          materialId,
          description: material.description,
          cost: material.cost,
        });

        if (material.cost === undefined) {
          showInfoToast(
            'Fetching material information',
            `No price maintained for material ${materialId}`,
          );
        }
      } else {
        props.packagingNonstandardCostsHandlers.changeMaterial(key, fmcRegion, {
          materialId,
          description: MATERIAL_NOT_FOUND_TEXT,
          cost: undefined,
        });
      }
    },
    [props.packagingNonstandardCostsHandlers],
  );

  async function fetchMaterial(materialId: number) {
    const [err, material, response] = await callEndpoint({
      endpoint: MaterialGetEndpoint,
      input: { materialId: materialId },
      errorHandling: { disable: true },
    });

    if (response?.status === 404) {
      return;
    }

    if (err) {
      showErrorToast('Fetching material information', 'An error occurred');
      return;
    }

    return material;
  }

  const shownRows = useMemo(() => {
    const row: Unpacked<typeof props.packagingNonstandardCostsRows> = {
      rowId: TOTAL_KEY,
      description: 'Total',
      fmc1manualCost: 0,
      fmc2manualCost: 0,
      fmc1cost: props.packagingNonstandardCostsTotal.cost.fmc1,
      fmc2cost: props.packagingNonstandardCostsTotal.cost.fmc2,
      fmc1costPlusScrap: props.packagingNonstandardCostsTotal.costPlusScrap.fmc1,
      fmc2costPlusScrap: props.packagingNonstandardCostsTotal.costPlusScrap.fmc2,
      fmc1quantity: props.packagingNonstandardCostsTotal.quantity.fmc1,
      fmc2quantity: props.packagingNonstandardCostsTotal.quantity.fmc2,
      fmc1material: undefined,
      fmc2material: undefined,
      fmc1materialDescription: '',
      fmc2materialDescription: '',
      currency: 'DKK',
      report: [],
    };

    return props.packagingNonstandardCostsRows.concat(row);
  }, [props]);

  const columns = useMemo(
    () =>
      getPackagingNonstandardCostsColumns({
        commaAsDecimalSeperator: !!userData?.comma_as_decimal_seperator,
        onReportSelect: props.setReport,
        packagingNonstandardCostsHandlers: props.readOnly
          ? undefined
          : props.packagingNonstandardCostsHandlers,
        packagingCostsMaterials: props.packagingNonstandardCostsMaterials,
        showReport,
        updateRowWithMaterial,
      }),
    [
      props.packagingNonstandardCostsHandlers,
      props.packagingNonstandardCostsMaterials,
      props.setReport,
      updateRowWithMaterial,
      userData?.comma_as_decimal_seperator,
      props.readOnly,
      showReport,
    ],
  );

  async function fetchAndUpdatePackagingNonstandardRows(productId: number, revision: number) {
    if (!props.productId) {
      showInternalApplicationError();
      return;
    }

    const [err, rows] = await callEndpoint({
      endpoint: PackagingNonstandardCostsEndpoint,
      input: { productId: convertProductIdTo8digits(productId), revision },
      errorHandling: { header: 'Importing packaging non-standard costs' },
    });

    if (err) return;

    props.packagingNonstandardCostsHandlers?.importRows(rows);
  }

  return (
    <Table
      id="packaging_packaging-costs_nonstandard"
      onPaste={(updates) =>
        props.packagingNonstandardCostsHandlers?.onPaste(updates, updateRowWithMaterial)
      }
      headerContent={
        <div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 18,
              marginBottom: 8,
            }}
          >
            Non-standard
          </div>
          <div style={{ display: 'flex', columnGap: 8, alignItems: 'center' }}>
            <ExportButton rows={shownRows} columns={columns} />
            {!props.readOnly && (
              <>
                <Button
                  size="sm"
                  variant="outline"
                  style={GREEN_BUTTON_STYLES}
                  leftIcon={<PlusBold />}
                  onClick={() => {
                    if (!props.packagingNonstandardCostsHandlers?.addRow) {
                      showInternalApplicationError();
                      return;
                    }
                    props.packagingNonstandardCostsHandlers.addRow();
                  }}
                >
                  Add row
                </Button>
                <Button
                  disabled={!selectedRow}
                  size="sm"
                  variant="outline"
                  colorScheme="error"
                  leftIcon={<DeleteBold />}
                  onClick={() => {
                    if (!selectedRow) {
                      return;
                    }
                    if (!props.packagingNonstandardCostsHandlers?.deleteRow) {
                      showInternalApplicationError();
                      return;
                    }
                    props.packagingNonstandardCostsHandlers.deleteRow(selectedRow.rowId);
                    setSelectedRow(undefined);
                  }}
                >
                  Remove row
                </Button>
                {!props.readOnly && props.productId && (
                  <ImportFromRevisionButton
                    productId={props.productId}
                    fetchRows={fetchAndUpdatePackagingNonstandardRows}
                  />
                )}
              </>
            )}
          </div>
        </div>
      }
      rows={shownRows}
      removeInfoText
      onRowClick={props.readOnly ? undefined : setSelectedRow}
      removeSearch
      rowHasError={(r) => !!r.report.find((s) => s.isError)}
      selectedRow={props.readOnly ? undefined : selectedRow}
      rowKey="rowId"
      rowIsDisabled={(r) => r.rowId === TOTAL_KEY}
      columns={columns}
    />
  );
}
