import { CSSProperties } from 'react';
import { RequiredAsterix } from './RequiredAsterix';

interface FLabelProps {
  label?: string;
  isTopLabel?: boolean;
  required?: boolean;
  style?: CSSProperties;
}

export function FLabel(props: FLabelProps) {
  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        marginRight: props.isTopLabel ? undefined : 8,
        fontWeight: props.isTopLabel ? undefined : 'bold',
        marginBottom: props.isTopLabel ? 4 : undefined,
        ...props.style,
      }}
    >
      {props.label + (props.isTopLabel ? '' : ':')}
      <RequiredAsterix hide={!props.required} />
    </div>
  );
}
