import { FormControl, Input, Textarea } from '@lego/klik-ui';
import {
  createRef,
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { stringify } from '@frontend/common/lib/functions';
import { FLabel } from './FLabel';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { getFreshId } from '@core/util/util.geFreshId';

interface FInputProps {
  id?: string;
  label?: string;
  inputRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  topLabel?: string;
  value?: string | number | undefined | null;
  align?: 'start' | 'center' | 'end';
  readOnlyAlign?: 'start' | 'center' | 'end';
  placeholder?: string;
  required?: boolean;
  editable?: boolean;
  type?: React.HTMLInputTypeAttribute;
  regex?: RegExp;
  isInvalid?: boolean;
  size?: 'md';
  disallowChangeOnInvalid?: boolean;
  helpText?: string;
  containPaddingOnReadOnly?: boolean;
  disableSmartBehavior?: boolean;
  emptyValueReplacement?: string | number;
  disabled?: boolean;
  isText?: string;
  disableBlurOnEnter?: boolean;
  multiline?: boolean;
  suggestions?: string[];
  blankDash?: boolean;
  format?(v: string): string;
  setIsInvalid?(isInvalid: boolean): void;
  onChange?(value: string): void;
  onSubmit?(value: string): (boolean | void) | Promise<boolean | void>;
  onEnter?(invalid?: boolean): void;
  onBlur?(): void;
  onMultiValuePaste?(values: string[]): void;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  className?: string;
}

export function FInput(props: FInputProps) {
  const [value, setValue] = useState(stringify(props.value));
  const [invalid, setInvalid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const {
    onSubmit,
    format,
    value: propValue,
    setIsInvalid: propSetIsInvalid,
    onEnter,
    onBlur,
    disableBlurOnEnter,
    onChange,
    disallowChangeOnInvalid,
    emptyValueReplacement,
    disableSmartBehavior,
    regex,
    onMultiValuePaste,
    isText,
  } = props;

  const inputRef = props.inputRef || createRef<HTMLInputElement | HTMLTextAreaElement>();

  const { userData } = useContext(UserDataContext);

  const commaAsDecimalSeperator = useMemo(
    () => !!userData?.comma_as_decimal_seperator,
    [userData?.comma_as_decimal_seperator],
  );

  const updateInvalid = useCallback(
    (invalid: boolean) => {
      setInvalid(invalid);
      if (propSetIsInvalid) {
        propSetIsInvalid(invalid);
      }
    },
    [propSetIsInvalid],
  );

  useEffect(() => {
    let v = stringify(props.value);

    if (commaAsDecimalSeperator && !props.isText) {
      v = v.replaceAll('.', ',');
    }

    const isInvalid = !!props.regex && !props.regex.test(v);

    updateInvalid(isInvalid);
  }, [props.value, updateInvalid, props.regex, commaAsDecimalSeperator, props.isText]);

  useEffect(() => {
    if (JSON.stringify(props.value) !== JSON.stringify(value)) {
      if (props.onSubmit) {
        let v = props.value !== undefined && props.value !== null ? stringify(props.value) : '';

        if (commaAsDecimalSeperator && !props.isText) {
          v = v.replaceAll('.', ',');
        }

        updateValue(v);
      }
    }

    // Do not add extra dependencies to this array - it will cause it to roll back to internal/prop value erroneously on each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const smartRemoval = useCallback(
    (v: string) => {
      if (value !== '0' || v.length !== 2) {
        return v;
      }

      // we have 0x
      if (v[0] === '0') {
        // dont trim if we are writing 0.xxxx or 0,xxxx
        if (v[1] === '.' || v[1] === ',') {
          return v;
        }
        return v[1];
      }

      // we have x0, but remember: cursor is between x and 0 not all the way to the right, so we delete the 0
      if (v[1] === '0') {
        if (/[0-9]/.test(v[0])) {
          return v[0];
        } else {
          return v[1] + v[0];
        }
      }

      return v;
    },
    [value],
  );

  const updateValue = useCallback(
    (v: string) => {
      setValue(props.disableSmartBehavior ? v : smartRemoval(v));
    },
    [props.disableSmartBehavior, smartRemoval],
  );

  const displayValue = useMemo(() => {
    const val = onSubmit ? value : propValue ?? '';
    return format && !isFocused && !invalid ? format(`${val}`) : val;
  }, [onSubmit, format, propValue, value, isFocused, invalid]);

  const inputStyle: CSSProperties = {
    fontSize: 16,
    textOverflow: props.disabled ? 'ellipsis' : undefined,
    ...props.inputStyle,
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!disableSmartBehavior && !props.isText) {
        if (
          (commaAsDecimalSeperator && e.target.value === ',') ||
          (!commaAsDecimalSeperator && e.target.value === '.')
        ) {
          e.target.value = '';
        }
      }

      if (e.target.value === '' && emptyValueReplacement !== undefined) {
        e.target.value = emptyValueReplacement.toString();
      }

      const isInvalid = !!regex && !regex.test(e.target.value);

      if (disallowChangeOnInvalid && isInvalid) {
        return;
      }

      updateValue(e.target.value);

      updateInvalid(isInvalid);

      if (onChange) {
        let v = disableSmartBehavior ? e.target.value : smartRemoval(e.target.value);

        if (commaAsDecimalSeperator && !props.isText) {
          v = v.replaceAll(',', '.');
        }
        onChange(v);
      }
    },
    [
      updateInvalid,
      updateValue,
      onChange,
      disableSmartBehavior,
      disallowChangeOnInvalid,
      emptyValueReplacement,
      regex,
      commaAsDecimalSeperator,
      smartRemoval,
      props.isText,
    ],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
      const shouldSubmit = (e.ctrlKey || e.metaKey) && e.key === 's';

      if (e.key === 'Enter' || shouldSubmit) {
        if (onEnter) {
          if (!disableBlurOnEnter) {
            inputRef.current?.blur();
          }
          onEnter(invalid);
        }

        if (onSubmit) {
          inputRef.current?.blur();
        }
      }
    },
    [onEnter, disableBlurOnEnter, onSubmit, inputRef, invalid],
  );

  const handleBlur = useCallback(async () => {
    if (onBlur) {
      onBlur();
    }

    setIsFocused(false);

    if (invalid) return;

    if (onSubmit) {
      let input = `${value}`;

      if (commaAsDecimalSeperator && !props.isText) {
        input = input.replaceAll(',', '.');
      }

      const notOk = await onSubmit(input);

      if (notOk) {
        updateValue(`${propValue}`);
      }
    }
  }, [
    onSubmit,
    propValue,
    onBlur,
    commaAsDecimalSeperator,
    invalid,
    updateValue,
    value,
    props.isText,
  ]);

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      if (!onMultiValuePaste) {
        return;
      }

      let values = e.clipboardData.getData('text').replaceAll('\r', '').split('\n');

      if (!isText) {
        values = values.map((v) => (commaAsDecimalSeperator ? v.replaceAll(',', '.') : v));
        if (regex) {
          values = values.filter((v) => regex?.test(v));
        }
      }

      onMultiValuePaste(values);
    },
    [onMultiValuePaste, isText, regex, commaAsDecimalSeperator],
  );

  const canInput = useMemo(
    () => props.editable && !props.disabled,
    [props.editable, props.disabled],
  );

  const suggestionId = useMemo(() => getFreshId(), []);

  return (
    <div
      style={{ width: props.style?.width, ...props.containerStyle }}
      id={props.id}
      data-can-paste={canInput}
      data-regex={props.regex?.source}
    >
      {props.topLabel && (
        <FLabel
          isTopLabel
          label={props.topLabel}
          required={props.required}
          style={props.labelStyle}
        />
      )}
      <FormControl
        isRequired={props.required}
        size={props.size || 'sm'}
        style={{
          height: props.inputStyle?.height,
          display: 'flex',
          alignItems: 'center',
          ...props.style,
        }}
        isReadOnly={!props.editable}
        className={
          'felement ' +
          props.className +
          (props.size === 'md' || props.multiline ? ' felement-revert-height' : '')
        }
      >
        {props.label && <FLabel label={props.label} required={props.required} />}
        {!props.editable ? (
          <div
            style={{
              textAlign: props.multiline
                ? undefined
                : props.readOnlyAlign ?? (props.align || 'end'),
              width: '100%',
              whiteSpace: props.multiline ? undefined : 'nowrap',
              paddingRight: props.containPaddingOnReadOnly ? 8 : undefined,
            }}
          >
            {displayValue === '' && props.blankDash ? '—' : displayValue}
          </div>
        ) : props.multiline ? (
          <Textarea
            name="."
            placeholder={props.placeholder}
            size={props.size || 'sm'}
            ref={inputRef as React.RefObject<HTMLTextAreaElement>}
            value={displayValue}
            disabled={props.disabled}
            onFocus={() => setIsFocused(true)}
            textAlign={props.align}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            autoComplete="off"
            isInvalid={invalid || props.isInvalid}
            style={{ ...inputStyle, transition: 'none' }}
            className="finput"
          />
        ) : (
          <Input
            list={suggestionId}
            onPaste={handlePaste}
            name="."
            placeholder={props.placeholder}
            size={props.size || 'sm'}
            ref={inputRef as React.RefObject<HTMLInputElement>}
            value={displayValue}
            disabled={props.disabled}
            onFocus={() => setIsFocused(true)}
            textAlign={props.align || 'end'}
            className="finput"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            type={props.type}
            autoComplete="off"
            isInvalid={invalid || props.isInvalid}
            style={inputStyle}
          />
        )}
      </FormControl>
      {props.helpText && (
        <div style={{ color: '#767674', fontSize: 14, marginTop: 10, lineHeight: 'normal' }}>
          {props.helpText}
        </div>
      )}
      {props.suggestions && (
        <datalist id={suggestionId}>
          {props.suggestions.map((suggestion, i) => (
            <option key={i} value={suggestion} onClick={() => alert('cool')} />
          ))}
        </datalist>
      )}
    </div>
  );
}
