import { FilterRange } from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';
import {
  REGEX_POSITIVE_INTEGER_OR_EMPTY,
  get_REGEX_NONNEGATIVE_INTEGER_OR_1TO5DECIMAL_OR_EMPTY,
} from '@core/types/types.regex';
import { SchemaProperty } from '@core/types/types.schema';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { FInput } from '@frontend/form/components/FInput';
import { useCallback, useContext } from 'react';

interface FilterSettingsRangeProps {
  property: SchemaProperty & { filter: FilterRange };
  onChange(newFilterValue: FilterRange['value']): void;
}

export function FilterSettingsRange(props: FilterSettingsRangeProps) {
  const { onChange, property } = props;

  const { userData } = useContext(UserDataContext);

  const changeValue = useCallback(
    (value: string, prop: keyof FilterRange['value']) => {
      const newValue = structuredClone(property.filter.value);

      newValue[prop] = value;

      onChange(newValue);
    },
    [property.filter.value, onChange],
  );

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 24 }}>
      <FInput
        placeholder="Minium"
        editable
        topLabel="Minimum:"
        value={property.filter.value.min}
        regex={
          property.inputType !== 'ZodNumber'
            ? undefined
            : property.int
            ? REGEX_POSITIVE_INTEGER_OR_EMPTY
            : get_REGEX_NONNEGATIVE_INTEGER_OR_1TO5DECIMAL_OR_EMPTY(
                !!userData?.comma_as_decimal_seperator,
              )
        }
        disableSmartBehavior
        disableBlurOnEnter
        disallowChangeOnInvalid
        align="start"
        onChange={(v) => changeValue(v, 'min')}
      />
      <FInput
        placeholder="Maximum"
        editable
        topLabel="Maximum:"
        disableSmartBehavior
        regex={
          property.inputType !== 'ZodNumber'
            ? undefined
            : property.int
            ? REGEX_POSITIVE_INTEGER_OR_EMPTY
            : get_REGEX_NONNEGATIVE_INTEGER_OR_1TO5DECIMAL_OR_EMPTY(
                !!userData?.comma_as_decimal_seperator,
              )
        }
        value={property.filter.value.max}
        disallowChangeOnInvalid
        align="start"
        disableBlurOnEnter
        onChange={(v) => changeValue(v, 'max')}
      />
    </div>
  );
}
