import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';

interface NCAccordionProps {
  sections: {
    title: string;
    content: React.ReactNode;
    hide?: boolean;
    noBottomMargin?: boolean;
    defaultCollapsed?: boolean;
  }[];
}

export function NCAccordion(props: NCAccordionProps) {
  return (
    <Accordion
      defaultIndex={props.sections
        .map((s, i) => ({ ...s, i }))
        .filter((s) => !s.defaultCollapsed)
        .map((s) => s.i)}
      allowMultiple
    >
      {props.sections
        .filter((sec) => !sec.hide)
        .map((section) => (
          <AccordionItem key={section.title}>
            <AccordionButton>
              {section.title}
              <Spacer />
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel marginBottom={section.noBottomMargin ? undefined : '48px'}>
              {section.content}
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  );
}
