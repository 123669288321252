export enum PackingNonstandardKey {
  BIPrepack = 'B',
  FinalPackCollectablesDisplay = 'C',
  FinalPackBucket = 'E',
  FinalPackManual = 'F',
  BoxInBox = 'G',
  FinalPackDynamicCardboardSpecialPH = 'H',
  PrepackBoosterCollectableAuto = 'O',
  PrepackManualCollectableMan = 'P',
}

export interface PackingKey {
  packingKey: PackingKeyId;
  description: string;
}

export enum PackingKeyId {
  FinalPack = 'FIPAK',
  SpecialSingle = 'SPECI',
  Singlebag = 'SINGL',
  QSinglebag = 'QSING',
  Doublebag = 'DOUBL',
  QDoublebag = 'QDOUB',
  F99 = 'F99',
  Joker = 'JOKER',
  GlobalBIPrepack = 'BIPRE',
}

export enum FoilPackingKey {
  Doublebag = PackingKeyId.Doublebag,
  F99 = PackingKeyId.F99,
  Joker = PackingKeyId.Joker,
  QDoublebag = PackingKeyId.QDoublebag,
  QSinglebag = PackingKeyId.QSinglebag,
  Singlebag = PackingKeyId.Singlebag,
}

export enum PackagingPackingKey {
  BIPrepack = 'BIPRE',
  Box = 'BOX',
  Foil = 'FOIL',
  Outer = 'OUT',
  OuterLabel = 'OUTL',
  SealingLabel = 'SEAL',
}

export enum PackagingAddOnKey {
  Embossing = 'EMBOS',
  Hotfoil = 'HOTFO',
  UVLacquer = 'UVLAC',
  WindowCutout = 'WINCU',
  WindowFoil = 'WINFO',
}

export enum PackSource {
  TruvantBoosterLine = 'TRUVANT_BOOSTER_LINE', // 10
  TruvantSpinnerLine = 'TRUVANT_SPINNER_LINE', // 20
  TruvantHFLine = 'TRUVANT_HF_LINE', // 30
  TruvantCanLine = 'TRUVANT_CAN_LINE', // 40
  TruvantLELine = 'TRUVANT_LE_LINE', // 50
  Duplo = 'DUPLO', // 60
  LEGOTechnic = 'LEGO_TECHNIC', // 70
}

export interface PackagingCostMaterial {
  materialId: number;
  description: string | undefined;
  cost: number | undefined;
}
