import { useContext, useMemo } from 'react';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { Table } from '../../table/table';
import { getBiProductCostsColumns } from '../bi.columns';
import { useBiCostsOverviewRow } from '../logic/useBiCostsOverviewRows';
import { Book } from '../logic/useBooksAndRates';
import { ExportButton } from '@frontend/common/components/ExportButton';
import { BIGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.bi';

interface BICostOverviewProps {
  productCosts: BIGetEndpointResponse['productCosts'];
  biCosts: Book[];
  readOnly?: boolean;
}

export function BICostOverview(props: BICostOverviewProps) {
  const { userData } = useContext(UserDataContext);

  const { rows, cc_Instructions: cc70 } = useBiCostsOverviewRow({
    books: props.biCosts,
    productCosts: props.productCosts,
  });

  const shownRows = useMemo(() => (rows || []).concat(cc70), [rows, cc70]);

  const columns = useMemo(
    () => getBiProductCostsColumns(!!userData?.comma_as_decimal_seperator),
    [userData?.comma_as_decimal_seperator],
  );

  return (
    <Table
      id="bi_product-costs"
      rows={shownRows}
      headerContent={
        props.readOnly ? undefined : <ExportButton rows={shownRows} columns={columns} />
      }
      removeInfoText
      removeSearch
      removeLastRowBottomBorder
      stackHeaderTitles
      rowKey="costComp"
      columns={columns}
    />
  );
}
