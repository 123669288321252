export function now(options?: { omitMilliSeconds?: boolean }) {
  let isoString = new Date().toISOString();

  if (options?.omitMilliSeconds) {
    isoString = isoString.slice(0, -5) + 'Z';
  }

  return isoString;
}

export function defaultTs() {
  return new Date(0).toISOString();
}
