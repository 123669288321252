import { GREEN, RED } from '@frontend/common/lib/common.styles';
import { prettifyTimestamp } from '@frontend/common/lib/common.util';
import { TableColumn } from '@frontend/table/table';
import { DBAMLog } from '@core/schemas/db/schema.db.common';
import { ADGroup, ADGroupMember, VirtualGroupMember } from '@core/schemas/schema.common';
import { Tag } from '@lego/klik-ui';
import { ADGroupWithTags } from './administration.lib';
import { Unpacked } from '@core/util/util.typing';
import { VIRTUAL_GROUPS } from '@core/const/const.VIRTUAL_GROUPS';
import { IoPersonRemove } from 'react-icons/io5';
import { AMGetAllUsersEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.am';
import { isDev } from '@frontend/common/lib/functions';

export function AMLogsForGroupColumns(
  getName: (email: string | undefined) => string,
): TableColumn<DBAMLog>[] {
  return [
    {
      dataIndex: 'timestamp',
      title: 'Timestamp',
      sorted: 'descending',
      format: (v) => prettifyTimestamp(v),
    },
    {
      dataIndex: '',
      title: 'Description',
      width: 'auto',
      format: (v, r) =>
        `${r.action_by} ${r.action_type === 'add' ? 'added' : 'removed'} ${r.action_on}`,
      render: (_v, r) => (
        <span>
          {getName(r.action_by)}{' '}
          {r.action_type === 'add' ? (
            <span style={{ color: GREEN }}>added</span>
          ) : (
            <span style={{ color: RED }}>removed</span>
          )}{' '}
          {getName(r.action_on)}
        </span>
      ),
    },
  ];
}

export function getAMLogsColumns(
  groups: ADGroup[],
  getName: (email: string | undefined) => string,
): TableColumn<DBAMLog>[] {
  return [
    {
      dataIndex: 'timestamp',
      title: 'Timestamp',
      format: (v) => prettifyTimestamp(v),
      sorted: 'descending',
    },
    {
      dataIndex: 'group_id',
      title: 'Group',
      format: (v) => groups.find((g) => g.id === v)?.name || '',
    },
    {
      dataIndex: '',
      title: 'Description',
      width: 'auto',
      format: (v, r) =>
        `${r.action_by} ${r.action_type === 'add' ? 'added' : 'removed'} ${r.action_on}`,
      render: (_v, r) => (
        <span>
          {getName(r.action_by)}{' '}
          {r.action_type === 'add' ? (
            <span style={{ color: GREEN }}>added</span>
          ) : (
            <span style={{ color: RED }}>removed</span>
          )}
          {' you'}
        </span>
      ),
    },
  ];
}

export const ADGroupColumns: TableColumn<ADGroupWithTags>[] = [
  { dataIndex: 'id', title: 'ID' },
  {
    dataIndex: 'name',
    title: 'Group name',
    sorted: 'ascending',
    sorter: (a, b) => {
      if (a.name.includes('Administration') && !b.name.includes('Administration')) {
        return 1;
      }
      if (!a.name.includes('Administration') && b.name.includes('Administration')) {
        return -1;
      }
      if (a.name.includes('DataAdmin') && !b.name.includes('DataAdmin')) {
        return 1;
      }
      if (!a.name.includes('DataAdmin') && b.name.includes('DataAdmin')) {
        return -1;
      }
      return a.name.localeCompare(b.name);
    },
  },
  { dataIndex: 'description', title: 'Description', width: 'auto' },
  {
    dataIndex: 'tags',
    title: 'Tags',
    render: (v: ADGroupWithTags['tags']) => (
      <div style={{ display: 'flex', columnGap: 4 }}>
        {v.map((t) => (
          <Tag key={t}>{t}</Tag>
        ))}
      </div>
    ),
  },
];

export const ADGroupMemberColumns: TableColumn<ADGroupMember>[] = [
  { dataIndex: 'name', title: 'Name' },
  { dataIndex: 'email', title: 'Email', width: 'auto' },
];

export function getVirtualGroupMemberColumns(input: {
  onRemoveMember: (email: string) => void;
  isWorking: boolean;
}): TableColumn<VirtualGroupMember>[] {
  const { isWorking, onRemoveMember } = input;

  return [
    { dataIndex: 'name', title: 'Name', sorted: 'ascending' },
    { dataIndex: 'email', title: 'Email', width: 'auto' },
    {
      id: 'action', // need this because missingGroupIds is already used to identity above column
      title: '',
      dataIndex: 'email',
      render: (email: string) =>
        isDev() ? (
          <div />
        ) : (
          <IoPersonRemove
            style={{ cursor: 'pointer', color: RED }}
            onClick={() => {
              if (isWorking) {
                return;
              }
              onRemoveMember(email);
            }}
          />
        ),
    },
  ];
}

export const VirtualGroupsColumns: TableColumn<Unpacked<typeof VIRTUAL_GROUPS>>[] = [
  { title: 'Group name', dataIndex: 'name' },
  { title: 'Description', dataIndex: 'description' },
];

export function getAllUsersColumns(
  getName: (email: string | undefined) => string,
): TableColumn<Unpacked<AMGetAllUsersEndpointResponse>>[] {
  return [
    {
      title: 'Name',
      dataIndex: 'email',
      id: 'name',
      render: (v: string) => getName(v),
      sorted: 'ascending',
    },
    { title: 'Email', dataIndex: 'email' },
    {
      title: 'Groups',
      dataIndex: 'virtualGroups',
      format: (v: string[]) => v.join(', '),
    },
  ];
}
