import { useContext, useMemo, useState } from 'react';
import { PackagingStandardCostsRow } from '../lib/packaging.types';
import { Unpacked } from '@core/util/util.typing';
import { UsePackagingCostsRowsReturnValue } from '../lib/usePackagingCostsRows';
import { getPackagingStandardCostsColumns } from '../lib/packaging.columns';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { ReportStatement } from '@core/calculator/calculator.util.report';
import { Table } from '@frontend/table/table';
import { GREEN, GREEN_BUTTON_STYLES } from '@frontend/common/lib/common.styles';
import { ExportButton } from '@frontend/common/components/ExportButton';
import { Button } from '@lego/klik-ui';
import { showInternalApplicationError } from '@frontend/common/lib/functions';
import { DeleteBold, PlusBold } from '@lego/klik-ui/icons';
import { PackagingStandardCostsEndpoint } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { ImportFromRevisionButton } from './ImportFromRevisionButton';
import { TOTAL_KEY } from '@core/const/const.TOTAL_KEY';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';

interface PackagingStandardCostsTableProps {
  productId: number | undefined;
  maxRevision: number | undefined;
  revision: number | undefined;
  packagingStandardCostsRows: PackagingStandardCostsRow[];
  packagingStandardCostsTotal: UsePackagingCostsRowsReturnValue['standardTotals'];
  packagingStandardCostsHandlers: UsePackagingCostsRowsReturnValue['standardHandlers'] | undefined;
  readOnly: boolean;
  setReport(report: { header: string; statements: ReportStatement[] } | undefined): void;
}

export function PackagingStandardCostsTable(props: PackagingStandardCostsTableProps) {
  const [selectedRow, setSelectedRow] = useState<PackagingStandardCostsRow>();

  const { userData, showReport } = useContext(UserDataContext);

  const shownRows = useMemo(() => {
    const row: Unpacked<typeof props.packagingStandardCostsRows> = {
      key: TOTAL_KEY,
      description: 'Total',
      fmc1manualCost: 0,
      fmc2manualCost: 0,
      fmc1cost: props.packagingStandardCostsTotal.cost.fmc1,
      fmc2cost: props.packagingStandardCostsTotal.cost.fmc2,
      fmc1quantity: props.packagingStandardCostsTotal.quantity.fmc1,
      fmc2quantity: props.packagingStandardCostsTotal.quantity.fmc2,
      currency: 'DKK',
      custom: false,
      report: [],
    };

    return props.packagingStandardCostsRows.concat(row);
  }, [props]);

  const columns = useMemo(
    () =>
      getPackagingStandardCostsColumns({
        commaAsDecimalSeperator: !!userData?.comma_as_decimal_seperator,
        onReportSelect: props.setReport,
        packagingStandardCostsHandlers: props.readOnly
          ? undefined
          : props.packagingStandardCostsHandlers,
        showReport,
      }),
    [
      props.setReport,
      props.packagingStandardCostsHandlers,
      userData?.comma_as_decimal_seperator,
      props.readOnly,
      showReport,
    ],
  );

  async function fetchAndUpdatePackagingStandardRows(productId: number, revision: number) {
    if (!props.productId) {
      showInternalApplicationError();
      return;
    }

    const [err, rows] = await callEndpoint({
      endpoint: PackagingStandardCostsEndpoint,
      input: { productId: convertProductIdTo8digits(productId), revision },
      errorHandling: { header: 'Importing packaging standard costs' },
    });

    if (err) return;

    props.packagingStandardCostsHandlers?.importRows(rows);
  }

  return (
    <Table
      id="packaging_packaging-costs_standard"
      onPaste={props.packagingStandardCostsHandlers?.onPaste}
      headerContent={
        <div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 18,
              marginBottom: 8,
              color: GREEN,
            }}
          >
            Standard
          </div>
          <div style={{ display: 'flex', columnGap: 8, alignItems: 'center' }}>
            <ExportButton rows={shownRows} columns={columns} />
            {!props.readOnly && (
              <>
                <Button
                  size="sm"
                  variant="outline"
                  style={GREEN_BUTTON_STYLES}
                  leftIcon={<PlusBold />}
                  onClick={() => {
                    if (!props.packagingStandardCostsHandlers?.addRow) {
                      showInternalApplicationError();
                      return;
                    }
                    props.packagingStandardCostsHandlers.addRow();
                  }}
                >
                  Add row
                </Button>
                <Button
                  disabled={!selectedRow || !selectedRow.custom}
                  size="sm"
                  variant="outline"
                  colorScheme="error"
                  leftIcon={<DeleteBold />}
                  onClick={() => {
                    if (!selectedRow) {
                      return;
                    }
                    if (!props.packagingStandardCostsHandlers?.deleteRow) {
                      showInternalApplicationError();
                      return;
                    }
                    props.packagingStandardCostsHandlers.deleteRow(selectedRow.key);
                    setSelectedRow(undefined);
                  }}
                >
                  Remove row
                </Button>
                {!props.readOnly && props.productId && (
                  <ImportFromRevisionButton
                    productId={props.productId}
                    fetchRows={fetchAndUpdatePackagingStandardRows}
                  />
                )}
              </>
            )}
          </div>
        </div>
      }
      rows={shownRows}
      removeInfoText
      onRowClick={props.readOnly ? undefined : setSelectedRow}
      removeSearch
      rowHasError={(r) => !!r.report.find((s) => s.isError)}
      selectedRow={props.readOnly ? undefined : selectedRow}
      rowKey="key"
      rowIsDisabled={(r) => r.key === TOTAL_KEY}
      columns={columns}
    />
  );
}
