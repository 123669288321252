import { OMIT_DB_RECORD } from '@core/schemas/const/schema.const.OMIT_DB_RECORD';
import { DBRecord } from '@core/schemas/db/schema.db.common';
import { PK } from '@core/types/types.pk';
import { CustomZodSubSchema, InputType, SchemaPropDescription } from '@core/types/types.schema';
import { z } from 'zod';

export function createDescription(description: Partial<SchemaPropDescription>) {
  return JSON.stringify(description);
}

export function getDescription(schema: z.ZodObject<any>, prop: string): SchemaPropDescription {
  const description = schema.shape[prop]?.description;

  if (!description) {
    return {
      supportLov: false,
      isPartOfKey: false,
    };
  }

  const parsedDescription = JSON.parse(description);

  return {
    isPartOfKey: !!parsedDescription.isPartOfKey,
    supportLov: parsedDescription.supportLov === undefined ? false : parsedDescription.supportLov,
  };
}

export function getKeys(
  schema: z.ZodObject<any>,
): { key: string; description: SchemaPropDescription }[] {
  const descriptions = Object.keys(schema.shape).map((k) => ({
    key: k,
    description: getDescription(schema, k),
  }));
  return descriptions.filter((d) => d.description.isPartOfKey);
}

export class SchemaError extends Error {
  constructor(props: { pk: PK; field: string }) {
    super(`Unable to deduce field type for ${props.pk}-${props.field}`);
    this.name = 'SchemaValidationError';
  }
}

export function extractType(
  type: CustomZodSubSchema,
  optional: boolean | undefined,
  meta: { pk: PK; field: string },
): {
  typeName: 'ZodString' | 'ZodNumber' | 'ZodBoolean';
  int?: boolean;
  enumValues?: string[];
  optional: boolean;
} {
  try {
    const typeName = type._def.typeName;

    if (typeName === 'ZodLiteral') {
      const v = type._def.value;

      if (typeof v === 'string') return { typeName: 'ZodString', optional: !!optional };
      if (typeof v === 'number') return { typeName: 'ZodNumber', optional: !!optional };
      if (typeof v === 'boolean') return { typeName: 'ZodBoolean', optional: !!optional };
      throw new SchemaError(meta);
    }

    if (typeName === 'ZodOptional') {
      return extractType(type._def.innerType, true, meta);
    }

    if (typeName === 'ZodEffects') {
      return extractType(type._def.schema, optional, meta);
    }

    if (typeName === 'ZodEnum') {
      const v = type._def.values[0];

      if (typeof v === 'string')
        return { typeName: 'ZodString', enumValues: type._def.values, optional: !!optional };
      if (typeof v === 'number')
        return { typeName: 'ZodNumber', enumValues: type._def.values, optional: !!optional };
      if (typeof v === 'boolean')
        return { typeName: 'ZodBoolean', enumValues: type._def.values, optional: !!optional };
      throw new SchemaError(meta);
    }

    if (typeName === 'ZodNativeEnum') {
      const v = Object.values(type._def.values)[0];
      const vals = Object.values(type._def.values).map((v) => `${v}`);

      if (typeof v === 'string')
        return { typeName: 'ZodString', enumValues: vals, optional: !!optional };
      if (typeof v === 'number')
        return { typeName: 'ZodNumber', enumValues: vals, optional: !!optional };
      if (typeof v === 'boolean')
        return { typeName: 'ZodBoolean', enumValues: vals, optional: !!optional };
      throw new SchemaError(meta);
    }

    if (typeName === 'ZodUnion') {
      return extractType(type._def.options[0], optional, meta);
    }

    if (!['ZodString', 'ZodNumber', 'ZodBoolean'].includes(typeName)) {
      throw new SchemaError(meta);
    }

    if (typeName === 'ZodNumber') {
      return {
        typeName,
        int:
          Array.isArray(type._def.checks) &&
          !!type._def.checks.find((c: { kind: string }) => c.kind === 'int'),
        optional: !!optional,
      };
    }

    if (typeName === 'ZodBoolean') {
      return { typeName, enumValues: ['true', 'false'], optional: !!optional };
    }

    return { typeName, optional: !!optional };
  } catch (e) {
    throw new SchemaError(meta);
  }
}

type SchemaProperty = {
  key: string;
  inputType: InputType;
  enumValues: string[] | undefined;
  int: boolean;
  isPartOfKey: boolean;
  supportLov: boolean;
  isDbRecordProp: boolean;
  optional: boolean;
};

export function getSchemaProperties(schema: z.ZodObject<any>): SchemaProperty[] {
  return Object.entries(schema.shape).map(([key, type]: [string, any]) => {
    const { typeName, enumValues, int, optional } = extractType(type, undefined, {
      pk: schema.shape.PK?._def.value,
      field: key,
    });

    const { isPartOfKey, supportLov } = getDescription(schema, key);

    return {
      key,
      inputType: typeName,
      enumValues,
      int: !!int,
      isPartOfKey,
      supportLov,
      isDbRecordProp: !!OMIT_DB_RECORD[key as keyof DBRecord],
      optional,
    };
  });
}

export function getSchemaProperty(
  schema: z.ZodObject<any>,
  key: string,
): SchemaProperty | undefined {
  return getSchemaProperties(schema).find((p) => p.key === key);
}
