import { z } from 'zod';
import { DBHiddenColumnSchema } from '@core/schemas/db/schema.db.hiddenColumns';
import { defineEndpoint } from '@core/util/util.defineEndpoint';

// HiddenColumnsGetEndpoint
export const HiddenColumnsGetEndpoint = defineEndpoint({
  path: '/hidden-columns/get',
  input: z.object({}),
  response: z.array(DBHiddenColumnSchema),
});
export type HiddenColumnsGetEndpointInput = z.infer<typeof HiddenColumnsGetEndpoint.input>;
export type HiddenColumnsGetEndpointResponse = z.infer<typeof HiddenColumnsGetEndpoint.response>;

// HiddenColumnsHideEndpoint
export const HiddenColumnsHideEndpoint = defineEndpoint({
  path: '/hidden-columns/hide',
  mutation: true,
  input: z.object({
    tableId: z.string(),
    columnId: z.string(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
export type HiddenColumnsHideEndpointInput = z.infer<typeof HiddenColumnsHideEndpoint.input>;
export type HiddenColumnsHideEndpointResponse = z.infer<typeof HiddenColumnsHideEndpoint.response>;

// HiddenColumnsShowEndpoint
export const HiddenColumnsShowEndpoint = defineEndpoint({
  path: '/hidden-columns/show',
  mutation: true,
  input: z.object({
    tableId: z.string(),
    columnId: z.string(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});
export type HiddenColumnsShowEndpointInput = z.infer<typeof HiddenColumnsShowEndpoint.input>;
export type HiddenColumnsShowEndpointResponse = z.infer<typeof HiddenColumnsShowEndpoint.response>;
