import { Calculator } from '../calculator';
import { CostComponentCost } from '../calculator.types';

export function calculate_CC_sum(costComponestCosts: CostComponentCost[]): CostComponentCost {
  const fmc1cost = costComponestCosts.reduce<number | undefined>(
    (sum, curr) => Calculator.Common.Sum(sum, curr.fmc1cost),
    undefined,
  );
  const fmc2cost = costComponestCosts.reduce<number | undefined>(
    (sum, curr) => Calculator.Common.Sum(sum, curr.fmc2cost),
    undefined,
  );

  return { fmc1cost, fmc2cost };
}
