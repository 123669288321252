import { DBElemError } from '@core/schemas/db/schema.db.elements';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { CostComponent } from '@core/types/types.costComponent';

export const ElemErrorExample: OmitDBRecord<DBElemError>[] = [
  {
    product_id: 123,
    revision: 1,
    material: 123333,
    cost_comp: CostComponent.PurchasedElements,
    message: 'Some error',
  },
];
