import { DBPackagingFoilRate } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { FoilPackingKey, PackSource } from '@core/types/types.packaging';

export const PackagingFoilRateExample: OmitDBRecord<DBPackagingFoilRate>[] = [
  {
    year: 2134,
    source: PackSource.Duplo,
    model_bag: true,
    packing_key: FoilPackingKey.Doublebag,
    rate: 1.23,
    currency: 'DKK',
  },
];
