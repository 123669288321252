import { useCallback, useEffect, useRef, useState } from 'react';
import {
  getBookKey,
  logDev,
  showErrorToast,
  showInternalApplicationError,
} from '@frontend/common/lib/functions';
import { Calculator } from '@core/calculator/calculator';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { DBBICost, DBBIRate } from '@core/schemas/db/schema.db.bi';
import {
  BIRatesGetEndpoint,
  BIRatesGetSmartEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.bi';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { Region, RegionFMC } from '@core/schemas/schema.common';
import { WithOptional } from '@core/util/util.typing';
import { Message } from '@frontend/common/lib/models';
import { useMessages } from '@frontend/common/lib/hooks/useMessages';
import { FMCREGION_TO_PRETTY } from '@core/const/const.FMCREGION_TO_PRETTY';

export type Book = Omit<OmitDBRecord<DBBICost>, 'product_id' | 'revision'> & {
  _manualCost: number;
  description: string;
};
export type BookKey = Pick<Book, 'book_number' | 'fmc_region'>;
type DesignKey = Pick<
  DBBIRate,
  | 'super_design'
  | 'total_number_of_pages'
  | 'year'
  | 'cover_weight_gr'
  | 'number_of_colors_backside'
  | 'number_of_colors_frontside'
  | 'number_of_cover_pages'
  | 'paper_weight_gr'
> & { region: Region };

export type UseBooksAndRatesReturnValue = {
  books: Book[];
  isFetchingRate: boolean;
  handlers: {
    updateBookByFetchingDesign(input: {
      book: BookKey;
      design: WithOptional<DesignKey, 'super_design'>;
      onMultipleRates?(rates: (DBBIRate & { description: string })[]): void;
    }): Promise<void>;
    updateBookKeyToRateNotFound: (book: BookKey, notFound: boolean) => void;
    changeBook(bookKey: BookKey, field: keyof Book, newValue: string | number | undefined): void;
    getIsMissingRate(book: BookKey): boolean;
    addBook(): void;
    deleteBook(bookNumber: number): Promise<void>;
    copyToUs(bookNumber: number): void;
  };
  messages: Message[];
};

export function useBooksAndRates(props: {
  payloadBiCosts:
    | Omit<OmitDBRecord<DBBICost & { description: string }>, 'product_id' | 'revision'>[]
    | undefined;
  useQrCode: boolean;
  fmcYear: number | undefined;
  readOnly?: boolean;
}): UseBooksAndRatesReturnValue {
  const { fmcYear, payloadBiCosts, useQrCode, readOnly } = props;
  const [books, setBooks] = useState<Book[]>([]);
  const [isFetchingRate, setIsFetchingRate] = useState(false);
  const [bookKeyToRateNotFound, setBookKeyToRateNotFound] = useState<Record<string, boolean>>({});

  const { messages, clearAndAddMessages } = useMessages();

  useEffect(() => {
    clearAndAddMessages(
      /.*/,
      Object.entries(bookKeyToRateNotFound)
        .filter(([, notFound]) => notFound)
        .map(([key]) => {
          const [bookNumber, region] = key.split(',');

          return {
            message: `Rate could not be found for book ${bookNumber} (${
              FMCREGION_TO_PRETTY[region as RegionFMC]
            })`,
            variant: 'error',
            id: key,
          };
        }),
    );
  }, [bookKeyToRateNotFound, clearAndAddMessages]);

  const lastBookKeyToRateNotFound = useRef<typeof bookKeyToRateNotFound>({});

  const fetchDesign = useCallback(
    async (input: {
      designQuery: WithOptional<DesignKey, 'super_design'>;
      referenceBook: BookKey;
      smartFetch: boolean;
    }): Promise<
      (DBBIRate & { description: string }) | (DBBIRate & { description: string })[] | null
    > => {
      const { designQuery, referenceBook, smartFetch } = input;

      updateBookKeyToRateNotFound(referenceBook, false);

      if (!designQuery.super_design) {
        return null;
      }

      if (smartFetch) {
        setIsFetchingRate(true);
        const [error, rates] = await callEndpoint({
          endpoint: BIRatesGetSmartEndpoint,
          input: {
            pages: designQuery.total_number_of_pages,
            superDesign: designQuery.super_design,
            year: designQuery.year,
          },
          errorHandling: { disable: true },
        });
        setIsFetchingRate(false);

        if (error || rates.length === 0) {
          updateBookKeyToRateNotFound(referenceBook, true);
          return null;
        }

        if (rates.length === 1) {
          return rates[0];
        }

        return rates;
      } else {
        setIsFetchingRate(true);
        const [error, rate] = await callEndpoint({
          endpoint: BIRatesGetEndpoint,
          input: {
            superDesign: designQuery.super_design,
            pages: designQuery.total_number_of_pages,
            year: designQuery.year,
            colorsBackside: designQuery.number_of_colors_backside,
            colorsFrontside: designQuery.number_of_colors_frontside,
            coverPages: designQuery.number_of_cover_pages,
            coverWeight: designQuery.cover_weight_gr,
            paperWeight: designQuery.paper_weight_gr,
          },
          errorHandling: { disable: true },
        });
        setIsFetchingRate(false);

        if (error) {
          updateBookKeyToRateNotFound(referenceBook, true);
        }
        return rate;
      }
    },
    [],
  );

  const getUpdatedBookByFetchingDesign = useCallback(
    async (input: {
      book: Book;
      designQuery: WithOptional<DesignKey, 'super_design'>;
      hasQrCode: boolean;
      onMultipleRates?(rates: (DBBIRate & { description: string })[]): void;
    }): Promise<Book | null> => {
      const { book, designQuery, hasQrCode, onMultipleRates } = input;

      const designOrDesigns = await fetchDesign({
        designQuery,
        referenceBook: book,
        smartFetch: !!onMultipleRates,
      });

      if (Array.isArray(designOrDesigns)) {
        if (!onMultipleRates) {
          showInternalApplicationError(
            'Multiple designs was fetched but onMultipleRates callback was not provided',
          );
          return null;
        }
        // We got multiple hits so we abort  and return list to page via callback
        onMultipleRates(designOrDesigns);
        return null;
      }

      return await getUpdatedBookFromNewDesign({
        year: designQuery.year,
        book: {
          ...book,
          // Overwrite these props from query since they have not yet been updated from the user input (setState is async)
          region: designQuery.region,
          number_of_pages: designQuery.total_number_of_pages,
          super_design: designQuery.super_design,
          cover_weight_gr: designQuery.cover_weight_gr,
          number_of_colors_backside: designQuery.number_of_colors_backside,
          number_of_colors_frontside: designQuery.number_of_colors_frontside,
          number_of_cover_pages: designQuery.number_of_cover_pages,
          paper_weight_gr: designQuery.paper_weight_gr,
        },
        design: designOrDesigns,
        hasQrCode,
      });
    },
    [fetchDesign],
  );

  useEffect(() => {
    if (!fmcYear) {
      return;
    }

    const preBooks = (payloadBiCosts || []).map((bi) => ({
      ...bi,
      _manualCost: !bi.super_design ? bi.cost : 0,
      cost: !bi.super_design ? 0 : bi.cost,
    }));

    if (readOnly) {
      setBooks(preBooks);
      return;
    }

    Promise.all(
      preBooks.map(
        async (book) =>
          await getUpdatedBookByFetchingDesign({
            book,
            designQuery: {
              year: fmcYear,
              region: book.region,
              super_design: book.super_design,
              total_number_of_pages: book.number_of_pages,
              cover_weight_gr: book.cover_weight_gr,
              number_of_colors_backside: book.number_of_colors_backside,
              number_of_colors_frontside: book.number_of_colors_frontside,
              number_of_cover_pages: book.number_of_cover_pages,
              paper_weight_gr: book.paper_weight_gr,
            },
            hasQrCode: useQrCode,
          }),
      ),
    ).then((updatedBooks) => {
      if (updatedBooks.find((b) => b === null)) {
        showInternalApplicationError();
        return;
      }
      setBooks(updatedBooks as Book[]);
    });
  }, [payloadBiCosts, useQrCode, fmcYear, getUpdatedBookByFetchingDesign, readOnly]);

  const updateBookByFetchingDesign = useCallback(
    async (input: {
      book: Book;
      design: WithOptional<DesignKey, 'super_design'>;
      onMultipleRates?(rates: (DBBIRate & { description: string })[]): void;
    }) => {
      const { book, design, onMultipleRates } = input;

      const updatedBook = await getUpdatedBookByFetchingDesign({
        book,
        designQuery: design,
        hasQrCode: useQrCode,
        onMultipleRates,
      });

      if (updatedBook === null) {
        return;
      }

      setBooks((currBooks) =>
        currBooks.map((b) => (getBookKey(b) === getBookKey(book) ? updatedBook : b)),
      );
    },
    [useQrCode, getUpdatedBookByFetchingDesign],
  );

  function changeBook(bookKey: BookKey, field: keyof Book, newValue: string | number | undefined) {
    changeBookBulk(bookKey, [field, newValue]);
  }

  function changeBookBulk(
    bookKey: BookKey,
    ...changes: [keyof Book, string | number | undefined][]
  ) {
    let errorOccurred = false;

    setBooks((currBooks) => {
      const newBooks: Book[] = JSON.parse(JSON.stringify(currBooks));

      const b = newBooks.find(
        (b) => b.book_number === bookKey.book_number && b.fmc_region === bookKey.fmc_region,
      );
      if (!b) {
        showErrorToast(
          'Application error',
          'Unable to update building instruction details: Unable to find book.',
        );
        errorOccurred = true;
        return currBooks;
      }

      for (const [field, newValue] of changes) {
        if (
          (typeof newValue === 'string' && typeof b[field] === 'number') ||
          (typeof newValue === 'number' && typeof b[field] === 'string')
        ) {
          logDev(field, b[field], newValue);
          showErrorToast('Application error: Unable to update building instruction details.');
          errorOccurred = true;
          return currBooks;
        }
      }

      changes.forEach(([field, newValue]) => {
        b[field] = newValue as never;
      });

      return newBooks;
    });

    return errorOccurred;
  }

  async function getUpdatedBookFromNewDesign(input: {
    year: number;
    book: Book;
    design: (DBBIRate & { description: string }) | null;
    hasQrCode: boolean;
  }): Promise<Book> {
    const { book, design, hasQrCode, year } = input;

    const useQr = book.book_number === 1 && hasQrCode;

    const updatedBook = { ...book };

    if (design) {
      updatedBook.cost = await Calculator.BI.BICost.Calculate({
        input: { biCost: book, hasQrCode: useQr, year },
        cache: { biRate: design },
      });

      updatedBook.super_design = design.super_design;
      updatedBook.description = design.description;
      updatedBook.number_of_pages = design.total_number_of_pages;
      updatedBook.number_of_colors_frontside = design.number_of_colors_frontside;
      updatedBook.number_of_colors_backside = design.number_of_colors_backside;
      updatedBook.number_of_cover_pages = design.number_of_cover_pages;
      updatedBook.paper_weight_gr = design.paper_weight_gr;
      updatedBook.cover_weight_gr = design.cover_weight_gr;
      updatedBook._manualCost = 0;
    } else {
      updatedBook.cost = 0;
    }

    return updatedBook;
  }

  const deleteBook = useCallback(
    async (bookNumber: number) => {
      if (!fmcYear) {
        showInternalApplicationError();
        return;
      }

      // copy to avoid reference issues when reassigning book numbers
      let newBooks: Book[] = structuredClone(
        books
          .filter((details) => details.book_number !== bookNumber)
          .sort((a, b) => a.book_number - b.book_number),
      );

      const newBookKeyToRateNotFound: typeof bookKeyToRateNotFound = {};

      let currBookNumber = 1;
      let regionCount = 0;
      newBooks.forEach((details) => {
        const missingRate = bookKeyToRateNotFound[getBookKey(details)];
        details.book_number = currBookNumber;
        newBookKeyToRateNotFound[getBookKey(details)] = missingRate;
        regionCount++;
        if (regionCount === ['FMC1', 'FMC2'].length) {
          regionCount = 0;
          currBookNumber++;
        }
      });

      if (lastBookKeyToRateNotFound.current !== newBookKeyToRateNotFound) {
        setBookKeyToRateNotFound(newBookKeyToRateNotFound);
        lastBookKeyToRateNotFound.current = newBookKeyToRateNotFound;
      }

      if (newBooks.length > 0 && bookNumber === 1 && useQrCode) {
        // we deleted the first book and the new first book needs to use qr rate
        newBooks = await Promise.all(
          newBooks.map(async (b) => {
            if (b.book_number !== 1) {
              return b;
            }

            return (await getUpdatedBookByFetchingDesign({
              book: b,
              designQuery: {
                year: fmcYear,
                region: b.region,
                total_number_of_pages: b.number_of_pages,
                super_design: b.super_design,
                cover_weight_gr: b.cover_weight_gr,
                number_of_colors_backside: b.number_of_colors_backside,
                number_of_colors_frontside: b.number_of_colors_frontside,
                number_of_cover_pages: b.number_of_cover_pages,
                paper_weight_gr: b.paper_weight_gr,
              },
              hasQrCode: useQrCode,
            })) as Book;
          }),
        );
      }

      setBooks(newBooks);
    },
    [books, fmcYear, getUpdatedBookByFetchingDesign, useQrCode, bookKeyToRateNotFound],
  );

  function addBook() {
    setBooks((currBooks) => {
      const newBookCount = Math.round(currBooks.length / 2) + 1;
      const activeFmcRegions: RegionFMC[] = ['FMC1', 'FMC2'];
      const newBiCosts: typeof books = currBooks.concat(
        ...activeFmcRegions.map((fmcRegion) => ({
          number_of_pages: 0,
          fmc_region: fmcRegion,
          cost: 0,
          _manualCost: 0,
          book_number: newBookCount,
          number_of_colors_frontside: 0,
          number_of_colors_backside: 0,
          number_of_cover_pages: 0,
          paper_weight_gr: 0,
          super_design: undefined,
          description: '',
          cover_weight_gr: 0,
          currency: 'DKK',
          region: (fmcRegion === 'FMC1' ? 'EU' : 'US') as Region,
          note: '',
        })),
      );

      return newBiCosts;
    });
  }

  function getIsMissingRate(book: BookKey) {
    return !!bookKeyToRateNotFound[getBookKey(book)];
  }

  function updateBookKeyToRateNotFound(book: BookKey, notFound: boolean) {
    setBookKeyToRateNotFound((curr) => {
      const newState = { ...curr };
      newState[getBookKey(book)] = notFound;
      return newState;
    });
  }

  function copyToUs(bookNumber: number) {
    const euBook = books.find((b) => b.book_number === bookNumber && b.fmc_region === 'FMC1');
    const usBook = books.find((b) => b.book_number === bookNumber && b.fmc_region === 'FMC2');

    if (!euBook || !usBook || !props.fmcYear) {
      showInternalApplicationError();
      return;
    }

    updateBookByFetchingDesign({
      book: usBook,
      design: {
        year: props.fmcYear,
        ...usBook,
        super_design: euBook.super_design,
        total_number_of_pages: euBook.number_of_pages,
        region: usBook.region,
        cover_weight_gr: euBook.cover_weight_gr,
        number_of_colors_backside: euBook.number_of_colors_backside,
        number_of_colors_frontside: euBook.number_of_colors_frontside,
        number_of_cover_pages: euBook.number_of_cover_pages,
        paper_weight_gr: euBook.paper_weight_gr,
      },
    });
  }

  return {
    books,
    isFetchingRate,
    handlers: {
      updateBookByFetchingDesign,
      updateBookKeyToRateNotFound,
      changeBook,
      getIsMissingRate,
      addBook,
      deleteBook,
      copyToUs,
    },
    messages,
  };
}
