import { CalculatorCommonPPO, CostComponentFull } from '../calculator.types';
import { Calculator } from '../calculator';
import { calculate_CC_Packing_sum } from './calculate.CC_Packing.sum';
import {
  DBPackingStandardCost,
  DBPackingNonstandardCost,
} from '@core/schemas/db/schema.db.packaging';
import { round2relaxed } from '../calculator.util';

export function calculate_CC_Packing_full(props: {
  packingStandardCosts: Pick<DBPackingStandardCost, 'fmc1_cost' | 'fmc2_cost'>[];
  packingNonstandardCosts: Pick<DBPackingNonstandardCost, 'fmc1_cost' | 'fmc2_cost'>[];
  ppos: CalculatorCommonPPO | undefined;
  copyPpoToCost?: boolean;
}): CostComponentFull {
  const { ppos, packingStandardCosts, packingNonstandardCosts, copyPpoToCost } = props;

  if (copyPpoToCost) {
    return {
      fmc1cost: round2relaxed(ppos?.FMC1),
      fmc2cost: round2relaxed(ppos?.FMC2),
      fmc1ppo: round2relaxed(ppos?.FMC1),
      fmc2ppo: round2relaxed(ppos?.FMC2),
      fmc1variance: round2relaxed(ppos?.FMC1 === undefined ? undefined : 0),
      fmc2variance: round2relaxed(ppos?.FMC2 === undefined ? undefined : 0),
    };
  }

  const { fmc1cost, fmc2cost } = calculate_CC_Packing_sum(
    packingStandardCosts,
    packingNonstandardCosts,
  );

  return {
    fmc1cost: round2relaxed(fmc1cost),
    fmc2cost: round2relaxed(fmc2cost),
    fmc1ppo: round2relaxed(ppos?.FMC1),
    fmc2ppo: round2relaxed(ppos?.FMC2),
    fmc1variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC1, fmc1cost)),
    fmc2variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC2, fmc2cost)),
  };
}
